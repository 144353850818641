import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/accounts/cost_of_goods/component";
import { Loading } from "@/layouts/loading";
import { Revenue, SearchParams } from "@/screens/accounts/revenue/model";

const API_URL = import.meta.env.VITE_API_URL as string

type RevenueDeps = {
  data: Revenue[]
  overlayData: Revenue[]
}

export const Route = createFileRoute("/accounts/cost-of-goods/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { type, start_date, end_date, time_compare, moving_average, moving_average_days } }) => ({ type, start_date, end_date, time_compare, moving_average, moving_average_days }),
  loader: async (params): Promise<RevenueDeps> => {
    let overlay_data = [] as Revenue[];
    // Start and End date are unix timestamps
    let start_date = 0
    let end_date = 0
    if (params.deps.type === "CUSTOM" && params.deps.start_date && params.deps.end_date) {
      start_date = params.deps.start_date
      end_date = params.deps.end_date
    }
    if (params.deps.type === "WEEK") {
      start_date = Math.floor(Date.now() / 1000) - 604800
      end_date = Math.floor(Date.now() / 1000)
    }
    if (params.deps.type === "MONTH") {
      start_date = Math.floor(Date.now() / 1000) - 2592000
      end_date = Math.floor(Date.now() / 1000)
    }
    if (params.deps.type === "QUATER") {
      start_date = Math.floor(Date.now() / 1000) - 7776000
      end_date = Math.floor(Date.now() / 1000)
    }
    if (params.deps.type === "HALF_YEAR") {
      start_date = Math.floor(Date.now() / 1000) - 15552000
      end_date = Math.floor(Date.now() / 1000)
    }
    if (params.deps.type === "YEAR") {
      start_date = Math.floor(Date.now() / 1000) - 31536000
      end_date = Math.floor(Date.now() / 1000)
    }
    const response = await httpClient.post(API_URL + `/public/pharmacy/account/cost_of_goods`, {
      pharmacy_code: getPharmacyID(),
      start_date: start_date,
      end_date: end_date,
      show_moving_average: params.deps.moving_average,
      moving_average_window: params.deps.moving_average_days
    });

    const data = await response.data;

    if (!data.status) {
      throw new Error("API Fetch Error");
    }

    const current_data = data.data as Revenue[];

    if (params.deps.time_compare) {
      const response = await httpClient.post(API_URL + `/public/pharmacy/account/cost_of_goods`, {
        pharmacy_code: getPharmacyID(),
        start_date: start_date - (end_date - start_date),
        end_date: start_date,
      });

      const data = await response.data;

      if (!data.status) {
        throw new Error("API Fetch Error");
      }

      overlay_data = data.data as Revenue[];
    }

    return {
      data: current_data,
      overlayData: overlay_data
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1
});
