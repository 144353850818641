import { LucideProps } from 'lucide-react'
import React from 'react'

const Details = ({
    icon,
    title,
    text
}: {
    icon: any,
    title: string,
    text: string
}) => {
    return (
        <div className='flex flex-col items-center'>
            <div className='text-gray-500 mb-2'>{icon}</div>
            <p className='text-sm text-gray-500'>{title}</p>
            <p className='text-2xl font-semibold'>{text}</p>
        </div>
    )
}

export default Details