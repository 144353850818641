import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const Supplier = z.object({
  id: z.string(),
  name: z.string(),
  pharmacy_code: z.string(),
  created_at: z.string(),
  metadata: z.object({
    phone_number: z.string(),
    contact_person_name: z.string(),
  }),
  credit_terms: z.object({
    is_credit_allowed: z.boolean(),
    credit_period: z.number(),
    monthly_payment_date: z.number(),
  }),
  full_count: z.number().optional(),
})

export const SupplierSearchParams = z.object({
  page: z.number().int().default(1),
  pageSize: z.number().int().default(10),
  search: z.string().optional(),
})

export type Supplier = z.infer<typeof Supplier>
export type SupplierSearchParams = z.infer<typeof SupplierSearchParams>