import React, { useState } from 'react'

import { BadgeCheck, BadgeXIcon, CreditCard, X } from 'lucide-react'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { convertToCurrencyRounded } from '@/utils/currency_converter'
import { FinalSalesData, InventoryCombinedData, SalesRowData } from '../models/inventoryData'
import { Button } from '@/components/ui/button'
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useNavigate } from '@tanstack/react-router'
import { Input } from '@/components/ui/input'
import { makeSaleByCash, makeSaleByCard, makeSaleBySplit } from '../api'
import AttachPatient from './attachPatient'
import { PatientMinimal } from '@/screens/patients/components/data/schema'
import {useLiveQuery} from 'dexie-react-hooks'
import db, { SalesItems, SalesItemsBatches } from '@/db'

const Component1 = ({ head, foot }: any) => {
    return (
        <span className='flex items-center mx-2'>
            <p className='text-sm font-semibold mr-8'>{head}</p>
            <p className='text-gray-400 text-sm'>{foot}</p>
        </span>
    )
}

type SaleItemSQLData = {
    id: number,
    sale_id: number,
    item_id: string,
    unit: number,
    unit_type: string,
    barcode: string,
    trade_name: string,
    quantity_added: string,
    item_discount: string,
    type: string
}

type SaleItemBatchSQLData = {
    id: number,
    sale_id: number,
    item_id: string,
    inventory_id: string,
    batch_number: string,
    expiry_date: string,
    cost: string,
    price: string,
    available_stock: string,
    sold_quantity: string,
    price_history_id: string,
    vat: string,
    type: string,
    is_selected: boolean
}

const formData = (currentSaleItems: SalesItems[] | undefined, currentSaleItemsBatchesSelected: SalesItemsBatches[] | undefined): FinalSalesData[] => {
    if (currentSaleItems && currentSaleItemsBatchesSelected) {
        const finalData: FinalSalesData[] = currentSaleItems.map((item) => {
            const batchInfo = currentSaleItemsBatchesSelected.filter((batch) => batch.item_id === item.item_id && batch.type === item.type)
            return {
                id: item.item_id,
                trade_name: item.trade_name,
                barcode: item.barcode!,
                unit: item.unit,
                unit_type: item.unit_type,
                type: item.type === "MEDICAL" ? "MEDICAL" : "NON_MEDICAL",
                inventory_id: batchInfo[0].inventory_id,
                batch_number: batchInfo[0].batch_number,
                expiry_date: batchInfo[0].expiry_date!,
                cost: batchInfo[0].cost,
                price: batchInfo[0].price,
                available_stock: batchInfo[0].available_stock,
                sold_quantity: batchInfo[0].sold_quantity,
                price_history_id: batchInfo[0].price_history_id,
                vat: batchInfo[0].vat,
                quantity_added: item.quantity_added,
                item_discount: item.item_discount
            }
        })
        return finalData
    } else {
        throw new Error("No items selected")
    }
}

const Footer = ({
    total,
    discount = 0,
    vat,
    internalSalesId,
    checkoutButtonRef
}: { 
    total: number,
    discount: number,
    vat: number,
    internalSalesId: number,
    checkoutButtonRef: React.RefObject<HTMLButtonElement>
}) => {
    const [openCheckout, setOpenCheckout] = React.useState<boolean>(false)
    const [tabState, setTabState] = React.useState<string>('cash')

    //Common
    const [addDiscount, setAddDiscount] = useState<number>(discount)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    //Cash
    const [cashReceived, setCashReceived] = useState<number | null>(null)

    //Card
    const [approvalCode, setApprovalCode] = useState<string>("")
    const [last4digits, setLast4digits] = useState<string>("")
    
    //Split
    const [cardAmount, setCardAmount] = useState<number | null>(null)

    //Patient
    const [patientInfo, setPatientInfo] = useState<PatientMinimal>()

    React.useEffect(() => {
        setAddDiscount(discount);
    }, [discount]);


    // const currentSaleItems = useLiveQuery<SaleItemSQLData>(`SELECT * FROM sales_items WHERE sale_id = $1`, [internalSalesId])
    // const currentSaleItemsBatchesSelected = useLiveQuery<SaleItemBatchSQLData>(`SELECT * FROM sales_items_batches WHERE sale_id = $1 AND is_selected = TRUE`, [internalSalesId]);

    const currentSaleItems = useLiveQuery(() => db.sales_items.where('sale_id').equals(internalSalesId).toArray())
    const currentSaleItemsBatchesSelected = useLiveQuery(() => db.sales_items_batches.where('sale_id').equals(internalSalesId).and((item) => item.is_selected === true).toArray())

    return (
        <div className='flex justify-between items-center bg-[#F6F6F6] sticky bottom-0 rounded-2xl h-[50px]'>
            <div className='flex'>
                <Component1 
                    head='Co-Pay'
                    foot={convertToCurrencyRounded(0)}
                />
                <Component1 
                    head='Disc.'
                    foot={convertToCurrencyRounded(addDiscount)}
                />
                <Component1 
                    head='Net Amt.'
                    foot={convertToCurrencyRounded(total - addDiscount - vat)}
                />
                <Component1 
                    head='VAT'
                    foot={convertToCurrencyRounded(vat)}
                />
            </div>

            <div className='bg-[#F6F6F6] flex space-x-2'>
                <Component1 
                    head='Total Amount'
                    foot={convertToCurrencyRounded(total - addDiscount)}
                />
                <Sheet  
                    open={openCheckout}
                >
                    <SheetTrigger asChild>
                        <Button
                            className='bg-blue-700 px-6 rounded-none rounded-r-2xl'
                            size={'lg'}
                            onClick={() => setOpenCheckout(true)}
                            ref={checkoutButtonRef}
                        >
                            <BadgeCheck className='mr-2' />
                            Checkout
                        </Button>
                    </SheetTrigger>
                    <SheetContent
                        side={'bottom'}
                        className=''
                    >
                        <SheetHeader className='border-b-[0.5px] pb-4'>
                            <SheetTitle>Checkout</SheetTitle>
                            <Button
                                variant={'ghost'} 
                                className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                                onClick={() => setOpenCheckout(false)}
                            >
                                <X className="h-4 w-4" />
                                <span className="sr-only">Close</span>
                            </Button>
                        </SheetHeader>
                        <div className='my-4'>
                            <Tabs 
                                defaultValue={tabState}
                                onValueChange={(value) => setTabState(value)}
                                className='grid place-items-center'
                            >
                                <TabsList className='border-b-2'>
                                    <TabsTrigger value="cash" className='w-[150px]'>Cash</TabsTrigger>
                                    <TabsTrigger value="card" className='w-[150px]'>Card</TabsTrigger>
                                    <TabsTrigger value="split" className='w-[150px]'>Split</TabsTrigger>
                                </TabsList>
                                <hr className='h-[10px] w-screen my-4'/>
                                <TabsContent value="cash" className=''>                                    
                                    <div className='grid grid-cols-2 divide-x-2 w-screen'>
                                        <div className='mx-auto w-1/2'>
                                            <div className='flex flex-col items-center justify-center'>
                                                <p className='text-gray-600 font-semibold'>Total for {currentSaleItems?.length} item(s)</p>
                                                <div
                                                    className='border rounded-lg px-4 py-2 text-2xl font-bold'
                                                >{convertToCurrencyRounded(total - addDiscount)}</div>

                                                <div className='grid grid-cols-3 divide-x-2 place-content-center mt-4'>
                                                    <div className='flex flex-col items-center justify-center px-4'>
                                                        <p className='text-gray-600 text-sm'>Cash Received</p>
                                                        <p className='font-semibold'>{convertToCurrencyRounded(cashReceived!)}</p>
                                                    </div>
                                                    <div className='flex flex-col items-center justify-center'>
                                                        <p className='text-gray-600 text-sm'>{(cashReceived! + addDiscount!) >= total ? "Change" : "Balance"}</p>
                                                        <p className='font-semibold'>{convertToCurrencyRounded(cashReceived! + addDiscount! - total, false)}</p>
                                                    </div>
                                                    <div className='flex flex-col items-center justify-center'>
                                                        <p className='text-gray-600 text-sm'>Discount</p>
                                                        <p className='font-semibold'>{convertToCurrencyRounded(addDiscount!)}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='border-t-[0.5px] mt-4'>
                                                <p className='text-sm font-semibold mt-4 mb-2'>Cash Received<span className='text-red-400'>*</span></p>
                                                <Input 
                                                    placeholder='45.00'
                                                    value={cashReceived!}
                                                    type='number'
                                                    onChange={e => setCashReceived(e.target.value ? parseFloat(e.target.value) : null)}
                                                />
                                            </div>
                                        </div>

                                        <AttachPatient setPatientInfo={setPatientInfo} patientInfo={patientInfo}                                         
                                        />
                                    </div>
                                </TabsContent>
                                <TabsContent value="card">
                                    <div className='grid grid-cols-2 divide-x-2 w-screen'>
                                        <div className='mx-auto w-1/2'>
                                            <div className='flex flex-col items-center justify-center'>
                                                <p className='text-gray-600 font-semibold'>Total for {currentSaleItems?.length} item(s)</p>
                                                <div
                                                    className='border rounded-lg px-4 py-2 text-2xl font-bold'
                                                >{convertToCurrencyRounded(total - addDiscount)}</div>
                                            </div>

                                            <div className='border-t-[0.5px] mt-4'>
                                                <p className='text-sm font-semibold mt-4 mb-2'>Approval Code<span className='text-red-400'>*</span></p>
                                                <Input 
                                                    placeholder='16AC'
                                                    value={approvalCode}
                                                    onChange={e => setApprovalCode(e.target.value)}
                                                />

                                                <p className='text-sm font-semibold mt-4 mb-2'>Last 4 Digits<span className='text-red-400'>*</span></p>
                                                <Input 
                                                    placeholder='5678'
                                                    value={last4digits}
                                                    type='number'
                                                    onChange={e => setLast4digits(e.target.value)}
                                                />

                                                
                                            </div>
                                        </div>

                                        <AttachPatient setPatientInfo={setPatientInfo} patientInfo={patientInfo}
                                        />

                                    </div>
                                </TabsContent>
                                <TabsContent value="split">
                                    <div className='grid grid-cols-2 divide-x-2 w-screen'>
                                        <div className='mx-auto w-1/2'>
                                            <div className='flex flex-col items-center justify-center'>
                                                <p className='text-gray-600 font-semibold'>Total</p>
                                                <div
                                                    className='border rounded-lg px-4 py-2 text-2xl font-bold'
                                                >{convertToCurrencyRounded(total - addDiscount)}</div>

                                                <div className='grid grid-cols-2 gap-x-4 mt-4'>
                                                    <div>
                                                        <p className='text-gray-600 font-semibold text-sm text-center'>Card Amount</p>
                                                        <div
                                                            className='border rounded-lg px-4 py-2 text-lg font-bold'
                                                        >{convertToCurrencyRounded(cardAmount!)}</div>
                                                    </div>
                                                    <div>
                                                        <p className='text-gray-600 font-semibold text-sm text-center'>Total cash to be collected</p>
                                                        <div
                                                            className='border rounded-lg px-4 py-2 text-lg font-bold'
                                                        >{convertToCurrencyRounded(total - addDiscount - cardAmount!)}</div>
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div>
                                                    <p className='text-gray-600 font-semibold text-sm text-center'>{(cashReceived! + discount! + cardAmount!) >= total ? "Change" : "Balance"}</p>
                                                    <div
                                                        className='border rounded-lg px-4 py-2 text-lg font-bold'
                                                    >{convertToCurrencyRounded(cashReceived! + discount! + cardAmount! - total, false)}</div>
                                                </div>
                                            </div>

                                            <div className='grid grid-cols-2 gap-x-4 divide-x-2 border-t-[0.5px] mt-4 pt-4'>
                                                <div className='px-4'>
                                                    <p className='text-sm font-semibold mt-4 mb-2'>Card Amount<span className='text-red-400'>*</span></p>
                                                    <Input 
                                                        placeholder='45.00'
                                                        type='number'
                                                        value={cardAmount!}
                                                        onChange={e => setCardAmount(e.target.value ? parseFloat(e.target.value) : null)}
                                                    />

                                                    <p className='text-sm font-semibold mt-4 mb-2'>Approval Code<span className='text-red-400'>*</span></p>
                                                    <Input 
                                                        placeholder='16CF'
                                                        value={approvalCode}
                                                        onChange={e => setApprovalCode(e.target.value.toUpperCase())}
                                                    />

                                                    <p className='text-sm font-semibold mt-4 mb-2'>Last 4 Digits<span className='text-red-400'>*</span></p>
                                                    <Input 
                                                        placeholder='5678'
                                                        type='number'
                                                        value={last4digits}
                                                        onChange={e => setLast4digits(e.target.value)}
                                                    />
                                                </div>
                                                <div className='px-4'>
                                                    <p className='text-sm font-semibold mt-4 mb-2'>Cash Received<span className='text-red-400'>*</span></p>
                                                    <Input 
                                                        placeholder='45.00'
                                                        type='number'
                                                        value={cashReceived!}
                                                        onChange={e => setCashReceived(e.target.value ? parseFloat(e.target.value) : null)}
                                                    />

                                                    
                                                </div>                                            
                                            </div>
                                        </div>

                                        <AttachPatient setPatientInfo={setPatientInfo} patientInfo={patientInfo}
                                        />
                                    </div>
                                </TabsContent>
                            </Tabs>
                        </div>
                        <SheetFooter className='w-full border-t-[0.5px] pt-4 mt-2'>
                            <div className='flex w-screen items-center justify-between'>
                                <p>{patientInfo ? patientInfo.name + " | " + patientInfo.phone + " | " + patientInfo.emirates_id : "OTC Patient"}</p>
                                <div>
                                    <Button
                                        className='px-12 py-4'
                                        variant={'outline'}
                                        size={'lg'}
                                        onClick={() => setOpenCheckout(false)}
                                    >
                                        <BadgeXIcon className='h-4 w-4 mr-2' />
                                        Cancel
                                    </Button>

                                    <Button
                                        className='bg-blue-700 ml-4 px-12 py-4'
                                        size={'lg'}
                                        onClick={async () => {
                                            if(tabState === 'cash') {
                                                setLoading(true)
                                                const salesItems: FinalSalesData[] = formData(currentSaleItems!, currentSaleItemsBatchesSelected)
                                                const salesId = await makeSaleByCash(salesItems, cashReceived!, addDiscount!, total - addDiscount, vat, patientInfo ? patientInfo.id : undefined)
                                                await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                    await tx.sales_items.where('sale_id').equals(internalSalesId).delete()
                                                    await tx.sales_items_batches.where('sale_id').equals(internalSalesId).delete()
                                                })
                                                await db.transaction('rw', db.active_sales, async (tx) => {
                                                    const activeNumberOfSales = await tx.active_sales.count()
                                                    if (activeNumberOfSales === 1) {
                                                        await tx.active_sales.clear()
                                                        await tx.active_sales.add({ created_at: new Date(), selected: true })
                                                    } else {
                                                        await tx.active_sales.where('id').equals(internalSalesId).delete()
                                                    }
                                                })
                                                navigate({
                                                    to: `/receipt/$saleId`,
                                                    params: { saleId: salesId },
                                                    search: { return_to: "sales" }
                                                })
                                                setLoading(false)
                                            } else if(tabState === 'card') {
                                                setLoading(true)
                                                const salesItems: FinalSalesData[] = formData(currentSaleItems, currentSaleItemsBatchesSelected)
                                                const salesId = await makeSaleByCard(salesItems, addDiscount!, total - addDiscount, vat, last4digits, approvalCode, patientInfo ? patientInfo.id : undefined)
                                                await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                    await tx.sales_items.where('sale_id').equals(internalSalesId).delete()
                                                    await tx.sales_items_batches.where('sale_id').equals(internalSalesId).delete()
                                                })
                                                await db.transaction('rw', db.active_sales, async (tx) => {
                                                    const activeNumberOfSales = await tx.active_sales.count()
                                                    if (activeNumberOfSales === 1) {
                                                        await tx.active_sales.clear()
                                                        await tx.active_sales.add({ created_at: new Date(), selected: true })
                                                    } else {
                                                        await tx.active_sales.where('id').equals(internalSalesId).delete()
                                                    }
                                                })
                                                navigate({
                                                    to: `/receipt/$saleId`,
                                                    params: { saleId: salesId },
                                                    search: { return_to: "sales" }
                                                })
                                                setLoading(false)
                                            } else {
                                                setLoading(true)
                                                const salesItems: FinalSalesData[] = formData(currentSaleItems, currentSaleItemsBatchesSelected)
                                                const salesId = await makeSaleBySplit(salesItems, addDiscount!, total - addDiscount, vat, last4digits, approvalCode, cardAmount!, cashReceived!, patientInfo ? patientInfo.id : undefined)
                                                await db.transaction('rw', db.sales_items, db.sales_items_batches, async (tx) => {
                                                    await tx.sales_items.where('sale_id').equals(internalSalesId).delete()
                                                    await tx.sales_items_batches.where('sale_id').equals(internalSalesId).delete()
                                                })
                                                await db.transaction('rw', db.active_sales, async (tx) => {
                                                    const activeNumberOfSales = await tx.active_sales.count()
                                                    if (activeNumberOfSales === 1) {
                                                        await tx.active_sales.clear()
                                                        await tx.active_sales.add({ created_at: new Date(), selected: true })
                                                    } else {
                                                        await tx.active_sales.where('id').equals(internalSalesId).delete()
                                                    }
                                                })
                                                navigate({
                                                    to: `/receipt/$saleId`,
                                                    params: { saleId: salesId },
                                                    search: { return_to: "sales" }
                                                })
                                                setLoading(false)
                                            }
                                        }}
                                        disabled={loading || (tabState === 'cash' && (cashReceived === null || addDiscount === null || currentSaleItems?.length === 0 || (cashReceived! + addDiscount!) < total)) || (tabState === 'card' && (approvalCode === "" || last4digits === "" || addDiscount === null || currentSaleItems?.length === 0)) || (tabState === 'split' && (cardAmount === null || cashReceived === null || approvalCode === "" || last4digits === "" || addDiscount === null || currentSaleItems?.length === 0 || ((cashReceived! + addDiscount! + cardAmount!) < total)))}
                                    >
                                        { loading 
                                            ? 
                                                "Please Wait..." 
                                            : 
                                            <>
                                                <BadgeCheck className='h-4 w-4 mr-2' />
                                                Checkout
                                            </>
                                        }
                                        
                                    </Button>
                                </div>
                            </div>
                        </SheetFooter>
                    </SheetContent>
                </Sheet>
            </div>
        </div>
    )
}

export default Footer