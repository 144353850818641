import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { UsersListSchema } from "./types";
import { Route as userListRoute } from "@/routes/settings/index";
const AddMember = () => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button variant="outline">Add User</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Member</DialogTitle>
          <DialogDescription>
            This action cannot be undone. This will permanently delete your
            account and remove your data from our servers.
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

const Manage = () => {
  const data = userListRoute.useLoaderData();
  const [usersList, setUsersList] = useState<UsersListSchema[]>(data.users);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!usersList.length) {
      setError("Failed to load users");
    }
  }, [usersList]);

  return (
    <>
      <div>
        <h2 className="text-2xl font-bold tracking-tight">Manage Members</h2>
        <p className="text-muted-foreground">
          Invite your team members to collaborate.
        </p>
      </div>
      <hr className="my-4" />
      {error && <p className="text-red-500">{error}</p>}
      <div className="flex gap-x-4">
        <div className="flex-1 flex flex-col gap-y-4">
          {usersList.map((user) => (
            <div
              key={user.user_info.id}
              className="flex justify-between items-center"
            >
              <div>
                <p className="text-gray-700">
                  {user.user_info.firstName} {user.user_info.lastName}
                </p>
                <p className="text-sm text-gray-500">{user.user_info.email}</p>
              </div>
              {/* <div className="flex divide-x">
                {user.roles.map((role) => (
                  <p key={role.id} className="text-gray-500 px-4">
                    {role.name}
                  </p>
                ))}
              </div> */}
            </div>
          ))}
        </div>
        <div className="rounded-lg border border-gray-700 p-4">
          <h2 className="text-xl font-bold tracking-tight">Add</h2>
          <p className="text-muted-foreground text-sm">
            Manage your settings <br /> and other preferences.
          </p>
          <div className="mt-6 grid place-items-center">
            <AddMember />
          </div>
        </div>
      </div>
    </>
  );
};

export default Manage;
