import React, { useEffect, useState } from 'react'
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "@/components/ui/dialog"
import { ChevronRight, Percent, CalendarIcon } from 'lucide-react'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { AutoComplete, Option } from "@/components/ui/autocomplete";
import { getMedicalInventoryItemDetails, getNonMedicalInventoryItemDetails } from '../api'
import { MedicalInventoryItemData, NonMedicalInventoryItemData, OfferInfoRequestSchema } from '../model'
import { toast } from '@/components/ui/use-toast'
import { Loading } from '@/layouts/loading'
import dayjs from 'dayjs'
import { format } from "date-fns";
import { cn } from "@/lib/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { httpClient } from '@/httpClient'
import getPharmacyID from '@/utils/pharmacy_code'
import { SearchAllSchema } from '@/screens/create-purchase-invoice/components/data/schema'
import { useDebounce } from '@uidotdev/usehooks'
import { MedicalInventoryData, NonMedicalInventoryData, Offer } from '@/screens/sales/models/inventoryData'
import { getMedicalItem, getNonMedicalItem } from '@/screens/sales/api'
import { useRouter } from '@tanstack/react-router'

const OfferItem = ({inventory_id, type, existingOffers}: {inventory_id: string; type: "MEDICAL" | "NON_MEDICAL", existingOffers: Offer[]}) => {
  const [step, setStep] = useState(1)
  const [sameProduct, setSameProduct] = useState(false)
  const [itemData, setItemData] = useState<MedicalInventoryItemData | NonMedicalInventoryItemData | null>(null)
  const [loading, setLoading] = useState(false)
  const [offerInfo, setOfferInfo] = useState<OfferInfoRequestSchema>({
    offer_name: "",
    benefit_type: "DiscountPercentage",
    benefit_discount_percentage: "",
    benefit_free_items: 0,
    condition_quantity_required: 0,
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: "",
    benefit_medical_inventory_id: "",
    benefit_non_medical_inventory_id: "",
    pharmacy_code: getPharmacyID(),
    condition_medical_inventory_id: type === "MEDICAL" ? inventory_id : "",
    condition_non_medical_inventory_id: type === "NON_MEDICAL" ? inventory_id : "",
  })

  const [showAddOffer, setShowAddOffer] = useState(existingOffers.length === 0)

  const router = useRouter()

  useEffect(() => {
    // Fetch data based on the inventory_id and type
    async function getMedicalInventoryData() {
      const res = await getMedicalInventoryItemDetails(inventory_id)
      setItemData(res)
    }

    async function getNonMedicalInventoryData() {
      const res = await getNonMedicalInventoryItemDetails(inventory_id)
      setItemData(res)
    }

    if (inventory_id && type) {
      // Fetch data here
      try {
        setLoading(true)
        if (type === "MEDICAL") {
          getMedicalInventoryData()
        } else {
          getNonMedicalInventoryData()
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toast({
          description: "Failed to fetch inventory data",
          title: "Error",
          duration: 5000,
        })
      }
    }
  }, [inventory_id, type])

  const [results, setResults] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [batches, setBatches] = useState<{
    medical: MedicalInventoryData[];
    non_medical: NonMedicalInventoryData[];
  }>({
    medical: [],
    non_medical: [],
  });

  useEffect(() => {
    try {
      offerInfo && setOfferInfo({
        ...offerInfo,
        [type === "MEDICAL" ? "benefit_medical_inventory_id" : "benefit_non_medical_inventory_id"]: undefined,
      })
      const getMeds = async () => {
        if (debouncedSearchTerm.length === 0) {
          setResults([]);
          return;
        }
        if (debouncedSearchTerm.length < 3) return;

        const response = await httpClient.post(
          `${import.meta.env.VITE_API_URL as string
          }/public/pharmacy/search/available`,
          {
            pharmacy_code: getPharmacyID(),
            query: debouncedSearchTerm,
            limit: 20,
          }
        );
        const data = response.data.data as SearchAllSchema[];
        setResults(data.map((item) => ({ label: item.trade_name + " " + item.unit_type + ":::" + item.manufacturer + ":::" + item.owner, value: item.id, type: item.type, vat: item.vat.toString(), trade_name: item.trade_name })));
      };
      getMeds();
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to search",
        duration: 5000,
      });
    }
  }, [debouncedSearchTerm]);

  return (
    <Dialog>
      <DialogTrigger
      >
        <Percent className="h-4 w-4" />
      </DialogTrigger>
      <DialogContent className="z-50">
        {loading ? <Loading /> : showAddOffer ? (<>
          <div className="flex gap-x-2 mb-4">
            <div className={`font-bold px-3 py-1 rounded-full border border-gray-300 ${
              step === 1 
                ? 'bg-blue-300 text-blue-700' 
                : 'bg-gray-300 text-gray-500'
            }`}>
              1
            </div>
            <div className={`self-center ${
              step === 1 
                ? 'font-bold text-black' 
                : 'text-gray-500'
            }`}>Add Details</div>
            <ChevronRight className="self-center text-gray-500" />
            <div className={`font-bold px-3 py-1 rounded-full border border-gray-300 ${
              step === 2 
                ? 'bg-blue-300 text-blue-700' 
                : 'bg-gray-300 text-gray-500'
            }`}>
              2
            </div>
            <div className={`self-center ${
              step === 2 
                ? 'font-bold text-black' 
                : 'text-gray-500'
            }`}>Map Details</div>
            <div className="ml-auto">
              <Button 
                onClick={() => setShowAddOffer(false)}
                variant={"outline"}
              >Close</Button>
            </div>
          </div>

          {step === 1 && (
            <div>
              <h2 className="text-lg font-semibold">Add Offer Details</h2>
              <p className="text-sm text-gray-500 mb-8">This is for the selected batch {itemData?.batch_number}.</p>
              
              <div className="space-y-4">
                <div>
                  <p className="text-sm font-semibold mt-4 mb-2">Offer Name</p>
                  <Input placeholder="Offer name" value={offerInfo?.offer_name} onChange={(e) => {
                    offerInfo ? setOfferInfo({...offerInfo, offer_name: e.target.value}) : null
                  }}/>
                </div>

                <div>
                  <p className="text-sm font-semibold mt-4 mb-2">Offer Type</p>
                  <Select value={offerInfo?.benefit_type} onValueChange={(value) => {
                    offerInfo ? setOfferInfo({...offerInfo, benefit_type: value as "DiscountPercentage" | "FreeItems"}) : null
                  }}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select offer type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="DiscountPercentage">Discount Percentage</SelectItem>
                      <SelectItem value="FreeItems">Free Items</SelectItem>
                    </SelectContent>
                  </Select>
                </div>

                <div>
                  <p className="text-sm font-semibold mt-4 mb-2">Minimum Quantity Required</p>
                  <Input type="number" placeholder="Minimum quantity required" value={offerInfo?.condition_quantity_required} onChange={(e) => {
                    offerInfo && e.target.value ? setOfferInfo({...offerInfo, condition_quantity_required: parseInt(e.target.value)}) : null
                  }}/>
                </div>

                <div className='grid grid-cols-2 gap-4 mt-4 mb-2'>
                  <div>
                    <p className="text-sm font-semibold mb-2">Start Date</p>
                    <Popover
                      modal={true}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !offerInfo?.start_date && "text-muted-foreground"
                          )}
                        >
                          {offerInfo?.start_date ? (
                            format(new Date(offerInfo.start_date), "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0 z-[9999]" align="start">
                        <Calendar
                          mode="single"
                          selected={offerInfo?.start_date ? new Date(offerInfo.start_date) : undefined}
                          onSelect={(date) => {
                            setOfferInfo((prev) => {
                              if (prev) {
                                return {
                                  ...prev,
                                  start_date: date ? dayjs(date).format("YYYY-MM-DD") : ""
                                }
                              } else {
                                return offerInfo
                              }
                            })
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  </div>

                  <div>
                    <p className="text-sm font-semibold mb-2">End Date</p>
                    <Popover
                      modal={true}
                    >
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !offerInfo?.end_date && "text-muted-foreground"
                          )}
                        >
                          {offerInfo?.end_date ? (
                            format(new Date(offerInfo.end_date), "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0 z-[9999]" align="start">
                        <Calendar
                          mode="single"
                          selected={offerInfo?.end_date ? new Date(offerInfo.end_date) : undefined}
                          onSelect={(date) => {
                            setOfferInfo((prev) => {
                              if (prev) {
                                return {
                                  ...prev,
                                  end_date: date ? dayjs(date).format("YYYY-MM-DD") : ""
                                }
                              } else {
                                return offerInfo
                              }
                            })
                          }}
                          initialFocus
                          fromDate={offerInfo?.start_date ? new Date(offerInfo.start_date) : undefined}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>

                <div
                  className='mt-16 flex justify-end'
                >
                  <Button 
                      onClick={() => setStep(2)}
                      variant="ghost"
                  >Next</Button>
                </div>
              </div>
            </div>
          )}

          {step === 2 && (
            <div>
              <h2 className="text-lg font-semibold">Map Details</h2>
              <p className="text-sm text-gray-500 mb-8">This is for the selected batch {itemData?.batch_number}.</p>
              
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <input 
                    type="checkbox" 
                    id="sameProduct" 
                    className="w-4 h-4" 
                    checked={sameProduct}
                    onChange={(e) => {
                      setSameProduct(e.target.checked)
                      if (e.target.checked) {
                        offerInfo && setOfferInfo({
                          ...offerInfo,
                          [type === "MEDICAL" ? "benefit_medical_inventory_id" : "benefit_non_medical_inventory_id"]: inventory_id,
                        })
                      } else {
                        offerInfo && setOfferInfo({
                          ...offerInfo,
                          [type === "MEDICAL" ? "benefit_medical_inventory_id" : "benefit_non_medical_inventory_id"]: undefined,
                        })
                      }
                    }}
                  />
                  <label htmlFor="sameProduct" className="text-sm font-medium">Offer on same product</label>
                </div>

                <hr className="my-2" />

                {!sameProduct && (
                  <>
                    <div>
                      <p className="text-sm font-semibold mt-4 mb-2">Product</p>
                      <AutoComplete
                        placeholder="Search for product..."
                        options={results} // Add your product options here
                        emptyMessage="No products found"
                        input={searchTerm}
                        onInputChanged={(value) => {
                          setSearchTerm(value);
                        }}
                        onValueChange={async (value) => {
                          if (value) {
                            if (type === "MEDICAL") {
                              const data = await getMedicalItem(value.value)
                              setBatches({
                                medical: data,
                                non_medical: [],
                              })
                            } else if (type === "NON_MEDICAL") {
                              const data = await getNonMedicalItem(value.value)
                              setBatches({
                                medical: [],
                                non_medical: data,
                              })
                            }
                          }
                        }}
                      />
                    </div>

                    <div>
                      <p className="text-sm font-semibold mt-4 mb-2">Select Batch</p>
                      <Select value={
                        type === "MEDICAL" ? offerInfo?.benefit_medical_inventory_id : offerInfo?.benefit_non_medical_inventory_id
                      } onValueChange={(value) => {
                        offerInfo ? setOfferInfo({
                          ...offerInfo,
                          [type === "MEDICAL" ? "benefit_medical_inventory_id" : "benefit_non_medical_inventory_id"]: value as string
                        }) : null
                      }}>
                        <SelectTrigger>
                          <SelectValue placeholder="Select batch" />
                        </SelectTrigger>
                        <SelectContent>
                          {type === "MEDICAL" && batches.medical.map((batch) => (
                            <SelectItem key={batch.batch_number} value={batch.inventory_id}>{batch.batch_number}</SelectItem>
                          ))}
                          {type === "NON_MEDICAL" && batches.non_medical.map((batch) => (
                            <SelectItem key={batch.batch_number} value={batch.non_medical_inventory_id}>{batch.batch_number}</SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </>
                )}

                <div>
                  <p className="text-sm font-semibold mt-4 mb-2">{
                    offerInfo?.benefit_type === "DiscountPercentage" 
                      ? "Discount Percentage" 
                      : "Free Items"
                    }</p>
                  <Input type="text" placeholder={
                    offerInfo?.benefit_type === "DiscountPercentage" 
                      ? "Discount percentage" 
                      : "Free items"
                    } value={
                    offerInfo?.benefit_type === "DiscountPercentage" 
                      ? offerInfo?.benefit_discount_percentage 
                      : offerInfo?.benefit_free_items
                    } onChange={(e) => {
                    offerInfo && e.target.value ? setOfferInfo({...offerInfo,
                      [offerInfo.benefit_type === "DiscountPercentage" 
                        ? "benefit_discount_percentage" 
                        : "benefit_free_items"
                      ]: offerInfo.benefit_type === "DiscountPercentage"
                        ? parseFloat(e.target.value).toString()
                        : parseInt(e.target.value)
                    }) : null
                  }}/>
                </div>
              </div>

              <div className='mt-16 flex items-center justify-between'>
                <Button 
                  onClick={() => setStep(1)}
                  variant="ghost"
                >Back</Button>
                <Button 
                  className='bg-blue-700 text-white'
                  disabled={(!offerInfo?.benefit_discount_percentage && !offerInfo?.benefit_free_items) || !offerInfo?.benefit_type || !offerInfo?.condition_quantity_required || !offerInfo?.offer_name || !offerInfo?.start_date || (!sameProduct && !offerInfo?.benefit_medical_inventory_id && !offerInfo?.benefit_non_medical_inventory_id)}
                  onClick={async () => {
                    try {
                      console.log(offerInfo)
                      await httpClient.post(import.meta.env.VITE_API_URL as string + "/public/pharmacy/offers/create", offerInfo)
                      toast({
                        title: "Success",
                        description: "Offer created successfully",
                        duration: 5000,
                      });
                      router.invalidate();
                      // Click escape key to close the dialog
                      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
                    } catch (error) {
                      console.error("Error:", error);
                      toast({
                        title: "Error",
                        description: "Failed to create offer",
                        duration: 5000,
                      });
                    }
                  }}
                >Create</Button>
              </div>
            </div>
          )}
        </>) : (
          <div className="flex flex-col items-center justify-center space-y-4">
            <h2 className="text-lg font-semibold">Offers</h2>
            <p className="text-sm text-gray-500">This item has existing offers.</p>
            <Button 
              onClick={() => setShowAddOffer(true)}
              variant={"outline"}
            >Add More Offers</Button>
            {/* List of existing offers */}
            <div className="flex flex-col gap-y-2">
              {existingOffers.map((offer, index) => (
                <div key={index} className="flex flex-col gap-y-2">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">{offer.offer_name}</p>
                    <p className="text-sm text-gray-500">{offer.benefit_type === "DiscountPercentage" ? `${offer.benefit_discount_percentage}% off` : `${offer.benefit_free_item_quantity.Int32} free items`}</p>
                  </div>
                  <div className="flex items-center justify-between space-x-1">
                    <p className="text-sm text-gray-500">{dayjs(offer.offer_start).format("DD MMM YYYY")} - {offer.offer_end.Valid ? dayjs(offer.offer_end.Time).format("DD MMM YYYY") : "N/A"}</p>
                    <p className="text-sm text-gray-500">{"| " + offer.condition_quantity_required} minimum quantity</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default OfferItem
