import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type OfferItem = {
  id: string;
  medicineName: string;
  discount: string;
  status: 'active' | 'inactive' | 'draft';
};

const AddOffer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [offerItems, setOfferItems] = useState<OfferItem[]>([]);
  const [currentItem, setCurrentItem] = useState<OfferItem>({
    id: '',
    medicineName: '',
    discount: '',
    status: 'draft'
  });

  const handleAddItem = () => {
    if (currentItem.medicineName && currentItem.discount) {
      setOfferItems([...offerItems, { ...currentItem, id: Date.now().toString() }]);
      setCurrentItem({
        id: '',
        medicineName: '',
        discount: '',
        status: 'draft'
      });
    }
  };

  const handleRemoveItem = (id: string) => {
    setOfferItems(offerItems.filter(item => item.id !== id));
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button>
          Add Offers
        </Button>
      </SheetTrigger>
      <SheetContent className="w-[800px] sm:max-w-[800px]">
        <SheetHeader>
          <SheetTitle>Add New Offer</SheetTitle>
        </SheetHeader>
        
        <div className="mt-6 space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <Input
              placeholder="Medicine Name"
              value={currentItem.medicineName}
              onChange={(e) => setCurrentItem({ ...currentItem, medicineName: e.target.value })}
            />
            <Input
              placeholder="Discount (%)"
              type="number"
              value={currentItem.discount}
              onChange={(e) => setCurrentItem({ ...currentItem, discount: e.target.value })}
            />
            <Select
              value={currentItem.status}
              onValueChange={(value: 'active' | 'inactive' | 'draft') => 
                setCurrentItem({ ...currentItem, status: value })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="active">Active</SelectItem>
                <SelectItem value="inactive">Inactive</SelectItem>
                <SelectItem value="draft">Draft</SelectItem>
              </SelectContent>
            </Select>
          </div>
          
          <Button onClick={handleAddItem}>Add Item</Button>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Medicine Name</TableHead>
                <TableHead>Discount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {offerItems.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.medicineName}</TableCell>
                  <TableCell>{item.discount}%</TableCell>
                  <TableCell>
                    <span className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                      item.status === 'active' 
                        ? 'bg-green-50 text-green-700' 
                        : item.status === 'inactive'
                        ? 'bg-red-50 text-red-700'
                        : 'bg-yellow-50 text-yellow-700'
                    }`}>
                      {item.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="ghost" 
                      size="sm"
                      onClick={() => handleRemoveItem(item.id)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => {
              // Handle save logic here
              setIsOpen(false);
            }}>
              Save Offer
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default AddOffer;