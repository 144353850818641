import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/receipt/component";
import { Supplier } from "@/screens/suppliers/components/data/schema";
import { SaleOne, SearchSchemaReceipt } from "@/screens/receipt/model";
import { Loading } from "@/layouts/loading";

const API_URL = import.meta.env.VITE_API_URL as string

export const Route = createFileRoute('/receipt/$saleId')({
    component: Component,
    validateSearch: SearchSchemaReceipt,
    loader: async ({params}): Promise<SaleOne> => {
        if (!params.saleId) {
            throw new Error("Sale ID is required");
        }
        const response = await httpClient.post(API_URL + '/public/pharmacy/sales/get/one', {
            sale_id: params.saleId,
        });

        const data = await response.data;

        if (!data.status) {
            throw new Error("API Fetch Error");
        }

        return data.data as SaleOne;
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});