import { PDFBody, pdfCN, PDFHead, PDFRoot, PDFTable, PDFTableRow } from "@/components/composable-pdf/composable-pdf";
import OrderInfo from "@/screens/create-purchase-order/components/orderInfo";
import { Supplier } from "@/screens/suppliers/components/data/schema";
import { Text, View } from "@react-pdf/renderer"
import dayjs from "dayjs";

export type PurchaseInvoicePDFItemSchema = {
    trade_name: string,
    manufacturer: string,
    owner: string,
    quantity: number,
    bonus: number,
    intended_cost?: number,
}

export type PODetails = {
    order_number: string,
    supplier: {
        name: string,
        phone_number: string,
        contact_person_name: string,
    }
}

export default function OrderInvoicePDF(
    {

        pharmacyName,
        details,
        medicalItems,
        nonMedicalItems
    }: {
        nonMedicalItems: PurchaseInvoicePDFItemSchema[]
        medicalItems: PurchaseInvoicePDFItemSchema[],
        details: PODetails,
        pharmacyName: string
    }
) {
    return <PDFRoot>
        <PDFHead>
            <View style={pdfCN("flex flex-col gap-1 w-full text-[18pt] font-[700] py-5")}>
                <Text>
                    Purchase Order | {pharmacyName}
                </Text>
                <Text style={pdfCN("text-[12pt] font-[300]")}>
                    Generated on {dayjs().format("DD-MM-YYYY")}
                </Text>
            </View>
            <View style={pdfCN("flex justify-center")}>
                <View style={pdfCN("w-[50pt] h-[50pt] bg-[#d9d9d9]")}>

                </View>
            </View>
        </PDFHead>
        <PDFBody>
            <View style={pdfCN("font-[400] text-[15pt]")}>
                <Text>Order Number: {details.order_number}</Text>
            </View>
            <View style={pdfCN("my-10 flex flex-row")}>
                <View style={pdfCN("flex flex-col gap-4 pr-20")}>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Supplier name
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {details.supplier.name}
                        </Text>
                    </View>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Contact Person
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {details.supplier.contact_person_name}
                        </Text>
                    </View>
                </View>
                <View style={pdfCN("flex flex-col gap-4 border-l-2 border-l-[#d9d9d9] pl-20 mb-20")}>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Phone Number
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {details.supplier.phone_number || "N/A"}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={pdfCN("my-5 relative w-full")}>
                <View style={pdfCN("")}>
                    <Text>Medical Items:</Text>
                </View>
                {
                    medicalItems.length === 0 ? <View style={pdfCN("flex items-center justify-center text-[#d9d9d9] mt-5")}>
                        <Text>No Medical Items</Text>
                    </View> :
                        <PDFTable>
                            {/* Table Head */}
                            <PDFTableRow className="">

                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    SL No.
                                </Text>
                                <Text style={pdfCN("w-[40vw] px-2 text-center")}>
                                    Product Name
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Total Quantity
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Bonus Quantity
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Intended Cost
                                </Text>

                            </PDFTableRow>
                            {/* Table Body */}
                            {
                                medicalItems.map((item, idx) => {
                                    return <PDFTableRow>


                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {idx + 1}
                                        </Text>
                                        <Text style={pdfCN("w-[40vw] px-2 text-center")}>
                                            {item.trade_name}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.quantity}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.bonus}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.intended_cost}
                                        </Text>

                                    </PDFTableRow>
                                })
                            }
                        </PDFTable>
                }
                <View style={pdfCN("mt-5")}>
                    <Text>Non Medical Items:</Text>
                </View>
                {
                    nonMedicalItems.length === 0 ? <View style={pdfCN("flex items-center justify-center text-[#d9d9d9] mt-5")}>
                        <Text>No Medical Items</Text>
                    </View> :
                        <PDFTable>
                            {/* Table Head */}
                            <PDFTableRow className="">

                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    SL No.
                                </Text>
                                <Text style={pdfCN("w-[40vw] px-2 text-center")}>
                                    Product Name
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Total Quantity
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Bonus Quantity
                                </Text>
                                <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                    Intended Cost
                                </Text>

                            </PDFTableRow>
                            {/* Table Body */}
                            {
                                nonMedicalItems.map((item, idx) => {
                                    return <PDFTableRow>


                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {idx + 1}
                                        </Text>
                                        <Text style={pdfCN("w-[40vw] px-2 text-center")}>
                                            {item.trade_name}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.quantity}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.bonus}
                                        </Text>
                                        <Text style={pdfCN("w-[15vw] px-2 text-center")}>
                                            {item.intended_cost}
                                        </Text>

                                    </PDFTableRow>
                                })
                            }
                        </PDFTable>
                }
            </View>
        </PDFBody>
    </PDFRoot>
}