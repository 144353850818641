import {z} from 'zod';

export const SearchParams = z.object({
    type: z.enum(["WEEK", "MONTH", "QUATER", "HALF_YEAR", "YEAR", "CUSTOM"]).default("WEEK"),
    start_date: z.number().optional(),
    end_date: z.number().optional(),
    time_compare: z.boolean().default(false),
    moving_average: z.boolean().default(false),
    moving_average_days: z.number().optional(),
})

export const Revenue = z.object({
    time_interval: z.string(),
    total_revenue: z.number(),
    moving_average: z.number(),
})

export type Revenue = z.infer<typeof Revenue>

export type SearchParams = z.infer<typeof SearchParams>
