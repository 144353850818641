import { useState } from "react";
import DetailsPath from "./components/DetailsPath";
import UserDetails from "./components/UserDetails";
import ItemDetails from "./components/ItemDetails";
import QuickActions from "./components/QuickActions";

const Component: React.FC = () => {
  return (
    <div className="overflow-hidden h-full">
      <DetailsPath />
      {/* <div className="grid grid-cols-8 gap-x-4 divide-x-[0.5px]">
        <UserDetails />        
      </div> */}
      <div className="flex h-full">
        <div className="flex-0.5">
          <UserDetails />
        </div>

        <div className="flex flex-col justify-between flex-auto">
          <ItemDetails />
          <QuickActions />
        </div>
      </div>
    </div>
  );
};

export default Component;
