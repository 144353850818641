import { z } from "zod";

export const ProductProfitabilty = z.object({
  inventory_id: z.string(),
  master_id: z.string(),
  trade_name: z.string(),
  unit: z.number(),
  unit_type: z.string(),
  barcode: z.string().optional(),
  batch_number: z.string(),
  expiry_date: z.union([z.string(), z.object({
    Time: z.string(),
    Valid: z.boolean(),
  })]),
  sold_quantity: z.number(),
  selling_price: z.number(),
  cost: z.number(),
  product_profit: z.number(),
  full_count: z.number(),
});

export type ProductProfitabilty = z.infer<typeof ProductProfitabilty>;
