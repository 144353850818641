import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/inventory/pending-acceptance-inventory/component";
import { SearchParams } from "../../../screens/inventory/pending-acceptance-inventory/model";
import { Loading } from "@/layouts/loading";
import { PricingPendingAcceptance } from "@/screens/inventory/model";
import { fetchPricingPendingAcceptance } from "@/screens/inventory/pending-acceptance-inventory/api";

export const Route = createFileRoute("/inventory/pending-acceptance-inventory/")({
    component: Component,
    validateSearch: SearchParams,
    loaderDeps: ({ search: { page, pageSize } }) => ({ page, pageSize }),
    loader: async (params): Promise<PricingPendingAcceptance> => {
        const response = await fetchPricingPendingAcceptance(params.deps.page - 1, params.deps.pageSize);
        return response;
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});