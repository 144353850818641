import { PDFBody, pdfCN, PDFHead, PDFRoot, PDFTable, PDFTableRow } from "@/components/composable-pdf/composable-pdf";
import normalizeEnums from "@/utils/normalize_enums";
import { View, Text } from "@react-pdf/renderer";
import dayjs from "dayjs";

export type PurchaseInvoicePDFItemSchema = {
    trade_name: string,
    batch_number: string,
    quantity: number,
    vat: number,
    cost: number,
}

export type InvoiceDetails = {
    invoice_number: string,
    supplier_name: string,
    payment_type: string,
    payment_mode: string,
    delivery_date: string,
    calculated_payment_date?: string
}




const InvoicePDF = (props: { medicalItems: PurchaseInvoicePDFItemSchema[], nonMedicalItems: PurchaseInvoicePDFItemSchema[], invoiceDetails: InvoiceDetails, pharmacyName: string }) => {
    const { medicalItems, nonMedicalItems, pharmacyName, invoiceDetails } = props;

    return <PDFRoot>
        <PDFHead>
            <View style={pdfCN("flex flex-col gap-1 w-full text-[18pt] font-[700] py-5")}>
                <Text>
                    Purchase Invoice | {pharmacyName}
                </Text>
                <Text style={pdfCN("text-[12pt] font-[300]")}>
                    Generated on {dayjs().format("DD-MM-YYYY")}
                </Text>
            </View>
            <View style={pdfCN("flex justify-center")}>
                <View style={pdfCN("w-[50pt] h-[50pt] bg-[#d9d9d9]")}>

                </View>
            </View>
        </PDFHead>
        <PDFBody>
            <View style={pdfCN("font-[400] text-[15pt]")}>
                <Text>Invoice Number: {invoiceDetails.invoice_number}</Text>
            </View>
            <View style={pdfCN("my-10 flex flex-row")}>
                <View style={pdfCN("flex flex-col gap-4 pr-20")}>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Supplier name
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {invoiceDetails.supplier_name}
                        </Text>
                    </View>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Payment Type
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {normalizeEnums(invoiceDetails.payment_type)}
                        </Text>
                    </View>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Payment Mode
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {normalizeEnums(invoiceDetails.payment_mode)}
                        </Text>
                    </View>
                </View>
                <View style={pdfCN("flex flex-col gap-4 border-l-2 border-l-[#d9d9d9] pl-20 mb-20")}>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Delivery Date
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {dayjs(invoiceDetails.delivery_date).format("DD-MM-YYYY")}
                        </Text>
                    </View>
                    <View style={pdfCN("flex flex-col gap-1")}>
                        <Text style={pdfCN("font-[300] text-[12pt]")}>
                            Payment Date
                        </Text>
                        <Text style={pdfCN("font-[800] text-[15pt]")}>
                            {
                                invoiceDetails.calculated_payment_date === undefined ? "N/A" :
                                    dayjs(invoiceDetails.calculated_payment_date).format("DD-MM-YYYY")
                            }
                        </Text>
                    </View>
                </View>
            </View>
            <View style={pdfCN("my-5 relative w-full")}>
                <View style={pdfCN("")}>
                    <Text>Medical Items:</Text>
                </View>
                {
                    medicalItems.length === 0 ? <View style={pdfCN("flex items-center justify-center text-[#d9d9d9] mt-5")}>
                        <Text>No Medical Items</Text>
                    </View> :
                        <PDFTable>
                            {/* Table Head */}
                            <PDFTableRow>
                                <View style={pdfCN("w-[50vw] flex flex-row")}>
                                    <Text style={pdfCN("w-[10vw] px-2 text-center")}>
                                        SL No.
                                    </Text>
                                    <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                        Product Name
                                    </Text>
                                    <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                        Total Quantity
                                    </Text>
                                </View>
                                <View style={pdfCN("w-[50vw] flex flex-row justify-evenly")}>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        VAT
                                    </Text>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        Net Total
                                    </Text>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        Total
                                    </Text>
                                </View>
                            </PDFTableRow>
                            {/* Table Body */}
                            {
                                medicalItems.map((item, idx) => {
                                    return <PDFTableRow>
                                        <View style={pdfCN("w-[50vw] flex flex-row")}>
                                            <Text style={pdfCN("w-[10vw] px-2 text-center")}>
                                                {idx + 1}
                                            </Text>
                                            <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                                {item.trade_name} | Batch: {item.batch_number}
                                            </Text>
                                            <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                                {item.quantity}
                                            </Text>
                                        </View>
                                        <View style={pdfCN("w-[50vw] flex flex-row justify-evenly")}>
                                            <Text style={pdfCN("flex-1 px-2 text-center")}>
                                                {item.vat} %
                                            </Text>
                                            <Text style={pdfCN("flex-1 px-2 text-center")}>
                                                AED {item.cost}
                                            </Text>
                                            <Text style={pdfCN("flex-1 px-2 text-center")}>
                                                AED {(1 + item.vat / 100) * item.cost * item.quantity}
                                            </Text>
                                        </View>
                                    </PDFTableRow>
                                })
                            }
                        </PDFTable>
                }
                <View style={pdfCN("mt-5")}>
                    <Text>Non Medical Items:</Text>
                </View>
                {
                    nonMedicalItems.length === 0 ? <View style={pdfCN("flex items-center justify-center text-[#d9d9d9] mt-5")}>
                        <Text>No Non Medical Items</Text>
                    </View> : <>
                        {/* Table Head */}
                        <PDFTable>
                            <PDFTableRow>
                                <View style={pdfCN("w-[50vw] flex flex-row")}>
                                    <Text style={pdfCN("w-[10vw] px-2 text-center")}>
                                        SL No.
                                    </Text>
                                    <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                        Product Name
                                    </Text>
                                    <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                        Total Quantity
                                    </Text>
                                </View>
                                <View style={pdfCN("w-[50vw] flex flex-row justify-evenly")}>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        VAT
                                    </Text>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        Net Total
                                    </Text>
                                    <Text style={pdfCN("flex-1 px-2 text-center")}>
                                        Total
                                    </Text>
                                </View>
                            </PDFTableRow>
                        </PDFTable>
                        {
                            nonMedicalItems.map((item, idx) => {
                                return <PDFTableRow>
                                    <View style={pdfCN("w-[50vw] flex flex-row")}>
                                        <Text style={pdfCN("w-[10vw] px-2 text-center")}>
                                            {idx + 1}
                                        </Text>
                                        <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                            {item.trade_name} | Batch: {item.batch_number}
                                        </Text>
                                        <Text style={pdfCN("w-[20vw] px-2 text-center")}>
                                            {item.quantity}
                                        </Text>
                                    </View>
                                    <View style={pdfCN("w-[50vw] flex flex-row justify-evenly")}>
                                        <Text style={pdfCN("flex-1 px-2 text-center")}>
                                            {item.vat} %
                                        </Text>
                                        <Text style={pdfCN("flex-1 px-2 text-center")}>
                                            AED {item.cost}
                                        </Text>
                                        <Text style={pdfCN("flex-1 px-2 text-center")}>
                                            AED {(1 + item.vat / 100) * item.cost * item.quantity}
                                        </Text>
                                    </View>
                                </PDFTableRow>
                            })
                        }
                    </>
                }
            </View>
        </PDFBody>
    </PDFRoot>
}

export default InvoicePDF