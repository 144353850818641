import { useEffect, useState } from 'react';
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, CommandShortcut } from '../ui/command';


import { Link, useNavigate, UseNavigateResult } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { SearchAvailableSchema } from '@/screens/sales/models/search';
import getPharmacyID from '@/utils/pharmacy_code';
import { httpClient } from '@/httpClient';
import { AICommandPredictionSchema } from './models';
import intent_url_mapper from '@/utils/intent_mapper';

interface CommandItemwrapperProps {
    title: string,
    subtitle: string,
    href: string,
    setOpen: (open: boolean) => void,
    navigate: UseNavigateResult<string>
    disabled: boolean
}

function CommandItemWrapper(props: CommandItemwrapperProps) {

    const { href, subtitle, title, setOpen, navigate, disabled } = props;
    return <CommandItem asChild value={title} disabled={disabled}
        onSelect={() => {
            setOpen(false)
            if (!disabled) {
                navigate({
                    to: href
                })
            }
        }}
        className='p-0 m-0'>
        <div className='cursor-pointer'>
            <Link className='flex flex-col p-2 rounded-md' onClick={() => {
                setOpen(false)
            }} to={href} disabled={disabled} style={{
                pointerEvents: disabled ? 'none' : 'auto',
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.5 : 1
            }}>
                <p className='text-lg font-semibold'>
                    {title}
                </p>
                <p className='text-sm font-normal text-muted-foreground'>
                    {subtitle}
                </p>
            </Link>
        </div>
    </CommandItem>
}

type InternalCommand = {
    title: string,
    subtitle: string,
    href: string,
    disabled: boolean
}

export default function GlobalCommandMenu() {
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('')
    const navigate = useNavigate()

    const [extraCommands, setExtraCommands] = useState<InternalCommand[]>([
        // Default Commands
        {
            title: 'Overview',
            subtitle: 'Go to Overview',
            href: '/overview',
            disabled: false
        },
        {
            title: 'Sales',
            subtitle: 'Go to Sales',
            href: '/sales',
            disabled: false
        },
        {
            title: 'Patients',
            subtitle: 'Go to Patients',
            href: '/patients',
            disabled: false
        },
        {
            title: 'Inventory',
            subtitle: 'Go to Inventory',
            href: '/inventory',
            disabled: false
        },
        {
            title: 'Suppliers',
            subtitle: 'Go to Suppliers',
            href: '/suppliers',
            disabled: false
        },
        {
            title: 'Reports',
            subtitle: 'Go to Reports',
            href: '/invoicing',
            disabled: false
        },
        {
            title: 'Purchase Invoice',
            subtitle: 'Create New Purchase Invoice',
            href: '/create-purchase-invoice',
            disabled: false
        },
        {
            title: 'Available Inventory',
            subtitle: 'Show Available inventory',
            href: '/inventory/available-inventory',
            disabled: false
        },
        {
            title: 'Settings',
            subtitle: 'Pharmacy Settings',
            href: '/settings',
            disabled: false
        }
    ])
    const [searchCommands, setSearchCommands] = useState<InternalCommand[]>([])
    const [aiCommands, setAiCommands] = useState<InternalCommand[]>([])
    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setValue('')
            }
            if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                setOpen((open) => !open)
            }
        }



        document.addEventListener("keydown", down)
        //@ts-ignore
        document.addEventListener("open-command-menu", () => { setOpen(true) })
        return () => {
            //@ts-ignore
            document.removeEventListener("open-command-menu", down)
            document.removeEventListener("keydown", down)
        }
    }, [])

    useEffect(() => {
        const finalCommands: InternalCommand[] = []
        if (value.length > 0) {
            // Filter Commands
            const commands = extraCommands.filter((c) => c.title.toLowerCase().includes(value.toLowerCase()))
            finalCommands.push(...commands)
            setExtraCommands(finalCommands)
            if (value.length > 3) {
                const getMeds = async () => {
                    const response = await httpClient.post(
                        `${import.meta.env.VITE_API_URL as string}/public/pharmacy/search/available`,
                        {
                            pharmacy_code: getPharmacyID(),
                            query: value,
                            limit: 10,
                        }
                    );
                    const finalData = response.data.data as SearchAvailableSchema[]
                    const commands = finalData.map((c) => ({
                        title: c.trade_name,
                        subtitle: `${c.unit} ${c.unit_type}`,
                        href: `/inventory/item/${c.type}/${c.id}`,
                        disabled: false
                    }))
                    setSearchCommands(commands)
                }
                const getAIPredictions = async () => {
                    const response = await httpClient.post(
                        `${import.meta.env.VITE_AI_API_URL as string}/nlu/predict`,
                        {
                            text: value,
                        }
                    );
                    const finalData = response.data as AICommandPredictionSchema
                    if (finalData.intent !== 'fallback_intent') {
                        const url = intent_url_mapper[finalData.intent]
                        const commands = [{
                            title: finalData.config.display_name,
                            subtitle: `${!finalData.config.requires_start_date && !finalData.config.requires_end_date ? "Open report" : ""} ${finalData.config.requires_start_date ? finalData.entities.start_date ? dayjs(finalData.entities.start_date).format("DD MMM YYYY") : 'Start Date Required' : ''} ${finalData.config.requires_end_date ? " to " : ''} ${finalData.config.requires_end_date ? finalData.entities.end_date ? dayjs(finalData.entities.end_date).format("DD MMM YYYY") : 'End Date Required' : ''}`,
                            href: `${url}?${finalData.config.requires_start_date && !finalData.config.requires_end_date ? `date=${finalData.entities.start_date}` : ''}${finalData.config.requires_start_date && finalData.config.requires_end_date ? `startDate=${finalData.entities.start_date}&endDate=${finalData.entities.end_date}` : ''}`,
                            disabled: finalData.config.requires_start_date && !finalData.entities.start_date || finalData.config.requires_end_date && !finalData.entities.end_date
                        }]
                        setAiCommands(commands)
                    }
                }
                getMeds()
                getAIPredictions()
            }
        } else {
            setExtraCommands([
                // Default Commands
                {
                    title: 'Overview',
                    subtitle: 'Go to Overview',
                    href: '/overview',
                    disabled: false
                },
                {
                    title: 'Sales',
                    subtitle: 'Go to Sales',
                    href: '/sales',
                    disabled: false
                },
                {
                    title: 'Patients',
                    subtitle: 'Go to Patients',
                    href: '/patients',
                    disabled: false
                },
                {
                    title: 'Inventory',
                    subtitle: 'Go to Inventory',
                    href: '/inventory',
                    disabled: false
                },
                {
                    title: 'Suppliers',
                    subtitle: 'Go to Suppliers',
                    href: '/suppliers',
                    disabled: false
                },
                {
                    title: 'Reports',
                    subtitle: 'Go to Reports',
                    href: '/invoicing',
                    disabled: false
                },
                {
                    title: 'Purchase Invoice',
                    subtitle: 'Create New Purchase Invoice',
                    href: '/create-purchase-invoice',
                    disabled: false
                },
                {
                    title: 'Available Inventory',
                    subtitle: 'Show Available inventory',
                    href: '/inventory/available-inventory',
                    disabled: false
                },
                {
                    title: 'Settings',
                    subtitle: 'Pharmacy Settings',
                    href: '/settings',
                    disabled: false
                }
            ])
        }
    }, [value])

    return (
        <CommandDialog open={open} onOpenChange={setOpen}>

            <CommandInput placeholder="Type a command or search..." value={value} onValueChange={setValue}>

            </CommandInput>
            <CommandList className='min-h-[70vh]'>
                <CommandEmpty>No Results Found.</CommandEmpty>
                <CommandGroup heading="Actions" className='[&_[cmdk-item]]:p-0'>
                    {extraCommands.map((c, i) => <CommandItemWrapper navigate={navigate} setOpen={setOpen} key={i} title={c.title} subtitle={c.subtitle} href={c.href} disabled={c.disabled}></CommandItemWrapper>)}
                </CommandGroup>
                <CommandGroup heading="Search Results">
                    {searchCommands.map((c, i) => <CommandItemWrapper navigate={navigate} setOpen={setOpen} key={i} title={c.title} subtitle={c.subtitle} href={c.href} disabled={c.disabled}></CommandItemWrapper>)}
                </CommandGroup>
                <CommandGroup heading="Neulink Business Intelligence Results">
                    {aiCommands.map((c, i) => <CommandItemWrapper navigate={navigate} setOpen={setOpen} key={i} title={c.title} subtitle={c.subtitle} href={c.href} disabled={c.disabled}></CommandItemWrapper>)}
                </CommandGroup>
            </CommandList>
        </CommandDialog>
    )
}