import { ScrollArea } from "@/components/ui/scroll-area";
import { CheckCircle } from "lucide-react";
import {
  NovuProvider,
  useNotifications,
  useRemoveNotification,
} from "@novu/notification-center";
import getPharmacyID from "@/utils/pharmacy_code";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { TailSpin } from "react-loader-spinner";

const AlertComponent = ({
  label,
  description,
  loading,
  onClickAction,
}: {
  label: string;
  description: string;
  onClickAction: () => void;
  loading: boolean;
}) => {
  return (
    <div className="grid grid-cols-5 border-b-[1px] py-2 px-4">
      <div className="col-span-4 w-full">
        <p className="text-sm text-gray-400">{label}</p>
        <p className="text-sm truncat font-semibold">{description}</p>
      </div>
      <div className="col-span-1 col-start-6 flex items-center justify-center space-x-2">
        {/* <p className="border rounded-lg px-2 py-1 text-center text-sm font-semibold">
          Documentation
        </p> */}
        <Button
          disabled={loading}
          className="border rounded-lg py-1 px-2 bg-gray-100 hover:bg-gray-100"
          style={{
            cursor: "pointer",
          }}
        >

          {
            loading ? <TailSpin
              color="#1E40AF"
              height={20}
              width={20}
            /> :
              <CheckCircle className="w-4 text-gray-700" onClick={onClickAction} />
          }
        </Button>
      </div>
    </div>
  );
};

const PharmacyAlerts = () => {
  const pharmacyCode = getPharmacyID();
  return (
    <div className="col-span-4 border rounded-lg">
      <div className="h-[300px]">
        <h2 className="text-2xl font-bold pt-4 px-4">Pharmacy Alerts</h2>
        <ScrollArea className="h-full mt-4">
          <NovuProvider
            subscriberId={pharmacyCode}
            applicationIdentifier={"pTf2KSaU5tzF"}
            initialFetchingStrategy={{
              fetchNotifications: true,
              fetchUserPreferences: true,
            }}
            onLoad={(i) => {}}
          >
            <NotificationList />
          </NovuProvider>
        </ScrollArea>
      </div>
    </div>
  );
};

const NotificationList = () => {
  const { notifications, fetchNextPage, hasNextPage, isLoading, isFetching, markNotificationAsRead } =
    useNotifications();
  const unread = useMemo(() => notifications ? notifications.filter(n => !n.read) : [], [notifications])
  const onSuccess = () => { };

  const onError = (error: Error) => {
    console.log(error);
  };
  //mark if any notification action is loading (used for read action)
  const [loading, setLoading] = useState<string[]>([])
  const { removeNotification, isError, error } = useRemoveNotification({
    onSuccess,
    onError,
  });
  return (
    <>
      {!isLoading ? (
        unread.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-gray-400 text-sm">No new notifications</p>
          </div>
        ) : (
          unread.slice(0, 5).map((notification) => {
            console.log(notification)
            return (
              <AlertComponent
                loading={loading.includes(notification._id)}
                key={notification.createdAt}
                label={dayjs(notification.createdAt).format(
                  "DD MMM, YYYY HH:mm:ss"
                )}
                description={notification.payload.message as string}
                onClickAction={() => {
                  markNotificationAsRead(notification._id)
                  setLoading([...loading, notification._id])
                }
                }
              />
            );
          })
        )
      ) : (
        <div className="flex flex-col items-center justify-center h-full">
          <p className="text-gray-400 text-sm">Loading...</p>
        </div>
      )}
    </>
  );
};

export default PharmacyAlerts;
