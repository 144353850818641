import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Route as patientsOneRoute } from "@/routes/patients/patient-info/$patientId";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { useEffect, useState } from "react";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { MoveRightIcon } from "lucide-react";
import { Button } from "@/components/ui/button";

export default function Items() {
  const { patientDetails, salesInfo } = patientsOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = patientsOneRoute.useSearch();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = salesInfo.slice().sort((a, b) => {
    if (orderBy === "created_at") {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <Card className="w-full border-none shadow-none overflow-auto">
      <CardContent>
        <Table className="">
          <TableHeader>
            <TableRow>
              <TableHead className="sm:table-cell">
                <Checkbox className="h-4 w-4 border border-gray-500 rounded-sm"></Checkbox>
              </TableHead>
              <TableHead>Invoice ID</TableHead>{" "}
              <TableHead
                onClick={() => handleRequestSort("created_at")}
                style={{ cursor: "pointer" }}
              >
                <div className="flex items-center">
                  <span>Created At</span>
                  {orderBy === "created_at" && (
                    <CaretSortIcon
                      className={`ml-2 h-4 w-4 ${
                        order === "asc" ? "rotate-180" : ""
                      }`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead>ERX Number</TableHead>
              <TableHead>Payment Type</TableHead>
              <TableHead>Billed Amount</TableHead>
              <TableHead>Refunded</TableHead>
              <TableHead>Void</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData.map((sale, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox className="h-4 w-4 border border-gray-500 rounded-sm"></Checkbox>
                  </TableCell>
                  <TableCell>{sale.invoice_id}</TableCell>
                  <TableCell>
                    {dayjs(sale.created_at).format("DD MMM, YYYY")}
                  </TableCell>

                  <TableCell>{sale.insurance_details.erx_number}</TableCell>
                  <TableCell>{sale.sale_payment_type}</TableCell>
                  <TableCell>
                    {convertToCurrencyRounded(
                      sale.sale_amounts.card +
                        sale.sale_amounts.cash +
                        sale.sale_amounts.insurance_amount -
                        sale.sale_amounts.total_discount
                    )}
                  </TableCell>
                  <TableCell>
                    {sale.sale_status.refunded ? "Yes" : "No"}
                  </TableCell>
                  <TableCell>{sale.sale_status.void ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Button
                      variant={"ghost"}
                      onClick={() => {
                        navigate({
                          to: "/overview/sales_history/$saleId",
                          params: { saleId: sale.sale_id },
                        });
                      }}
                    >
                      View Sale<MoveRightIcon className="p-1" />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
