import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { PricingPendingAcceptance } from "../model";

const API_URL = import.meta.env.VITE_API_URL as string
export async function fetchPricingPendingAcceptance(page: number, limit: number) {
    const response = await httpClient.post(`${API_URL}/public/pharmacy/medical_master/pricing/get/pending_acceptance?page=${page}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });

    const data = await response.data;

    if (!data.status) {
        throw new Error("API Fetch Error");
    }

    return data.data as PricingPendingAcceptance;
}