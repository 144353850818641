import { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Route as ReportAvailableRoute } from "@/routes/reports/pl-report/index";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { CSVLink } from "react-csv";

interface CSVDataItem {
  created_at: string;
  patient_name: string;
  invoice_amount: number;
  payment_type: "CASH" | "SPLIT" | "CARD" | undefined;
  cogs: number;
  profit_percentage: string;
}

const Component = () => {
  const searchParams = ReportAvailableRoute.useSearch();
  const data = ReportAvailableRoute.useLoaderData();
  const navigate = useNavigate();

  const [csvData, setCSVData] = useState<CSVDataItem[]>([]);

  const headers = [
    { label: "Created Date", key: "created_at" },
    { label: "Patient Name", key: "patient_name" },
    { label: "Invoice Amount", key: "invoice_amount" },
    { label: "Payment Type", key: "payment_type" },
    { label: "Cost of Goods Sold", key: "cogs" },
    { label: "Profit Percentage", key: "profit_percentage" },
  ];

  useEffect(() => {
    if (data.length > 0) {
      const csvDataItems: CSVDataItem[] = data.map((item) => ({
        created_at: dayjs(item.created_at).format("DD/MM/YYYY"),
        patient_name: item.is_valid_patient ? item.patient_details.name : "OTC",
        invoice_amount: item.invoice_amount,
        payment_type: item.payment_type,
        cogs: item.cogs,
        profit_percentage: item.profit_percentage.toFixed(2) + "%",
      }));
      setCSVData(csvDataItems);
    }
  }, [data]);

  const filename = "exported_data.csv";

  return (
    <>
      <div className="overflow-y-scroll h-screen pb-48">
        <div className="flex items-center justify-between">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink
                  onClick={() => {
                    navigate({
                      to: "/invoicing",
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Reports Overview
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>PL Report</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <CSVLink data={csvData} headers={headers} filename={filename}>
            <Button>Export List</Button>
          </CSVLink>
        </div>
        <hr className="my-4" />

        <Table className="mt-4 text-sm">
          <TableHeader>
            <TableRow>
              <TableHead>Created Date</TableHead>
              <TableHead>Patient Name</TableHead>
              <TableHead>Invoice Amount</TableHead>
              <TableHead>Payment Type</TableHead>
              <TableHead>Cost of Goods Sold</TableHead>
              <TableHead>Profit Percentage</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow key={index} className="font-medium">
                  <TableCell>
                    {dayjs(item.created_at).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {item.is_valid_patient ? item.patient_details.name : "OTC"}
                  </TableCell>
                  <TableCell>
                    {convertToCurrencyRounded(item.invoice_amount)}
                  </TableCell>
                  <TableCell>{item.payment_type}</TableCell>
                  <TableCell>{convertToCurrencyRounded(item.cogs)}</TableCell>
                  <TableCell>
                    {item.profit_percentage.toFixed(2) + "%"}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <hr />

        <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {searchParams.page} of{" "}
            {data.length > 0
              ? Math.ceil(data[0].full_count / searchParams.pageSize)
              : 1}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page - 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page + 1,
                  },
                });
              }}
              disabled={
                data.length === 0 ||
                data[0].full_count <= searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: Math.ceil(data[0].full_count / searchParams.pageSize),
                  },
                });
              }}
              disabled={
                data.length === 0 ||
                data[0].full_count <= searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
