import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table" 
import { MoveRightIcon, PlusCircleIcon } from "lucide-react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  MixerHorizontalIcon
} from "@radix-ui/react-icons"
import {Route as PendingAcceptanceRoute} from "@/routes/inventory/pending-acceptance-inventory/index"
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";

const Component = () => {
  const searchParams = PendingAcceptanceRoute.useSearch();
  const data = PendingAcceptanceRoute.useLoaderData();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <Breadcrumb>
              <BreadcrumbList>
                  <BreadcrumbItem>
                      <BreadcrumbLink onClick={() => {
                        navigate({
                          to: '/inventory',
                        })
                      }} style={{
                        cursor: 'pointer'
                      }}>Inventory Overview</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                      <BreadcrumbPage>Pending Price Acceptance</BreadcrumbPage>
                  </BreadcrumbItem>
              </BreadcrumbList>
          </Breadcrumb>
        </div>
        <hr className="my-4" />

        <div className="flex items-center justify-between">
          <Button>Accept All</Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                className="ml-auto hidden h-8 lg:flex"
              >
                <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                View
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[150px]">
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Table className='mt-4'>
        <TableHeader>
                <TableRow>
                  <TableHead>Trade Name</TableHead>
                  <TableHead>Unit</TableHead>
                  <TableHead>Unit Type</TableHead>
                  <TableHead>Updated Price</TableHead>
                  <TableHead></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.pending_medicines.map((item, index) => {
                    return (
                      <TableRow key={index}>
                          <TableCell>{item.trade_name}</TableCell>
                          <TableCell>{item.unit}</TableCell>
                          <TableCell>{item.unit_type}</TableCell>
                          <TableCell>{convertToCurrencyRounded(item.price)}</TableCell>
                          <TableCell><Button>Accept Price</Button></TableCell>
                      </TableRow>
                  )
                })}
            </TableBody>
        </Table>

        <div className='flex items-center justify-center w-full fixed bottom-6'>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              Page {searchParams.page} of{" "} {Math.ceil(data.pending_medicines_count / searchParams.pageSize)}
          </div>
          <div className="flex space-x-2">
              <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: 1
                      }
                    })
                  }}
                  disabled={searchParams.page === 1}
              >
                  <span className="sr-only">Go to first page</span>
                  <DoubleArrowLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page - 1
                      }
                    })
                  }}
                  disabled={searchParams.page === 1}
              >
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page + 1
                      }
                    })
                  }}
                  disabled={data.pending_medicines_count <= searchParams.page * searchParams.pageSize}
              >
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: Math.ceil(data.pending_medicines_count / searchParams.pageSize)
                      }
                    })
                  }}
                  disabled={data.pending_medicines_count <= searchParams.page * searchParams.pageSize}
              >
                  <span className="sr-only">Go to last page</span>
                  <DoubleArrowRightIcon className="h-4 w-4" />
              </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
