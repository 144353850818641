import dayjs from 'dayjs';
import {z} from 'zod';

export const SearchParams = z.object({
    page: z.number().default(1),
    pageSize: z.number().default(10),
    type: z.enum(["MEDICAL", "NON_MEDICAL"]).default("MEDICAL"),
    start_date: z.string().default(dayjs().format('YYYY-MM-DD')),
});

export type SearchParams = z.infer<typeof SearchParams>;