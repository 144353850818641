import { httpClient } from "@/httpClient";
import { PatientId, PatientSalesHistory } from "../components/data/schema";

export const getPatientDetails = async (id: string): Promise<PatientId> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/patients/get/one`, {
        id : id,
    });
    return response.data.data as PatientId;
};

export const getPatientSalesHistory = async (id: string): Promise<PatientSalesHistory[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/sales/get/history/patient`, {
        id : id,
    })
    return response.data.data as PatientSalesHistory[];
}