import dayjs from 'dayjs';
import {z} from 'zod';

export const SearchParams = z.object({
    page: z.number().default(1),
    pageSize: z.number().default(10),
    startDate: z.string().default(dayjs().add(-1,'years').format('YYYY-MM-DD')),
    endDate: z.string().default(dayjs().format('YYYY-MM-DD')),
});

export type SearchParams = z.infer<typeof SearchParams>;