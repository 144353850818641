import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { DatePickerWithRange } from "@/components/ui/date-picker"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts"
import { Revenue } from "../model"
import { Route as revenueRoute } from '@/routes/accounts/revenue/index'
import { useNavigate, useRouter } from "@tanstack/react-router"
import { useEffect, useState, useCallback } from "react"
import { DateRange } from "react-day-picker"
import { addDays } from "date-fns"
import dayjs from "dayjs"
import { convertToCurrencyRounded } from "@/utils/currency_converter"
import { format } from "date-fns" // Add this import at the top of the file

const Chart = () => {
    const { type, time_compare, moving_average, moving_average_days, start_date, end_date } = revenueRoute.useSearch()
    const {data, overlayData} = revenueRoute.useLoaderData()
    const navigate = useNavigate();
    const router = useRouter()
    let count = 7;
    let typeDayJs: dayjs.ManipulateType = "days";
    if (type === "MONTH") {
        count = 1;
        typeDayJs = "month"
    }
    if (type === "QUATER") {
        count = 3;
        typeDayJs = "month"
    }
    if (type === "HALF_YEAR") {
        count = 6;
        typeDayJs = "month"
    }
    if (type === "YEAR") {
        count = 1;
        typeDayJs = "year"
    }
    const [dateRange, setDateRange] = useState<DateRange | undefined>({
        from: type === "CUSTOM" ? dayjs.unix(start_date!).toDate() : dayjs().subtract(count, typeDayJs).toDate(),
        to: type === "CUSTOM" ? dayjs.unix(end_date!).toDate() : dayjs().toDate(),
    })

    let finalData = data

    if (overlayData.length > 0) {
        finalData = data.map((item, index) => {
            if (!overlayData[index]) {
                return item
            }
            return {
                ...item,
                overlay_total_revenue: overlayData[index]?.total_revenue,
                overlay_time_interval: overlayData[index].time_interval,
            }
        })
    }

    const [isCustom, setIsCustom] = useState(type === "CUSTOM")

    const handleDateChange = useCallback((newDateRange: DateRange | undefined) => {
        setDateRange(newDateRange)
    }, [])

    const handleSubmit = useCallback(() => {
        if (dateRange?.from && dateRange?.to) {
            navigate({
                search: {
                    type: "CUSTOM",
                    time_compare,
                    moving_average,
                    moving_average_days,
                    start_date: Math.round(dateRange.from.getTime() / 1000),
                    end_date: Math.round(dateRange.to.getTime() / 1000),
                },
            })
        }
    }, [navigate, dateRange, time_compare, moving_average, moving_average_days])

    const handleClear = useCallback(() => {
        setDateRange(undefined)
        navigate({
            search: {
                type,
                time_compare,
                moving_average,
                moving_average_days,
            },
        })
    }, [navigate, type, time_compare, moving_average, moving_average_days])

    return (
        <div className='flex flex-col flex-1'>
            <div className="flex items-center my-4">
                <div className="w-[2px] h-12 bg-green-700 mr-4" aria-hidden="true"></div>
                <div>
                    <h2 className="text-gray-600">Revenue</h2>
                    <p className="text-xl font-semibold">{convertToCurrencyRounded(
                        data.reduce((acc, item) => acc + item.total_revenue, 0)
                    )}</p>
                    {
                        overlayData.length > 0 && (
                            <p className="text-sm text-gray-600">
                                {"Comparision Period"}: {convertToCurrencyRounded(
                                    overlayData.reduce((acc, item) => acc + item.total_revenue, 0)
                                )}
                            </p>
                        )
                    }
                </div>
            </div>

            <div className='flex-1 min-h-0'>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart 
                        data={finalData} 
                        margin={{ top: 40, right: 30, left: 50, bottom: 5 }}
                    >
                        <CartesianGrid 
                            stroke="rgba(255, 255, 255, 0.1)" 
                            fill='#FCFCFC' 
                            strokeWidth={1}
                            horizontal={true}
                            vertical={true}
                            style={{
                                stroke: 'hsl(var(--border))',
                                strokeWidth: 1,
                            }}
                        />
                        <XAxis 
                            dataKey="time_interval" 
                            stroke="hsl(var(--foreground))"
                            fontSize={12}
                            tickLine={false}
                            axisLine={{ stroke: 'hsl(var(--muted))' }}
                            tickFormatter={(value) => dayjs(value).format("MMM DD, YYYY")}
                            dy={10}
                        />
                        <YAxis
                            stroke="hsl(var(--foreground))"
                            fontSize={12}
                            tickLine={false}
                            axisLine={{ stroke: 'hsl(var(--muted))' }}
                            tickFormatter={(value) => `${convertToCurrencyRounded(value)}`}
                            width={40}
                            dx={-10}
                        />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: "hsl(var(--background))",
                                border: "1px solid hsl(var(--border))",
                                borderRadius: "var(--radius)",
                            }}
                            labelStyle={{ color: "hsl(var(--foreground))" }}
                            itemStyle={{ color: "hsl(var(--primary))" }}
                            labelFormatter={(label, payload) => {
                                if (time_compare && payload.length > 1) {
                                    return `Current: ${dayjs(label).format("MMM DD, YYYY")}, Previous: ${dayjs(payload[1].payload.overlay_time_interval).format("MMM DD, YYYY")}`
                                }
                                return dayjs(label).format("MMM DD, YYYY")
                            }}
                            formatter={(value, name, props) => {
                               if (name === "overlay_total_revenue") {
                                   return [`${convertToCurrencyRounded(props.payload.overlay_total_revenue)}`, "Overlay Total Revenue"]
                               }
                               return [`${convertToCurrencyRounded(props.payload.total_revenue)}`, "Total Revenue"]
                            }}
                        />
                        
                        <Line 
                            type="monotone" 
                            dataKey="total_revenue" 
                            stroke="#2A9D90" 
                            strokeWidth={2}
                            dot={false}
                        />
                        {overlayData.length > 0 && <Line 
                            type="monotone" 
                            dataKey="overlay_total_revenue" 
                            stroke="#E9C46A"
                            strokeWidth={2}
                            dot={false}
                            strokeDasharray={time_compare ? "5 5" : undefined}
                            strokeDashoffset={time_compare ? 5 : undefined}
                        />}
                        {moving_average && <Line 
                            type="monotone" 
                            dataKey="moving_average" 
                            stroke="#F4A261"
                            strokeWidth={2}
                            dot={false}
                            strokeDasharray={moving_average ? "5 5" : undefined}
                            strokeDashoffset={moving_average ? 5 : undefined}
                        />}
                    </LineChart>
                </ResponsiveContainer>
            </div>                

            <hr className="my-4" />

            <div
                className='flex items-center justify-between'
            >
                <Tabs defaultValue={type} className="w-[400px]">
                    <TabsList>
                        <TabsTrigger value="WEEK" onClick={() => {
                            navigate({
                                search: {
                                    type: "WEEK",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }} onMouseOver={async () => {
                            await router.preloadRoute({
                                search: {
                                    type: "WEEK",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }}>Week</TabsTrigger>
                        <TabsTrigger value="MONTH" onClick={() => {
                            navigate({
                                search: {
                                    type: "MONTH",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }} onMouseOver={async () => {
                            await router.preloadRoute({
                                search: {
                                    type: "MONTH",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }}>Month</TabsTrigger>
                        <TabsTrigger value="QUATER" onClick={() => {
                            navigate({
                                search: {
                                    type: "QUATER",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }} onMouseOver={async () => {
                            await router.preloadRoute({
                                search: {
                                    type: "QUATER",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }}>Quarter</TabsTrigger>
                        <TabsTrigger value="HALF_YEAR" onClick={() => {
                            navigate({
                                search: {
                                    type: "HALF_YEAR",
                                    time_compare,
                                    moving_average,
                                    moving_average_days

                                },
                            })
                        }} onMouseOver={async () => {
                            await router.preloadRoute({
                                search: {
                                    type: "HALF_YEAR",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }}>Half Year</TabsTrigger>
                        <TabsTrigger value="YEAR" onClick={() => {
                            navigate({
                                search: {
                                    type: "YEAR",
                                    time_compare,
                                    moving_average,
                                    moving_average_days

                                },
                            })
                        }} onMouseOver={async () => {
                            await router.preloadRoute({
                                search: {
                                    type: "YEAR",
                                    time_compare,
                                    moving_average,
                                    moving_average_days
                                },
                            })
                        }}>Year</TabsTrigger>
                    </TabsList>
                </Tabs>

                <DatePickerWithRange 
                    date={dateRange}
                    setDate={handleDateChange}
                    onSubmit={handleSubmit}
                    onClear={handleClear}
                    className="w-[300px]"
                    disabledAfter={dayjs().add(1, "D").toDate()}
                />
            </div>
        </div>
    )
}

export default Chart