import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "../../../screens/inventory/available-inventory/model";
import { getAvailableMedicalInventoryExpired } from "@/screens/inventory/available-inventory/api";
import { InventorySchema } from "@/screens/inventory/available-inventory/type";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/expiry-list/component";

export const Route = createFileRoute("/reports/expiry-list/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize, expired } }) => ({
    page,
    pageSize,
    expired,
  }),
  loader: async (params): Promise<InventorySchema> => {
    const response = await getAvailableMedicalInventoryExpired(
      params.deps.page - 1,
      params.deps.pageSize
    );
    return {
      type: "MEDICAL",
      data: response.map((item) => ({
        ...item,
        type: "MEDICAL",
      })),
    };
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
