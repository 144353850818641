import { Input } from "@/components/ui/input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { HelpCircle, LogOut, Search } from "lucide-react";
import {
  Link,
  useMatchRoute,
  useNavigate,
  useRouterState,
} from "@tanstack/react-router";
import { useOidc } from "@/oidc";
import normalizeEnums from "@/utils/normalize_enums";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { CommandItem, CommandList } from "cmdk";
import {
  Command,
  CommandGroup,
  CommandSeparator,
} from "@/components/ui/command";
import { useEffect, useState } from "react";
import { Dialog } from "@/components/ui/dialog";
import {
  DialogContent,
  DialogOverlay,
  DialogTrigger,
} from "@radix-ui/react-dialog";
import { Button } from "@/components/ui/button";
import { Settings, Command as CommandIcon } from "lucide-react";

function getTabName(tab: string) {
    switch (tab) {
        case "":
            return "overview"
        case "invoicing":
            return "reports"
        case "create-purchase-invoice":
            return "inventory"
        case "create-purchase-order":
            return "inventory"
        case "draft-purchase-invoice":
            return "inventory"
        default:
            return tab
    }
}

enum OS {
  Windows = "Windows",
  Macintosh = "Macintosh",
  Linux = "Linux",
}

const Index = () => {
  const matchRoute = useMatchRoute();
  const router = useRouterState();
  const currentTab = router.location.pathname.split("/")[1];
  const oidc = useOidc({ assertUserLoggedIn: true });
  const navigate = useNavigate();
  const [mobileNavMenuOpen, setMobileNavMenuOpen] = useState(false);
  const [os, setOs] = useState(OS.Windows);

  const placeholdres = [
    "Search for medicines",
    "Search for invoices",
    "Search for reports",
  ];
  const [placeHolderIdx, setPlaceHolderIdx] = useState(0);
  const [placeholder, setPlaceholder] = useState(placeholdres[placeHolderIdx]);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    if (ua.includes("Macintosh")) {
      setOs(OS.Macintosh);
    }
    const placeholderRotatior = setInterval(() => {
      //weird issues with useState closures and use effect
      //https://stackoverflow.com/questions/53024496/state-not-updating-when-using-react-state-hook-within-setinterval
      setPlaceHolderIdx((oldIdx) => (oldIdx + 1) % placeholdres.length);
    }, 2500);
    return () => {
      clearInterval(placeholderRotatior);
    };
  }, []);

  return (
    <nav className="sticky top-0 flex border-b-[1px] bg-background p-4 z-50 gap-4 justify-between mb-4 print:hidden">
      <div className="flex items-center justify-center space-x-4">
        <div className="border rounded-md p-2 grid place-items-center">
          {/* <img src="/logo.svg" alt="logo" width={24} height={24} /> */}
          <svg
            width="22"
            height="22"
            viewBox="0 0 82 82"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.6852 36.5542L21.046 0H27.0024L21.6416 36.5542H15.6852ZM28.7893 36.5542L34.1501 0H40.1065L34.7458 36.5542H28.7893ZM52.0194 7.70602L53.2107 0H59.1671L57.9758 7.70602H52.0194ZM65.1235 7.70602L66.3148 0H72.2712L71.0799 7.70602H65.1235ZM5.95642 18.5735V12.6458H13.8983L13.1041 18.5735H5.95642ZM42.4891 18.5735L43.2833 12.6458H82V18.5735H42.4891ZM5.95642 31.6145V25.6867H12.1114L11.1186 31.6145H5.95642ZM40.5036 31.6145L41.4964 25.6867H82V31.6145H40.5036ZM54.2034 82L60.954 36.5542H66.9104L60.1598 82H54.2034ZM41.0993 82L47.8499 36.5542H53.8063L47.0557 82H41.0993ZM0 50.188V44.2602H41.4964L40.5036 50.188H0ZM69.8886 50.188L70.8814 44.2602H76.0436V50.188H69.8886ZM0 63.2289V57.3012H39.5109L38.7167 63.2289H0ZM67.9031 63.2289L68.8959 57.3012H76.0436V63.2289H67.9031ZM8.93463 82L10.9201 68.1687H16.8765L14.891 82H8.93463ZM22.0387 82L24.0242 68.1687H29.9806L27.9952 82H22.0387Z"
              fill="#787878"
            />
          </svg>
        </div>
        <div
          className={`block md:hidden w-[150px]   ${mobileNavMenuOpen ? "rounded-t-md border border-b-white" : "rounded-md border"}`}
        >
          <Popover open={mobileNavMenuOpen} onOpenChange={setMobileNavMenuOpen}>
            <Dialog
              open={mobileNavMenuOpen}
              onOpenChange={setMobileNavMenuOpen}
            >
              <PopoverTrigger asChild>
                <DialogTrigger asChild>
                  <button
                    role="combobox"
                    className="w-full flex flex-row justify-between items-center px-2 py-2 border-none"
                  >
                    {normalizeEnums(getTabName(currentTab))}
                    <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </button>
                </DialogTrigger>
              </PopoverTrigger>
              <PopoverAnchor />
              <DialogOverlay></DialogOverlay>
              <PopoverContent className="border border-t-0 rounded-b-lg w-[150px]">
                <DialogContent asChild>
                  <Command>
                    <CommandList className="w-[150px] border-0">
                      <CommandGroup className="flex flex-col gap-2 px-2 text-normal ">
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/overview" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Overview
                          </div>
                        </CommandItem>
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/sales" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Sales
                          </div>
                        </CommandItem>
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/inventory" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Inventory
                          </div>
                        </CommandItem>
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/invoicing" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Reports
                          </div>
                        </CommandItem>
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/patients" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Patients
                          </div>
                        </CommandItem>
                        <CommandSeparator />
                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/accounts" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Accounts
                          </div>
                        </CommandItem>
                        <CommandSeparator />

                        <CommandItem asChild>
                          <div
                            role="link"
                            className="py-2"
                            onClick={() => {
                              navigate({ to: "/insurance" });
                              setMobileNavMenuOpen(false);
                            }}
                          >
                            Insurance
                          </div>
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </DialogContent>
              </PopoverContent>
            </Dialog>
          </Popover>
        </div>
        <div className="hidden md:block h-full">
          <Tabs value={getTabName(currentTab)} className="w-[400px]">
            <TabsList>
              <TabsTrigger value="overview" asChild>
                <Link to="/overview" preload="intent">
                  Overview
                </Link>
              </TabsTrigger>
              <TabsTrigger value="sales" asChild>
                <Link to="/sales" preload="intent">
                  Sales
                </Link>
              </TabsTrigger>
              <TabsTrigger value="inventory" asChild>
                <Link to="/inventory" preload="intent">
                  Inventory
                </Link>
              </TabsTrigger>
              <TabsTrigger value="reports" asChild>
                <Link to="/invoicing" preload="intent">
                  Reports
                </Link>
              </TabsTrigger>
              <TabsTrigger value="suppliers" asChild>
                <Link to="/suppliers" preload="intent">
                  Suppliers
                </Link>
              </TabsTrigger>
              <TabsTrigger value="patients" asChild>
                <Link to="/patients" preload="intent">
                  Patients
                </Link>
              </TabsTrigger>
              <TabsTrigger value="accounts" asChild>
                <Link to="/accounts" preload="intent">
                  Accounts
                </Link>
              </TabsTrigger>
              <TabsTrigger value="insurance" asChild>
                Insurance
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>
      <div className="flex items-center justify-center space-x-4">
        <div
          role="button"
          className="border rounded-md p-2 grid place-items-center cursor-pointer md:hidden"
          onMouseDown={(e) => {
            e.preventDefault();
            const commandEvent = new Event("open-command-menu");
            document.dispatchEvent(commandEvent);
          }}
        >
          <Search size={15}></Search>
        </div>

        <div
          role="button"
          onMouseDown={(e) => {
            e.preventDefault();
            const commandEvent = new Event("open-command-menu");
            document.dispatchEvent(commandEvent);
          }}
          className=" md:flex hidden border border-input justify-end items-center rounded-md px-2"
        >
          <Input
            id="MainSearch"
            placeholder={placeholdres[placeHolderIdx]}
            //delay to sync with the placeholder rotation on down blip
            className="hidden md:block h-8 min-w-lg border-0 text-foreground animate-pulse duration-[5000s] delay-2500 pl-0"
          />
          <span className="border border-input text-sm rounded-md flex gap-2 items-center px-2 my-2">
            {os === OS.Macintosh ? (
              <span className="flex gap-1 items-center text-xs font-semibold text-muted-foreground">
                <kbd className="text-sm">⌘</kbd> + <kbd>K</kbd>
              </span>
            ) : (
              <span className="flex gap-1 items-center text-xs font-semibold text-muted-foreground">
                <kbd>Ctrl</kbd> + <kbd>K</kbd>
              </span>
            )}
          </span>
        </div>
        <Popover>
          <PopoverTrigger asChild>
            <div className="border h-full rounded-md grid place-items-center cursor-pointer p-2">
              <HelpCircle size={18} />
            </div>
          </PopoverTrigger>
          <PopoverContent className="bg-white">
            <div className="flex flex-col space-y-2">
              <Button variant="ghost" className="justify-start text-sm h-8">
                <CommandIcon className="mr-2 h-4 w-4 text-black" />
                Keyboard Shortcuts
              </Button>
              <hr className="my-2" />
              <Button
                variant="ghost"
                className="justify-start text-sm h-8"
                onClick={() => navigate({ to: "/settings" })}
              >
                <Settings className="mr-2 h-4 w-4" />
                Settings
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        {oidc.isUserLoggedIn && (
          <div
            className="border h-full rounded-md p-2 grid place-items-center cursor-pointer"
            onClick={() => {
              localStorage.clear();
              oidc.logout({
                redirectTo: "home",
              });
            }}
          >
            <LogOut size={15} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Index;
