import { useEffect, useMemo, useState } from "react"
import { PurchaseOrderItem } from "../component"
import { SearchAllSchema } from "@/screens/create-purchase-invoice/components/data/schema"
import { toast } from "@/components/ui/use-toast"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { Pen, Trash } from "lucide-react"
import { AddNonMedical } from "@/components/add-non-medical"
import { AutoComplete, Option } from "@/components/styled-autocomplete"
import ActionAndInfo from "@/screens/inventory/add-stock/components/action-and-info"
import { Input } from "@/components/ui/input"
import { useDebounce } from "@uidotdev/usehooks"
import { httpClient } from "@/httpClient"
import getPharmacyID from "@/utils/pharmacy_code"
import { numericStringDefault, stringToNumericString } from "@/utils/numeric_string"
import { convertToCurrencyRounded } from "@/utils/currency_converter"

export default function AddInventory({
    purchaseOrderItems, addPurchaseOrderItem, removePurchaseOrderItem, editPurchaseOrderItem
}: {
    purchaseOrderItems: PurchaseOrderItem[],
    addPurchaseOrderItem: (item: PurchaseOrderItem) => void
    removePurchaseOrderItem: (id: string) => void
    editPurchaseOrderItem: (id: string, item: PurchaseOrderItem) => void
}
) {
    const defaultFormState = {
        itemSearchQuery: "",
        itemSearchResults: [] as Option[],
        itemValue: undefined as Option | undefined,
        quantity: numericStringDefault(),
        bonusQuantity: numericStringDefault(),
        intendedCost: numericStringDefault(),
    }
    const [formState, setFormState] = useState(defaultFormState)
    const [formMode, setFormMode] = useState<'add' | 'edit'>('add')
    const resetForm = () => {
        setFormState(defaultFormState)
    }
    const isFormValid = useMemo(() => {
        if (formState.itemValue && formState.quantity.value > 0 && formState.intendedCost.value > 0) {
            return true;
        }
        return false;
    }, [formState])

    const itemToFormState = (item: PurchaseOrderItem) => {
        return {
            ...defaultFormState,
            itemValue: { label: item.trade_name, value: item.id, type: item.type, trade_name: item.trade_name },
            quantity: { value: item.quantity, strValue: item.quantity.toString() },
            bonusQuantity: { value: item.bonus_quantity, strValue: item.bonus_quantity.toString() },
            intendedCost: { value: item.intended_cost, strValue: item.intended_cost.toString() },
        }
    }
    const debouncedSearchTerm = useDebounce(formState.itemSearchQuery, 500);

    useEffect(() => {
        try {
            const getMeds = async () => {
                if (debouncedSearchTerm.length === 0) {
                    setFormState({
                        ...formState,
                        itemSearchResults: [],
                    })
                    return;
                }
                if (debouncedSearchTerm.length < 3) return;

                const response = await httpClient.post(
                    `${import.meta.env.VITE_API_URL as string
                    }/public/pharmacy/search/all`,
                    {
                        pharmacy_code: getPharmacyID(),
                        query: debouncedSearchTerm,
                        limit: 20,
                    }
                );
                const data = response.data.data as SearchAllSchema[];
                setFormState({
                    ...formState,
                    itemSearchResults: data.map((item) => ({ label: item.trade_name + " " + item.unit_type + ":::" + item.manufacturer + ":::" + item.owner, value: item.id, type: item.type, trade_name: item.trade_name })),
                })
            };
            getMeds();
        } catch (error) {
            console.error("Error:", error);
            toast({
                title: "Error",
                description: "Failed to search",
                duration: 5000,
            });
        }
    }, [debouncedSearchTerm])

    return <ResizablePanelGroup className="col-span-8 flex" direction={"horizontal"}>
        <ResizablePanel className="flex-1 mr-2">
            <div className='overflow-auto h-full'>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>SL No.</TableHead>
                            <TableHead>Product Name</TableHead>
                            <TableHead>Quantity</TableHead>
                            <TableHead>Bonus</TableHead>
                            <TableHead>Intented Cost</TableHead>
                            <TableHead></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            purchaseOrderItems.length > 0
                            && purchaseOrderItems.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{item.trade_name}</TableCell>
                                    <TableCell>{item.quantity}</TableCell>
                                    <TableCell>{item.bonus_quantity}</TableCell>
                                    <TableCell>{convertToCurrencyRounded(item.intended_cost)}</TableCell>
                                    <TableCell>
                                        <div className="flex flex-row justify-evenly">
                                            <Button variant={'outline'} onClick={() => {
                                                removePurchaseOrderItem(item.id)
                                            }}>
                                                <Trash className='w-4 h-4' />
                                            </Button>
                                            <Button variant={"outline"} onClick={() => {
                                                setFormMode('edit')
                                                setFormState(itemToFormState(item))
                                            }}>
                                                <Pen className='w-4 h-4' />
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>

                {
                    purchaseOrderItems.length === 0
                    && (
                        <div className='grid place-items-center h-[90%]'>
                            <div className='flex flex-col justify-center'>
                                <p className='text-2xl'>Start by adding products</p>
                                <img
                                    src='/arrow.png'
                                    alt='empty'
                                    width={300}
                                    className='ml-10'
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel
            minSize={20}
            maxSize={40}
            defaultSize={20}
            className="bg-[#F6F6F6] overflow-auto">
            <div className='bg-[#F6F6F6] rounded-sm p-4 h-full overflow-auto'>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    if (!isFormValid) return;
                    if (formMode === 'edit') {
                        editPurchaseOrderItem(formState.itemValue!.value, {
                            id: formState.itemValue!.value,
                            quantity: formState.quantity.value,
                            type: formState.itemValue!.type,
                            trade_name: formState.itemValue!.trade_name,
                            bonus_quantity: formState.bonusQuantity.value,
                            intended_cost: formState.intendedCost.value,
                        })
                        resetForm();
                        setFormMode('add')
                        return;
                    }
                    addPurchaseOrderItem({
                        id: formState.itemValue!.value,
                        quantity: formState.quantity.value,
                        type: formState.itemValue!.type,
                        trade_name: formState.itemValue!.trade_name,
                        bonus_quantity: formState.bonusQuantity.value,
                        intended_cost: formState.intendedCost.value,
                    })
                    resetForm();
                }} className="w-full flex flex-col gap-4 mt-4 mb-24">
                    <div className='flex items-center justify-between'>
                        <p className="text-sm font-semibold">
                            Product Name<span className="text-red-400">*</span>
                        </p>
                        <AddNonMedical />
                    </div>
                    <div className='border rounded-md px-2 flex items-center bg-white'>
                        <AutoComplete
                            options={formState.itemSearchResults}
                            emptyMessage="No results."
                            placeholder="Search for a product"
                            onValueChange={(value) => {
                                setFormState({
                                    ...formState,
                                    itemValue: value,
                                    itemSearchQuery: value.label,
                                    itemSearchResults: [],
                                })
                            }}
                            value={formState.itemSearchQuery ? { label: formState.itemSearchQuery, value: formState.itemSearchQuery } : undefined}
                            onInputChanged={(value) => {
                                setFormState({
                                    ...formState,
                                    itemSearchQuery: value,
                                })
                            }
                            }
                            shouldFilter={false}
                            input={formState.itemSearchQuery}
                        />
                        <ActionAndInfo value={
                            {
                                id: formState.itemValue?.value || "",
                                //@ts-ignore
                                type: formState.itemValue?.type,
                            }
                        } />
                    </div>
                    <div className=''>
                        <p className="text-sm font-semibold">
                            Quantity<span className="text-red-400">*</span>
                        </p>
                        <Input
                            type="text"
                            inputMode="numeric"
                            value={formState.quantity.strValue}
                            className="border rounded-md px-2"
                            onChange={(e) => {
                                stringToNumericString(
                                    e.target.value,
                                    (v) => {
                                        setFormState({
                                            ...formState,
                                            quantity: v,
                                        })
                                    },
                                    (value) => {
                                        return value[value.length - 1] !== "."
                                    }
                                )
                            }}
                            placeholder="Quantity"
                        />
                    </div>
                    <div className=''>
                        <p className="text-sm font-semibold">
                            Bonus Quantity
                        </p>
                        <Input
                            type="text"
                            inputMode="numeric"
                            value={formState.bonusQuantity.strValue}
                            className="border rounded-md px-2"
                            onChange={(e) => {
                                stringToNumericString(
                                    e.target.value,
                                    (v) => {
                                        setFormState({
                                            ...formState,
                                            bonusQuantity: v,
                                        })
                                    },
                                    (value) => {
                                        return value[value.length - 1] !== "."
                                    }
                                )
                            }}
                            placeholder="Bonus Quantity"
                        />
                    </div>
                    <div className=''>
                        <p className="text-sm font-semibold">
                            Intended Cost<span className="text-red-400">*</span>
                        </p>
                        <Input
                            type="text"
                            inputMode="numeric"
                            value={formState.intendedCost.strValue}
                            className="border rounded-md px-2"
                            onChange={(e) => {
                                stringToNumericString(
                                    e.target.value,
                                    (v) => {
                                        setFormState({
                                            ...formState,
                                            intendedCost: v,
                                        })
                                    }
                                )
                            }}
                            placeholder="Intended Cost"
                        />
                    </div>
                    <hr />
                    <Button type="submit" className="bg-blue-700 mt-2" disabled={!isFormValid}>
                        {formMode === 'add' ? 'Confirm & Add' : 'Confirm & Update'}
                    </Button>
                    <Button type="button" variant={'secondary'} className="mt-2" onClick={resetForm}>
                        Cancel
                    </Button>
                </form>
            </div>
        </ResizablePanel>
    </ResizablePanelGroup>
}
