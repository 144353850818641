import { ArrowUpLeftSquareIcon } from 'lucide-react'

const NoSaleSelected = () => {
    return (
        <div className='h-full w-full grid place-items-center bg-gray-100 py-16'>
            <div className='flex flex-col items-center justify-center space-y-6'>
                <ArrowUpLeftSquareIcon 
                    className='text-[#71717A]'
                    size={48}
                />
                <div className='text-center'>
                    <p className='text-lg'>No sale selected</p>
                    <p className='text-sm text-gray-500'>You may want to select a sale tab from above</p>
                </div>
            </div>
        </div>
    )
}

export default NoSaleSelected