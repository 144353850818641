import { useState } from 'react'
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useToast } from "@/components/ui/use-toast"
import { httpClient } from '@/httpClient'
import getPharmacyID from '@/utils/pharmacy_code'
import { PlusCircle } from 'lucide-react'

const AddSupplier = () => {
    const { toast } = useToast()

    const [supplier_name, set_supplier_name] = useState('')
    const [supplier_phone, set_supplier_phone] = useState('')
    // const [patient_emirates_id, set_patient_emirates_id] = useState('')
    const [loading, setLoading] = useState(false)
    const SubmitSupplierDetails = async () => {
        setLoading(true)
        
        try {
            const response = await httpClient.post(
                `${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/create`,
                {
                    credit_terms: {},
                    name: supplier_name,
                    metadata: {
                        phone_number: supplier_phone
                    },
                    pharmacy_code: getPharmacyID()
                }
            );

            console.log('Response:', response.data);

            toast({
                title: "New Supplier Added",
                description: `Supplier ${supplier_name} has been added successfully`,
                duration: 5000,
            }); 
        } catch (error) {
            console.error('Error:', error);
            toast({
                title: "Error",
                description: "Failed to add supplier",
                duration: 5000,
            });
        } finally {
            setLoading(false);
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        }
    }

    return (
        <>
            <Sheet>
                <SheetTrigger>
                    <Button
                        variant={'outline'}
                        className='flex items-center gap-2'
                        size={'sm'}
                        type='button'
                    >
                        <PlusCircle size={16} />
                        Create New Supplier</Button>
                </SheetTrigger>
                <SheetContent>
                    <SheetHeader>
                    <SheetTitle>Add a new suppliers</SheetTitle>
                    <SheetDescription>
                        The following details are required to add a new supplier
                    </SheetDescription>
                    </SheetHeader>
                    <p className='text-sm font-semibold mt-4 mb-2'>Supplier Name</p>
                    <Input 
                        placeholder='Supplier Name'
                        value={supplier_name}
                        onChange={e => set_supplier_name(e.target.value)}
                    />
                    <p className='text-sm font-semibold mt-4 mb-2'>Phone Number</p>
                    <Input 
                        placeholder='Phone Number'
                        value={supplier_phone}
                        type='number'
                        onChange={e => set_supplier_phone(e.target.value)}
                    />
                    {/* <hr className='my-4'/> */}
                    <Button
                        className='absolute bottom-2 right-2'
                        onClick={SubmitSupplierDetails}
                        disabled={loading}
                    >
                        {loading ? 'Loading...' : 'Add Supplier'}
                    </Button>
                </SheetContent>
            </Sheet>
        </>
    )
}

export default AddSupplier