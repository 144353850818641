import { z } from "zod";

export const masterMetadataSchema = z.object({
    manufacturer: z.string(),
    owner: z.string(),
    route_of_administration: z.string(),
    scientific_name: z.string(),
    strength: z.string(),
    source: z.string(),
    ddc_code: z.string(),
    ingredient: z.string(),
    ddc_last_updated: z.string()
})

export const metadataSchema = z.object({
    purchase_invoice_id: z.string(),
    maximum_discount_type: z.string(),
    maximum_discount_value: z.number(),
    custom_barcode: z.string()
})

export const productionDateSchema = z.object({
    Time: z.date(),
    Valid: z.boolean()
})

export const quantitySchema = z.object({
    original: z.number(),
    bonus: z.number()
})

export const medicalItemSchema = z.object({
    id: z.string(),
    created_at: z.date(),
    medical_master_id: z.string(),
    pharmacy_code: z.string(),
    quantity: quantitySchema,
    batch_number: z.string(),
    expiry_date: z.date(),
    cost: z.number(),
    production_date: productionDateSchema,
    metadata: metadataSchema,
    trade_name: z.string(),
    unit: z.number(),
    unit_type: z.string(),
    master_metadata: masterMetadataSchema,
    barcode: z.string(),
    vat: z.number()
})

export const nonMedicalItemSchema = z.object({
    barcode:z.string(),
    batch_number:z.string(),
    cost:z.number(),
    created_at:z.string(),
    expiry_date:z.object({
        time:z.string(),
        valid:z.boolean()
    }),
    id:z.string(),
    master_metadata:z.object({
        category:z.string(),
        manufacturer:z.string(),
        owner:z.string(),
        sub_category:z.string()
    }),
    metadata: z.object({
        custom_barcode:z.string(),
        maximum_discount_type:z.string(),
        maximum_discount_value:z.number(),
        purchase_invoice_id:z.string()
    }),
    non_medical_master_id:z.string(),
    production_data: z.object({
        time:z.string(),
        valid:z.boolean()
    }),
    quantity:z.object({
        bonus:z.number(),
        original:z.number()
    }),
    trade_name: z.string(),
    unit:z.number(),
    unit_type:z.string(),
    vat:z.number()
})

export const purchaseInvoiceSingleSchema = z.object({
    id: z.string(),
    pharmacy_code: z.string(),
    supplier_id: z.string(),
    invoice_number: z.string(),
    invoice_date: z.date(),
    delivery_date: z.date(),
    payment_mode: z.string(),
    payment_type: z.string(),
    medical_items: z.array(medicalItemSchema),
    non_medical_items: z.array(nonMedicalItemSchema),
    supplier_name: z.string()
})


export type PurchaseInvoiceSingleSchema = z.infer<typeof purchaseInvoiceSingleSchema>;

export const responseSchema = z.object({
    status: z.boolean(),
    message: z.string(),
    data: purchaseInvoiceSingleSchema
})



export const PurchaseInvoiceManySchema = z.object({
    calculated_payment_date: z.object({
        Time: z.string(),
        Valid: z.boolean()
    }),
    created_at: z.string(),
    delivery_date: z.string(),
    id: z.string(),
    invoice_date: z.string(),
    invoice_number: z.string(),
    payment_mode: z.string(),
    payment_type: z.string(),
    pharmacy_code: z.string(),
    supplier_id: z.string(),
    supplier_name: z.string(),
    full_count: z.number()
})

export type PurchaseInvoiceManySchema = z.infer<typeof PurchaseInvoiceManySchema>;