import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Route as offerRoute } from "@/routes/offers/index";
import dayjs from "dayjs";
import { useNavigate } from "@tanstack/react-router";
import AddOffer from "./components/add-offer";

type Offer = {
    id: number;
    name: string;
    description: string;
    status: 'active' | 'inactive' | 'draft';
    created_at: string;
    full_count?: number;
};
  
const mockOffers: Offer[] = [
    {
      id: 1,
      name: "Diabetes Care Bundle",
      description: "15% off on all diabetes medications and supplies",
      status: "active",
      created_at: "2024-03-15T10:00:00Z",
      full_count: 8
    },
    {
      id: 2,
      name: "First Aid Kit Promotion",
      description: "Buy First Aid Kit and get 20% off on bandages",
      status: "active",
      created_at: "2024-03-14T15:30:00Z",
      full_count: 8
    },
    {
      id: 3,
      name: "Senior Citizen Special",
      description: "Additional 10% off for seniors on all medications",
      status: "draft",
      created_at: "2024-03-13T09:15:00Z",
      full_count: 8
    },
    {
      id: 4,
      name: "Vitamin Bundle",
      description: "Buy 2 Get 1 Free on all vitamins",
      status: "inactive",
      created_at: "2024-03-12T14:20:00Z",
      full_count: 8
    },
    {
      id: 5,
      name: "Prescription Loyalty",
      description: "Double points on prescription refills",
      status: "active",
      created_at: "2024-03-11T11:45:00Z",
      full_count: 8
    }
];

const Component = () => {
  const offers = mockOffers
  const searchParams = offerRoute.useSearch<any>(); //TODO: To remove type any. Added just to fix build.
  const navigate = useNavigate();
  const [search, setSearch] = useState(searchParams.search);
//   const [search, setSearch] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("created_at");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = offers.slice().sort((a, b) => {
    if (orderBy === "created_at") {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <div className="overflow-y-scroll h-screen pb-48">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Offers</h2>
          <p className="text-muted-foreground">
            Here is a list of all your offers.
          </p>
        </div>
        <AddOffer />
      </div>
      <hr className="my-4" />
      <Input
        placeholder="Search Offers..."
        className="h-8 w-[150px] lg:w-[250px]"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            navigate({
              search: {
                ...searchParams,
                search,
              },
            });
          }
        }}
      />

      <Table className="mt-4 text-sm">
        <TableHeader>
          <TableRow>
            <TableHead>Offer Name</TableHead>
            <TableHead>Description</TableHead>
            <TableHead>Status</TableHead>
            <TableHead
              onClick={() => handleRequestSort("created_at")}
              style={{ cursor: "pointer" }}
            >
              <div className="flex items-center">
                <span>Created At</span>
                {orderBy === "created_at" && (
                  <CaretSortIcon
                    className={`ml-2 h-4 w-4 ${
                      order === "asc" ? "rotate-180" : ""
                    }`}
                  />
                )}
              </div>
            </TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedData.map((offer, index) => (
            <TableRow key={index}>
              <TableCell>{offer.name}</TableCell>
              <TableCell>{offer.description}</TableCell>
              <TableCell>{offer.status}</TableCell>
              <TableCell>
                {dayjs(offer.created_at).format("DD MMM, YYYY")}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <hr className="my-0" />

      <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {searchParams.page} of{" "}
          {offers.length > 0
            ? Math.ceil(offers[0].full_count! / searchParams.pageSize)
            : 1}
        </div>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page - 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page + 1,
                },
              });
            }}
            disabled={
              offers.length === 0 ||
              offers[0].full_count! <= searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: Math.ceil(offers[0].full_count! / searchParams.pageSize),
                },
              });
            }}
            disabled={
              offers.length === 0 ||
              offers[0].full_count! <= searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Component;