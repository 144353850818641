import { z } from "zod";

export const CustomerWiseRevenue = z.object({
  id: z.string(),
  name: z.string(),
  phone: z.string(),
  emirates_id: z.string(),
  customer_revenue: z.number(),
  full_count: z.number(),
});

export type CustomerWiseRevenue = z.infer<typeof CustomerWiseRevenue>;
