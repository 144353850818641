import { z } from "zod"

export const endTimeSchema = z.object({
  Time: z.string(),
  Valid: z.boolean()
})

export const amountSchema = z.object({
  startAmount: z.number(),
  endAmount: z.number()
})

export const shiftDetails = z.object({
  cashDrawerAmount: amountSchema,
  cardAmount: amountSchema,
  shiftName: z.string(),
  cashRegisterId: z.string(),
  payouts: z.number()
})

export const shiftDetailsSchema = z.object({
  id: z.string(),
  pharmacy_code: z.string(),
  created_at: z.string(),
  start_time: z.string(),
  end_time: endTimeSchema,
  user_id: z.string(),
  shift_details: shiftDetails,
  system_ended: z.boolean(),
  calculated_hours: z.number(),
  full_count: z.number(),
})

export const userDetailsSchema = z.object({
  email: z.string(),
  first_name: z.string(),
  username: z.string(),
  last_name: z.string(),
})


export const ShiftHistoryResponseSchema = z.object({
  shift_details: shiftDetailsSchema,
  user_details: userDetailsSchema
})



export const responseSchema = z.object({
  status: z.boolean(),
  message: z.string(),
  data: z.array(ShiftHistoryResponseSchema)
})

export type ShiftHistoryResponseSchema = z.infer<typeof ShiftHistoryResponseSchema>