import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from "@/components/ui/breadcrumb";
import { Drawer, DrawerClose, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Route as PurchaseOrderListRoute } from "@/routes/reports/purchase-order-list";
import { Link, useNavigate } from "@tanstack/react-router";
import { PurchaseOrderManyType, PurchaseOrderSingleSchema, PurchaseOrderSingleType } from "./type";
import { PropsWithChildren, useEffect, useState } from "react";
import { LoadState } from "@/utils/load_state";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { Loading } from "@/layouts/loading";
import { Tabs } from "@radix-ui/react-tabs";
import { TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import OrderInvoicePDF from "./components/order-invoice";
import { pdf } from "@react-pdf/renderer";


const apiUrl = import.meta.env.VITE_API_URL as string;


function DrawerDataRenderer({ purchaseOrder }: { purchaseOrder: PurchaseOrderManyType }) {
    const [pharmacyName, setPharmacyName] = useState<string>("")
    const [detailedData, setDetailedData] = useState<PurchaseOrderSingleType>();
    const [detailedDataLoading, setDetailedDataLoading] = useState(LoadState.Loading)
    const [medicalItems, setMedicalItems] = useState<PurchaseOrderSingleType["items"]>([])
    const [nonMedicalItems, setNonMedicalItems] = useState<PurchaseOrderSingleType["items"]>([])
    const getOrder = async () => {
        setDetailedDataLoading(LoadState.Loading)
        try {
            const data = await httpClient.post(`${apiUrl}/public/pharmacy/purchase_order/get/one`, {
                purchase_order_id: purchaseOrder.id
            })
            const pharmacyData = await httpClient.post(`${apiUrl}/public/pharmacy`, {
                pharmacy_code: getPharmacyID()
            })
            setPharmacyName(pharmacyData.data.data.metadata.pharmacy_name)


            const newData = PurchaseOrderSingleSchema.parse(data.data.data)
            const newMedicalItems: PurchaseOrderSingleType["items"] = [];
            const newNonMedicalItems: PurchaseOrderSingleType["items"] = [];
            newData.items.forEach((item) => {
                if (item.type === "MEDICAL") {
                    newMedicalItems.push(item)
                }
                if (item.type === "NON_MEDICAL") {
                    newNonMedicalItems.push(item)
                }
            })
            setMedicalItems(newMedicalItems)
            setNonMedicalItems(newNonMedicalItems)
            try {
                setDetailedData(data.data.data as PurchaseOrderSingleType)

            } catch (e) {
                console.error("Error parsing data:", e)
            }
            setDetailedDataLoading(LoadState.Loaded)
        } catch (e) {
            console.error("Error fetching purchase order:", e);
            setDetailedDataLoading(LoadState.Error)
        }
    }

    const Title = (props: PropsWithChildren) => {
        return <div className="text-md font-normal text-[#848484]">
            {props.children}
        </div>
    }
    const Content = (props: PropsWithChildren) => {
        return <div className="text-md font-normal text-black ">
            {props.children}
        </div>
    }
    const Cell = (props: PropsWithChildren) => {
        return <div className="flex flex-col gap-1 items-start justify-center">
            {props.children}
        </div>
    }
    const Row = (props: PropsWithChildren) => {
        return <div className="grid grid-cols-2 gap-6 py-1 items-start justify-start border-b border-[#D9D9D9] px-4">
            {props.children}
        </div>
    }

    useEffect(() => {
        getOrder()
    }, [purchaseOrder])

    return <DrawerContent className="focus-visible:outline-none w-fit min-w-[60vw] max-w-[80vw] font-lexend">
        <DrawerClose asChild className="fixed inset-y-2 right-4">
            <Button className="h-fit aspect-square p-2 grid place-items-center bg-[#eceff8] hover:bg-[#eceff8]">
                <X className="text-[#667085] h-6 w-6"></X>
            </Button>
        </DrawerClose>

        {
            detailedDataLoading === LoadState.Loading && <Loading></Loading>
        }
        {
            detailedDataLoading === LoadState.Error && <div>Failed to load data</div>
        }
        {
            detailedDataLoading === LoadState.Loaded && detailedData &&
            <div className="grid grid-cols-[6fr_25vw] h-full w-full">
                <div className="flex flex-col gap-4 border-r border-r-[#D9D9D9] px-2">
                    <DrawerHeader className="flex flex-row justify-between w-full">
                        <DrawerTitle>PO Details</DrawerTitle>
                        <div className="text-[#848484] text-lg  font-normal">
                            Total Items :   <span className="text-[#1458DD]">{purchaseOrder.items_count}</span>
                        </div>
                    </DrawerHeader>
                    <div className="h-full relative mb-4">
                        <Tabs defaultValue="medical" className="">
                            <div className="w-full grid place-items-center">
                                <TabsList>
                                    <TabsTrigger value="medical">Medical</TabsTrigger>
                                    <TabsTrigger value="non-medical">Non-Medical</TabsTrigger>
                                </TabsList>
                            </div>
                            <TabsContent value="medical">
                                {
                                    medicalItems.length === 0 ?
                                        <div className="flex items-center justify-center h-full mt-10">
                                            <div className="text-[#848484] text-lg font-normal">
                                                No Medical Items Found
                                            </div>
                                        </div> :
                                        <div className="max-h-[70vh] overflow-y-auto">
                                            <Table className="text-sm" >
                                                <TableHeader>
                                                    <TableRow className="hover:bg-inherit">
                                                        <TableHead>SL No.</TableHead>
                                                        <TableHead>Product Name</TableHead>
                                                        <TableHead>Manufacturer</TableHead>
                                                        <TableHead>Owner</TableHead>
                                                        <TableHead>Quantity</TableHead>
                                                        <TableHead>Bonus</TableHead>
                                                        <TableHead>Intended Cost</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody className="">
                                                    {
                                                        medicalItems.map((item, idx) => {
                                                            return (
                                                                <TableRow key={idx} className="font-normal cursor-pointer">
                                                                    <TableCell className="text-center">
                                                                        {idx + 1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.trade_name}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.manufacturer}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.owner}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.quantity}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.bonus_quantity}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.intended_cost || "N/A"}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                }
                            </TabsContent>
                            <TabsContent value="non-medical">
                                {
                                    nonMedicalItems.length === 0 ?
                                        <div className="flex items-center justify-center h-full mt-10">
                                            <div className="text-[#848484] text-lg font-normal">
                                                No Non-Medical Items Found
                                            </div>
                                        </div> :
                                        <div className="max-h-[70vh] overflow-y-auto">
                                            <Table className="text-sm" >
                                                <TableHeader>
                                                    <TableRow className="hover:bg-inherit">
                                                        <TableHead>SL No.</TableHead>
                                                        <TableHead>Product Name</TableHead>
                                                        <TableHead>Manufacturer</TableHead>
                                                        <TableHead>Owner</TableHead>
                                                        <TableHead>Quantity</TableHead>
                                                        <TableHead>Bonus</TableHead>
                                                        <TableHead>Intended Cost</TableHead>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody className="">
                                                    {
                                                        nonMedicalItems.map((item, idx) => {
                                                            return (
                                                                <TableRow key={idx} className="font-normal cursor-pointer">
                                                                    <TableCell className="text-center">
                                                                        {idx + 1}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item.trade_name}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.manufacturer || "N/A"}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.owner || "N/A"}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.quantity}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.bonus_quantity}
                                                                    </TableCell>
                                                                    <TableCell className="text-center">
                                                                        {item.intended_cost || "N/A"}
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                }
                            </TabsContent>

                        </Tabs>
                        <div className="w-full absolute flex flex-row gap-2 items-center justify-center bottom-0">
                            <Button onClick={() => {
                                const pdfDoc = <OrderInvoicePDF
                                    details={{
                                        order_number: purchaseOrder.purchase_order_number,
                                        supplier: {
                                            name: purchaseOrder.supplier_info.name,
                                            contact_person_name: purchaseOrder.supplier_info.metadata.contact_person_name,
                                            phone_number: purchaseOrder.supplier_info.metadata.phone_number,
                                        }
                                    }}
                                    medicalItems={medicalItems.map(item => {
                                        return {
                                            trade_name: item.trade_name,
                                            bonus: item.bonus_quantity,
                                            intended_cost: item.intended_cost,
                                            quantity: item.quantity,
                                            manufacturer: item.manufacturer,
                                            owner: item.owner,
                                        }
                                    })}
                                    nonMedicalItems={nonMedicalItems.map(item => {
                                        return {
                                            trade_name: item.trade_name,
                                            bonus: item.bonus_quantity,
                                            intended_cost: item.intended_cost,
                                            quantity: item.quantity,
                                            manufacturer: item.manufacturer,
                                            owner: item.owner,
                                        }
                                    })}
                                    pharmacyName={pharmacyName}
                                />
                                pdf(pdfDoc).toBlob().then((blob) => {
                                    const url = URL.createObjectURL(blob)
                                    window.open(url, '_blank')
                                })
                            }}>
                                Print Order
                            </Button>
                            <Link to={`/draft-purchase-invoice?POId=${purchaseOrder.id}`}>
                                <Button>
                                    Create Invoice
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col px-1">
                    <DrawerHeader>
                        <DrawerTitle>Order Details</DrawerTitle>
                    </DrawerHeader>
                    <Row>
                        <Cell>
                            <Title>
                                Purchase Order Number
                            </Title>
                            <Content>
                                {purchaseOrder.purchase_order_number}
                            </Content>
                        </Cell>
                        <Cell>
                            <Title>
                                Supplier Name
                            </Title>
                            <Content>
                                {purchaseOrder.supplier_info.name || "N/A"}
                            </Content>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <Title>
                                Contact Person
                            </Title>
                            <Content>
                                {purchaseOrder.supplier_info.metadata.contact_person_name || "N/A"}
                            </Content>
                        </Cell>
                        <Cell>
                            <Title>
                                Phone Number
                            </Title>
                            <Content>
                                {purchaseOrder.supplier_info.metadata.phone_number || "N/A"}
                            </Content>
                        </Cell>
                    </Row>
                </div>
            </div>
        }
    </DrawerContent>
}


export default function Component() {
    const data = PurchaseOrderListRoute.useLoaderData();
    const searchParams = PurchaseOrderListRoute.useSearch();
    const navigate = useNavigate();

    const [drawerData, setDrawerData] = useState<PurchaseOrderManyType>()

    return <>
        <Drawer
            direction="right"
        >
            {
                drawerData &&
                <DrawerDataRenderer purchaseOrder={drawerData}></DrawerDataRenderer>
            }
            <div className="flex items-center justify-between">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                onClick={() => {
                                    navigate({
                                        to: "/invoicing",
                                    });
                                }}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                Reports Overview
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>Purchase Order List</BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
            </div>
            <hr className="my-4" />
            <Table className="mt-4">
                <TableHeader>
                    <TableRow className="hover:bg-inherit">
                        <TableHead rowSpan={2}>Purchase Order No.</TableHead>
                        <TableHead rowSpan={1} colSpan={3} className="text-center">Supplier Info</TableHead>
                        <TableHead rowSpan={2}>Items</TableHead>
                    </TableRow>
                    <TableRow className="hover:bg-inherit">
                        <TableHead className="text-center">Supplier Name</TableHead>
                        <TableHead className="text-center">Contact Person</TableHead>
                        <TableHead className="text-center">Phone Number</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {data.map((item, index) => {
                        return (
                            // @ts-ignore
                            <DrawerTrigger key={index} type="" asChild className="w-full">
                                <TableRow className="font-medium cursor-pointer" onClick={() => {
                                    setDrawerData(item)
                                }}>

                                    <TableCell>
                                        {item.purchase_order_number}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {item.supplier_info.name}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {item.supplier_info.metadata.contact_person_name || "N/A"}
                                    </TableCell>
                                    <TableCell className="text-center">
                                        {item.supplier_info.metadata.phone_number || "N/A"}
                                    </TableCell>
                                    <TableCell>
                                        {item.items_count}
                                    </TableCell>

                                </TableRow>
                            </DrawerTrigger>
                        );
                    })}
                </TableBody>
            </Table>
        </Drawer>
    </>
}