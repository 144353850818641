import { useEffect, useState } from "react";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { X } from "lucide-react";
import { FilePenLine } from "lucide-react"; // Add this import
import { Route as singleItemInventory } from '@/routes/inventory/item/$type.$itemId'
import { MedicalInventoryItemData, NonMedicalInventoryItemData, NonMedicalInventoryPriceHistory } from "../model";
import { createNewPriceForNonMedicalItem, getMedicalInventoryItemDetails, getNonMedicalInventoryItemDetails, getNonMedicalInventoryItemPriceHistory, updateMedicalInventoryItem, updateNonMedicalInventoryItem } from "../api";
import { useRouter } from "@tanstack/react-router";
import { Separator } from "@/components/ui/separator";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";

const EditItem = ({ itemId, type, disabled }: { itemId: string; type: "MEDICAL" | "NON_MEDICAL"; disabled: boolean }) => {
    const { toast } = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [newPrice, setNewPrice] = useState(0);
    const [effectiveFrom, setEffectiveFrom] = useState(dayjs().toDate());

    const router = useRouter();

    const [item, setItem] = useState<MedicalInventoryItemData | NonMedicalInventoryItemData>();
    const [nonMedicalPriceHistory, setNonMedicalPriceHistory] = useState<NonMedicalInventoryPriceHistory[]>([]);


    useEffect(() => {
        async function get() {
            if (type === "MEDICAL") {
                const data = await getMedicalInventoryItemDetails(itemId);
                setItem(data);
            } else {
                const data = await getNonMedicalInventoryItemDetails(itemId);
                const priceHistory = await getNonMedicalInventoryItemPriceHistory(itemId);
                setItem(data);
                setNonMedicalPriceHistory(priceHistory);
            }
        }
        if (isOpen) {
            get();
        }
    }, [isOpen, itemId, type]);
    const [discountStr, setDiscountStr] = useState<string>(item?.metadata.maximum_discount_value.toString() || "");

    return (
        <>
            <Sheet open={isOpen} onOpenChange={setIsOpen}>
                <SheetTrigger>
                    <Button
                        //     onClick={() => {
                        //     disabled || setIsOpen(true);
                        // }} 
                        variant="ghost" disabled={disabled}>
                        <FilePenLine className="h-4 w-4" style={{
                            cursor: disabled ? "not-allowed" : "pointer"
                        }} />
                    </Button>
                </SheetTrigger>
                <SheetContent
                    side={"bottom"}
                    className="pb-24"
                >
                    <SheetHeader>
                        <SheetTitle>Edit Item</SheetTitle>
                        <SheetDescription>
                            Update item details here
                        </SheetDescription>
                        <SheetClose
                            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </SheetClose>
                    </SheetHeader>

                    <hr className="my-4" />

                    <div className="grid grid-cols-2 space-x-4">
                        <div className="space-y-4 mt-4">
                            <div>
                                <p className="text-sm font-semibold mb-2">Discount</p>
                                <Input type="string" disabled={item?.metadata.maximum_discount_type === "NONE"} placeholder="Enter discount value" value={discountStr} onChange={(e) => {
                                    if (item) {
                                        const value = e.target.value;
                                        let valueNum = 0;
                                        if (value === "") {
                                            setDiscountStr(value);
                                            return;
                                        }
                                        valueNum = Number(value)
                                        if (isNaN(valueNum)) {
                                            return;
                                        }
                                        if (valueNum < 0) {
                                            return;
                                        }
                                        if (item.metadata.maximum_discount_type === "PERCENTAGE" && valueNum > 100) {
                                            return;
                                        }
                                        if (item.metadata.maximum_discount_type !== "PERCENTAGE" && valueNum > item.cost) {
                                            return;
                                        }
                                        setDiscountStr(value);
                                        (e.target.value && item) ? setItem({ ...item, metadata: { ...item.metadata, maximum_discount_value: valueNum } }) : item && setItem({ ...item, metadata: { ...item.metadata, maximum_discount_value: 0 } });
                                    }
                                }} />
                            </div>

                            <div>
                                <p className="text-sm font-semibold mb-2">Discount Type</p>
                                <Select value={item?.metadata.maximum_discount_type} onValueChange={(value) => {
                                    item && setItem({ ...item, metadata: { ...item.metadata, maximum_discount_type: value as "NONE" | "PERCENTAGE" | "VALUE" | "ALWAYS" } });
                                }}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select discount type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="NONE">None</SelectItem>
                                        <SelectItem value="PERCENTAGE">Percentage</SelectItem>
                                        <SelectItem value="VALUE">Value</SelectItem>
                                        <SelectItem value="ALWAYS">Always</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div>
                                <p className="text-sm font-semibold mb-2">Custom Barcode</p>
                                <Input placeholder="Enter custom barcode" value={item?.metadata.custom_barcode} onChange={(e) => {
                                    item && setItem({ ...item, metadata: { ...item.metadata, custom_barcode: e.target.value } });
                                }} />
                            </div>
                            <div>
                                <p className="text-sm font-semibold mb-2">Cost of Item</p>
                                <Input placeholder="Enter custom barcode" value={item?.cost} onChange={(e) => {
                                    item && e.target.value ? setItem({ ...item, cost: parseFloat(e.target.value) }) : item && setItem({ ...item, cost: 0 });
                                }} />
                            </div>
                        </div>
                        {type === "NON_MEDICAL" && <div>
                            <div className='flex items-center justify-between'>
                                <div>
                                    <p className="font-semibold">Past price updates</p>
                                    <p className="text-gray-500">
                                        Item price updates history
                                    </p>
                                </div>
                                <Button variant={'secondary'} onClick={() => setShowUpdateForm(!showUpdateForm)}>
                                    {showUpdateForm ? 'Hide' : 'Show'} Update Form
                                </Button>
                            </div>
                            {showUpdateForm && <div>
                                <hr className='my-4' />
                                <div className='flex gap-x-4'>
                                    <p className='text-gray-600 text-sm'>New Price</p>
                                    <Input type='number' placeholder='Enter price' value={newPrice} onChange={(e) => {
                                        e.target.value ? setNewPrice(parseFloat(e.target.value)) : setNewPrice(0);
                                    }} />
                                    <Button disabled={loading} onClick={async () => {
                                        try {
                                            setLoading(true);
                                            await createNewPriceForNonMedicalItem(newPrice, itemId);
                                            toast({
                                                title: "Price updated successfully",
                                                description: "Price has been updated successfully.",
                                                duration: 5000,
                                            });
                                            setLoading(false);
                                            router.invalidate();
                                        } catch (e) {
                                            console.error(e);
                                            toast({
                                                title: "Failed to update price",
                                                description: "An error occurred while updating the price. Please try again later.",
                                                duration: 5000,
                                            })
                                            setLoading(false);
                                        }
                                    }}>Update Price</Button>
                                </div>
                            </div>}
                            <hr className='my-4' />
                            <div className='flex flex-col space-y-8'>
                                {nonMedicalPriceHistory.map((price, index, array) => (
                                    <div className='flex gap-x-4' key={index}>
                                        <p className='text-gray-600'>{dayjs(price.effective_from).format("DD MMM YYYY")}</p>
                                        <Separator className='mx-4 h-[120px] w-1 bg-gray-500' orientation="vertical" />
                                        <div>
                                            <p className='font-semibold text-gray-700'>{convertToCurrencyRounded(price.price)} {index < array.length - 1 ? `| ${price.price - array[index + 1].price > 0 ? 'Increased By' : 'Decreased By'} ${convertToCurrencyRounded(price.price - array[index + 1].price)}` : null}</p>
                                            {index === 0 && <div className='flex items-center space-x-4 mt-4'>
                                                <p className='text-gray-600 text-sm'>Current Active Price for your Pharmacy <br /> Effective from {dayjs(price.effective_from).format("DD MMM YYYY")}</p>
                                            </div>}
                                            {/* If index is not 0, get the effective from and to dates */}
                                            {index !== 0 && <div className='flex items-center space-x-4 mt-4'>
                                                <p className='text-gray-600 text-sm'>Effective till {dayjs(array[index - 1].effective_from).format("DD MMM YYYY")} from {dayjs(price.effective_from).format("DD MMM YYYY")}</p>
                                            </div>}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>

                    <Button
                        className="absolute bottom-2 right-2"
                        disabled={loading}
                        onClick={async () => {
                            try {
                                if (item?.cost === 0) {
                                    toast({
                                        title: "Invalid cost",
                                        description: "Cost of item cannot be zero.",
                                        duration: 5000,
                                    });
                                    return;
                                }
                                setLoading(true);
                                if (type === "MEDICAL") {
                                    await updateMedicalInventoryItem(item as MedicalInventoryItemData);
                                } else {
                                    await updateNonMedicalInventoryItem(item as NonMedicalInventoryItemData);
                                }
                                toast({
                                    title: "Item updated successfully",
                                    description: "Item has been updated successfully.",
                                    duration: 5000,
                                });
                                setLoading(false);
                                setIsOpen(false);
                                router.invalidate();
                            } catch (e) {
                                console.error(e);
                                toast({
                                    title: "Failed to update item",
                                    description: "An error occurred while updating the item. Please try again later.",
                                    duration: 5000,
                                })
                            }
                        }}
                    >
                        Update Item
                    </Button>
                </SheetContent>
            </Sheet>
        </>
    );
};

export default EditItem;