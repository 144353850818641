import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/settings/component";
import { UsersListSchema } from "@/screens/settings/components/types";
import { Loading } from "@/layouts/loading";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";

const API_URL = import.meta.env.VITE_API_URL as string;

export const Route = createFileRoute("/settings/")({
  component: Component,
  loader: async () => {
    const pharmacyCode = getPharmacyID();
    if (!pharmacyCode) {
      throw new Error("Pharmacy code is missing");
    }

    try {
      const response = await httpClient.post(
        `${API_URL}/public/pharmacy/users/get/list`,
        { pharmacy_code: pharmacyCode }
      );

      const data = response.data;

      if (!data.status || !Array.isArray(data.data)) {
        throw new Error("API Fetch Error: Invalid response structure");
      }

      return { users: data.data as UsersListSchema[] };
    } catch (error) {
      console.error("Loader error:", error);
      throw new Error("Failed to fetch users list");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
