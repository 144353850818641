import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { VoidSaleSchema } from "./types";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getVoidSales = async (
    offset: number,
    limit: number,
): Promise<VoidSaleSchema[]> => {
    try {
        const response = await httpClient.post(
            `${apiUrl}/public/pharmacy/sales/get/void?offset=${offset}&limit=${limit}`,
            {
                pharmacy_code: getPharmacyID(),
            }
        );
        return response.data.data.map((item: any) => VoidSaleSchema.parse(item));
    } catch (error) {
        console.error("Error fetching void sales:", error);
        throw new Error("Failed to fetch void sales");
    }
}