import { Loading } from "@/layouts/loading";
import { getVoidSales } from "@/screens/reports/void-sales/api";
import { VoidSaleParams } from "@/screens/reports/void-sales/model";
import { VoidSaleSchema } from "@/screens/reports/void-sales/types";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/void-sales/component";

export const Route = createFileRoute("/reports/void-sales/")({
    component: Component,
    validateSearch: VoidSaleParams,
    loaderDeps: ({ search: { page, pageSize } }) => ({
        page,
        pageSize,
    }),
    loader: async (params): Promise<VoidSaleSchema[]> => {
        const response = await getVoidSales(
            params.deps.page - 1,
            params.deps.pageSize
        );
        return response;
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
});
