import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MoveRightIcon, PlusCircleIcon } from "lucide-react";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import { Route as OutOfStockRoute } from "@/routes/reports/out-of-stock/index";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import {
  OutOfStockMedicalInventorSchema,
  OutOfStockNonMedicalInventorSchema,
} from "./type";
import { useState } from "react";

const parseDate = (
  date:
    | string
    | number
    | Date
    | dayjs.Dayjs
    | { Time: string; Valid: boolean }
    | null
    | undefined
) => {
  if (typeof date === "string") {
    return dayjs(date, "YYYY-MM-DD"); // Adjust format as necessary
  }
  return dayjs(); // Default to current date if invalid
};

const Component = () => {
  const searchParams = OutOfStockRoute.useSearch();
  const data = OutOfStockRoute.useLoaderData();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("expiry_date");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = data.data.slice().sort((a, b) => {
    if (orderBy === "expiry_date") {
      const dateA = parseDate(a.expiry_date).valueOf();
      const dateB = parseDate(b.expiry_date).valueOf();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <div className="overflow-y-scroll h-screen pb-48">
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  navigate({
                    to: "/invoicing",
                  });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Reports Overview
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Out Of Stock</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <hr className="my-4" />
      <div className="w-full grid mb-4">
        <Tabs defaultValue={searchParams.type}>
          <TabsList>
            <TabsTrigger
              value={"MEDICAL"}
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    type: "MEDICAL",
                  },
                });
              }}
            >
              Medical
            </TabsTrigger>
            <TabsTrigger
              value={"NON_MEDICAL"}
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    type: "NON_MEDICAL",
                  },
                });
              }}
            >
              Non Medical
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      <Table className="mt-4 text-sm">
        <TableHeader>
          <TableRow>
            <TableHead>Trade Name</TableHead>
            <TableHead>Batch Number</TableHead>
            <TableHead
              onClick={() => handleRequestSort("expiry_date")}
              style={{ cursor: "pointer" }}
            >
              <div className="flex items-center">
                <span>Expiry Date</span>
                {orderBy === "expiry_date" && (
                  <CaretSortIcon
                    className={`ml-2 h-4 w-4 ${
                      order === "asc" ? "rotate-180" : ""
                    }`}
                  />
                )}
              </div>
            </TableHead>
            <TableHead>Unit Type</TableHead>
            <TableHead>Barcode</TableHead>
            <TableHead>Price</TableHead>
            <TableHead>Cost</TableHead>
            <TableHead>Actual Quantity</TableHead>
            <TableHead>Sold Quantity</TableHead>
            <TableHead>Available Quantity</TableHead>
            <TableHead>Last Sold On</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedData.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item.trade_name}</TableCell>
                <TableCell>{item.batch_number}</TableCell>
                <TableCell>
                  {dayjs(
                    typeof item.expiry_date === "string"
                      ? item.expiry_date
                      : item.expiry_date.Time
                  ).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{item.unit_type}</TableCell>
                <TableCell>{item.barcode}</TableCell>
                <TableCell>{convertToCurrencyRounded(item.price)}</TableCell>
                <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                <TableCell>
                  {searchParams.type === "MEDICAL"
                    ? (item as OutOfStockMedicalInventorSchema)
                        .mis_sold_quantity
                    : (item as OutOfStockNonMedicalInventorSchema)
                        .nmis_sold_quantity}
                </TableCell>
                <TableCell>
                  {item.quantity.bonus + item.quantity.original}
                </TableCell>
                <TableCell>
                  {searchParams.type === "MEDICAL"
                    ? (item as OutOfStockMedicalInventorSchema)
                        .mis_available_stock
                    : (item as OutOfStockNonMedicalInventorSchema)
                        .nmis_available_stock}
                </TableCell>
                <TableCell>
                  {dayjs(
                    searchParams.type === "MEDICAL"
                      ? (item as OutOfStockMedicalInventorSchema).last_sold_date
                      : (item as OutOfStockNonMedicalInventorSchema)
                          .last_sold_date
                  ).format("DD/MM/YYYY")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <hr />

      <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
        <div className="flex items-center justify-center w-full fixed bottom-6">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {searchParams.page} of{" "}
            {data.data.length > 0
              ? Math.ceil(data.data[0].full_count / searchParams.pageSize)
              : 1}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page - 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page + 1,
                  },
                });
              }}
              disabled={
                data.data.length === 0 ||
                data.data[0].full_count <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: Math.ceil(
                      data.data[0].full_count / searchParams.pageSize
                    ),
                  },
                });
              }}
              disabled={
                data.data.length === 0 ||
                data.data[0].full_count <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Component;
