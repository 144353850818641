import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { Route as patientsOneRoute } from "@/routes/patients/patient-info/$patientId";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Ban } from "lucide-react";
import EditPatient from "./EditPatient";

const CompanyDetails: React.FC = () => {
  const { patientDetails, salesInfo } = patientsOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = patientsOneRoute.useSearch();

  return (
    <div className="col-span-1 lg:max-w-[400px] overflow-hidden border-r-[0.5px] h-full border-gray-200 shadow-transparent">
      <CardHeader className="p-0 space-y-0 flex flex-row items-start ">
        <div className="grid gap-0.5 mb-4">
          <CardTitle className="group flex items-center font-bold text-2xl">
            {patientDetails.name}
          </CardTitle>
        </div>
      </CardHeader>
      <hr className="mr-4" />
      <CardContent className="p-3 pl-0 text-sm">
        <div className="grid gap-2">
          <div className="grid grid-cols-5 gap-y-4 gap-x-12 my-2">
            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Full Name
            </p>
            <p className=" text-gray-500 font-medium col-span-3">
              {patientDetails.name}
            </p>

            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Emirates ID
            </p>
            <p className=" text-gray-500 font-medium col-span-3">
              {patientDetails.emirates_id}
            </p>

            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Address
            </p>
            <p className=" text-gray-500 font-medium col-span-3">
              {patientDetails.metadata.address}
            </p>
            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Gender
            </p>
            <p className=" text-gray-500 font-medium col-span-3">
              {patientDetails.metadata.gender.toUpperCase()}
            </p>
            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Date of Birth
            </p>
            <p className=" text-gray-500 font-medium col-span-3">
              {dayjs(patientDetails.metadata.date_of_birth).isValid() ? dayjs(patientDetails.metadata.date_of_birth).format("DD MMM, YYYY") : "Not Available"}
            </p>

            <p className="text-gray-500 whitespace-nowrap col-span-2">
              Contact details
            </p>
            <div className="flex flex-col col-span-3">
              <p className=" text-gray-500 font-medium">
                {patientDetails.phone}
              </p>
            </div>
            <div>
              <EditPatient patient_id={patientDetails.id}/>
            </div>
          </div>
        </div>
      </CardContent>
    </div>
  );
};

export default CompanyDetails;
