// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/inventory/add-stock/component";
import { Loading } from "@/layouts/loading";

export const Route = createFileRoute("/inventory/add-stock/")({
    component: Component,
    // loaderDeps: ({ search: { page, pageSize, search, expiring_in_30_days, type, expired } }) => ({ page, pageSize, search, expiring_in_30_days, type, expired }),
    // loader: async (params): Promise<InventorySchema> => {
    //     if (params.deps.type === "MEDICAL") {
    //         if (params.deps.expiring_in_30_days) {
    //             const response = await getAvailableMedicalInventoryExpiring(params.deps.page - 1, params.deps.pageSize);
    //             return {
    //                 type: "MEDICAL",
    //                 data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "MEDICAL"
    //                     }
    //                 }),
    //             };
    //         }
    //         if (params.deps.expired) {
    //             const response = await getAvailableMedicalInventoryExpired(params.deps.page - 1, params.deps.pageSize);
    //             return {
    //                 type: "MEDICAL",
    //                 data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "MEDICAL"
    //                     }
    //                 }),
    //             };
    //         }
    //         const response = await getAvailableMedicalInventory(params.deps.page - 1, params.deps.pageSize);
    //         return {
    //             type: "MEDICAL",
    //             data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "MEDICAL"
    //                     }
    //                 }),
    //         };
    //     } else {
    //         if (params.deps.expiring_in_30_days) {
    //             const response = await getAvailableNonMedicalInventoryExpiring(params.deps.page - 1, params.deps.pageSize);
    //             return {
    //                 type: "NON_MEDICAL",
    //                 data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "NON_MEDICAL"
    //                     }
    //                 }),
    //             }
    //         }
    //         if (params.deps.expired) {
    //             const response = await getAvailableNonMedicalInventoryExpired(params.deps.page - 1, params.deps.pageSize);
    //             return {
    //                 type: "NON_MEDICAL",
    //                 data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "NON_MEDICAL"
    //                     }
    //                 }),
    //             }
    //         }
    //         const response = await getAvailableNonMedicalInventory(params.deps.page - 1, params.deps.pageSize);
    //         return {
    //             type: "NON_MEDICAL",
    //             data: response.map(item => {
    //                     return {
    //                         ...item,
    //                         type: "NON_MEDICAL"
    //                     }
    //                 }),
    //         }
    //     }
    // },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});