import { useState } from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { CalendarIcon, X } from "lucide-react";
import { Select } from "@/components/ui/select";
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Calendar } from "@/components/ui/custom-calendar";
import dayjs from "dayjs";

const AddSupplier = () => {
  const { toast } = useToast();
  const creditPeriodOptions = [15, 30, 60, 90, 120, 180];
  const [supplier_name, set_supplier_name] = useState("");
  const [supplier_phone, set_supplier_phone] = useState("");
  const [contactPersonName, SetcontactPersonName] = useState("");
  const [creditPeriod, SetCreditPeriod] = useState("");
  const [monthlyPaymentDate, setMonthlyPaymentDate] = useState("");
  const [monthlyPaymentDateAsDate, setMonthlyPaymentDateAsDate] = useState(
    dayjs("2021/08/01").toDate()
  );
  const [isCreditAllowed, SetIsCreditAllowed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const SubmitSupplierDetails = async () => {
    setLoading(true);
    const creditPeriodInt = isCreditAllowed
      ? parseInt(creditPeriod, 10)
      : undefined;
    const monthlyPaymentDateInt = isCreditAllowed
      ? parseInt(monthlyPaymentDate, 10)
      : undefined;

    try {
      const response = await httpClient.post(
        `${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/create`,
        {
          credit_terms: {
            credit_period: creditPeriodInt,
            is_credit_allowed: isCreditAllowed,
            monthly_payment_date: monthlyPaymentDateInt,
          },
          name: supplier_name,
          metadata: {
            contact_person_name: contactPersonName,
            phone_number: supplier_phone,
          },
          pharmacy_code: getPharmacyID(),
        }
      );

      console.log("Response:", response.data);

      toast({
        title: "New Supplier Added",
        description: `Supplier ${supplier_name} has been added successfully`,
        duration: 5000,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to add supplier",
        duration: 5000,
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <>
      <Sheet open={isOpen}>
        <SheetTrigger>
          <Button onClick={() => setIsOpen(!isOpen)}>Add Suppliers</Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Add a new supplier</SheetTitle>
            <SheetDescription>
              The following details are required to add a new supplier
            </SheetDescription>
            <SheetClose
              className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-secondary"
              onClick={() => {
                set_supplier_name("");
                set_supplier_phone("");
                SetCreditPeriod("");
                setMonthlyPaymentDate("");
                SetIsCreditAllowed(false);
                setIsOpen(false);
              }}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </SheetHeader>
          <p className="text-sm font-semibold mt-4 mb-2">Supplier Name</p>
          <Input
            placeholder="Supplier Name"
            value={supplier_name}
            onChange={(e) => set_supplier_name(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Contact Person Name</p>
          <Input
            placeholder="Contact Person Name"
            value={contactPersonName}
            onChange={(e) => SetcontactPersonName(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Phone Number</p>
          <Input
            placeholder="Phone Number"
            value={supplier_phone}
            type="number"
            onChange={(e) => set_supplier_phone(e.target.value)}
          />
          <div className="mt-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={isCreditAllowed}
                onChange={() => SetIsCreditAllowed(!isCreditAllowed)}
                className="form-checkbox"
              />
              <span className="ml-2">Is Credit Allowed?</span>
            </label>
          </div>
          {isCreditAllowed && (
            <>
              <p className="text-sm font-semibold mt-4 mb-2">Credit Period</p>
              <Select onValueChange={(value) => SetCreditPeriod(value)}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Credit Period" />
                </SelectTrigger>
                <SelectContent>
                  {creditPeriodOptions.map((option) => (
                    <SelectItem key={option} value={option.toString()}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <p className="text-sm font-semibold mt-4 mb-2">
                Monthly Payment Date
              </p>
              <Popover open={calendarOpen}>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    onClick={(e) => {
                      e.stopPropagation(); // Ensure that clicking here does not propagate to the checkbox
                      setCalendarOpen(true);
                    }}
                    className="w-full pl-3 text-left font-normal"
                  >
                    <span>
                      {monthlyPaymentDate || "Select Monthly Payment Date"}
                    </span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-2 select-none">
                  <Calendar
                    mode="single"
                    selected={monthlyPaymentDateAsDate}
                    showOutsideDays={false}
                    disableNavigation
                    onSelect={(date) => {
                      setMonthlyPaymentDateAsDate(date!);
                      setMonthlyPaymentDate(dayjs(date).format("DD-MM-YYYY"));
                      setCalendarOpen(false);
                    }}
                    today={monthlyPaymentDateAsDate}
                  />
                </PopoverContent>
              </Popover>
            </>
          )}
          <Button
            className="absolute bottom-2 right-2"
            onClick={SubmitSupplierDetails}
            disabled={loading}
          >
            {loading ? "Loading..." : "Add Supplier"}
          </Button>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default AddSupplier;
