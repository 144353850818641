import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import {SalesPerItemMedicalInventorySchema, SalesPerItemNonMedicalInventorySchema} from "./type";

export const getMedicalSalesPerItem = async (offset: number, limit: number): Promise<SalesPerItemMedicalInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/reports/sales_per_item_medical?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as SalesPerItemMedicalInventorySchema[];
};

export const getNonMedicalSalesPerItem = async (offset: number, limit: number): Promise<SalesPerItemNonMedicalInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/reports/sales_per_item_non_medical?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as SalesPerItemNonMedicalInventorySchema[];
};
