// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/inventory/component";
import { fetchInventoryOverview, fetchPricingPendingAcceptance } from "@/screens/inventory/api";
import { InventoryOverviewSchema, PricingPendingAcceptance } from "@/screens/inventory/model";
import { Loading } from "@/layouts/loading";

type Props = {
    inventory_overview: InventoryOverviewSchema;
    pending_acceptance: PricingPendingAcceptance;
}
export const Route = createFileRoute("/inventory/")({
    component: Component,
    loader: async (): Promise<Props> => {
        const [inventoryOverview,pendingAcceptance] = await Promise.all([fetchInventoryOverview(), fetchPricingPendingAcceptance()]);
        return {
            inventory_overview: inventoryOverview,
            pending_acceptance: pendingAcceptance
        };
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});