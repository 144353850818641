import { AddedItemsSchema } from "@/screens/inventory/add-stock/schema"


const ReviewInfo = ({
    text,
    Value
}: {
    text: string,
    Value: string
}) => {
    return (
        <div className='bg-white px-2 py-2 w-full my-1 flex items-center justify-between'>
            <p className='text-sm font-semibold'>{text}</p>
            <p className='text-sm text-gray-600 font-semibold'>{Value}</p>
        </div>
    )
}

const ReviewColumn = ({globalItems}: {
    globalItems: AddedItemsSchema[]
}) => {
    return (
        <div>
            <div className='pb-2 border-b-[0.5px] '>
                <p className='font-semibold'>Breakdown</p>
                <p className='text-gray-500'>This contains the item you add /text needs to be worked on</p>
            </div>

            <div className='mt-6'>
                <ReviewInfo 
                    text='No of Items'
                    Value={
                        globalItems.length.toString()
                    }
                />
                <ReviewInfo 
                    text='Total Quantity'
                    Value={
                        globalItems.reduce((acc, curr) => acc + curr.quantity.original+curr.quantity.bonus, 0).toString()
                    }
                />
                <ReviewInfo 
                    text='Total'
                    Value={
                        "AED " + globalItems.reduce((acc, curr) => acc + (curr.quantity.original*curr.cost), 0).toString()
                    }
                />
            </div>
        </div>
    )
}

export default ReviewColumn