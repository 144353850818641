// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { Supplier } from "@/screens/suppliers/components/data/schema";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";


import Stepper from "@/components/multipage-stepper"
import React, { useCallback, useEffect } from "react"
import ReviewTable from "@/screens/create-purchase-invoice/components/reviewtable"
import ReviewColumn from "@/screens/create-purchase-invoice/components/reviewcolumn"
import { submitPurchaseInvoice } from "@/screens/create-purchase-invoice/components/data/api"
import { toast } from "@/components/ui/use-toast"
import PurchaseInvoiceForm from "@/screens/create-purchase-invoice/components/purchaseInvoiceForm"
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable"
import TableList from "@/screens/inventory/add-stock/components/table-list"
import Add, { addedItemsValidator, checkDuplicateBatch } from "@/screens/inventory/add-stock/components/add"
import InvoicePDF, { PurchaseInvoicePDFItemSchema } from "@/screens/reports/purchase-invoice-list/components/invoice-pdf"
import { pdf } from "@react-pdf/renderer"
import { formObjToAddedItems } from "@/screens/inventory/add-stock/component"
import dayjs from "dayjs"
import { AddedItemsSchema } from "@/screens/inventory/add-stock/schema"
import { PurchaseInvoiceSchema } from "@/screens/create-purchase-invoice/components/data/schema"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent } from "@/components/ui/dialog"
import { AddStockItemRow, deleteAddStockItem, addAddStockItem, updateAddStockItem } from "@/utils/db/addStock";
import { completeCreatePIInstance, PIInfoRow, setPIInfo } from "@/utils/db/purchaseInvoice";
import db from "@/db";

//circular imports causing issues
function Component() {
    const [activeStep, setActiveStep] = React.useState(0)
    const [globalItems, setGlobalItems] = React.useState<AddedItemsSchema[]>([])
    const [purchaseInvoiceData, setPurchaseInvoiceData] = React.useState<PurchaseInvoiceSchema>({
        instance_id: undefined,
        invoice_date: undefined,
        delivery_date: undefined,
        invoice_number: "",
        payment_mode: undefined,
        payment_type: undefined,
        supplier_id: "",
        pharmacy_code: getPharmacyID()
    })
    const [lastInfo, setLastInfo] = React.useState<PurchaseInvoiceSchema>()
    const [lastItems, setLastItems] = React.useState<AddedItemsSchema[]>()
    const setInstanceId = useCallback((id: number) => {
        setPurchaseInvoiceData((prev) => {
            return {
                ...prev,
                instance_id: id
            }
        })
    }, [purchaseInvoiceData])
    const [supplierName, setSupplierName] = React.useState("")
    const [editItem, setEditItem] = React.useState<AddedItemsSchema>()
    const navigate = useNavigate()
    async function handleSubmit() {
        if (purchaseInvoiceData.instance_id === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (purchaseInvoiceData === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (purchaseInvoiceData.delivery_date === undefined || purchaseInvoiceData.invoice_date === undefined || purchaseInvoiceData.invoice_number === undefined || purchaseInvoiceData.payment_mode === undefined || purchaseInvoiceData.payment_type === undefined) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            })
            return
        }
        if (globalItems.length === 0) {
            toast({
                title: "Error",
                description: "Please add at least one item",
                duration: 5000,
            })
            return
        }
        try {
            const res = await submitPurchaseInvoice(globalItems, purchaseInvoiceData)
            if (!res) {
                toast({
                    title: "Error",
                    description: "An error occurred while submitting the purchase invoice",
                    duration: 5000,
                })
                return;
            } else {
                const apiUrl = import.meta.env.VITE_API_URL as string;
                try {
                    const pharmacyData = await httpClient.post(`${apiUrl}/public/pharmacy`, {
                        pharmacy_code: getPharmacyID()
                    })
                    const medicalPDFItems: PurchaseInvoicePDFItemSchema[] = []
                    const nonMedicalPDFItems: PurchaseInvoicePDFItemSchema[] = []
                    for (let item of globalItems) {
                        if (item.type === "MEDICAL") {
                            medicalPDFItems.push({
                                trade_name: item.trade_name,
                                cost: item.cost,
                                quantity: item.quantity.original + item.quantity.bonus,
                                batch_number: item.batch_number,
                                vat: !isNaN(Number(item.vat)) ? Number(item.vat) : 0
                            })
                        } else if (item.type === "NON_MEDICAL") {
                            nonMedicalPDFItems.push({
                                trade_name: item.trade_name,
                                cost: item.cost,
                                quantity: item.quantity.original + item.quantity.bonus,
                                batch_number: item.batch_number,
                                vat: !isNaN(Number(item.vat)) ? Number(item.vat) : 0
                            })
                        }
                    }
                    const pdfDoc = <InvoicePDF
                        invoiceDetails={{
                            calculated_payment_date: undefined,
                            delivery_date: purchaseInvoiceData.delivery_date.toDateString(),
                            supplier_name: supplierName,
                            payment_mode: purchaseInvoiceData.payment_mode,
                            payment_type: purchaseInvoiceData.payment_type,
                            invoice_number: purchaseInvoiceData.invoice_number,

                        }}
                        medicalItems={medicalPDFItems}
                        nonMedicalItems={nonMedicalPDFItems}
                        pharmacyName={pharmacyData.data.data.metadata.pharmacy_name}
                    >
                    </InvoicePDF>
                    pdf(pdfDoc).toBlob().then((blob) => {
                        const url = URL.createObjectURL(blob)
                        window.open(url, '_blank')
                    })
                } catch (e) {
                    toast({
                        title: "Couldn't generate PDF",
                        description: "An error occurred while generating the PDF",
                        duration: 5000,
                    })
                    console.log(e)
                }
                completeCreatePIInstance(purchaseInvoiceData.instance_id, () => {
                    toast({
                        title: "Purchase Invoice Submitted",
                        description: "Purchase Invoice has been submitted successfully",
                        duration: 5000,
                    })
                    navigate({
                        to: "/reports/purchase-invoice-list",
                    })
                }, (e) => {
                    console.log(e);
                    throw new Error("Error while completing the instance")
                })
            }


        } catch (error) {
            console.log(error)
            toast({
                title: "Error",
                description: "An error occurred while submitting the purchase invoice",
                duration: 5000,
            })
        }
    }
    const getLastInfoAttempt = async () => {
        const infoRows = await db.create_pi_info.toArray()
        const instanceRows = await db.create_pi_instance.toArray()
        if (infoRows.length > 0) {
            const lastInfo = infoRows[0]
            const lastInstance = instanceRows.find((instance) => instance.id === lastInfo.instance_id)
            return {
                ...lastInfo,
                updated_at: lastInstance?.updated_at,
                instance_id: lastInstance?.id
            }
        }
        return undefined
    }
    const getLastItemsAttempt = async () => {
        const itemRows = await db.add_stock_item.toArray()
        const instanceRows = await db.create_pi_instance.toArray()
        if (itemRows.length > 0) {
            return itemRows.map((row) => {
                const instance = instanceRows.find((instance) => instance.id === row.instance_id)
                return {
                    ...row,
                    updated_at: instance?.updated_at,
                    instance_id: instance?.id
                }
            })
        }
        return undefined
    }
    const deleteItem = (item: AddedItemsSchema) => {
        deleteAddStockItem(item.db_item_id, () => {
            setGlobalItems(globalItems.filter((i) => i.db_item_id !== item.db_item_id))
        }, (e) => {
            console.log(e)
            toast({
                title: "Error",
                description: "Failed to delete item. Please try again later. If the problem persists, contact support.",
                duration: 5000,
            })
        })
    }

    useEffect(() => {
        getLastInfoAttempt().then((row) => {
            if (row) {
                console.log(row)
                const local_instance_id = row.instance_id;
                const lastInfo = {
                    instance_id: local_instance_id,
                    pharmacy_code: getPharmacyID(),
                    delivery_date: row.delivery_date ? dayjs(row.delivery_date).toDate() : undefined,
                    invoice_date: row.invoice_date ? dayjs(row.invoice_date).toDate() : undefined,
                    invoice_number: row.invoice_number || undefined,
                    payment_mode: row.payment_mode as "CASH" | "CHEQUE" | "CREDIT_CARD" | "BANK_TRANSFER" || undefined,
                    payment_type: row.payment_type as "INSTANT" | "CREDIT" || undefined,
                    supplier_id: row.supplier_id || undefined
                }
                setLastInfo(lastInfo)
            }
        })
        getLastItemsAttempt().then((rows) => {
            if (rows) {
                console.log(rows)
                if (!purchaseInvoiceData.instance_id) {
                    setInstanceId(rows[0]?.instance_id!)
                }
                const lastItems = rows.map((row) => {
                    const { instance_id, updated_at, ...rest } = row
                    return {
                        batch_number: rest.batch_no,
                        db_item_id: rest.id,
                        cost: Number(rest.cost),
                        medical_master_id: rest.type === "MEDICAL" ? rest.item_id : undefined,
                        non_medical_master_id: rest.type === "NON_MEDICAL" ? rest.item_id : undefined,
                        price: rest.type === "NON_MEDICAL" ? Number(rest.price) : undefined,
                        production_date: rest.production_date,
                        trade_name: rest.product_name,
                        type: rest.type as "MEDICAL" | "NON_MEDICAL",
                        expiry_date: rest.expiry_date,
                        quantity: {
                            original: Number(rest.quantity),
                            bonus: Number(rest.bonus)
                        },
                        pharmacy_code: getPharmacyID(),
                        metadata: {
                            custom_barcode: rest.custom_barcode,
                            discount_type: rest.discount_type as "NONE" | "PERCENTAGE" | "VALUE" | "ALWAYS" | undefined,
                            discount_value: Number(rest.discount_value),
                        },
                        vat: rest.vat.toString()

                    }
                })
                setLastItems(lastItems)
            }
        })

    }, [])

    return (
        <>
            <Dialog open={!!(lastItems || lastInfo)}>
                <DialogContent>
                    <div className="flex flex-col">
                        <p>
                            Previous attempt found
                        </p>
                        <div className="flex w-full justify-end gap-2">
                            <Button onClick={() => {

                                if (lastItems) {
                                    setGlobalItems(lastItems)
                                    setLastItems(undefined)
                                }
                                if (lastInfo) {
                                    setPurchaseInvoiceData(lastInfo)
                                    setLastInfo(undefined)
                                }

                            }}>
                                Load
                            </Button>
                            <Button onClick={() => {
                                //since dialog is opened last info is there
                                completeCreatePIInstance(lastInfo!.instance_id!, () => {
                                    setLastItems(undefined)
                                    setLastInfo(undefined)
                                    console.log("Instance ignored and deleted");
                                }, (e) => {
                                    console.log(e)
                                })
                            }}>
                                Ignore
                            </Button>
                        </div>
                    </div>
                </DialogContent>
                <div className="h-full mb-24">
                    <div
                        className="grid grid-cols-8 space-x-4 h-full"
                    >
                        {activeStep === 0 &&
                            <div className="col-span-8 flex flex-col">
                                <div>
                                    <h2 className="text-2xl font-bold tracking-tight">Purchase Invoice</h2>
                                    <p className="text-muted-foreground">
                                        The following details are required to create a new invoice
                                    </p>
                                </div>
                                <hr className="my-6" />
                                <PurchaseInvoiceForm data={purchaseInvoiceData} setData={(info) => {
                                    setPurchaseInvoiceData(info)
                                    try {
                                        setPIInfo(info, (returnedInstanceId) => {
                                            console.log('returned instance id = ', returnedInstanceId)
                                            if (returnedInstanceId != purchaseInvoiceData.instance_id) {
                                                setInstanceId(returnedInstanceId)
                                            }
                                        }, (e) => {
                                            console.log(e)
                                            toast({
                                                title: "Error",
                                                description: "Failed to save invoice info. Please try again later. If the problem persists, contact support.",
                                                duration: 5000,
                                            })
                                        })
                                    } catch (err) {

                                    }
                                }} supplierName={supplierName} setSupplierName={setSupplierName} />
                            </div>
                        }
                        {
                            activeStep === 1 &&
                            <>
                                <ResizablePanelGroup className="col-span-8 flex" direction={"horizontal"}>
                                    <ResizablePanel className="flex-1 mr-2">
                                        <TableList
                                            items={globalItems}
                                            deleteItem={deleteItem}
                                            setEditItem={setEditItem}
                                        />
                                    </ResizablePanel>
                                    <ResizableHandle withHandle />
                                    <ResizablePanel
                                        minSize={20}
                                        maxSize={40}
                                        defaultSize={20}
                                        className="bg-[#F6F6F6] overflow-auto">
                                        <Add key={globalItems.length}
                                            injectItem={editItem}
                                            resetInjectItem={() => setEditItem(undefined)}
                                            addItem={async (formObjItem) => {
                                                let copied_instanceId = purchaseInvoiceData.instance_id
                                                //if no instance_id create one
                                                if (copied_instanceId === undefined) {
                                                    copied_instanceId = await db.create_pi_instance.add({ complete: false, updated_at: new Date(), type: "CREATE_PI" })
                                                    setInstanceId(copied_instanceId)

                                                }
                                                addAddStockItem({
                                                    product_name: formObjItem.trade_name,
                                                    batch_no: formObjItem.batchNo,
                                                    cost: formObjItem.cost.value,
                                                    bonus: formObjItem.bonus.value,
                                                    custom_barcode: formObjItem.customBarcode,
                                                    discount_type: formObjItem.discountType,
                                                    discount_value: formObjItem.discountValue.value,
                                                    expiry_date: formObjItem.expiryDate ? dayjs(formObjItem.expiryDate).format("YYYY-MM-DD") : dayjs().add(1, "year").format("YYYY-MM-DD"),
                                                    price: formObjItem.price.value || 0,
                                                    production_date: dayjs(formObjItem.productionDate!).format("YYYY-MM-DD"),
                                                    quantity: formObjItem.quantity.value,
                                                    type: formObjItem.type,
                                                    vat: formObjItem.vat,
                                                    instanceId: Number(copied_instanceId),
                                                    item_id: formObjItem.item_id,
                                                },
                                                    "CREATE_PI",
                                                    (v) => {
                                                        console.log("new id  = ", v)
                                                        const newItem = formObjToAddedItems(formObjItem, v!)
                                                        setGlobalItems([...globalItems, newItem])
                                                    },
                                                    () => {
                                                        toast({
                                                            title: "Error",
                                                            description: "Failed to add item. Please try again later. If the problem persists, contact support.",
                                                            duration: 5000,
                                                        })
                                                    }
                                                )
                                            }}
                                            editItem={(formObjItem, dbItemId) => {
                                                updateAddStockItem({
                                                    dbId: dbItemId,
                                                    batch_no: formObjItem.batchNo,
                                                    cost: formObjItem.cost.value,
                                                    bonus: formObjItem.bonus.value,
                                                    custom_barcode: formObjItem.customBarcode,
                                                    discount_type: formObjItem.discountType,
                                                    discount_value: formObjItem.discountValue.value,
                                                    expiry_date: formObjItem.expiryDate ? dayjs(formObjItem.expiryDate).format("YYYY-MM-DD") : dayjs().add(1, "year").format("YYYY-MM-DD"),
                                                    price: formObjItem.price.value || 0,
                                                    production_date: dayjs(formObjItem.productionDate!).format("YYYY-MM-DD"),
                                                    quantity: formObjItem.quantity.value,
                                                    type: formObjItem.type,
                                                    vat: formObjItem.vat
                                                },
                                                    "CREATE_PI",
                                                    () => {
                                                        const newItem = formObjToAddedItems(formObjItem, dbItemId)
                                                        setGlobalItems(globalItems.map((item) => {
                                                            if (item.db_item_id === dbItemId) {
                                                                return newItem
                                                            }
                                                            return item
                                                        }))
                                                    },
                                                    (e) => {
                                                        console.log(e)
                                                        toast({
                                                            title: "Error",
                                                            description: "Failed to edit item. Please try again later. If the problem persists, contact support.",
                                                            duration: 5000,
                                                        })
                                                    }
                                                )
                                            }}

                                            items={globalItems} />
                                    </ResizablePanel>
                                </ResizablePanelGroup>
                            </>
                        }
                        {
                            activeStep === 2 &&
                            <>
                                <div className="col-span-6 overflow-auto">
                                    <ReviewTable globalItems={globalItems} purchaseInvoiceData={purchaseInvoiceData} supplierName={supplierName} deleteItem={deleteItem} editItem={(item) => {
                                        setEditItem(item)
                                        setActiveStep(1)
                                    }} />
                                </div>
                                <div className="col-span-2 bg-[#F5F6F7] min-h-full p-4">
                                    <ReviewColumn globalItems={globalItems} />
                                </div>
                            </>
                        }
                    </div>
                </div>
                <Stepper
                    stepValues={[
                        {
                            name: 'Invoice Info',
                            next: () => { },
                            validate: () => purchaseInvoiceSchemaValidator(purchaseInvoiceData)
                        },
                        {
                            name: 'Add Items',
                            next: () => { },
                            validate: () => addedItemsValidator(globalItems)
                            ,
                        },
                        {
                            name: 'Review',
                            next: handleSubmit,
                            validate: () => {
                                return purchaseInvoiceSchemaValidator(purchaseInvoiceData) && addedItemsValidator(globalItems)
                            }
                        },
                    ]}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                />
            </Dialog>

        </>
    )
}

export const purchaseInvoiceSchemaValidator = (purchaseInvoiceData: PurchaseInvoiceSchema) => {
    if (purchaseInvoiceData.delivery_date && purchaseInvoiceData.invoice_date && purchaseInvoiceData.invoice_number && purchaseInvoiceData.payment_mode && purchaseInvoiceData.payment_type && purchaseInvoiceData.supplier_id) {
        return true
    }
    return false;
}

export const Route = createFileRoute("/create-purchase-invoice/")({
    component: Component,
    loader: async (): Promise<Supplier[]> => {
        const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/get/list?offset=0&limit=100000`, {
            pharmacy_code: getPharmacyID()
        })
        return response.data.data as Supplier[];
    }
});




