import { z } from "zod"

export const amountValuesSchema = z.object({
  cash: z.number(),
  card: z.number(),
  total_discount: z.number(),
  insurance_amount: z.number(),
  vat: z.number(),
  cash_received: z.number()
})

export const cardDetailsSchema = z.object({
  card_number: z.string(),
  auth_code: z.string()
})

export const insuranceDetailsSchema = z.object({
  erx_number: z.string(),
  insurance_id: z.string()
})

export const statusSchema = z.object({
  paid: z.boolean(),
  void: z.boolean(),
  refunded: z.boolean()
})

export const VoidSaleSchema = z.object({
  id: z.string(),
  pharmacy_code: z.string(),
  user_id: z.string(),
  payment_type: z.string(),
  insurance_details: insuranceDetailsSchema,
  card_details: cardDetailsSchema,
  created_at: z.string(),
  updated_at: z.string(),
  patient_id: z.string().nullable(),
  status: statusSchema,
  amount_values: amountValuesSchema,
  linked_invoice_id: z.string().nullable(),
  full_count: z.number()
})

export type VoidSaleSchema = z.infer<typeof VoidSaleSchema>

export const mainSchema = z.object({
  status: z.boolean(),
  message: z.string(),
  data: z.array(VoidSaleSchema)
})
