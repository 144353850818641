import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { UniqueUsersSalesSchema } from "../components/data/schema";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getUniqueUserSales = async (): Promise<UniqueUsersSalesSchema[]> => {
    const response = await httpClient.post(`${apiUrl}/public/pharmacy/sales/get/unique/users`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as UniqueUsersSalesSchema[];
};
