import React, { useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { AddedItemsSchema } from '../schema'
import { convertToCurrencyRounded } from '@/utils/currency_converter';
import { Button } from '@/components/ui/button';
import { Pen, Trash } from 'lucide-react';
import dayjs from 'dayjs';

const TableList = ({ items, setEditItem, deleteItem }: {
    items: AddedItemsSchema[];
    setEditItem: (item: AddedItemsSchema) => void
    deleteItem: (item: AddedItemsSchema) => void
}) => {

    return (
        <div className='overflow-auto h-full'>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>SL No.</TableHead>
                        <TableHead>Product Name</TableHead>
                        <TableHead>Batch Number</TableHead>
                        <TableHead>Expiry Date</TableHead>
                        <TableHead>Production Date</TableHead>
                        <TableHead>Original Quantity</TableHead>
                        <TableHead>Bonus Quantity</TableHead>
                        <TableHead>Cost</TableHead>
                        <TableHead>Price</TableHead>
                        <TableHead>Net Total</TableHead>
                        <TableHead></TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        items.length > 0 
                            && items.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index+1}</TableCell>
                                    <TableCell>{item.trade_name}</TableCell>
                                    <TableCell>{item.batch_number}</TableCell>
                                    <TableCell>{item.expiry_date ? dayjs(item.expiry_date).format("DD/MM/YYYY") : "N/A"}</TableCell>
                                    <TableCell>{item.production_date ? dayjs(item.production_date).format("DD/MM/YYYY") : "N/A"}</TableCell>
                                    <TableCell>{item.quantity.original}</TableCell>
                                    <TableCell>{item.quantity.bonus}</TableCell>
                                    <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                                    <TableCell>{convertToCurrencyRounded(item.price!)}</TableCell>
                                    <TableCell>{convertToCurrencyRounded(item.quantity.original * item.cost)}</TableCell>
                                    <TableCell>
                                        <Button variant={'outline'} onClick={() => {
                                        deleteItem(item)
                                    }}>
                                        <Trash className='w-4 h-4' />
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant={'outline'} onClick={() => setEditItem(item)}>
                                        <Pen className='w-4 h-4' />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))                        
                    }
                </TableBody>
            </Table>

            {
                items.length === 0 
                    && (
                        <div className='grid place-items-center h-[90%]'>
                            <div className='flex flex-col justify-center'>
                                <p className='text-2xl'>Start by adding products</p>
                                <img 
                                    src='/arrow.png' 
                                    alt='empty' 
                                    width={300}
                                    className='ml-10'
                                />
                            </div>
                        </div>
                    )
            }
        </div>
    )
}

export default TableList