const intent_url_mapper: { [key: string]: string } = {
    pl_report: "/reports/pl-report",
    sales_summary_report: "/reports/sales-summary",
    expiry_list: "/reports/expiry-list/",
    purchase_invoice_list: "/reports/purchase-invoice-list",
    void_sales: "/reports/void-sales",
    sales_per_item: "/reports/sales-per-item",
    out_of_stock_items: "/reports/out-of-stock",
    shift_summary: "/reports/shift-history",
    sales_history: "/overview/sales_history",
    product_profitability: "/reports/product-profitablity/",
    supplier_profitability: "/reports/supplier-profitability/",
    customer_wise_revenue: "/reports/customer-wise-revenue",
    purchase_order_list: "/reports/purchase-order-list",
};

export default intent_url_mapper;