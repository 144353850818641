import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { StockMovement } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getProfitabilityReportMedical = async (
  offset: number,
  limit: number,
  date?:string,
): Promise<StockMovement[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/stock_movement/medical?offset=${offset}&limit=${limit}`,
        {
          date,
          pharmacy_code: getPharmacyID()
        }
    );
    return response.data.data.map((item: any) => StockMovement.parse(item)); 
  } catch (error) {
    console.error("Error fetching stock movement report:", error);
    throw new Error("Failed to fetch stock movement report");
  }
};
export const getProfitabilityReportNonMedical = async (
  offset: number,
  limit: number,
  start_date?:string,
): Promise<StockMovement[]> => {
  try {
    const response = await httpClient.post(
        `${apiUrl}/public/pharmacy/reports/stock_movement/non_medical?offset=${offset}&limit=${limit}`,
          {
            date : start_date,
            pharmacy_code: getPharmacyID()
          }
      );
    return response.data.data.map((item: any) => StockMovement.parse(item)); 
  } catch (error) {
    console.error("Error fetching stock movement report:", error);
    throw new Error("Failed to fetch stock movement report");
  }
};
