import { Button } from '@/components/ui/button'
import React, { useState } from 'react'
import { acceptAllPricingChanges } from '../api'
import { useNavigate } from '@tanstack/react-router'

const InventoryUpdate = ({pending_acceptance_count}: {pending_acceptance_count: number}) => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    return (
        <div
            className=''
        >
            <p className='text-xl font-semibold text-gray-600 mb-4 mt-12'>Inventory Updates</p>

            <div
                className='flex items-center justify-between'
            >
                <div>
                    <p className='text-sm text-gray-400'>Last Updated on 12th, Jan 2023</p>
                    <p className='font-semibold text-lg'>Pricing changes by Ministry</p>
                </div>

                <div className='space-x-2'>
                    <Button
                        variant={"outline"}
                        disabled={true}
                    >Ignore</Button>
                    <Button
                        variant={"outline"}
                        onClick={() => {
                            navigate({
                                to: '/inventory/pending-acceptance-inventory'
                            })
                        }}
                    >Review & Confirm</Button>
                    <Button
                        variant={"outline"}
                        disabled={pending_acceptance_count === 0 || loading}
                        onClick={async () => {
                            setLoading(true)
                            acceptAllPricingChanges()
                            setLoading(false)
                        }}
                    >{loading ? "Please Wait..." : "Update All"}</Button>
                </div>
            </div>
        </div>
    )
}

export default InventoryUpdate