import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/purchase-order-list/component"
import { PurchaseOrderManySchema, PurchaseOrderManyType } from "@/screens/reports/purchase-order-list/type";
import { PurchaseOrderParams } from "@/screens/reports/purchase-order-list/model";
import { getPurchaseOrderList } from "@/screens/reports/purchase-order-list/api";
import { Loading } from "@/layouts/loading";
export const Route = createFileRoute("/reports/purchase-order-list/")({
    component: Component,
    validateSearch: PurchaseOrderParams,
    loaderDeps: ({ search: { page, pageSize } }) => ({
        page,
        pageSize,
    }),
    loader: async (params): Promise<PurchaseOrderManyType[]> => {
        const response = await getPurchaseOrderList(
            params.deps.page - 1,
            params.deps.pageSize
        );
        return response;
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
})