import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  Table,
  TableBody,
  TableCell as CTableCell,
  TableHead as CTableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Route as ShiftHistoryRoute } from "@/routes/reports/shift-history";
import {
  dateFormat,
  dayOfWeekFormat,
  hoursFormat,
  timeFormat,
} from "@/utils/time_format";
import { useNavigate } from "@tanstack/react-router";
import { shiftDetails } from "./type";
import { Button } from "@/components/ui/button";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { SetStateAction, useState } from "react";
import dayjs from "dayjs";

const TableCell = (props: React.ThHTMLAttributes<HTMLTableCellElement>) => {
  return <CTableCell className="text-center" {...props} />;
};
const TableHead = (props: React.ThHTMLAttributes<HTMLTableCellElement>) => {
  return <CTableHead className="text-center" {...props} />;
};

export default function Component() {
  const data = ShiftHistoryRoute.useLoaderData();
  const searchParams = ShiftHistoryRoute.useSearch();
  const navigate = useNavigate();

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");

  const handleRequestSort = (property: SetStateAction<string>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy === "created_at") {
      const dateA = new Date(a.shift_details.created_at).getTime();
      const dateB = new Date(b.shift_details.created_at).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <div className="overflow-y-scroll h-screen pb-48">
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  navigate({
                    to: "/invoicing",
                  });
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Reports Overview
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Shift Reports</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <hr className="my-4" />
      <Table className="mt-4 text-sm">
        <TableHeader className="group">
          <TableRow className="hover:bg-inherit cursor-pointer">
            <TableHead
              rowSpan={2}
              onClick={() => handleRequestSort("created_at")}
              style={{ cursor: "pointer" }}
            >
              <div className="flex items-center">
                <span>Created At</span>
                {orderBy === "created_at" && (
                  <CaretSortIcon
                    className={`ml-2 h-4 w-4 ${
                      order === "asc" ? "rotate-180" : ""
                    }`}
                  />
                )}
              </div>
            </TableHead>
            <TableHead rowSpan={2}>Day</TableHead>
            <TableHead rowSpan={2}>User</TableHead>
            <TableHead rowSpan={2}>Register ID</TableHead>
            <TableHead rowSpan={2}>Start Time</TableHead>
            <TableHead rowSpan={2}>End Time</TableHead>
            <TableHead rowSpan={2}>Hours</TableHead>
            <TableHead rowSpan={2}>Payouts</TableHead>
            <TableHead className="text-center" colSpan={2}>
              Cash Amount
            </TableHead>
            <TableHead className="text-center" colSpan={2}>
              Card Amount
            </TableHead>
            <TableHead rowSpan={2}>Auto End?</TableHead>
          </TableRow>
          <TableRow className="hover:bg-inherit">
            <TableHead>Start</TableHead>
            <TableHead>End</TableHead>
            <TableHead>Start</TableHead>
            <TableHead>End</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedData.map((row) => {
            const shiftDetails = row.shift_details;
            const userDetails = row.user_details;

            const handleRowClick = () => {
              navigate({
                to: `/reports/shift-history/shift-summary/$shiftId`,
                params: { shiftId: shiftDetails.id },
              });
            };
            return (
              <TableRow
                key={shiftDetails.id}
                onClick={handleRowClick}
                className="cursor-pointer"
              >
                <TableCell>{dateFormat(shiftDetails.created_at)}</TableCell>
                <TableCell>
                  {dayOfWeekFormat(shiftDetails.created_at)}
                </TableCell>
                <TableCell>
                  {userDetails.first_name} {userDetails.last_name}
                </TableCell>
                <TableCell>
                  {shiftDetails.shift_details.cashRegisterId}
                </TableCell>
                <TableCell>{timeFormat(shiftDetails.start_time)}</TableCell>
                <TableCell>{timeFormat(shiftDetails.end_time.Time)}</TableCell>
                <TableCell>
                  {hoursFormat(shiftDetails.calculated_hours)}
                </TableCell>
                <TableCell>{shiftDetails.shift_details.payouts}</TableCell>
                <TableCell>
                  {shiftDetails.shift_details.cashDrawerAmount.startAmount}
                </TableCell>
                <TableCell>
                  {shiftDetails.shift_details.cashDrawerAmount.endAmount}
                </TableCell>
                <TableCell>
                  {shiftDetails.shift_details.cardAmount.startAmount}
                </TableCell>
                <TableCell>
                  {shiftDetails.shift_details.cardAmount.endAmount}
                </TableCell>
                <TableCell>
                  {shiftDetails.system_ended ? "Yes" : "No"}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {searchParams.page} of{" "}
          {data.length > 0
            ? Math.ceil(
                data[0].shift_details.full_count / searchParams.pageSize
              )
            : 1}
        </div>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page - 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page + 1,
                },
              });
            }}
            disabled={
              data.length === 0 ||
              data[0].shift_details.full_count <=
                searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: Math.ceil(
                    data[0].shift_details.full_count / searchParams.pageSize
                  ),
                },
              });
            }}
            disabled={
              data.length === 0 ||
              data[0].shift_details.full_count <=
                searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
