import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell as CTableCell,
  TableHead as CTableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Route as VoidSalesRoute } from "@/routes/reports/void-sales/index";
import normalizeEnums from "@/utils/normalize_enums";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";

const TableCell = (props: React.ThHTMLAttributes<HTMLTableCellElement>) => {
  return <CTableCell className="text-center" {...props} />;
};
const TableHead = (props: React.ThHTMLAttributes<HTMLTableCellElement>) => {
  return <CTableHead className="text-center" {...props} />;
};

export default function Component() {
  const data = VoidSalesRoute.useLoaderData();
  const searchParams = VoidSalesRoute.useSearch();
  const navigate = useNavigate();
  return (
    <div className="overflow-y-scroll h-screen pb-48">
      <div className="flex items-center justify-between">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/invoicing">
                Reports Overview
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Void Sales</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <hr className="my-4" />
      <Table className="mt-4 text-sm">
        <TableHeader>
          <TableRow className="hover:bg-inherit">
            <TableHead rowSpan={2}>Created At</TableHead>
            <TableHead rowSpan={2}>Day</TableHead>
            <TableHead rowSpan={2}>Payment Type</TableHead>
            <TableHead rowSpan={1} colSpan={3}>
              Amount
            </TableHead>
            <TableHead rowSpan={2}>Insurance Number</TableHead>
            <TableHead rowSpan={2}>Discount</TableHead>
            <TableHead rowSpan={2}>Vat</TableHead>
          </TableRow>
          <TableRow className="hover:bg-inherit">
            <TableHead>Cash</TableHead>
            <TableHead>Card</TableHead>
            <TableHead>Cash Received</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => {
            return (
              <TableRow className="font-medium cursor-pointer">
                <TableCell>
                  {dayjs(item.created_at).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>{dayjs(item.created_at).format("dddd")}</TableCell>
                <TableCell>{normalizeEnums(item.payment_type)}</TableCell>
                <TableCell>{item.amount_values.cash}</TableCell>
                <TableCell>{item.amount_values.card}</TableCell>
                <TableCell>{item.amount_values.cash_received}</TableCell>
                <TableCell>
                  {item.insurance_details.erx_number || "N/A"}
                </TableCell>
                <TableCell>{item.amount_values.total_discount}</TableCell>
                <TableCell>{item.amount_values.vat}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <hr />

      <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {searchParams.page} of{" "}
          {data.length > 0
            ? Math.ceil(data[0].full_count / searchParams.pageSize)
            : 1}
        </div>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page - 1,
                },
              });
            }}
            disabled={searchParams.page === 1}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: searchParams.page + 1,
                },
              });
            }}
            disabled={
              data.length === 0 ||
              data[0].full_count <= searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  page: Math.ceil(data[0].full_count / searchParams.pageSize),
                },
              });
            }}
            disabled={
              data.length === 0 ||
              data[0].full_count <= searchParams.page * searchParams.pageSize
            }
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
}
