import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface DatePickerWithRangeProps {
  className?: string;
  date: DateRange | undefined;
  setDate: (date: DateRange | undefined) => void;
  onSubmit: () => void;
  onClear: () => void;
  disabledAfter?: Date;
  disabledBefore?: Date;
}

export function DatePickerWithRange({
  className,
  date,
  setDate,
  onSubmit,
  onClear,
  disabledAfter,
  disabledBefore,
}: DatePickerWithRangeProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              `w-full justify-start text-left font-normal ${className}`,
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            disabled={{ after: disabledAfter!, before: disabledBefore! }}
          />
          <div className="flex justify-end gap-2 p-3">
            <Button onClick={onSubmit} variant="default" className="text-sm">
              Submit
            </Button>
            <Button onClick={onClear} variant="outline" className="text-sm">
              Clear
            </Button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
