import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "../../../screens/reports/pl-report/model";
import { getPLReport } from "@/screens/reports/pl-report/api";
import { PLReportSchema } from "@/screens/reports/pl-report/type";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/pl-report/component";

export const Route = createFileRoute("/reports/pl-report/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize, startDate, endDate } }) => ({
    page,
    pageSize,
    startDate,
    endDate,
  }),
  loader: async (params) => {
    const { page, pageSize, startDate, endDate } = params.deps;
    try {
      const offset = page - 1;
      const limit = pageSize;
      const start = startDate;
      const end = endDate;
      const getPLReportData = await getPLReport(offset, limit, start, end);
      return getPLReportData;
    } catch (error) {
      console.error("Error fetching PL report:", error);
      throw new Error("Failed to fetch PL report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
