import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
} from "@/components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import {
  useState,
  useRef,
  useCallback,
  type KeyboardEvent,
  forwardRef,
} from "react";

import { Skeleton } from "@/components/ui/skeleton";

import { Check } from "lucide-react";
import { cn } from "@/lib/utils";

export type Option = Record<"value" | "label", string> & Record<string, string>;

type AutoCompleteProps = {
  options: Option[];
  emptyMessage: string;
  value?: Option;
  onValueChange?: (value: Option) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  input: string;
  onInputChanged: (input: string) => void;
  shouldFilter?: boolean;
};

export const AutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(
  (
    {
      options,
      placeholder,
      emptyMessage,
      value,
      onValueChange,
      isLoading = false,
      input,
      onInputChanged,
      shouldFilter = true,
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const inputRef = (ref as React.RefObject<HTMLInputElement>) || innerRef;

    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option>(value as Option);

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input) {
          return;
        }

        // Keep the options displayed when the user is typing
        if (!isOpen) {
          setOpen(true);
        }

        // This is not a default behaviour of the <input /> field
        if (event.key === "Enter" && input.value !== "") {
          const optionToSelect = options.find(
            (option) => option.label === input.value
          );
          if (optionToSelect) {
            setSelected(optionToSelect);
            onValueChange?.(optionToSelect);
          }
        }

        if (event.key === "Escape") {
          input.blur();
        }
      },
      [isOpen, options, onValueChange]
    );

    const handleBlur = useCallback(() => {
      setOpen(false);
      onInputChanged(selected?.label);
    }, [selected]);

    const handleSelectOption = useCallback(
      (selectedOption: Option) => {
        onInputChanged(selectedOption.label);

        setSelected(selectedOption);
        onValueChange?.(selectedOption);

        // This is a hack to prevent the input from being focused after the user selects an option
        // We can call this hack: "The next tick"
        setTimeout(() => {
          inputRef?.current?.blur();
        }, 0);
      },
      [onValueChange]
    );

    return (
      <CommandPrimitive
        onKeyDown={handleKeyDown}
        className="w-full"
        shouldFilter={shouldFilter}
      >
        <div>
          <CommandInput
            ref={inputRef}
            value={input}
            onValueChange={isLoading ? undefined : onInputChanged}
            onBlur={handleBlur}
            onFocus={() => setOpen(true)}
            placeholder={placeholder}
            className="text-sm w-full text-center border-0"
          />
        </div>
        <div className="relative mt-1">
          <div
            className={cn(
              "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
              isOpen ? "block" : "hidden"
            )}
          >
            <CommandList className="rounded-lg ring-1 ring-slate-200">
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-8 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {options.length > 0 && !isLoading ? (
                // {options.length == 0 && !isLoading ? (
                <CommandGroup>
                  <>
                    {options.map((option) => {
                      const isSelected = selected?.value === option.value;
                      const [tradeName, manufacturer, owner] =
                        option.label.split(":::");
                      return (
                        <CommandItem
                          key={option.value}
                          value={option.label}
                          onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          onSelect={() => handleSelectOption(option)}
                          className={cn(
                            "flex w-full items-center gap-2",
                            !isSelected ? "pl-8" : null
                          )}
                        >
                          <div className="flex flex-col w-full">
                            <div className="flex items-center text-sm">
                              {isSelected ? (
                                <Check className="w-4 mr-2 flex-shrink-0" />
                              ) : null}
                              <span>{tradeName}</span>
                            </div>
                            <div className="text-xs text-gray-500 mt-1">
                              {manufacturer} • {owner}
                            </div>
                          </div>
                        </CommandItem>
                      );
                    })}
                  </>
                </CommandGroup>
              ) : null}
              {!isLoading && options.length === 0 ? (
                <CommandPrimitive.Empty className="select-none rounded-sm px-2 py-3 text-center text-sm">
                  {emptyMessage}
                </CommandPrimitive.Empty>
              ) : null}
            </CommandList>
          </div>
        </div>
      </CommandPrimitive>
    );
  }
);

AutoComplete.displayName = "AutoComplete";
