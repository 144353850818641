import { z } from "zod"

export const metadataSchema = z.object({
    phone_number: z.string(),
    contact_person_name: z.string()
})

export const supplierInfoSchema = z.object({
    id: z.string(),
    name: z.string(),
    metadata: metadataSchema
})

export const PurchaseOrderManySchema = z.object({
    id: z.string(),
    purchase_order_number: z.string(),
    supplier_info: supplierInfoSchema,
    items: z.null(),
    items_count: z.number()
})

export type PurchaseOrderManyType = z.infer<typeof PurchaseOrderManySchema>

export const itemSchema = z.object({
    trade_name: z.string(),
    barcode: z.string(),
    unit: z.number(),
    unit_type: z.string(),
    id: z.string(),
    vat: z.number(),
    is_active: z.boolean(),
    type: z.string(),
    manufacturer: z.string(),
    owner: z.string(),
    scientific_name: z.string(),
    category: z.string(),
    sub_category: z.string(),
    quantity: z.number().default(0),
    bonus_quantity: z.number().default(0),
    intended_cost: z.number(),
})

export const PurchaseOrderSingleSchema = z.object({
    id: z.string(),
    purchase_order_number: z.string(),
    supplier_info: supplierInfoSchema,
    items: z.array(itemSchema),
    items_count: z.number()
})

export type PurchaseOrderSingleType = z.infer<typeof PurchaseOrderSingleSchema>

export const mainSchema = z.object({
    status: z.boolean(),
    message: z.string(),
    data: z.array(PurchaseOrderManySchema)
})
