import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

const Account = () => {
  return (
    <>
        <div>
            <h2 className="text-2xl font-bold tracking-tight">Accounts</h2>
            <p className="text-muted-foreground">
              Manage your accounts details.
            </p>
        </div>

        <hr className='my-4' />

        <div className="flex flex-col gap-y-8">
            <div className="">
                <Label htmlFor="name" className="text-gray-700">
                    Name
                </Label>
                <Input
                    className="max-w-[300px] mt-2 h-8"
                    placeholder='Your name'
                />
                <Label className="text-gray-500 text-xs">
                    This is the name that will be displayed on your receipts after checkout.
                </Label>
            </div>

            <div className="">
                <Label htmlFor="pharmacyName" className="text-gray-700">
                    Pharmacy Name
                </Label>
                <Input
                    className="max-w-[300px] mt-2 h-8"
                    placeholder='Pharmacy name'
                />
                <Label className="text-gray-500 text-xs">
                    This is the name that will be displayed on your receipts after checkout.
                </Label>
            </div>

            <Button
                className='max-w-[300px]'
            >
                Update Account
            </Button>
        </div>
    </>
  )
}

export default Account