import dayjs from "dayjs"
import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
const AmountValue = z.object({
  card: z.number(),
  cash: z.number(),
  insurance_amount: z.number(),
  total_discount: z.number(),
  vat: z.number(),
  cash_received: z.number(),
})

export const RecentTransacitons = z.object({
  id: z.string(),
  user_id: z.string(),
  updated_at: z.string(),
  sold_items_count: z.number(),
  payment_type: z.string(),
  amount_values: AmountValue,
  patient_name: z.string(),
  is_valid_patient: z.boolean(),
})

export const SalesByDayByHour = z.object({
  h_hour: z.string(),
  sales_count: z.number(),
})

export const SalesByDayByHourData = z.object({
  today_sales: z.array(SalesByDayByHour),
  yesterday_sales: z.array(SalesByDayByHour),
})

export const DailyItemsChecklist = z.object({
  id: z.number(),
  item_name: z.string(),
  item_type: z.string(),
  marked: z.boolean(),
  checklist_id: z.object({
    "Int32": z.number(),
    "Valid": z.boolean(),
  })
});

export const Transactions = z.object({
  current_month: z.number(),
  last_month: z.number(),
  current_year: z.number(),
  last_year: z.number(),
  inventory_spends: z.object({
    request_month_spends: z.number(),
    last_month_spends: z.number(),
  }),
  total_refunds: z.object({
    last_month_sales: z.object({
      card_sales: z.number(),
      cash_sales: z.number(),
      insurance_sales: z.number(),
      total_sales: z.number(),
      total_sales_count: z.number(),
    }),
    request_month_sales: z.object({
      card_sales: z.number(),
      cash_sales: z.number(),
      insurance_sales: z.number(),
      total_sales: z.number(),
      total_sales_count: z.number(),
    }),
  }),
  total_sales: z.object({
    last_month_sales: z.object({
      card_sales: z.number(),
      cash_sales: z.number(),
      insurance_sales: z.number(),
      total_sales: z.number(),
      total_sales_count: z.number(),
    }),
    request_month_sales: z.object({
      card_sales: z.number(),
      cash_sales: z.number(),
      insurance_sales: z.number(),
      total_sales: z.number(),
      total_sales_count: z.number(),
    }),
  }),
})

const Shift = z.object({
  calculated_hours: z.number(),
  created_at: z.string(),
  end_time: z.object({
    Time: z.string(),
    Valid: z.boolean(),
  }),
  id: z.string(),
  pharmacy_code: z.string(),
  shift_details: z.object({
    shiftName: z.string(),
    cashRegisterId: z.string(),
    payouts: z.number(),
    cardAmount: z.object({
      endAmount: z.number(),
      startAmount: z.number(),
    }),
    cashDrawerAmount: z.object({
      endAmount: z.number(),
      startAmount: z.number(),
    }),
  }),
  start_time: z.string(),
  user_id: z.string(),
  system_ended: z.boolean(),
})

export const CurrentlyActiveShift = z.object({
  currently_active: z.boolean(),
  last_active_shift_present: z.boolean(),
  currently_active_shift: Shift,
  last_active_shift: Shift,
})

export const SalesHistory = z.object({
  created_at: z.string(),
  full_count: z.number(),
  insurance_details: z.object({
    erx_number: z.string(),
    insurance_id: z.string(),
    insurance_name: z.string(),
  }),
  is_valid_insurance: z.boolean(),
  is_valid_patient: z.boolean(),
  patient_info: z.object({
    emirates_id: z.string(),
    metadata: z.object({
      address: z.string(),
    }),
    name: z.string(),
    patient_id: z.string(),
    phone: z.string(),
  }),
  pharmacy_code: z.string(),
  sale_amounts: AmountValue,
  sale_id: z.string(),
  sale_payment_type: z.string(),
  sale_status: z.object({
    paid: z.boolean(),
    refunded: z.boolean(),
    void: z.boolean(),
  }),
  user_info: z.object({
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    user_id: z.string(),
    username: z.string(),
  }),
  invoice_id: z.string(),
});

export const UniqueUsersSalesSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  username: z.string(),
  id: z.string()
})

export const SalesHistorySearchParams = z.object({
  page: z.number().default(1),
  pageSize: z.number().default(10),
  userID: z.string().optional(),
  startDate: z.number().optional(),
  endDate: z.number().optional(),
  type: z.enum(["", "CASH", "CARD", "SPLIT"]).default(""),
})

export type UniqueUsersSalesSchema = z.infer<typeof UniqueUsersSalesSchema>

export type RecentTransacitons = z.infer<typeof RecentTransacitons>

export type SalesByDayByHour = z.infer<typeof SalesByDayByHour>

export type DailyItemsChecklist = z.infer<typeof DailyItemsChecklist>

export type Transactions = z.infer<typeof Transactions>

export type CurrentlyActiveShift = z.infer<typeof CurrentlyActiveShift>

export type SalesHistory = z.infer<typeof SalesHistory>
export type SalesHistorySearchParams = z.infer<typeof SalesHistorySearchParams>
export type SalesByDayByHourData = z.infer<typeof SalesByDayByHourData>