import { Drawer } from "@/components/ui/drawer";
import { FormLabel } from "@/components/ui/form";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { useState, useEffect } from "react";
import { SearchAllSchema } from "../add-stock/schema";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandItem, CommandList } from "@/components/ui/command";
import { LoadState } from "@/utils/load_state";
import { Select, SelectTrigger, SelectContent, SelectItem, SelectValue } from "@/components/ui/select";
import dayjs from "dayjs";

export default function GenerateBarcode() {
    const [search, setSearch] = useState("");
    const [results, setResults] = useState<SearchAllSchema[]>([]);
    const [selected, setSelected] = useState<SearchAllSchema | undefined>();
    //TODO create type for this
    const [medicineData, setMedicinedata] = useState<any[]>([]);
    const [medicineDataLaoding, setMedicineDataLoading] = useState<LoadState>(LoadState.None);
    // const [batches, setBatches] = useState<string[]>([]);
    const [selectedMedicineData, setSelectedMedicineData] = useState<any>();
    const [showCanvas, setShowCanvas] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | undefined>();
    const getMedicineData = async (selected: SearchAllSchema) => {
        setMedicineDataLoading(LoadState.Loading);
        try {
            const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/one`, {
                is_not_expired: false,
                pharmacy_code: getPharmacyID(),
                medical_master_id: selected.id
            });
            console.log(response.data.data)
            if (response.data.data.length == 0) {

                setShowCanvas(true);
            }
            setMedicinedata(response.data.data);

            setMedicineDataLoading(LoadState.Loaded);
        } catch (error) {
            console.error("Error:", error);
            setMedicineDataLoading(LoadState.Error);
        }
    }

    useEffect(() => {
        try {
            const getMeds = async () => {
                if (search.length < 3) return;
                if (selected) return;

                const response = await httpClient.post(
                    `${import.meta.env.VITE_API_URL as string
                    }/public/pharmacy/search/all`,
                    {
                        pharmacy_code: getPharmacyID(),
                        query: search,
                        limit: 20,
                    }
                );
                console.log(response.data.data)
                setResults(response.data.data as SearchAllSchema[]);
            };
            getMeds();
        } catch (error) {
            console.error("Error:", error);
        }
    }, [search]);

    useEffect(() => {
        const canvas = document.getElementById('gencode') as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx && selected) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.textAlign = "center"
                ctx.strokeStyle = "black";
                ctx.fillStyle = "white"
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                const x = canvas.width / 2;
                const batch_code = "999999"
                ctx.font = "30px Arial";
                ctx.fillStyle = "black"
                ctx.fillText(batch_code, x, 35);
                ctx.font = "10px Arial";
                console.log(selectedMedicineData)
                if (selectedMedicineData) {
                    ctx.fillText(`${selected.trade_name} (AED ${selectedMedicineData.price})`, x, 60);
                    ctx.fillText(`${selectedMedicineData.batch_number} | ${dayjs(selectedMedicineData.expiry_date).format("DD/MM/YYYY")}`, x, 80);
                } else {
                    ctx.fillText(`${selected.trade_name}`, x, 60);
                }
                canvas.toBlob((blob) => {
                    if (blob) {
                        const url = URL.createObjectURL(blob);
                        setImageUrl(url);
                    }

                })
            }

        }
    }, [selectedMedicineData, showCanvas, selected])


    return (
        <div className="w-full h-full overflow-auto">
            <form
                className="w-full flex flex-col gap-4"
                onSubmit={(e) => {
                    e.preventDefault();
                }}>
                <div className="pr-4 pb-4 border-b-[0.5px]">
                    <p className="text-sm font-semibold mt-4 mb-2">
                        Product Name<span className="text-red-400">*</span>
                    </p>
                    <div className="flex-1 mx-4 relative">
                        <div className="border rounded-md px-2 flex items-center">
                            <Input
                                placeholder="Search for a product"
                                className="text-center border-0"
                                value={search}
                                onChange={(e) => {
                                    if (showCanvas) { setShowCanvas(false) };
                                    if (medicineDataLaoding == LoadState.Loaded) { setMedicineDataLoading(LoadState.None) }
                                    if (medicineData.length > 0) { setMedicinedata([]) }

                                    setSearch(e.target.value)
                                }}
                            />
                            <Button
                                className="px-2 py-0 h-8"
                                variant={"outline"}
                                size={"sm"}
                                onClick={() => {
                                    setSearch("");
                                    setResults([]);
                                    setSelected(undefined);
                                    if (showCanvas) { setShowCanvas(false) };
                                    if (medicineDataLaoding == LoadState.Loaded) { setMedicineDataLoading(LoadState.None) }
                                    if (medicineData.length > 0) { setMedicinedata([]) }
                                }}
                            >
                                Clear
                            </Button>
                        </div>
                        <Command className="w-full absolute bottom-25 z-50">
                            <CommandList>
                                {search.length !== 0 && results?.length > 0 ? (
                                    results.map((result, index: number) => (
                                        <CommandItem
                                            key={index}
                                            onSelect={async () => {
                                                setSelected(result);
                                                setSearch(result.trade_name);
                                                setResults([]);
                                                getMedicineData(result)
                                            }}
                                        >
                                            {result?.trade_name} | {result.unit}{" "}
                                            {result.unit_type}
                                        </CommandItem>
                                    ))
                                ) : search.length > 0 && !selected ? (
                                    <CommandEmpty title="No results found" />
                                ) : null}
                            </CommandList>
                        </Command>
                    </div>
                </div>

                {medicineDataLaoding == LoadState.Loading && <div className="flex justify-center items-center h-40"> LOADING </div>}
                {medicineDataLaoding == LoadState.Error && <div className="flex justify-center items-center h-40"> ERROR </div>}
                {medicineDataLaoding == LoadState.Loaded && medicineData.length > 0 &&
                    <div className="flex-1 mx-4 relative">
                        <Select onValueChange={(batch) => {
                            setSelectedMedicineData(medicineData[parseInt(batch)]);
                            setShowCanvas(true);
                        }}>
                            <SelectTrigger>
                                <SelectValue placeholder="Select Batch" />
                            </SelectTrigger>
                            <SelectContent>
                                {medicineData.map((data, index) => (
                                    <SelectItem key={index} value={index.toString()}>
                                        {data.batch_number}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                }
            </form>
            {
                showCanvas &&
                <div className="w-full flex flex-col items-center justify-center mt-4">
                    <canvas id="gencode" width="500" height="100" className="">
                    </canvas>
                    {
                        imageUrl &&
                        <a href={imageUrl} className="w-fit" download={selected?.trade_name}>
                            <Button variant={"default"}>
                                Export image
                            </Button>
                        </a>
                    }
                </div>
            }
        </div>
    )
}