import "./index.css";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { OidcProvider } from "./oidc";
import { routeTree } from "./routeTree.gen";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip";
import { FlagProvider } from '@unleash/proxy-client-react';

const router = createRouter({
  routeTree,
  defaultPreload: "intent"
});

const container = document.getElementById("root");

if (container === null) {
  throw new Error("app element does not exists.");
}

const config = {
  url: 'https://unleash.neulink.cloud/api/frontend/',
  clientKey: import.meta.env.VITE_UNLEASH_TOKEN,
  refreshInterval: 300,
  appName: 'pharma-dashboard',
  environment: process.env.NODE_ENV || 'production',
};

createRoot(container).render(
  <StrictMode>
    <Provider
      config={{
        accessToken: "78bfb0f7d89e400487b49f4795130cfd",
        environment: process.env.NODE_ENV || "development",
        captureDeviceInfo: true,
        captureIp: true,
        captureUnhandledRejections: true,
        captureUncaught: true,
        enabled: process.env.NODE_ENV === "production",
        payload: {
          client: {
            javascript: {
              code_version: import.meta.env.VITE_APP_VERSION,
              source_map_enabled: true,
              guess_uncaught_frames: true
            }
          }
        }
      }}
    >
      <ErrorBoundary>
        <OidcProvider>
          <Toaster />
            <TooltipProvider>
              <FlagProvider config={config}>
                <RouterProvider router={router} />
              </FlagProvider>
            </TooltipProvider>
        </OidcProvider>
      </ErrorBoundary>
    </Provider>
  </StrictMode>
);
