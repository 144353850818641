import React, { useState } from "react";
import { Plus, Minus } from "lucide-react";
import { Button } from "@/components/ui/button";

interface ExploreItemProps {
  icon: React.ReactNode;
  text: string;
  content: React.ReactNode;
  defaultExpanded?: boolean;
}

const ExploreItem: React.FC<ExploreItemProps> = ({ icon, text, content, defaultExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded || false);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center p-4 cursor-pointer">
          {icon}
          <span className="ml-2">{text}</span>
        </div>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <Minus className="w-4 h-4" /> : <Plus className="w-4 h-4" />}
        </div>
      </div>
      {isExpanded && <div className="mt-2">{content}</div>}
    </div>
  );
};

export default ExploreItem;