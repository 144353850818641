import { httpClient } from "@/httpClient";
import { PurchaseOrderManyType, PurchaseOrderManySchema } from "./type";
import getPharmacyID from "@/utils/pharmacy_code";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getPurchaseOrderList = async (offset: number, limit: number): Promise<PurchaseOrderManyType[]> => {
    try {
        const response = await httpClient.post(
            `${apiUrl}/public/pharmacy/purchase_order/get/all?offset=${offset}&limit=${limit}`,
            {
                pharmacy_code: getPharmacyID(),
            }
        );
        return response.data.data.map((item: any) => PurchaseOrderManySchema.parse(item));
    } catch (error) {
        console.error("Error fetching purchase order:", error);
        throw new Error("Failed to fetch purchase order");
    }
}