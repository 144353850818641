import { Card, CardContent } from "@/components/ui/card";
import { Route as patientsOneRoute } from "@/routes/patients/patient-info/$patientId";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";

interface CSVDataItem {
  invoice_id: string;
  created_at: string;
  erx_number: string;
  sale_payment_type: string;
  sales_amount: string;
  refunded: string;
  void: string;
}

export default function QuickActions() {
  const { patientDetails, salesInfo } = patientsOneRoute.useLoaderData();
  const [csvData, setCSVData] = useState<CSVDataItem[]>([]);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const headers = [
    { label: "Invoice Id", key: "invoice_id" },
    { label: "Created Date", key: "created_at" },
    { label: "ERX Number", key: "erx_number" },
    { label: "Payment Type", key: "sale_payment_type" },
    { label: "Billed Amount", key: "sales_amount" },
    { label: "Refunded", key: "refunded" },
    { label: "Void", key: "void" },
  ];

  useEffect(() => {
    if (salesInfo.length > 0) {
      const csvDataItems: CSVDataItem[] = salesInfo.map((item) => ({
        invoice_id: item.invoice_id,
        created_at: dayjs(item.created_at).format("DD/MM/YYYY"),
        erx_number: item.insurance_details.erx_number,
        sale_payment_type: item.sale_payment_type,
        sales_amount: convertToCurrencyRounded(
          item.sale_amounts.card +
            item.sale_amounts.cash +
            item.sale_amounts.insurance_amount -
            item.sale_amounts.total_discount
        ),
        refunded: item.sale_status.refunded ? "Yes" : "No",
        void: item.sale_status.void ? "Yes" : "No",
      }));
      setCSVData(csvDataItems);
      setIsDataAvailable(true); // Set flag to true when data is available
    } else {
      setIsDataAvailable(false); // Set flag to false when no data is available
    }
  }, [salesInfo]);

  const filename = "patients_sales_history.csv";

  return (
    <Card className="mt-6 sticky bottom-0 overflow-hidden rounded-xl shadow-transparent ml-4">
      <CardContent className="flex gap-2 p-4 space-y-0">
        <div className="ml-auto">
          {isDataAvailable ? (
            <CSVLink
              data={csvData}
              headers={headers}
              filename={filename}
              className="inline-block" // To make sure the button styles are applied correctly
            >
              <Button
                size="sm"
                variant="ghost"
                className="gap-1 bg-black text-white border border-gray-200 rounded-lg"
              >
                <Download className="p-1" />
                Export Patients Transactions
              </Button>
            </CSVLink>
          ) : (
            <Button
              size="sm"
              variant="ghost"
              className="gap-1 bg-gray-300 text-gray-700 border border-gray-200 rounded-lg cursor-not-allowed"
              disabled
            >
              <Download className="p-1" />
              Export Patients Transactions
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
