import { Button } from '@/components/ui/button'
import { X, Info as InfoIcon } from 'lucide-react'
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet"
import { useEffect, useState } from 'react'
import { getMedicalItemDetails, getNonMedicalItemDetails } from '../../item/api'
import { MedicalItemOne, NonMedicalItemOne } from '../../item/model'
import { Loading } from '@/layouts/loading'
import { toast } from '@/components/ui/use-toast'

const Info = ({ value }: {
    value?: {
        id: string,
        type: "MEDICAL" | "NON_MEDICAL" | "",
    }
}) => {
    const [medicalItemDetails, setMedicalItemDetails] = useState<MedicalItemOne>()
    const [nonMedicalItemDetails, setNonMedicalItemDetails] = useState<NonMedicalItemOne>()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        async function getMedicineInfo() {
            if (value) {
                try {
                    setLoading(true)
                    if (value.type === "MEDICAL") {
                        const data = await getMedicalItemDetails(value.id)
                        setMedicalItemDetails(data)
                    } else if (value.type === "NON_MEDICAL") {
                        const data = await getNonMedicalItemDetails(value.id)
                        setNonMedicalItemDetails(data)
                    }
                    setLoading(false)
                } catch (e) {
                    toast({
                        title: "Error",
                        description: "Failed to fetch item details",
                        duration: 5000,
                    })
                }
            }
        }
        getMedicineInfo()
    }, [value])
    return (
        <>
            {loading && <Loading />}
            <div className='grid grid-cols-2 space-x-4 py-4'>
                {(!loading && value?.type === "MEDICAL") && <div className='bg-gray-400/10 rounded-lg h-full p-4'>
                    <p className="font-semibold text-xl">Item Details</p>
                    <p className="text-gray-500">
                        Details acquired from DDC
                    </p>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Trade Name</p>
                        <p className='font-semibold'>{medicalItemDetails?.trade_name}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Scientific Name</p>
                        <p className='font-semibold'>{medicalItemDetails?.metadata.scientific_name}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Barcode</p>
                            <p className='font-semibold underline'>{medicalItemDetails?.barcode ? medicalItemDetails.barcode : "N/A"}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>DDC Code</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.ddc_code}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Last updated by DDC</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.ddc_last_updated}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Ingredient</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.ingredient}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Manufacturer</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.manufacturer}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Owner</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.owner}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Source</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.source}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Strength</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.strength}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit</p>
                            <p className='font-semibold'>{medicalItemDetails?.unit}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit Type</p>
                            <p className='font-semibold'>{medicalItemDetails?.unit_type}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Route of Administration</p>
                            <p className='font-semibold'>{medicalItemDetails?.metadata.route_of_administration}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>VAT %</p>
                            <p className='font-semibold'>{medicalItemDetails?.vat}%</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>}
                {(!loading && value?.type === "NON_MEDICAL") && <div className='p-4 bg-gray-400/10 rounded-lg h-full'>
                    <p className="font-semibold">Item Details</p>
                    <p className="text-gray-500">
                        Details entered during item creation
                    </p>
                    <hr className='my-2' />
                    <div className='text-sm'>
                        <p className='text-gray-500'>Trade Name</p>
                        <p className='font-semibold'>{nonMedicalItemDetails?.trade_name}</p>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Category</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.metadata.category}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Sub Category</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.metadata.sub_category}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Manufacturer</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.metadata.manufacturer ? nonMedicalItemDetails.metadata.manufacturer : "N/A"}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Owner</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.metadata.owner ? nonMedicalItemDetails.metadata.owner : "N/A"}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.unit}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Unit Type</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.unit_type}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                    <div className='grid grid-cols-2 space-x-4'>
                        <div className='text-sm'>
                            <p className='text-gray-500'>Barcode</p>
                            <p className='font-semibold underline'>{nonMedicalItemDetails?.barcode ? nonMedicalItemDetails?.barcode : "N/A"}</p>
                        </div>
                        <div className='text-sm'>
                            <p className='text-gray-500'>VAT %</p>
                            <p className='font-semibold'>{nonMedicalItemDetails?.vat}%</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>}

                {/* <div>
                    <p className="font-semibold text-xl">Past price updates</p>
                        <p className="text-gray-500">
                        Stock info in relation with your pharmacy
                    </p>
                    <hr className='my-2' />
                    <div className='flex flex-col space-y-4'>                   
                        <div className='flex gap-x-4'>
                            <p className='text-gray-600'>DEC 12</p>
                            <Separator className='mx-4 h-[80px] w-1 bg-gray-500' orientation="vertical" />
                            <div>
                                <p className='font-semibold text-gray-700'>AED 25.00</p>
                                <p className='text-gray-600 text-sm'>Applicable Emirates: United Kerala</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

const ActionAndInfo = ({ value }: {
    value?: {
        id: string,
        type: "MEDICAL" | "NON_MEDICAL" | "",
    }
}) => {
    const [isOpen, setIsOpen] = useState(false)
    
    return (
        <div className='flex items-center space-x-4'>
            <Sheet
                open={isOpen}
            >
                <SheetTrigger>
                    <Button
                        variant={'outline'}
                        onClick={() => setIsOpen(!isOpen)}
                        disabled={!value}
                    >
                        <InfoIcon 
                            className='w-4 h-4'
                        />
                    </Button>
                </SheetTrigger>
                <SheetContent
                    side={'bottom'}
                >
                    <SheetHeader>
                        <SheetTitle>Item Info</SheetTitle>
                        <SheetClose
                            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >                           
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </SheetClose>     
                        <hr className='my-2' />                   
                    </SheetHeader>
                    <Info value={value}/>
                </SheetContent>
            </Sheet>
        </div>
    )
}

export default ActionAndInfo