import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { OutOfStockMedicalInventorSchema, OutOfStockNonMedicalInventorSchema} from "./type";

export const getMedicalOutOfStock = async (offset: number, limit: number): Promise<OutOfStockMedicalInventorSchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/reports/out_of_stock_medical?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as OutOfStockMedicalInventorSchema[];
};

export const getNonMedicalOutOfStock = async (offset: number, limit: number): Promise<OutOfStockNonMedicalInventorSchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/reports/out_of_stock_non_medical?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as OutOfStockNonMedicalInventorSchema[];
};
