import { Button } from "@/components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select";
import { Link, useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import InventoryUpdate from "./components/InventoryUpdates";
import MinistryTable from "./components/MinistryTable";
import QuickLinks from "./components/QuickLinks";
import {Route as InventoryRoute} from '@/routes/inventory/index'

const OverviewCard = ({
        title,
        value,
        extra,
        color
    }: {
        title: string;
        value: string;
        extra?: string;
        color?: string;
    }) => {
        return (
            <div className="border-[2px] rounded-lg py-4 px-8">
                <p className="font-medium">{title}</p>
                <p className="font-semibold text-2xl">
                    {value}{" "}
                    <span className={`ml-2 text-${color ? color : 'gray'}-500 text-sm font-normal`}>{extra}</span>
                </p>
            </div>
        );
};

const Component = () => {
    const data = InventoryRoute.useLoaderData()
    const searchParams = InventoryRoute.useSearch()
    const navigate = useNavigate()

    return (
        <>
            <div className="space-y-8">
                <div className="flex items-center justify-between">
                    <div className="flex gap-x-4">
                        <h2 className="text-4xl font-bold tracking-tight">
                            Inventory Overview
                        </h2>
                        {/* <Select
                            value={searchParams.month}
                            onValueChange={(value) => {
                                navigate({
                                    search: {
                                        month: value,
                                    },
                                });
                            }}
                        >
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="This Month" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={dayjs().format("MM_YYYY")} defaultChecked>
                                    This Month
                                </SelectItem>
                                <SelectItem
                                    value={dayjs().subtract(1, "month").format("MM_YYYY")}
                                    defaultChecked
                                >
                                    Last Month
                                </SelectItem>
                                {[...Array(12).keys()].map((i) => {
                                const month = dayjs().subtract(i + 2, "month");
                                    return (
                                        <SelectItem
                                        value={month.format("MM_YYYY")}
                                        key={i.toString()}
                                        >
                                        {month.format("MMMM YYYY")}
                                        </SelectItem>
                                    );
                                })}
                            </SelectContent>
                        </Select> */}
                    </div>
                    {/* TODO: Delete Add Inventory? */}
                    <div className="flex flex-row gap-2">
                        <Link
                            to="/inventory/add-stock"
                        >
                            <Button>Add Stock</Button>
                        </Link>
                        <Link
                            to="/create-purchase-invoice"
                        >
                            <Button>Create Purchase Invoice</Button>
                        </Link>
                        <Link
                            to="/create-purchase-order"
                        >
                            <Button>Create Purchase Order</Button>
                        </Link>
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-x-4">
                    <OverviewCard 
                        title="Total Inventory"
                        value={data.inventory_overview.total_inventory_count.toString()}
                    />
                    <OverviewCard 
                        title="Expiring Soon"
                        value={data.inventory_overview.expiring_soon_count.toString()}
                    />
                    <OverviewCard 
                        title="Out of Stock"
                        value={data.inventory_overview.out_of_stock_count.toString()}
                    />
                </div>
                <InventoryUpdate pending_acceptance_count={data.pending_acceptance.pending_medicines_count}/>
                <MinistryTable items={data.pending_acceptance.pending_medicines}/>
                <QuickLinks />
            </div>
        </>
    )
}

export default Component