import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/suppliers/component";
import { Supplier, SupplierSearchParams } from "@/screens/suppliers/components/data/schema";
import { Loading } from "@/layouts/loading";

const API_URL = import.meta.env.VITE_API_URL as string

export const Route = createFileRoute("/suppliers/")({
  component: Component,
  validateSearch: SupplierSearchParams,
  loaderDeps: ({ search: { page, pageSize, search } }) => ({ page, pageSize, search }),
  loader: async (params): Promise<Supplier[]> => {
    const response = await httpClient.post(API_URL + `/public/pharmacy/supplier/get/list?offset=${params.deps.page-1}&limit=${params.deps.pageSize}`, {
      pharmacy_code: getPharmacyID(),
      search: params.deps.search,   
    });

    const data = await response.data;

    if (!data.status) {
      throw new Error("API Fetch Error");
    }

    return data.data as Supplier[];
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1
});