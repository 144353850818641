import dayjs from "dayjs";

export function dateFormat(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
}
export function timeFormat(date: string) {
    return dayjs(date).format("HH:mm");
}

export function dayOfWeekFormat(date: string) {
    return dayjs(date).format("dddd");
}

export function hoursFormat(hours: number) {
    const h = Math.floor(hours);
    const m = Math.floor((hours - h) * 60);
    const s = Math.floor(((hours - h) * 60 - m) * 60);
    return `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
}

export function dateToDB(date?: Date) {
    return date ? dayjs(date).format("YYYY-MM-DD") : undefined
}