import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/purchase-invoice-list/component"
import { PurchaseInvoiceParams } from "@/screens/reports/purchase-invoice-list/model";
import { PurchaseInvoiceManySchema } from "@/screens/reports/purchase-invoice-list/type";
import { getPurchaseInvoiceList } from "@/screens/reports/purchase-invoice-list/api";
import { Loading } from "@/layouts/loading";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { Supplier } from "@/screens/suppliers/components/data/schema";
export const Route = createFileRoute("/reports/purchase-invoice-list/")({
    component: Component,
    validateSearch: PurchaseInvoiceParams,
    loaderDeps: ({ search: { page, pageSize,supplierID } }) => ({
        page,
        pageSize,
        supplierID
    }),
    loader: async (params): Promise<{
        invoices: PurchaseInvoiceManySchema[],
        suppliers: Supplier[],
        supplierId?: string
    }> => {
        const invoiceResponse = await getPurchaseInvoiceList(
            params.deps.page - 1,
            params.deps.pageSize,
            params.deps.supplierID
        );
        const supplierResponse = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/get/list?offset=0&limit=100000`, {
            pharmacy_code: getPharmacyID()
        })
        return {
            invoices: invoiceResponse,
            suppliers: supplierResponse.data.data,
            supplierId: params.deps.supplierID
        };
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
});
