import DailyChecklist from './daily-checklist'
import PharmacyAlerts from './pharmacy-alerts'
import Shift from './shift'

import { DatePickerWithRange } from '@/components/ui/date-picker'
import {
    Card,
    CardContent,
    CardHeader,
    CardDescription,
    CardTitle,
  } from "@/components/ui/card"
import Chart from './chart'
import { Route as overviewRoute } from '@/routes/overview/index'
import dayjs from 'dayjs'
import { Button } from '@/components/ui/button'
import { ArrowRight } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useState } from 'react'
import { Transactions } from './components/data/schema'
import { httpClient } from '@/httpClient'
import getPharmacyID from '@/utils/pharmacy_code'
import { Link, useNavigate } from '@tanstack/react-router'
import { convertToCurrencyRounded } from '@/utils/currency_converter'

const API_URL = import.meta.env.VITE_API_URL as string

const Page = () => {  
    const props = overviewRoute.useLoaderData(); 
    const [initialTransactionData, setInitialTransactionData] = useState<Transactions>(props.transactions)

    async function handleDateChange(date: string) {

        const response = await httpClient.post(API_URL + '/public/pharmacy/overview/transactions', {
            pharmacy_code: getPharmacyID(),
            month: parseInt(date.split("_")[0]),
            year: parseInt(date.split("_")[1]),
        })
        const response_current_month_transactions_data = await response.data;
        setInitialTransactionData(response_current_month_transactions_data.data as Transactions)
    }

    const navigate = useNavigate()

    return (
        <div className='overflow-y-scroll h-screen pb-48'>
            <div className='grid grid-cols-9 gap-4 max-[780px]:grid-cols-1 max-[780px]:gap-2'>
                <Shift shift_info={props.currently_active_shift} />
                <PharmacyAlerts />
                <DailyChecklist data={props.daily_items_checklist}/>
            </div>

            <div className='flex items-center justify-between mt-12'>
                <h2 className='text-3xl font-semibold'>Transaction Overview</h2>
                <Select onValueChange={(v) => {
                    handleDateChange(v)
                }}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="This Month" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value={dayjs().format("MM_YYYY")} defaultChecked>This Month</SelectItem>
                        <SelectItem value={dayjs().subtract(1, 'month').format("MM_YYYY")} defaultChecked>Last Month</SelectItem>
                        {
                            [...Array(12).keys()].map((i) => {
                                const month = dayjs().subtract(i+2, 'month');
                                return <SelectItem value={month.format("MM_YYYY")} key={i.toString()}>{month.format("MMMM YYYY")}</SelectItem>
                            })
                        }
                    </SelectContent>
                </Select>
            </div>

            <div className='mt-4 grid grid-cols-4 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-4 max-[780px]:grid-cols-1 max-[780px]:gap-2'>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">
                            Total Sales
                        </CardTitle>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="h-4 w-4 text-muted-foreground"
                        >
                        <   path d="M12 2v20M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                        </svg>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{convertToCurrencyRounded(initialTransactionData.total_sales.request_month_sales.total_sales)}</div>
                        <p className="text-xs text-muted-foreground">
                            {
                                initialTransactionData.total_sales.request_month_sales.total_sales === initialTransactionData.total_sales.last_month_sales.total_sales ? <span className="text-green-500">+0% from last month</span> : initialTransactionData.total_sales.request_month_sales.total_sales > initialTransactionData.total_sales.last_month_sales.total_sales ? 
                                <span className="text-green-500">
                                    +{((initialTransactionData.total_sales.request_month_sales.total_sales - initialTransactionData.total_sales.last_month_sales.total_sales) / initialTransactionData.total_sales.last_month_sales.total_sales * 100).toFixed(2)}% from last month
                                </span> :
                                <span className="text-red-500">
                                    -{((initialTransactionData.total_sales.last_month_sales.total_sales - initialTransactionData.total_sales.request_month_sales.total_sales) / initialTransactionData.total_sales.last_month_sales.total_sales * 100).toFixed(2)}% from last month
                                </span>
                            }
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">
                            Refunds
                        </CardTitle>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="h-4 w-4 text-muted-foreground"
                            >
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                            <circle cx="9" cy="7" r="4" />
                        <path d="M22 21v-2a4 4 0 0 0-3-3.87M16 3.13a4 4 0 0 1 0 7.75" />
                        </svg>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{convertToCurrencyRounded(initialTransactionData.total_refunds.request_month_sales.total_sales)}</div>
                        <p className="text-xs text-muted-foreground">
                            {
                                initialTransactionData.total_refunds.request_month_sales.total_sales === initialTransactionData.total_refunds.last_month_sales.total_sales ? <span className="text-green-500">+0% from last month</span> : initialTransactionData.total_refunds.request_month_sales.total_sales > initialTransactionData.total_refunds.last_month_sales.total_sales ? 
                                <span className="text-green-500">
                                    +{((initialTransactionData.total_refunds.request_month_sales.total_sales - initialTransactionData.total_refunds.last_month_sales.total_sales) / initialTransactionData.total_refunds.last_month_sales.total_sales * 100).toFixed(2)}% from last month
                                </span> :
                                <span className="text-red-500">
                                    -{((initialTransactionData.total_refunds.last_month_sales.total_sales - initialTransactionData.total_refunds.request_month_sales.total_sales) / initialTransactionData.total_refunds.last_month_sales.total_sales * 100).toFixed(2)}% from last month
                                </span>
                            }
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">
                            Inventory Purchases
                        </CardTitle>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="h-4 w-4 text-muted-foreground"
                        >
                            <rect width="20" height="14" x="2" y="5" rx="2" />
                            <path d="M2 10h20" />
                        </svg>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{convertToCurrencyRounded(initialTransactionData.inventory_spends.request_month_spends)}</div>
                        <p className="text-xs text-muted-foreground">
                            {
                                initialTransactionData.inventory_spends.request_month_spends === initialTransactionData.inventory_spends.last_month_spends ? <span className="text-green-500">+0% from last month</span> : initialTransactionData.inventory_spends.request_month_spends > initialTransactionData.inventory_spends.last_month_spends ? 
                                <span className="text-green-500">
                                    +{((initialTransactionData.inventory_spends.request_month_spends - initialTransactionData.inventory_spends.last_month_spends) / initialTransactionData.inventory_spends.last_month_spends * 100).toFixed(2)}% from last month
                                </span> :
                                <span className="text-red-500">
                                    -{((initialTransactionData.inventory_spends.last_month_spends - initialTransactionData.inventory_spends.request_month_spends) / initialTransactionData.inventory_spends.last_month_spends * 100).toFixed(2)}% from last month
                                </span>
                            }
                        </p>
                    </CardContent>
                </Card>
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-sm font-medium">
                            Average Spending
                        </CardTitle>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            className="h-4 w-4 text-muted-foreground"
                        >
                            <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                        </svg>
                    </CardHeader>
                    <CardContent>
                        <div className="text-2xl font-bold">{
                            convertToCurrencyRounded(initialTransactionData.total_sales.request_month_sales.total_sales_count !== 0 ? initialTransactionData.total_sales.request_month_sales.total_sales / initialTransactionData.total_sales.request_month_sales.total_sales_count : 0)
                        }</div>
                        <p className="text-xs text-muted-foreground">
                            {
                                initialTransactionData.total_sales.request_month_sales.total_sales_count === initialTransactionData.total_sales.last_month_sales.total_sales_count ? <span className="text-green-500">+0% from last month</span> : initialTransactionData.total_sales.request_month_sales.total_sales_count > initialTransactionData.total_sales.last_month_sales.total_sales_count ? 
                                <span className="text-green-500">
                                    +{((initialTransactionData.total_sales.request_month_sales.total_sales_count - initialTransactionData.total_sales.last_month_sales.total_sales_count) / initialTransactionData.total_sales.last_month_sales.total_sales_count * 100).toFixed(2)}% from last month
                                </span> :
                                <span className="text-red-500">
                                    -{((initialTransactionData.total_sales.last_month_sales.total_sales_count - initialTransactionData.total_sales.request_month_sales.total_sales_count) / initialTransactionData.total_sales.last_month_sales.total_sales_count * 100).toFixed(2)}% from last month
                                </span>
                            }
                        </p>
                    </CardContent>
                </Card>
            </div>

            <div className='grid grid-cols-2 gap-4 mt-4 md:gap-8 lg:grid-cols-2 max-[780px]:grid-cols-1 max-[780px]:gap-2'>
                <Chart sales_by_day_by_hour={props.sales_by_day_by_hour.today_sales} yesterday_sales_by_hour_by_day={props.sales_by_day_by_hour.yesterday_sales} />
                <Card className="col-span-1">
                  <CardHeader className='space-y-0'>
                    <CardTitle className='text-lg font-semibold'>Recent Transactions</CardTitle>
                    <div className='w-full flex items-center justify-between'>
                        <CardDescription className='text-sm'>
                            You made '{props.recent_transactions.length}' sale(s) recently.
                        </CardDescription>
                            <Link to="/overview/sales_history" preload="intent">
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                >
                                    <div>View Sales History</div>
                                    <ArrowRight className='ml-2' size={14} />
                                </Button>
                            </Link>
                    </div>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-8">
                        {props.recent_transactions.map((rt, i) => <Link to={`/overview/sales_history/${rt.id}`}  className="flex items-center" key={i.toString()}>
                            <div className="space-y-1">
                                <p className="text-sm font-medium leading-none">{rt.is_valid_patient ? rt.patient_name : "OTC"}</p>
                                <p className="text-sm text-muted-foreground">
                                    <span className="text-muted-foreground">{rt.sold_items_count} Items | </span> {dayjs(rt.updated_at).isSame(dayjs(), 'day') ? dayjs(rt.updated_at).format('hh:mm A') : dayjs(rt.updated_at).format('DD MMM YYYY hh:mm A')}
                                </p>
                            </div>
                            <div className="ml-auto font-medium">{convertToCurrencyRounded(rt.amount_values.card + rt.amount_values.cash + rt.amount_values.insurance_amount + rt.amount_values.vat)}</div>
                        </Link>)}
                    </div>
                  </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default Page