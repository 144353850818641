import { ArrowRight } from 'lucide-react'
import { CurrentlyActiveShift } from './components/data/schema'
import dayjs from 'dayjs'
import { Button } from '@/components/ui/button'
import { useState } from 'react'
import StartShiftModal from '@/components/shifts/start-shift-modal'
import EndShiftModal from '@/components/shifts/end-shift-modal'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Link, useNavigate } from '@tanstack/react-router'
import AutoEndShiftModal from '@/components/shifts/system-end-modal'

const Shift = ({ shift_info }: { shift_info: CurrentlyActiveShift }) => {
    const [openShiftStart, setOpenShiftStart] = useState<boolean>(false)
    const [openShiftEnd, setOpenShiftEnd] = useState<boolean>(false)
    const [openAutoEndShift, setOpenAutoEndShift] = useState<boolean>(false)
    const navigate = useNavigate();
    return (
        <>
            <AutoEndShiftModal
                value={openAutoEndShift}
                setValue={setOpenAutoEndShift}
                endTime={dayjs(shift_info.last_active_shift.end_time.Time).format("hh:mm A")}
                shiftID={shift_info.last_active_shift.id}
            /> 
            <StartShiftModal
                value={openShiftStart}
                setValue={setOpenShiftStart}
                setDisplaySalesComp={() => { }}
                showTriggerButton={false}
                page='overview'
            />
            <EndShiftModal
                value={openShiftEnd}
                setValue={setOpenShiftEnd}
                showTriggerButton={false}
            />
            <div className='col-span-2 border rounded-lg'>
                <div className='col-span-1 flex flex-col justify-between py-4 px-2 w-full h-[400px]'>
                    {!shift_info.currently_active ? <p className='font-bold text-2xl text-blue-700'>Start Shift</p> : <p className='font-bold text-2xl text-red-700'>End Shift</p>}
                    <Button
                        variant={'outline'}
                        className={`w-full text-white py-12 px-4 rounded-md ${!shift_info.currently_active ? 'bg-blue-700 hover:bg-blue-700 hover:text-white' : 'bg-red-700 hover:bg-red-700  hover:text-white'} flex items-center space-x-4 cursor-pointer`}
                        onClick={() => !shift_info.currently_active ? shift_info.last_active_shift.system_ended ? setOpenAutoEndShift(true) : setOpenShiftStart(true) : setOpenShiftEnd(true)}
                    >
                        {!shift_info.currently_active ? <p className='text-2xl font-semibold'>Start Now</p> : <p className='text-white text-2xl font-semibold'>End Now</p>}
                        {
                            !shift_info.currently_active
                                ?
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-player-play-filled text-blue-700 bg-white rounded-full p-2 " width="36" height="36" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" strokeWidth="0" fill="currentColor" />
                                </svg>
                                :
                                <div className='circle bg-white flex items-center justify-center h-[36px] w-[36px] rounded-full'>
                                    <div className='rounded-sm bg-red-700 w-[16px] h-[16px]'></div>
                                </div>
                        }

                    </Button>
                    <Tooltip>
                        <TooltipTrigger>
                            <div className='p-4 border rounded-lg'>
                                {shift_info.last_active_shift_present ? <p className='text-sm'>Last shift was between {
                                    dayjs(shift_info.last_active_shift.start_time).format("hh:mm A") + " - " + dayjs(shift_info.last_active_shift.end_time.Time).format("hh:mm A") + " "
                                } on {
                                        dayjs(shift_info.last_active_shift.start_time).format("DD MMM YYYY")
                                    }</p> : <p className='text-sm'>No shift has been started yet</p>}
                            </div>
                            <TooltipContent>
                                Last Shift was ended by {shift_info.last_active_shift.system_ended ? 'System' : 'You'}
                            </TooltipContent>
                        </TooltipTrigger>
                    </Tooltip>
                    <Link>
                    </Link>


                    <Link to={"/reports/shift-history"}>
                        <div className='flex items-center space-x-1 cursor-pointer'>
                            <p className='text-sm text-gray-500 underline'>View Past Shift&apos;s here</p>
                            <ArrowRight className='w-4 text-gray-500' />
                        </div>
                    </Link>

                    {/* {activeShift && <div onClick={() => openEnd()}><p className='font-bold text-2xl text-red-500'>End Shift</p>
                    <div className='w-full mt-8 py-12 px-4 rounded-md bg-red-500 flex items-center justify-between cursor-pointer'>
                        <p className='text-white text-2xl font-semibold'>End Now | {moment().format("hh:mmA")}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-stop-filled text-red-500 bg-white rounded-full p-2" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M17 4h-10a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3 -3v-10a3 3 0 0 0 -3 -3z" strokeWidth="0" fill="currentColor" />
                        </svg>
                    </div></div>} */}
                </div>
            </div>
        </>

    )
}

export default Shift