import db from "@/db";

export type addAddStockParams = {
    instanceId: number,
    item_id?: string,
    product_name?: string,
    type?: string,
    batch_no?: string,
    cost?: number,
    price?: number,
    expiry_date?: string,
    production_date?: string,
    custom_barcode?: string,
    discount_type?: string,
    vat?: number,
    discount_value?: number,
    quantity?: number,
    bonus?: number
}
//float values returned as string
export type AddStockItemRow = {
    id: number;
    instance_id: number;
    item_id: string;
    product_name: string;
    type: string;
    batch_no: string;
    cost: number;
    price: number;
    expiry_date: string;
    production_date: string;
    custom_barcode: string;
    discount_type: string;
    vat: number;
    discount_value: number;
    quantity: number;
    bonus: number;
    instance_type: string;
};

export type AddStockInstanceRow = {
    id: number;
    complete: boolean;
    updated_at: string;
};

export function addAddStockItem(params: addAddStockParams, type: "ADD_STOCK" | "CREATE_PI" | "DRAFT_PI", onSuccess: (itemDBId: number) => void, onError?: (error: any) => void) {
    db.add_stock_item.add({
        instance_id: params.instanceId,
        item_id: params.item_id!,
        product_name: params.product_name!,
        type: params.type!,
        batch_no: params.batch_no!,
        cost: params.cost!,
        price: params.price!,
        expiry_date: params.expiry_date!,
        production_date: params.production_date!,
        custom_barcode: params.custom_barcode!,
        discount_type: params.discount_type!,
        vat: params.vat!,
        discount_value: params.discount_value!,
        quantity: params.quantity!,
        bonus: params.bonus!,
        instance_type: type
    }).then((s) => {
        onSuccess(s)
    }).catch(onError ? onError : () => { })
}


export function updateAddStockItem(params: {
    dbId: number,
    type: string,
    batch_no: string,
    cost: number,
    price: number,
    expiry_date: string,
    production_date: string,
    custom_barcode: string,
    discount_type: string,
    vat: number,
    discount_value: number,
    quantity: number,
    bonus: number
}, type: "ADD_STOCK" | "CREATE_PI" | "DRAFT_PI", onSuccess: () => void, onError?: (error: any) => void) {
    db.add_stock_item.update(params.dbId, {
        batch_no: params.batch_no,
        cost: params.cost,
        price: params.price,
        expiry_date: params.expiry_date,
        production_date: params.production_date,
        discount_type: params.discount_type,
        discount_value: params.discount_value,
        quantity: params.quantity,
        bonus: params.bonus,
        custom_barcode: params.custom_barcode,
        vat: params.vat,
        type: params.type,
        instance_type: type
    }).then(onSuccess).catch(onError ? onError : () => { })
}

export function deleteAddStockItem(dbId: number, onSuccess: () => void, onError?: (error: any) => void) {
    db.add_stock_item.delete(dbId).then(onSuccess).catch(onError ? onError : () => { })
}