import { useState } from "react";
import Stepper from "@/components/multipage-stepper";
import { Supplier } from "../suppliers/components/data/schema";
import getPharmacyID from "@/utils/pharmacy_code";
import { toast } from "@/components/ui/use-toast";
import AddInventory from "./components/addinventory";
import OrderInfo from "./components/orderInfo";
import Review from "./components/review";
import { httpClient } from "@/httpClient";
import { useRouter } from "@tanstack/react-router";



export type PurchaseOrderItem = {
    id: string
    trade_name: string
    bonus_quantity: number
    quantity: number
    intended_cost: number
    type: string
}


type PurchaseOrderObj = {
    pharmacy_code: string;
    purchase_order_items: (PurchaseOrderItem[]);
    supplier_id: string;
}

type CreatePurchaseOrderRequest = Omit<PurchaseOrderObj, "purchase_order_items"> & {
    purchase_order_items: Omit<PurchaseOrderItem, "trade_name">[]
}

const sendCreateRequest = async (purcahseOrderReq: CreatePurchaseOrderRequest) => {
    const apiUrl = import.meta.env.VITE_API_URL as string;
    return await httpClient.post(apiUrl + "/public/pharmacy/purchase_order/create", {
        pharmacy_code: purcahseOrderReq.pharmacy_code,
        supplier_id: purcahseOrderReq.supplier_id,
        purchase_order_items: purcahseOrderReq.purchase_order_items.map((item) => {
            return {
                id: item.id,
                quantity: item.quantity,
                type: item.type,
                bonus_quantity: item.bonus_quantity,
                intended_cost: item.intended_cost
            }
        })
    })
}

export default function CreatePurchaseOrder() {
    const [pageIdx, setPageIdx] = useState(0);
    const [supplier, setSupplier] = useState<Supplier>();
    const [purchaseOrderObj, setPurchaseOrderObj] = useState({
        pharmacy_code: getPharmacyID(),
        purchase_order_items: [] as (PurchaseOrderItem[]),
        supplier_id: "",
    })
    const router = useRouter();
    const validator = () => {
        const predicate = purchaseOrderObj.purchase_order_items.length > 0 && supplier && (purchaseOrderObj.purchase_order_items.filter((item) => item.quantity <= 0 || item.intended_cost == 0).length == 0)
        return !!predicate
    }
    const submit = async () => {
        if (!validator()) {
            toast({
                title: "Error",
                description: "Please fill in the required fields",
                duration: 5000,
            });
            return;
        }
        try {
            const res = await sendCreateRequest({
                ...purchaseOrderObj, purchase_order_items: purchaseOrderObj.purchase_order_items.map((item) => {
                    return {
                        id: item.id,
                        quantity: item.quantity,
                        type: item.type,
                        bonus_quantity: item.bonus_quantity,
                        intended_cost: item.intended_cost
                    }
                })
            })

            if (!res.data.status) {
                toast({
                    title: "Error",
                    description: "An error occurred while submitting the purchase order",
                    duration: 5000,
                });
                return;
            }
            router.navigate({ to: "/reports/purchase-order-list" })
        } catch (e) {
            toast({
                title: "Error",
                description: "An error occurred while submitting the purchase order",
                duration: 5000,
            });
        }
    }
    return <>
        <div className="h-full mb-2">
            <div
                className="grid grid-cols-8 space-x-4 h-full"
            >
                {pageIdx == 0 && <OrderInfo supplier={supplier} setSupplier={(supplier: Supplier) => {
                    setSupplier(supplier)
                    setPurchaseOrderObj({
                        ...purchaseOrderObj,
                        supplier_id: supplier.id
                    })
                }} />}
                {pageIdx == 1 && <AddInventory
                    purchaseOrderItems={purchaseOrderObj.purchase_order_items}
                    addPurchaseOrderItem={(newitem: PurchaseOrderItem) => {
                        setPurchaseOrderObj({
                            ...purchaseOrderObj,
                            purchase_order_items: [...purchaseOrderObj.purchase_order_items, newitem]
                        })
                    }}
                    removePurchaseOrderItem={(id: string) => {
                        setPurchaseOrderObj({
                            ...purchaseOrderObj,
                            purchase_order_items: purchaseOrderObj.purchase_order_items.filter((item) => item.id !== id)
                        })
                    }}
                    editPurchaseOrderItem={(id: string, newitem: PurchaseOrderItem) => {
                        setPurchaseOrderObj({
                            ...purchaseOrderObj,
                            purchase_order_items: purchaseOrderObj.purchase_order_items.map((item) => {
                                if (item.id == id) {
                                    return newitem
                                }
                                return item
                            })
                        })
                    }
                    }
                />}
                {
                    pageIdx == 2 && <Review purchaseOrderItems={purchaseOrderObj.purchase_order_items} supplierName={supplier?.name || ""}></Review>
                }
            </div>
        </div>
        <Stepper
            stepValues={[
                {
                    name: "Order Info",
                    next: () => { },
                    validate: () => purchaseOrderObj.supplier_id != ""
                },
                {
                    name: "Add Inventory",
                    next: () => { },
                    validate: () => {
                        return purchaseOrderObj.purchase_order_items.length > 0 && (purchaseOrderObj.purchase_order_items.filter((item) => item.quantity <= 0 || item.intended_cost == 0).length == 0)
                    }
                },
                {
                    name: "Confirm & Review",
                    next: submit,
                    validate: validator
                }
            ]}
            activeStep={pageIdx}
            setActiveStep={setPageIdx}
        ></Stepper>
    </>
}
