import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Timer, X } from 'lucide-react'
import { useState } from 'react'
import dayjs from 'dayjs'
import { endShift } from '@/screens/sales/api'
import { toast } from '../ui/use-toast'
import { useRouter } from '@tanstack/react-router'

type FormValuesSchema = {
    cash_drawer_end_amount: number | undefined,
    card_amount: number | undefined,
    payouts: number | undefined,
    end_time: string
}

const EndShiftModal = ({
    value,
    setValue,
    showTriggerButton,
}: {
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
    showTriggerButton?: boolean,
}) => {

    const [formValues, setFormValues] = useState<FormValuesSchema>({
        cash_drawer_end_amount: undefined,
        card_amount: undefined,
        payouts: undefined,
        end_time: dayjs().format('HH:mm')
    })

    const [loading, setLoading] = useState(false)
    const router = useRouter()
    
    return (
        <>
            <Dialog
                open={value}
            >
                {
                    showTriggerButton && (
                        <DialogTrigger asChild>
                            <Button
                                onClick={() => setValue(true)}
                            >
                                Start Shift
                            </Button>
                        </DialogTrigger>
                    )
                }
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>End Shift</DialogTitle>
                        <DialogDescription>
                            Following details are required.
                        </DialogDescription>
                        <Button
                            variant={'ghost'} 
                            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                            onClick={() => setValue(false)}
                        >
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </Button>
                    </DialogHeader>
                        <div className="grid gap-1 py-4">
                            <p className='text-sm font-semibold'>Cash Drawer Amount*</p>
                            <Input 
                                placeholder='AED 20.00'
                                value={formValues.cash_drawer_end_amount!}
                                type='number'
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        cash_drawer_end_amount: e.target.value ? parseFloat(e.target.value) : undefined
                                    })
                                }}
                                disabled={loading}
                            />
                            <p className='text-sm font-semibold'>Card Amount*</p>
                            <Input 
                                placeholder='AED 20.00'
                                value={formValues.card_amount!}
                                type='number'
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        card_amount: e.target.value ? parseFloat(e.target.value) : undefined
                                    })
                                }}
                                disabled={loading}
                            />
                            <p className='text-sm font-semibold mt-4'>Payouts</p>
                            <Input 
                                placeholder='1'
                                value={formValues.payouts}
                                type='number'
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        payouts: e.target.value ? parseFloat(e.target.value) : undefined
                                    })
                                }}
                                disabled={loading}
                            />
                             <p className='text-sm font-semibold mt-4'>End time</p>
                            <Input 
                                placeholder='6:00 am'
                                type='time'
                                value={formValues.end_time}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        end_time: e.target.value
                                    })
                                }}
                                disabled={loading}
                            />
                        </div>
                    <DialogFooter>
                        <Button 
                            onClick={async () => {
                                try {
                                    setLoading(true);
                                    if (formValues.cash_drawer_end_amount === undefined || formValues.card_amount === undefined || formValues.payouts === undefined) {
                                        toast({
                                            title: "Error",
                                            description: "All fields are required",
                                            duration: 5000,
                                        })
                                        setLoading(false);
                                        return;
                                    }
                                    const res = await endShift(formValues.cash_drawer_end_amount, formValues.card_amount, formValues.payouts, formValues.end_time)
                                    if (res) {
                                        toast({
                                            title: "Success",
                                            description: "Shift ended successfully",
                                            duration: 5000,
                                        });
                                        setLoading(false);
                                        router.invalidate()
                                        setValue(false)
                                    }
                                } catch (error) {
                                    console.error('Error:', error);
                                    toast({
                                        title: "Error",
                                        description: "An error occurred",
                                        duration: 5000,
                                    })
                                    setLoading(false);
                                } finally {
                                    setFormValues({
                                        cash_drawer_end_amount: undefined,
                                        card_amount: undefined,
                                        payouts: undefined,
                                        end_time: dayjs().format('HH:mm')   
                                    })
                                }
                            }}
                            disabled={loading}
                        >
                            <Timer
                                size={20}
                                className='mr-2'
                            />
                            {loading ? "Please Wait..." : "End and Save"}</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default EndShiftModal