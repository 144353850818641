// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/sales/component";
import getPharmacyID from "@/utils/pharmacy_code";
import { httpClient } from "@/httpClient";
import { CurrentlyActiveShift } from "@/screens/overview/components/data/schema";

type Props = {
  activeShiftFound: boolean;
  last_shift_system_ended: boolean;
  last_shift_id: string;
}

const API_URL = import.meta.env.VITE_API_URL as string

export const Route = createFileRoute("/sales/")({
  component: Component,
  loader: async (): Promise<Props> => {
    const response = await httpClient.post(API_URL + `/public/pharmacy/shifts/current_active`, {
      pharmacy_code: getPharmacyID(),
    });
    const data = await response.data;
    if (!data.status) {
      throw new Error("API Fetch Error");
    }

    const shiftData = data.data as CurrentlyActiveShift;
    return {
      activeShiftFound: shiftData.currently_active,
      last_shift_system_ended: shiftData.last_active_shift.system_ended,
      last_shift_id: shiftData.last_active_shift.id,
    }
  }
});