import { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { Route as ReportAvailableRoute } from "@/routes/reports/supplier-profitability/index";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

const Component = () => {
  const searchParams = ReportAvailableRoute.useSearch();
  const data = ReportAvailableRoute.useLoaderData();
  const navigate = useNavigate();

  return (
    <>
      <div className="overflow-y-scroll h-screen pb-48">
        <div className="flex items-center justify-between">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink
                  onClick={() => {
                    navigate({
                      to: "/invoicing",
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Reports Overview
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Supplier Profitability Report</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
        <hr className="my-4" />
      <div className="w-full grid mb-4">
        <Tabs defaultValue={searchParams.type}>
          <TabsList>
            <TabsTrigger
              value={"MEDICAL"}
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    type: "MEDICAL",
                  },
                });
              }}
            >
              Medical
            </TabsTrigger>
            <TabsTrigger
              value={"NON_MEDICAL"}
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    type: "NON_MEDICAL",
                  },
                });
              }}
            >
              Non Medical
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

        <Table className="mt-4 text-sm">
          <TableHeader>
            <TableRow>
              <TableCell>Supplier Name</TableCell>
              <TableCell>Contact Details</TableCell>
              <TableCell>Total Cost from Supplier</TableCell>
              <TableCell>Total Sold Price</TableCell>
              <TableCell>Total Paid Quantity</TableCell>
              <TableCell>Supplier Profit</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow key={index} className="font-medium">
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.metadata.contact_person_name} ({item.metadata.phone_number})</TableCell>
                    <TableCell>{convertToCurrencyRounded(item.total_supplier_purchase_cost)}</TableCell>
                    <TableCell>{convertToCurrencyRounded(item.total_sold_price)}</TableCell>
                    <TableCell>{item.total_paid_quantity}</TableCell>
                    <TableCell>{convertToCurrencyRounded(item.supplier_profit)}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <hr />

        <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {searchParams.page} of{" "}
            {data.length > 0
              ? Math.ceil(data[0].full_count / searchParams.pageSize)
              : 1}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page - 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page + 1,
                  },
                });
              }}
              disabled={
                data.length === 0 ||
                data[0].full_count <= searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: Math.ceil(data[0].full_count / searchParams.pageSize),
                  },
                });
              }}
              disabled={
                data.length === 0 ||
                data[0].full_count <= searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
