import { Button } from "@/components/ui/button";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { MoveRight } from "lucide-react";
import { PricingPendingAcceptanceMedicine } from "../model";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { useNavigate } from "@tanstack/react-router";

interface Medicine {
    name: string;
    ingredients: string;
    updatedPrice: number;
    previousPrice: number;
    id: number;
}
  
export default function MinistryTable({items}: {items: PricingPendingAcceptanceMedicine[]}) {
    const navigate = useNavigate()
    return (
        <div className="border rounded-lg p-2">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>
                            <div>
                                <p className="font-semibold text-black">Medicine Name</p>
                            </div>
                        </TableHead>
                        <TableHead className="text-black">Updated Price</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {
                        items.map((medicine: PricingPendingAcceptanceMedicine) => {
                            return (
                                <TableRow>
                                    <TableCell className="font-medium">
                                        <p className="">{medicine.trade_name}</p>
                                    </TableCell>
                                    <TableCell>{convertToCurrencyRounded(medicine.price)}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                        >Update Price</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
                {items.length > 0 ? <TableCaption>
                    <Button 
                        variant={'ghost'}
                        className="font-bold text-black"
                        size={"lg"}
                        onClick={() => {
                            navigate({
                                to: '/inventory/pending-acceptance-inventory'
                            })
                        }}
                    >
                        View All
                    </Button>
                </TableCaption> : <TableCaption>
                        <p className="text-gray-400 text-center">Hurray! All pending pricing changes have been accepted...</p>
                    </TableCaption>}
            </Table>
        </div>
    )
}
  