// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import { httpClient } from "@/httpClient";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/patients/patient-info/component";
import {
  getPatientDetails,
  getPatientSalesHistory,
} from "@/screens/patients/patient-info/api";

const API_URL = import.meta.env.VITE_API_URL as string;

export const Route = createFileRoute("/patients/patient-info/$patientId")({
  component: Component,
  loader: async ({ params }) => {
    if (!params.patientId) {
      throw new Error("Patient ID is required");
    }
    const { patientId } = params;
    try {
      const [getPatientDetail, getPatientSaleHistory] = await Promise.all([
        getPatientDetails(patientId),
        getPatientSalesHistory(patientId),
      ]);
      console.log(getPatientSaleHistory);
      return {
        patientDetails: getPatientDetail,
        salesInfo: getPatientSaleHistory,
      };
    } catch (error) {
      console.error("Error fetching Patient Sales History:", error);
      throw new Error("Failed to fetch Patient Sales History");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
