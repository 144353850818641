import { Button } from '@/components/ui/button'
import { Progress } from '@/components/ui/progress'
import React from 'react'


const Step = (
    {
        desc,
        active,
        onClick
    }: {
        desc: string,
        active: boolean,
        onClick: React.MouseEventHandler<HTMLDivElement> | undefined
    }) => {
    return (
        <div
            className={`flex flex-col min-w-[300px] ${active ? '' : 'opacity-30'}`}
            onClick={onClick}
        >
            <p className='text-sm mb-2'>{desc}</p>
            <Progress value={100} className='h-1' />
        </div>
    )
}

const Stepper = ({
    stepValues,
    activeStep,
    setActiveStep,
}: {
    stepValues: {
        name: string,
        next: () => void
        validate: () => boolean
    }[],
    activeStep: number,
    setActiveStep: (value: number) => void,
}) => {
    return (
        <div
            className='sticky w-full bottom-0 h-16 border-t pt-4 bg-[#F9FAFB] border-gray-200 flex items-center justify-center space-x-4'
        >
            <Button
                className='bg-gray-100 text-gray-700'
                variant={'outline'}
                onClick={() => {
                    if (activeStep !== 0) {
                        setActiveStep(activeStep - 1)
                    }
                }}
                disabled={activeStep === 0}
            >
                Back
            </Button>

            <div className='flex items-center justify-center space-x-8 flex-1'>
                {
                    stepValues.map((value, index) => {
                        return (
                            <Step
                                key={index}
                                desc={`Step ${index + 1}: ${value.name}`}
                                active={activeStep === index}
                                onClick={() => console.log('clicked')}
                            />
                        )
                    })
                }
            </div>

            <Button
                className='bg-blue-600 text-white'
                onClick={() => {
                    if (activeStep !== stepValues.length - 1) {
                        setActiveStep(activeStep + 1)
                    }
                    stepValues[activeStep].next()
                }}
                disabled={!stepValues[activeStep].validate()}
            >
                {activeStep !== stepValues.length - 1 ? 'Next' : 'Submit'}
            </Button>
        </div>
    )
}

export default Stepper