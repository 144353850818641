import axios from 'axios';
import { getOidc } from './oidc';

const httpClient = axios.create({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token') || null}`,
    },
});

httpClient.interceptors.response.use(function (response) {
    return response;
}, async function async (error) {
    if (401 === error.response.status) {
        try {
            const oidc = await getOidc();
            oidc.isUserLoggedIn && await oidc.renewTokens();
            window.location.reload();
        } catch (error) {
            alert("Session is no longer active. Please login again.")
            console.log(error);
            window.location.reload();
            return Promise.reject(error);
        }
    } else {
        return Promise.reject(error);
    }
});

export { httpClient }