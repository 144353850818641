import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { SupplierProfitabilty } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getSupplierProfitabilityReportMedical = async (
  offset: number,
  limit: number,
): Promise<SupplierProfitabilty[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/supplier_profitability/medical?offset=${offset}&limit=${limit}`,
      {
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data.map((item: any) => SupplierProfitabilty.parse(item)); 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
export const getSupplierProfitabilityReportNonMedical = async (
  offset: number,
  limit: number,
): Promise<SupplierProfitabilty[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/supplier_profitability/non_medical?offset=${offset}&limit=${limit}`,
      {
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data.map((item: any) => SupplierProfitabilty.parse(item)); 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
