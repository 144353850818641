import React, { useState } from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { convertToCurrencyRounded, getDiscount } from '@/utils/currency_converter';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/use-toast';
import { Button } from '@/components/ui/button';
import { Offer } from '../models/inventoryData';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import db from '@/db';

interface DiscountModalProps {
  item_discount: number;
  trade_name: string;
  allowed_to_open: boolean;
  discount_type: "PERCENTAGE" | "VALUE" | "ALWAYS" | "NONE";
  max_discount_value: number;
  price: number;
  sale_id: number;
  item_id: string;
  quantity: number;
  offers: Offer[];
}

export function DiscountModal({ item_discount, trade_name, allowed_to_open, discount_type, max_discount_value, price, sale_id, item_id, quantity, offers }: DiscountModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [discountValue, setDiscountValue] = useState(item_discount);
  const [error, setError] = useState<string | null>(null);

  const validateDiscount = (value: number) => {
    const discountNum = value;

    if (isNaN(discountNum) || discountNum < 0) {
      return "Discount value must be a positive number";
    }

    if (discount_type === "PERCENTAGE") {
      if (discountNum > 100) {
        return "Discount percentage cannot be greater than 100%";
      }
      if (discountNum > max_discount_value) {
        return "Discount percentage cannot be greater than the " + max_discount_value + "% preset discount";
      }
    } else {
      if (discountNum > price) {
        return "Discount value cannot be greater than the item price";
      }
      if (discountNum > max_discount_value) {
        return "Discount value cannot be greater than the " + convertToCurrencyRounded(max_discount_value) + " preset discount";
      }
    }

    return null;
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    setDiscountValue(newValue);
    setError(validateDiscount(newValue));
  };

  const validateAndSaveDiscount = async () => {
    const validationError = validateDiscount(discountValue);
    if (validationError) {
      toast({
        title: "Invalid Discount Value",
        description: validationError,
        duration: 5000,
      });
      return;
    }

    const discountNum = discountValue;
    const sale_item = await db.sales_items.where('sale_id').equals(sale_id).and((item) => item.item_id === item_id).first();
    if (sale_item) {
      sale_item.item_discount = discountNum;
      await db.sales_items.put(sale_item);
    }

    setIsOpen(false);
  };

  return (
    <>
      <span
        className={allowed_to_open ? `underline underline-offset-4 cursor-pointer` : ""}
        onClick={() => {
          if (allowed_to_open) setIsOpen(true);
        }}
        style={{ cursor: allowed_to_open ? 'pointer' : 'not-allowed' }}
      >
        {
          discount_type === "NONE" ? "N/A" :
            <>
              {
                convertToCurrencyRounded(
                  discount_type === "PERCENTAGE" ?
                    ((price * quantity) * (item_discount / 100))
                    : item_discount * quantity
                )
              }
              {
                discount_type === "PERCENTAGE" ? ` @ ${item_discount}%` : ` @ ${item_discount}/qty`
              }
            </>
        }

      </span>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Discount/Offers for {trade_name}</DialogTitle>
          </DialogHeader>
          <Tabs defaultValue={
            discount_type === "NONE" ? offers.length === 0 ? "discount" : "offers" : "discount"
          }>
            <TabsList>
              <TabsTrigger value='discount' disabled={discount_type === "NONE"} >Discount</TabsTrigger>
              <TabsTrigger value='offers' disabled={offers.length === 0 || !offers.some(a => quantity >= a.condition_quantity_required)}>Offers</TabsTrigger>
            </TabsList>
            <TabsContent value='discount'>
              {discount_type === "ALWAYS" && (
                <div>
                  <p>Discount is automatically applied to the item for this batch as preset in the stock</p>
                  <div className='flex flex-col items-center justify-center mt-8 mb-4'>
                    <p className='text-gray-600 font-semibold'>Calculations</p>
                    <div className='grid w-full grid-cols-3 divide-x-2 place-content-center mt-4'>
                      <div className='flex flex-col items-center justify-center px-2'>
                        <p className='text-gray-600 text-sm'>Gross Price</p>
                        <p className='font-semibold'>{
                          convertToCurrencyRounded(
                            price * quantity
                          )
                        }</p>
                      </div>
                      <div className='flex flex-col items-center w-full justify-center px-2'>
                        <p className='text-gray-600 text-sm'>Discount</p>
                        <p className='font-semibold text-center'>{
                          convertToCurrencyRounded(
                            getDiscount(
                              {
                                price: price,
                                quantity: quantity,
                                discount_amt: discountValue,
                                discount_type: discount_type
                              }
                            )
                          )
                        }
                          <br />
                          {
                            ` @ ${discountValue}/qty`
                          }
                        </p>
                      </div>
                      <div className='flex flex-col items-center justify-center px-2'>
                        <p className='text-gray-600 text-sm'>Net Price</p>
                        <p className='font-semibold'>{
                          convertToCurrencyRounded(
                            price * quantity -
                            getDiscount(
                              {
                                price: price,
                                quantity: quantity,
                                discount_amt: discountValue,
                                discount_type: discount_type
                              }
                            )
                          )
                        }</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {discount_type !== "ALWAYS" && (
                <div>
                  <div className="flex items-center space-x-2">
                    <p>Enter {discount_type}</p>
                    <Input
                      type="number"
                      value={discountValue}
                      onChange={handleDiscountChange}
                      className={`w-20 text-center ${error ? 'border-red-500' : ''}`}
                    />
                  </div>
                  {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
                  <div className="mt-4">
                    <div className='flex flex-col items-center justify-center mt-4 mb-4'>
                      <p className='text-gray-600 font-semibold'>Calculations</p>
                      <div className='grid w-full grid-cols-3 divide-x-2 place-content-center mt-4'>
                        <div className='flex flex-col items-center justify-center px-2'>
                          <p className='text-gray-600 text-sm'>Gross Price</p>
                          <p className='font-semibold'>{
                            convertToCurrencyRounded(
                              price * quantity
                            )
                          }</p>
                        </div>
                        <div className='flex flex-col items-center w-full justify-center px-2'>
                          <p className='text-gray-600 text-sm'>Discount</p>
                          <p className='font-semibold text-center'>{
                            convertToCurrencyRounded(
                              getDiscount(
                                {
                                  price: price,
                                  quantity: quantity,
                                  discount_amt: discountValue,
                                  discount_type: discount_type
                                }
                              )
                            )
                          }
                            <br />
                            {
                              discount_type === "PERCENTAGE" ? ` @ ${discountValue}%` : ` @ ${discountValue}/qty`
                            }
                          </p>
                        </div>
                        <div className='flex flex-col items-center justify-center px-2'>
                          <p className='text-gray-600 text-sm'>Net Price</p>
                          <p className='font-semibold'>{
                            convertToCurrencyRounded(
                              price * quantity -
                              getDiscount(
                                {
                                  price: price,
                                  quantity: quantity,
                                  discount_amt: discountValue,
                                  discount_type: discount_type
                                }
                              )
                            )
                          }</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </TabsContent>
            <TabsContent value='offers'>
              <div>
                {
                  offers.map((offer, index) => (
                    <div className='border-b border-gray-200' style={{
                      cursor: quantity >= offer.condition_quantity_required ? 'pointer' : 'not-allowed',
                    }} onClick={() => {
                      if (quantity < offer.condition_quantity_required) {
                          return;
                      }
                    }}>
                      <div key={index} className="flex items-center justify-between py-2">
                        <div>
                          <p>{offer.offer_name}</p>
                          <p className="text-sm text-gray-500">{offer.condition_quantity_required} items required</p>
                        </div>
                        <div>
                          <p>{offer.benefit_type}</p>
                          <p className="text-sm text-gray-500">
                            {
                              offer.benefit_type === "DiscountPercentage" ?
                                `${offer.benefit_discount_percentage.String}%`
                                : `${offer.benefit_free_item_quantity.Int32} free items`
                            }
                          </p>
                        </div>
                      </div>
                      </div>
                  ))
                }
              </div>
            </TabsContent>
          </Tabs>
          <DialogFooter>
            <Button
              onClick={validateAndSaveDiscount}
              className="btn"
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}