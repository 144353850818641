import { z } from "zod";

export const SupplierProfitabilty = z.object({
  supplier_id: z.string(),
  name: z.string(),
  metadata: z.object({
    phone_number: z.string().optional(),
    contact_person_name: z.string().optional()
  }),
  total_supplier_purchase_cost: z.number(),
  total_sold_price: z.number(),
  total_paid_quantity: z.number(),
  supplier_profit: z.number(),
  full_count: z.number(),
});

export type SupplierProfitabilty = z.infer<typeof SupplierProfitabilty>;
