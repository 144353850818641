import { Supplier } from "@/screens/suppliers/components/data/schema"
import { Route as CreatePurchaseOrderRoute } from "@/routes/create-purchase-order";
import { AutoComplete } from "@/components/ui/autocomplete";
import AddSupplier from "@/screens/create-purchase-invoice/components/addSupplier";
import { useState } from "react";

export default function OrderInfo({ supplier, setSupplier }: { supplier?: Supplier, setSupplier: (supplier: Supplier) => void }) {
    const suppliermap = CreatePurchaseOrderRoute.useLoaderData().reduce((acc, curr) => {
        acc.set(curr.id, curr)
        return acc
    }, new Map<string, Supplier>()) as Map<string, Supplier>
    const [suppliername, setSupplierName] = useState<string>("")
    return <div className="col-span-8 flex flex-col">
        <div>
            <h2 className="text-2xl font-bold tracking-tight">Supplier Info</h2>
            <p className="text-muted-foreground">
                Please select a supplier for this purchase order.
            </p>
        </div>
        <hr className="my-6" />

        <div className=" grid grid-cols-2 space-x-4 justify-items-stretch">
            <div>
                <p className="text-sm font-semibold mt-4 mb-2">
                    Selected Supplier Name{": "}{supplier?.name}
                    <span className="text-red-400">*</span>
                </p>

                <div className="border border-muted-input rounded-md">
                    <AutoComplete
                        options={
                            Array.from(suppliermap.values()).map((supplier) => {
                                return {
                                    label: supplier.name,
                                    value: supplier.id
                                }
                            })
                        }
                        placeholder="Search for supplier"
                        emptyMessage="No suppliers found"
                        input={suppliername}
                        onInputChanged={(value) => {
                            setSupplierName(value)
                        }}
                        onValueChange={(value) => {
                            setSupplier(suppliermap.get(value.value)!)
                        }}
                        value={supplier ? { label: supplier.name, value: supplier.id } : undefined}
                    />
                </div>
                <div className="mt-2">
                    <AddSupplier /></div>
            </div>
        </div>
    </div>
}
