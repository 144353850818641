import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { MedicalAvailableInventorySchema, MedicalExpiredInventorySchema, MedicalExpiringInventorySchema, NonMedicalAvailableInventorySchema, NonMedicalExpiredInventorySchema, NonMedicalExpiringInventorySchema } from "./type";

export const getAvailableMedicalInventory = async (offset: number, limit: number): Promise<MedicalAvailableInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/available?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as MedicalAvailableInventorySchema[];
};

export const getAvailableMedicalInventoryExpiring = async (offset: number, limit: number): Promise<MedicalAvailableInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/expiring?offset=${offset}&limit=${limit}&days=30`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as MedicalExpiringInventorySchema[];
};
export const getAvailableMedicalInventoryExpired = async (offset: number, limit: number): Promise<MedicalAvailableInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/expired?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as MedicalExpiredInventorySchema[];
};
export const getAvailableNonMedicalInventory = async (offset: number, limit: number): Promise<NonMedicalAvailableInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/available?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as NonMedicalAvailableInventorySchema[];
};

export const getAvailableNonMedicalInventoryExpiring = async (offset: number, limit: number): Promise<NonMedicalExpiringInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/expiring?offset=${offset}&limit=${limit}&days=30`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as NonMedicalExpiringInventorySchema[];
};
export const getAvailableNonMedicalInventoryExpired = async (offset: number, limit: number): Promise<NonMedicalExpiredInventorySchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/expired?offset=${offset}&limit=${limit}`, {
        pharmacy_code: getPharmacyID(),
    });
    return response.data.data as NonMedicalExpiredInventorySchema[];
};