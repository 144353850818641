// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/inventory/non-medical-master/component";
import { SearchParams } from "@/screens/inventory/non-medical-master/model";
import { getNonMedicalMasterItems } from "@/screens/inventory/non-medical-master/api";
import { NonMedicalMasterSchema } from "@/screens/inventory/non-medical-master/type";
import { Loading } from "@/layouts/loading";

export const Route = createFileRoute("/inventory/non-medical-master/")({
    component: Component,
    validateSearch: SearchParams,
    loaderDeps: ({ search: { page, pageSize, search } }) => ({ page, pageSize, search }),
    loader: async (params): Promise<NonMedicalMasterSchema[]> => {
        return await getNonMedicalMasterItems(params.deps.page - 1, params.deps.pageSize, params.deps.search!);
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});