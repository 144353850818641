import { ScrollArea } from "@/components/ui/scroll-area";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { DailyItemsChecklist } from "./components/data/schema";
import { Checkbox } from "@/components/ui/checkbox";
import { useEffect, useState } from "react";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";

const API_URL = import.meta.env.VITE_API_URL as string

async function markChecked(id: number) {
    const response = await httpClient.post(API_URL + "/public/pharmacy/dailyitems/mark", {
        checklist_id: id,
    })
    const response_data = await response.data;
    if (!response_data.status) {
        throw new Error("API Fetch Error");
    }
}

async function markUnchecked(id: number) {
    const response = await httpClient.post(API_URL + "/public/pharmacy/dailyitems/unmark", {
        id
    })
    const response_data = await response.data;
    if (!response_data.status) {
        throw new Error("API Fetch Error");
    }
}

const ChecklistComponent = ({ item, refreshData }: { item: DailyItemsChecklist; refreshData: () => Promise<void> }) => {
  const [checked, setChecked] = useState(item.marked);
  const [allowStateChange, setAllowStateChange] = useState(true);
  return (
    <div className="flex items-center space-x-4 px-4 py-2 border-b-[1px]">
      <Checkbox
        value={item.id.toString()}
        id={item.id.toString()}
        checked={checked}
        disabled={!allowStateChange}
        onCheckedChange={async (checked) => {
          if (checked) {
            setChecked(true);
            setAllowStateChange(false);
            await markChecked(item.id);
            await refreshData();
            setAllowStateChange(true);
          } else {
            setChecked(false);
            setAllowStateChange(false);
            await markUnchecked(item.checklist_id.Int32);
            await refreshData();
            setAllowStateChange(true);
          }
        }}
      />
      <div>
        <p className="text-sm text-gray-400">{item.item_type}</p>
        <p className="text-sm truncate font-semibold">{item.item_name}</p>
      </div>
    </div>
  );
};

const DailyChecklist = ({ data }: { data: DailyItemsChecklist[] }) => {
    const [dataState, setDataState] = useState<DailyItemsChecklist[]>([]);
    useEffect(() => {
        setDataState(data);
    }, [data])
    async function refreshData() {
        const response = await httpClient.post(API_URL + "/public/pharmacy/dailyitems/get/today", {
            pharmacy_code: getPharmacyID(),
        })
        const response_data = await response.data;
        if (!response_data.status) {
            throw new Error("API Fetch Error");
        }
        setDataState(response_data.data as DailyItemsChecklist[]);
    }
  return (
    <div className="col-span-3 border rounded-lg">
      <div className="h-[300px]">
        <h2 className="text-2xl font-bold pt-4 px-4">Daily Checklist</h2>
        {data.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <p className="text-gray-400 text-sm">No data available</p>
          </div>
        )}
        <ScrollArea className="h-full mt-4">
          <RadioGroup defaultValue="option-one">
            {dataState.map((item, index) => (
              <ChecklistComponent item={item} refreshData={refreshData} key={item.id}/>
            ))}
          </RadioGroup>
        </ScrollArea>
      </div>
    </div>
  );
};

export default DailyChecklist;
