import { Clock } from 'lucide-react'
import StartShiftModal from '@/components/shifts/start-shift-modal'

const NoShiftFound = ({
    value,
    setValue,
    setDisplaySalesComp
}: {
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
    setDisplaySalesComp: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    return (
        <div className='h-full w-full grid place-items-center bg-gray-100 py-16'>
            <div className='flex flex-col items-center justify-center space-y-6'>
                <Clock 
                    className='text-[#71717A]'
                    size={48}
                />
                <div className='text-center'>
                    <p className='text-lg'>No shift found</p>
                    <p className='text-sm text-gray-500'>You have not started your shift. Start shift to proceed with sales</p>
                </div>
                <StartShiftModal 
                    value={false}
                    setValue={() => setValue(true)}
                    setDisplaySalesComp={setDisplaySalesComp}
                    showTriggerButton={true}
                />
            </div>
        </div>
    )
}

export default NoShiftFound