import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { PurchaseInvoiceManySchema } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getPurchaseInvoiceList = async (
    offset: number,
    limit: number,
    supplierID?: string,
): Promise<PurchaseInvoiceManySchema[]> => {
    try {
        const reqObj = supplierID ? { pharmacy_code: getPharmacyID(), supplier_id: supplierID } : { pharmacy_code: getPharmacyID() };
        const response = await httpClient.post(
            `${apiUrl}/public/pharmacy/purchase_invoice/get/list?offset=${offset}&limit=${limit}`,
            reqObj
        );
        return response.data.data.map((item: any) => PurchaseInvoiceManySchema.parse(item));
    } catch (error) {
        console.error("Error fetching purchase invoice:", error);
        throw new Error("Failed to fetch purchse invoice");
    }
};
