import { createReactOidc } from "oidc-spa/react";
import { z } from "zod";

export const {
    OidcProvider,
    /**
     * Note: If you have multiple OidcProvider in your app
     * you do not need to use the useClient hook that that corresponds
     * to the above OidcProvider.
     */
    useOidc,
    getOidc,
} = createReactOidc({
    clientId: import.meta.env.VITE_OIDC_CLIENT_ID,
    issuerUri: import.meta.env.VITE_OIDC_ISSUER,
    publicUrl: import.meta.env.BASE_URL,
    decodedIdTokenSchema: z.object({
        sub: z.string(),
        preferred_username: z.string(),
        pharmacy_id: z.array(z.string()),
    })
});

getOidc().then((oidc) => {
    if (!oidc.isUserLoggedIn) {
        return;
    } 

    if (oidc.authMethod === "back from auth server") {
        const pharmacy_id_list = oidc.getTokens().decodedIdToken.pharmacy_id;
        localStorage.setItem(
            "pharmacy_id_list",
            JSON.stringify(pharmacy_id_list)
        );
        localStorage.setItem("token", oidc.getTokens().accessToken);
        window.location.reload();
    }
})