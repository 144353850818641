import { createRootRoute, redirect } from "@tanstack/react-router";
import Component from "@/layouts/layout/component";
import { getOidc } from "@/oidc";
import { Loader } from "lucide-react";
import { Loading } from "@/layouts/loading";

export const Route = createRootRoute({
    component: Component,
    beforeLoad: async () => {
        const oidc = await getOidc();
        if (!oidc.isUserLoggedIn) {
            await oidc.login({
                doesCurrentHrefRequiresAuth: true,
            });
        }
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
});