import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/accounts/component";
import { Loading } from "@/layouts/loading";
import { Revenue } from "@/screens/accounts/revenue/model";

const API_URL = import.meta.env.VITE_API_URL as string

type AccountsOverview = {
  current_week_revenue: Revenue[];
  last_week_revenue: Revenue[];
  current_week_cog: Revenue[];
  last_week_cog: Revenue[];
}

export const Route = createFileRoute("/accounts/")({
  component: Component,
  loader: async (params): Promise<AccountsOverview> => {
    const response_current_week_revenue_req = httpClient.post(API_URL + `/public/pharmacy/account/revenue`, {
      pharmacy_code: getPharmacyID(),
      // Start and End date are unix timestamps and are set to the last 7 days
      start_date: Math.floor(Date.now() / 1000) - 604800,
      end_date: Math.floor(Date.now() / 1000),
    });
    const response_last_week_revenue_req = httpClient.post(API_URL + `/public/pharmacy/account/revenue`, {
      pharmacy_code: getPharmacyID(),
      // Start and End date are unix timestamps and are set to the last 14 days
      start_date: Math.floor(Date.now() / 1000) - 1209600,
      end_date: Math.floor(Date.now() / 1000) - 604800,
    });

    const response_current_week_cog_req = httpClient.post(API_URL + '/public/pharmacy/account/cost_of_goods',{
      pharmacy_code: getPharmacyID(),
      // Start and End date are unix timestamps and are set to the last 7 days
      start_date: Math.floor(Date.now() / 1000) - 604800,
      end_date: Math.floor(Date.now() / 1000),
    })
    const response_last_week_cog_req = httpClient.post(API_URL + '/public/pharmacy/account/cost_of_goods',{
      pharmacy_code: getPharmacyID(),
      // Start and End date are unix timestamps and are set to the last 14 days
      start_date: Math.floor(Date.now() / 1000) - 1209600,
      end_date: Math.floor(Date.now() / 1000) - 604800,
    })

    const [response_current_week_revenue, response_last_week_revenue,response_current_week_cog,response_last_week_cog] = await Promise.all([response_current_week_revenue_req, response_last_week_revenue_req, response_current_week_cog_req, response_last_week_cog_req]);

    const data_current_week_revenue =  response_current_week_revenue.data;
    const data_last_week_revenue = await response_last_week_revenue.data;
    const data_current_week_cog = response_current_week_cog.data;
    const data_last_week_cog = response_last_week_cog.data;

    if (!data_current_week_revenue.status) {
      throw new Error("API Fetch Error");
    }
    
    if (!data_last_week_revenue.status) {
      throw new Error("API Fetch Error");
    }

    if (!data_current_week_cog.status) {
      throw new Error("API Fetch Error");
    }

    if (!data_last_week_cog.status) {
      throw new Error("API Fetch Error");
    }

    const current_week_revenue = data_current_week_revenue.data as Revenue[];
    const last_week_revenue = data_last_week_revenue.data as Revenue[];
    const current_week_cog = data_current_week_cog.data as Revenue[];
    const last_week_cog = data_last_week_cog.data as Revenue[];
    return {
      current_week_revenue,
      last_week_revenue,
      current_week_cog,
      last_week_cog
    };

  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1
});