import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "../../../screens/reports/stock-movement/model";
import { getProfitabilityReportNonMedical, getProfitabilityReportMedical } from "@/screens/reports/stock-movement/api";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/stock-movement/component";

export const Route = createFileRoute("/reports/stock-movement/")({
    component: Component,
    validateSearch: SearchParams,
    loaderDeps: ({ search: { page, pageSize, type, start_date } }) => ({
        page,
        pageSize,
        type,
        start_date
    }),
    loader: async (params) => {
        const { page, pageSize, type, start_date } = params.deps;
        try {
            const offset = page - 1;
            const limit = pageSize;
            if (type === "MEDICAL") {
                return await getProfitabilityReportMedical(offset, limit, start_date);
            } else {
                return await getProfitabilityReportNonMedical(offset, limit, start_date);
            }
        } catch (error) {
            console.error("Error fetching Stock Movement Report:", error);
            throw new Error("Failed to fetch Stock Movement Report");
        }
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1,
});
