import { Link } from '@tanstack/react-router'
import { ChevronLeft } from 'lucide-react'

const BackNav = ({
    title,
    id
}: {
    title: string,
    id: string
}) => {
    return (
        <div className='flex items-center justify-center'>
            <Link
                to={'/inventory/internal/$id'}
                params={{ id }}
                className='flex items-center gap-4'
            >
                <span 
                    className='grid place-items-center bg-[#F4F4F5] rounded-md w-8 h-8 cursor-pointer'
                >
                    <ChevronLeft 
                        size={16} 
                        className='text-gray-600'
                    />
                </span>
                <p className='font-bold text-3xl'>{title}</p>
            </Link>
        </div>
    )
}

export default BackNav