import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { ProductProfitabilty } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getProfitabilityReportMedical = async (
  offset: number,
  limit: number,
): Promise<ProductProfitabilty[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/product_profitability/medical?offset=${offset}&limit=${limit}`,
      {
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data.map((item: any) => ProductProfitabilty.parse(item)); 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
export const getProfitabilityReportNonMedical = async (
  offset: number,
  limit: number,
): Promise<ProductProfitabilty[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/product_profitability/non_medical?offset=${offset}&limit=${limit}`,
      {
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data.map((item: any) => ProductProfitabilty.parse(item)); 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
