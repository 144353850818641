// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/sales-per-item/component";
import { SearchParams } from "../../../screens/reports/sales-per-item/model";
import {
  getMedicalSalesPerItem,
  getNonMedicalSalesPerItem,
} from "@/screens/reports/sales-per-item/api";
import { InventorySchema } from "@/screens/reports/sales-per-item/type";
import { Loading } from "@/layouts/loading";

export const Route = createFileRoute("/reports/sales-per-item/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize, type } }) => ({
    page,
    pageSize,
    type,
  }),
  loader: async (params): Promise<InventorySchema> => {
    if (params.deps.type === "MEDICAL") {
      const response = await getMedicalSalesPerItem(
        params.deps.page - 1,
        params.deps.pageSize
      );
      return {
        type: "MEDICAL",
        data: response.map((item) => {
          return {
            ...item,
            type: "MEDICAL",
          };
        }),
      };
    } else {
      const response = await getNonMedicalSalesPerItem(
        params.deps.page - 1,
        params.deps.pageSize
      );
      return {
        type: "NON_MEDICAL",
        data: response.map((item) => {
          return {
            ...item,
            type: "NON_MEDICAL",
          };
        }),
      };
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
