import { z } from "zod"

export const StockMovement = z.object({
    master_id: z.string(),
    trade_name: z.string(),
    unit: z.number(),
    unit_type: z.string(),
    barcode: z.string(),
    sold_quantity: z.number(),
    available_stock: z.number(),
    stock_received: z.number(),
    opening_stock: z.number(),
    full_count: z.number()
})

export type StockMovement = z.infer<typeof StockMovement>;
