import { z } from "zod";

export const PLReportSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  is_valid_patient: z.boolean(),
  patient_details: z.object({
    patient_id: z.string(),
    name: z.string(),
    phone: z.string(),
    emirates_id: z.string(),
    metadata: z.object({
      address: z.string(),
    }),
  }),
  invoice_amount: z.number(),
  payment_type: z.enum(["CASH", "SPLIT", "CARD"]).optional(),
  cogs: z.number(),
  profit_percentage: z.number(),
  invoice_id: z.string(),
  full_count:z.number(),
});

export type PLReportSchema = z.infer<typeof PLReportSchema>;
