import { httpClient } from "@/httpClient";
import dayjs from "dayjs";
import getPharmacyID from "@/utils/pharmacy_code";
// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/overview/component";
import { CurrentlyActiveShift, DailyItemsChecklist, RecentTransacitons, SalesByDayByHour, SalesByDayByHourData, Transactions } from "@/screens/overview/components/data/schema";
import { Loading } from "@/layouts/loading";

const API_URL = import.meta.env.VITE_API_URL as string

type Props = {
  recent_transactions: RecentTransacitons[];
  sales_by_day_by_hour: SalesByDayByHourData;
  daily_items_checklist: DailyItemsChecklist[];
  transactions: Transactions;
  currently_active_shift: CurrentlyActiveShift;
}

export const Route = createFileRoute("/overview/")({
  component: Component,
  loader: async (): Promise<Props> => {
    try {
      const response_recent_trans_promise = httpClient.post(API_URL + '/public/pharmacy/overview/recent_transactions', {
        pharmacy_code: getPharmacyID(),
      });
      const response_sales_by_hour_promise = httpClient.post(API_URL + '/public/pharmacy/overview/sales_by_hour_per_day', {
        pharmacy_code: getPharmacyID(),
        date: dayjs().format('YYYY-MM-DD'),
      });
      const response_daily_items_promise = httpClient.post(API_URL + '/public/pharmacy/dailyitems/get/today', {
        pharmacy_code: getPharmacyID(),
      });
      const response_current_month_transactions_promise = httpClient.post(API_URL + '/public/pharmacy/overview/transactions', {
        pharmacy_code: getPharmacyID(),
        month: parseInt(dayjs().format('MM')),
        year: parseInt(dayjs().format('YYYY')),
      })
      const response_current_active_shift_promise = httpClient.post(API_URL + '/public/pharmacy/shifts/current_active', {
        pharmacy_code: getPharmacyID(),
      })

      const [response_recent_trans,
        response_sales_by_hour,
        response_daily_items,
        response_current_month_transactions,
        response_current_active_shift
      ] =
        await Promise.all([response_recent_trans_promise,
          response_sales_by_hour_promise,
          response_daily_items_promise,
          response_current_month_transactions_promise,
          response_current_active_shift_promise])
      const response_current_active_shift_data = await response_current_active_shift.data;
      if (!response_current_active_shift_data.status) {
        throw new Error("API Fetch Error");
      }
      const response_recent_trans_data = await response_recent_trans.data;
      if (!response_recent_trans_data.status) {
        throw new Error("API Fetch Error");
      }
      const response_sales_by_hour_data = await response_sales_by_hour.data;
      if (!response_sales_by_hour_data.status) {
        throw new Error("API Fetch Error");
      }
      const response_daily_items_data = await response_daily_items.data;
      if (!response_daily_items_data.status) {
        throw new Error("API Fetch Error");
      }
      const response_current_month_transactions_data = await response_current_month_transactions.data;
      if (!response_current_month_transactions_data.status) {
        throw new Error("API Fetch Error");
      }
      return {
        recent_transactions: response_recent_trans_data.data as RecentTransacitons[],
        sales_by_day_by_hour: response_sales_by_hour_data.data as SalesByDayByHourData,
        daily_items_checklist: !response_daily_items_data.data ? [] : response_daily_items_data.data as DailyItemsChecklist[],
        transactions: response_current_month_transactions_data.data as Transactions,
        currently_active_shift: response_current_active_shift_data.data as CurrentlyActiveShift,
      }
    } catch (error) {
      console.log('Error:', error);
      throw new Error("API Fetch Error");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
  errorComponent: () => <div>Loading...</div>,
});