import { useState } from "react";
import Overview from "./components/overview";
import Account from "./components/account";
import Manage from "./components/manage";

export default function SettingsPage() {
  const [activeTab, setActiveTab] = useState("overview");

  const tabs = [
    { id: "overview", label: "Overview", component: Overview },
    { id: "account", label: "Account", component: Account },
    { id: "manage", label: "Manage", component: Manage },
  ];

  return (
    <div className="flex h-screen gap-x-12">
      <div className="w-[250px]">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`block w-full px-4 py-2 text-sm text-gray-700 text-left ${
              activeTab === tab.id
                ? "bg-[#F4F4F5] rounded-md"
                : "bg-transparent"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="flex-1">
        {activeTab === "overview" && <Overview />}
        {activeTab === "account" && <Account />}
        {activeTab === "manage" && <Manage />}
      </div>
    </div>
  );
}
