import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "@/screens/reports/supplier-profitability/model";
import { getSupplierProfitabilityReportMedical, getSupplierProfitabilityReportNonMedical } from "@/screens/reports/supplier-profitability/api";
import { SupplierProfitabilty } from "@/screens/reports/supplier-profitability/type";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/supplier-profitability/component";

export const Route = createFileRoute("/reports/supplier-profitability/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize, type } }) => ({
    page,
    pageSize,
    type
  }),
  loader: async (params) => {
    const { page, pageSize, type } = params.deps;
    try {
      const offset = page - 1;
      const limit = pageSize;
      if (type === "MEDICAL") {
        return await getSupplierProfitabilityReportMedical(offset, limit);
      } else {
        return await getSupplierProfitabilityReportNonMedical(offset, limit);
      }
    } catch (error) {
      console.error("Error fetching Supplier Profitability Report:", error);
      throw new Error("Failed to fetch Supplier Profitability Report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
