import { Button } from '@/components/ui/button'
import BarChart from './components/chart/bar-chart'
import LineChart from './components/chart/line-chart'
import StatsBarChart from './components/chart/stats-bar-chart'
import { Route as accountsOverviewRoute } from '@/routes/accounts/index'
import { convertToCurrencyRounded } from '@/utils/currency_converter'
import { useNavigate } from '@tanstack/react-router'
import dayjs from 'dayjs'
import { useUnleashContext, useFlag } from '@unleash/proxy-client-react';
import getPharmacyID from '@/utils/pharmacy_code'

const Component = () => {
    const { current_week_revenue, last_week_revenue, current_week_cog, last_week_cog } = accountsOverviewRoute.useLoaderData();
    const navigate = useNavigate();
    const updateContext = useUnleashContext();
    const mapped_cog = current_week_cog.map((item) => {
        return {
            day: dayjs(item.time_interval).format('ddd'),
            "COG": item.total_revenue
        }
    })
    const this_week_cog_total = current_week_cog.reduce((acc, item) => acc + item.total_revenue, 0)
    const last_week_cog_total = last_week_cog.reduce((acc, item) => acc + item.total_revenue, 0)

    updateContext({
        userId: getPharmacyID()
    })

    const revenueGraphFlag = useFlag('revenue-accounts');
    const cogsGraphFlag = useFlag('cogs-accounts');
    const grossMarginGraphFlag = useFlag('gross-margin-accounts');

    return (
        <>
            <div className="overflow-y-scroll h-screen pb-48">
                <div className="flex items-center justify-between">
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight">Accounts Overview</h2>
                        <p className="text-muted-foreground">
                            Click on any of the cards to get more detailed view with more filters.
                        </p>
                    </div>
                    <Button
                        variant={"secondary"}
                    >
                        Customize
                    </Button>
                </div>
                <hr className="my-4" />
                <h2 className='text-3xl font-semibold mt-8 mb-4'>Revenue</h2>
                <div className='flex flex-col flex-1 gap-3'>
                    {revenueGraphFlag && <StatsBarChart
                        title="Revenue (Weekly)"
                        value={convertToCurrencyRounded(current_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0))}
                        trend={convertToCurrencyRounded(last_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0)) + " vs Last Week"}
                        trendDirection={
                            last_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0) > current_week_revenue.reduce((acc, item) => acc + item.total_revenue, 0) ? 'down' : 'up'
                        }
                        data={current_week_revenue}
                        xAxisKey="time_interval"
                        dataKey="total_revenue"
                        color="#71717A"
                        footer="Showing total revenue for the last 7 days"
                        onClick={() => {
                            navigate({
                                to: '/accounts/revenue',
                            })
                        }}
                        active={true}
                    />}
                </div>

                <hr className="my-4" />

                <div className='grid grid-cols-3 gap-x-6'>
                    {cogsGraphFlag && <BarChart
                        title="COGS"
                        value={convertToCurrencyRounded(this_week_cog_total)}
                        trend= {`${convertToCurrencyRounded(Math.abs(this_week_cog_total-last_week_cog_total))} ${this_week_cog_total > last_week_cog_total ? 'less' : 'more'} vs Last Week`}
                        data={mapped_cog}
                        xAxisKey="day"
                        dataKey="COG"
                        color="#1458DD"
                        footer="Showing COG for the last 7 days"
                        active={true}
                        onClick={()=>{
                            navigate({
                                to: '/accounts/cost-of-goods'
                            })
                        }}
                    />}
                    {grossMarginGraphFlag && <LineChart
                        title="Gross Margin"
                        value="9.7%"
                        trend="23.7 vs Last Month"
                        data={[
                            { month: dayjs().add(-5, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().add(-4, 'd').format("DD"), desktop: 305 },
                            { month: dayjs().add(-3, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().add(-2, 'd').format("DD"), desktop: 305 },
                            { month: dayjs().add(-1, 'd').format("DD"), desktop: 186 },
                            { month: dayjs().format("DD"), desktop: 305 },
                        ]}
                        xAxisKey="month"
                        dataKey="desktop"
                        color="#1458DD"
                        active={false}
                        footer='Showing totals for the last 6 days'
                    />}
                </div>
            </div>
        </>
    )
}

export default Component