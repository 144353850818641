import React from 'react'

const Card = ({
    title,
    text
}: {
    title: string,
    text: string
}) => {
    return (
        <div className='flex flex-col border rounded-lg py-8 px-4'>
            <p className='text-sm text-gray-500'>{title}</p>
            <p className='text-2xl font-semibold'>{text}</p>
        </div>
    )
}

export default Card