import React, { useState } from "react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { MoveRightIcon, PlusCircleIcon, X } from "lucide-react";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import { Route as InventoryAvailableRoute } from "@/routes/inventory/available-inventory/index";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "@/components/ui/drawer";
import GenerateBarcode from "../components/GenerateBarcode";

const Component = () => {
  const searchParams = InventoryAvailableRoute.useSearch();
  const data = InventoryAvailableRoute.useLoaderData();
  const navigate = useNavigate();

  // Sorting state
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("expiry_date");

  // Sort function
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting data
  const sortedData = data.data.slice().sort((a, b) => {
    if (orderBy === "expiry_date") {
      const dateA = new Date(
        typeof a.expiry_date === "string" ? a.expiry_date : a.expiry_date.Time
      ).getTime();
      const dateB = new Date(
        typeof b.expiry_date === "string" ? b.expiry_date : b.expiry_date.Time
      ).getTime();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return (
    <>
      <Drawer direction="right">
        <DrawerContent className="focus-visible:outline-none w-fit min-w-[30vw] px-4">
          <DrawerClose asChild className="fixed inset-y-2 right-4">
            <Button className="h-fit aspect-square p-2 grid place-items-center bg-[#eceff8] hover:bg-[#eceff8]">
              <X className="text-[#667085] h-6 w-6" />
            </Button>
          </DrawerClose>
          <div className="w-full h-full pt-4">
            <GenerateBarcode />
          </div>
        </DrawerContent>
        <div>
          <div className="flex items-center justify-between">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink
                    onClick={() => {
                      navigate({
                        to: "/inventory",
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Inventory Overview
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>All Inventory</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>

            <div className="grid px-4 py-2 h-10">
              <Tabs defaultValue={searchParams.type}>
                <TabsList>
                  <TabsTrigger
                    value={"MEDICAL"}
                    onClick={() => {
                      navigate({
                        search: {
                          ...searchParams,
                          type: "MEDICAL",
                        },
                      });
                    }}
                  >
                    Medical
                  </TabsTrigger>
                  <TabsTrigger
                    value={"NON_MEDICAL"}
                    onClick={() => {
                      navigate({
                        search: {
                          ...searchParams,
                          type: "NON_MEDICAL",
                        },
                      });
                    }}
                  >
                    Non Medical
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            </div>
            <DrawerTrigger asChild>
              <Button>Generate Barcode</Button>
            </DrawerTrigger>
          </div>
          <hr className="my-4" />

          <div className="overflow-y-scroll h-screen pb-48">
            <div className="flex items-center space-x-2">
              <div className="flex items-center space-x-2 ml-auto">
                {/* Uncomment and configure as needed
                <Input
                  placeholder="Search Inventory..."
                  className="h-8 w-[150px] lg:w-[250px]"
                  value={searchParams.search}
                  onChange={(e) => {
                    navigate({
                      search: {
                        ...searchParams,
                        search: e.target.value
                      }
                    });
                  }}
                />
                */}
                <Button
                  variant="outline"
                  size="sm"
                  className={`ml-auto hidden h-8 lg:flex ${searchParams.expiring_in_30_days ? "bg-primary text-white" : ""}`}
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        expiring_in_30_days: !searchParams.expiring_in_30_days,
                        expired: false,
                        page: 1,
                      },
                    });
                  }}
                >
                  <PlusCircleIcon className="mr-2 h-4 w-4" />
                  Expiring in 30 Days
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  className={`ml-auto hidden h-8 lg:flex ${searchParams.expired ? "bg-primary text-white" : ""}`}
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        expired: !searchParams.expired,
                        expiring_in_30_days: false,
                        page: 1,
                      },
                    });
                  }}
                >
                  <PlusCircleIcon className="mr-2 h-4 w-4" />
                  Expired
                </Button>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="sm"
                    className="ml-auto hidden h-8 lg:flex"
                  >
                    <MixerHorizontalIcon className="mr-2 h-4 w-4" />
                    View
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  align="end"
                  className="w-[150px]"
                ></DropdownMenuContent>
              </DropdownMenu>
            </div>

            <Table className="mt-4 text-sm">
              <TableHeader>
                <TableRow>
                  <TableHead>Trade Name</TableHead>
                  <TableHead>
                    Available Stock <br /> (Unit per stock)
                  </TableHead>
                  <TableHead>Batch Number</TableHead>
                  <TableHead
                    className="cursor-pointer flex items-center"
                    onClick={() => handleRequestSort("expiry_date")}
                  >
                    Expiry Date
                    {orderBy === "expiry_date" && (
                      <CaretSortIcon
                        className={`ml-2 h-4 w-4 ${order === "asc" ? "rotate-180" : ""}`}
                      />
                    )}
                  </TableHead>
                  <TableHead>Unit Type</TableHead>
                  <TableHead>Barcode</TableHead>
                  <TableHead>Price</TableHead>
                  <TableHead>Cost</TableHead>
                  <TableHead>Last Sold On</TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.trade_name}</TableCell>
                    <TableCell>
                      {item.available_stock} ({item.unit})
                    </TableCell>
                    <TableCell>{item.batch_number}</TableCell>
                    <TableCell>
                      {dayjs(
                        typeof item.expiry_date === "string"
                          ? item.expiry_date
                          : item.expiry_date.Time
                      ).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{item.unit_type}</TableCell>
                    <TableCell>{item.barcode}</TableCell>
                    <TableCell>
                      {convertToCurrencyRounded(item.price)}
                    </TableCell>
                    <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                    <TableCell>
                      {item.last_sold_date
                        ? dayjs(item.last_sold_date).format("DD/MM/YYYY")
                        : "Not Available"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="link"
                        onClick={() => {
                          navigate({
                            to: "/inventory/item/$type/$itemId",
                            params: {
                              type: searchParams.type,
                              itemId:
                                item.type === "MEDICAL"
                                  ? item.medical_master_id
                                  : item.non_medical_master_id,
                            },
                            search: searchParams,
                          });
                        }}
                      >
                        <MoveRightIcon className="w-4 h-4 mr-2" />
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <hr className="my-0" />

            <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
              <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                Page {searchParams.page} of{" "}
                {data.data.length > 0
                  ? Math.ceil(data.data[0].full_count / searchParams.pageSize)
                  : 1}
              </div>
              <div className="flex space-x-2">
                <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: 1,
                      },
                    });
                  }}
                  disabled={searchParams.page === 1}
                >
                  <span className="sr-only">Go to first page</span>
                  <DoubleArrowLeftIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page - 1,
                      },
                    });
                  }}
                  disabled={searchParams.page === 1}
                >
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page + 1,
                      },
                    });
                  }}
                  disabled={
                    data.data.length === 0 ||
                    data.data[0].full_count <=
                      searchParams.page * searchParams.pageSize
                  }
                >
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
                </Button>
                <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: Math.ceil(
                          data.data[0].full_count / searchParams.pageSize
                        ),
                      },
                    });
                  }}
                  disabled={
                    data.data.length === 0 ||
                    data.data[0].full_count <=
                      searchParams.page * searchParams.pageSize
                  }
                >
                  <span className="sr-only">Go to last page</span>
                  <DoubleArrowRightIcon className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Component;
