import { httpClient } from "@/httpClient";
import dayjs from "dayjs";
import getPharmacyID from "@/utils/pharmacy_code";
// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/sales-summary/component";
import {
  SalesHistory,
  SalesHistorySearchParams,
} from "@/screens/overview/components/data/schema";
import { Loading } from "@/layouts/loading";
import { PharmacyInfo } from "@/screens/receipt/model";
import { SearchParams } from "@/screens/reports/sales-summary/model";
import { getSalesReport } from "@/screens/reports/sales-summary/api";

const API_URL = import.meta.env.VITE_API_URL as string;

type Props = {
  sales_history: SalesHistory[];
  pharmacy_info: PharmacyInfo;
};

export const Route = createFileRoute("/reports/sales-summary/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { date } }) => ({
    date,
  }),
  loader: async (params) => {
    const { date } = params.deps;
    try {
      const selectedDate = date;
      const getSalesReportData = await getSalesReport(selectedDate);
      return getSalesReportData;
    } catch (error) {
      console.error("Error fetching shift report:", error);
      throw new Error("Failed to fetch shift report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
