import { ChartSpline, TrendingUp } from "lucide-react";
import { CartesianGrid, LabelList, Line, LineChart, XAxis, YAxis, ResponsiveContainer } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";

interface LineChartProps {
  title: string;
  value: string;
  trend: string;
  data: Array<{ [key: string]: string | number }>;
  xAxisKey: string;
  dataKey: string;
  color: string;
  footer?: string;
  xAxisType?: "number" | "date" | "amount" | "string";
  yAxisType?: "number" | "date" | "amount" | "string";
  onClick?: () => void;
  active?: boolean;
}
export default function Component({
  title,
  value,
  trend,
  data,
  xAxisKey,
  dataKey,
  color,
  footer,
  xAxisType = "string",
  yAxisType = "number",
  onClick,
  active
}: LineChartProps) {
  const chartConfig: ChartConfig = {
    [dataKey]: {
      label: dataKey,
      color: color,
    },
  };

  return (
    <Tooltip>
      {!active && <TooltipContent>
          <p>Coming Soon</p>
      </TooltipContent>}
      <TooltipTrigger>
        <Card className={`bg-[#FCFCFC] ${active ? '' : 'opacity-25'}`} onClick={() => {
        if (onClick) {
          onClick();
        }
      }} style={{
        cursor: onClick ? "pointer" : "default"
      }}>
        <div className="m-4 flex items-center text-gray-600">
          <ChartSpline className="w-4 h-4" />
          <p className="text-sm ml-1">{title}</p>
        </div>
        <hr className="my-4" />
        <CardHeader>
          <CardTitle>{value}</CardTitle>
          <CardDescription>
            <div className="font-medium leading-none">{trend}</div>
          </CardDescription>
        </CardHeader>
        
        <CardContent >
  
        <ResponsiveContainer width="100%" height={400}>
          <ChartContainer config={chartConfig} className="h-full w-full">
            {/* the componenet needs to be separated out for both gross margin and the revenue due to the 
            customasability of height and other factors */}
            <LineChart
              accessibilityLayer
              data={data}
              margin={{
                top: 20,
                left: 60, // Increased left margin
                right: 30,
                bottom: 20,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey={xAxisKey}
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => {
                  if (xAxisType === "date") {
                    return dayjs(value).format("MMM DD, YYYY");
                  } else if (xAxisType === "amount") {
                    return convertToCurrencyRounded(value as number);
                  } else if (xAxisType === "number") {
                    return value.toString();
                  } else {
                    return value.toString();
                  }
                }}
                tick={{ dx: 0, dy: 10 }} // Add some padding to X-axis ticks
              />
              <YAxis
                dataKey={dataKey}
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value, index) => {
                  // Add padding to the first tick
                  if (index === 0) return `  ${value}`;
                  if (yAxisType === "date") {
                    return dayjs(value).format("MMM DD, YYYY");
                  } else if (yAxisType === "amount") {
                    return convertToCurrencyRounded(value as number);
                  } else if (yAxisType === "number") {
                    return value.toString();
                  } else {
                    return value.toString();
                  }
                }}
                tick={{ dx: -10 }} // Move Y-axis ticks slightly to the left
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="line" labelFormatter={(value, payload) => {
                  if (xAxisType === "date") {
                    return dayjs(value).format("MMM DD, YYYY");
                  } else if (xAxisType === "amount") {
                    return convertToCurrencyRounded(value as number);
                  } else if (xAxisType === "number") {
                    return value.toString();
                  } else {
                    return value.toString();
                  }
                }}/>}
              />
              <Line
                dataKey={dataKey}
                stroke={color}
                type={"monotone"}
                strokeWidth={2}
                dot={false}
              >
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                  formatter={(value: any) => {
                    if (yAxisType === "date") {
                      return dayjs(value).format("MMM DD, YYYY");
                    } else if (yAxisType === "amount") {
                      return convertToCurrencyRounded(value as number);
                    } else if (yAxisType === "number") {
                      return value.toString();
                    } else {
                      return value.toString();
                    }
                  }}
                />
              </Line>
            </LineChart>
          </ChartContainer>
          </ResponsiveContainer>
        </CardContent>
        <CardFooter className="flex-col items-start gap-2 text-sm">
          {footer && (
            <div className="leading-none text-muted-foreground">{footer}</div>
          )}
        </CardFooter>
      </Card>
      </TooltipTrigger>
    </Tooltip>
  );
}
