import { httpClient } from "@/httpClient";
import dayjs from "dayjs";
import getPharmacyID from "@/utils/pharmacy_code";
// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/overview/sales-history/component";
import {
  SalesHistory,
  SalesHistorySearchParams,
  UniqueUsersSalesSchema,
} from "@/screens/overview/components/data/schema";
import { Loading } from "@/layouts/loading";
import { PharmacyInfo } from "@/screens/receipt/model";
import { getUniqueUserSales } from "@/screens/overview/sales-history/api";

const API_URL = import.meta.env.VITE_API_URL as string;

type Props = {
  sales_history: SalesHistory[];
  pharmacy_info: PharmacyInfo;
  users: UniqueUsersSalesSchema[];
};

export const Route = createFileRoute("/overview/sales_history/")({
  component: Component,
  validateSearch: SalesHistorySearchParams,
  loaderDeps: ({
    search: { page, pageSize, userID, startDate, endDate, type },
  }) => ({
    page,
    pageSize,
    userID,
    startDate,
    endDate,
    type,
  }),
  loader: async (params): Promise<Props> => {
    const userIdParam = params.deps.userID
      ? `&userId=${params.deps.userID}`
      : "";
    const startDateParam = params.deps.startDate
      ? dayjs.unix(params.deps.startDate).format("YYYY-MM-DD")
      : undefined;

    const endDateParam = params.deps.endDate
      ? dayjs.unix(params.deps.endDate).format("YYYY-MM-DD")
      : undefined;

    const response_promise = await httpClient.post(
      API_URL +
      `/public/pharmacy/sales/get/history?offset=${params.deps.page - 1}&limit=${params.deps.pageSize}&payment_type=${params.deps.type}${userIdParam}`,
      {
        pharmacy_code: getPharmacyID(),
        start_date: startDateParam,
        end_date: endDateParam,
      }
    );
    const unique_users_promise = await getUniqueUserSales();
    const response_pharmacy_info_promise = await httpClient.post(
      API_URL + "/public/pharmacy",
      {
        pharmacy_code: getPharmacyID(),
      }
    );

    const [response, unique_users, response_pharmacy_info] = await Promise.all([
      response_promise,
      unique_users_promise,
      response_pharmacy_info_promise,
    ]);

    const response_data = response.data.data as SalesHistory[];

    const response_pharmacy_info_data = response_pharmacy_info.data
      .data as PharmacyInfo;

    return {
      sales_history: response_data,
      pharmacy_info: response_pharmacy_info_data,
      users: unique_users,
    };
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
