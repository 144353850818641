import DetailsPath from "@/components/sale-info/DetailsPath";
import UserDetails from "@/components/sale-info/UserDetails";
import ItemDetails from "@/components/sale-info/ItemDetails";
import QuickActions from "@/components/sale-info/QuickActions";
import { useState } from "react";

const Component: React.FC = () => {
  const [partialMedicalItemsSelected, setPartialMedicalItemsSelected] = useState<string[]>([]);
  const [partialNonMedicalItemsSelected, setPartialNonMedicalItemsSelected] = useState<string[]>([]);
  return (
    <div className="overflow-hidden h-full">
      <DetailsPath />
      {/* <div className="grid grid-cols-8 gap-x-4 divide-x-[0.5px]">
        <UserDetails />        
      </div> */}
      <div className="flex h-full">
        <div className="flex-0.5">
          <UserDetails />
        </div>

        <div className="flex flex-col justify-between flex-auto">
          <ItemDetails partialMedicalItemsSelected={partialMedicalItemsSelected} partialNonMedicalItemsSelected={partialNonMedicalItemsSelected} setPartialMedicalItemsSelected={setPartialMedicalItemsSelected} setPartialNonMedicalItemsSelected={setPartialNonMedicalItemsSelected}/>
          <QuickActions partialMedicalItemsSelected={partialMedicalItemsSelected} partialNonMedicalItemsSelected={partialNonMedicalItemsSelected}/>
        </div>
      </div>
    </div>
  );
};

export default Component;
