import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table" 
import { MoveRightIcon, PlusCircleIcon } from "lucide-react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  MixerHorizontalIcon
} from "@radix-ui/react-icons"
import {Route as MedicalMasterRoute} from "@/routes/inventory/medical-master/index"
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { useState } from "react";

const Component = () => {
  const searchParams = MedicalMasterRoute.useSearch();
  const data = MedicalMasterRoute.useLoaderData();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState(searchParams.search);

  return (
    <>
      <div className="overflow-y-scroll h-screen pb-48">
        <div className="flex items-center justify-between">
          <Breadcrumb>
              <BreadcrumbList>
                  <BreadcrumbItem>
                      <BreadcrumbLink onClick={() => {
                        navigate({
                          to: '/inventory',
                        })
                      }} style={{
                        cursor: 'pointer'
                      }}>Inventory Overview</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                      <BreadcrumbPage>Medical Master</BreadcrumbPage>
                  </BreadcrumbItem>
              </BreadcrumbList>
          </Breadcrumb>
        </div>
        <hr className="my-4" />
        <div className="
          flex items-center justify-between gap-x-4
        ">
          <Input
            placeholder="Search for item"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate({
                  search: {
                    ...searchParams,
                    search: searchValue,
                    page: 1,
                    pageSize: 10
                  }
                })
              }
            }}
          />
          <Button
            onClick={() => {
              navigate({
                search: {
                  ...searchParams,
                  search: searchValue,
                  page: 1,
                  pageSize: 10
                }
              })
            }}
          >Search</Button>
          <Button
            onClick={() => {
              setSearchValue("");
              navigate({
                search: {
                  ...searchParams,
                  search: "",
                  page: 1,
                  pageSize: 10
                }
              })
            }}
          >Clear</Button>
        </div>

        <Table className='mt-4 text-sm'>
          <TableHeader>
                <TableRow>
                  <TableHead>Trade Name</TableHead>
                  <TableHead>Unit</TableHead>
                  <TableHead>Unit Type</TableHead>
                  <TableHead>Barcode</TableHead>
                  <TableHead>DDC Code</TableHead>
                  <TableHead>Manufacturer</TableHead>
                  <TableHead>Owner</TableHead>
                  <TableHead>Source</TableHead>
                  <TableHead>Scientific Name</TableHead>
                  <TableHead></TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.medical_master_items.map((item, index) => {
                    return (
                      <TableRow key={index}>
                          <TableCell>{item.trade_name}</TableCell>
                          <TableCell>{item.unit} ({item.unit})</TableCell>
                          <TableCell>{item.unit_type}</TableCell>
                          <TableCell>{item.barcode}</TableCell>
                          <TableCell>{item.metadata.ddc_code}</TableCell>
                          <TableCell>{item.metadata.manufacturer}</TableCell>
                          <TableCell>{item.metadata.owner}</TableCell>
                          <TableCell>{item.metadata.source}</TableCell>
                          <TableCell>{item.metadata.scientific_name}</TableCell>
                          <TableCell>
                              <Button variant='link' onClick={() => {
                                navigate({
                                  to: '/inventory/item/$type/$itemId',
                                  params: {
                                    type: "MEDICAL",
                                    itemId: item.id
                                  },
                                  search: searchParams,
                                })
                              }}>
                                  <MoveRightIcon className='w-4 h-4 mr-2' />
                                  View Details
                              </Button>
                          </TableCell>
                      </TableRow>
                  )
                })}
            </TableBody>
        </Table>

        <hr className="" />

        <div className='flex items-center justify-center w-full fixed bottom-4 bg-white pt-6'>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              Page {searchParams.page} of{" "} {data.count > 0 ? Math.ceil(data.count / searchParams.pageSize) : 1}
          </div>
          <div className="flex space-x-2">
              <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: 1
                      }
                    })
                  }}
                  disabled={searchParams.page === 1}
              >
                  <span className="sr-only">Go to first page</span>
                  <DoubleArrowLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page - 1
                      }
                    })
                  }}
                  disabled={searchParams.page === 1}
              >
                  <span className="sr-only">Go to previous page</span>
                  <ChevronLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="h-8 w-8 p-0"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: searchParams.page + 1
                      }
                    })
                  }}
                  disabled={data.count === 0 || data.count <= searchParams.page * searchParams.pageSize}
              >
                  <span className="sr-only">Go to next page</span>
                  <ChevronRightIcon className="h-4 w-4" />
              </Button>
              <Button
                  variant="outline"
                  className="hidden h-8 w-8 p-0 lg:flex"
                  onClick={() => {
                    navigate({
                      search: {
                        ...searchParams,
                        page: Math.ceil(data.count / searchParams.pageSize)
                      }
                    })
                  }}
                  disabled={data.count === 0 || data.count <= searchParams.page * searchParams.pageSize}
              >
                  <span className="sr-only">Go to last page</span>
                  <DoubleArrowRightIcon className="h-4 w-4" />
              </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
