import { Input } from "@/components/ui/input";
import React, { useEffect, useMemo, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import { CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import AddSupplier from "./addSupplier";
import { PurchaseInvoiceSchema } from "./data/schema";
import { AutoComplete, Option } from "@/components/ui/autocomplete";
import { Route as CreatePurchaseInvoiceRoute } from "@/routes/create-purchase-invoice";
import { toast } from "@/components/ui/use-toast";

//form controlled by parent component
const PurchaseInvoiceForm = ({ data, setData, supplierName, setSupplierName }: { data: Partial<PurchaseInvoiceSchema>, setData: (info: PurchaseInvoiceSchema) => void, supplierName: string, setSupplierName: React.Dispatch<React.SetStateAction<string>> }) => {
    const [supplier, setSupplier] = useState<string>("");
    const [supplierValue, setSupplierValue] = useState<Option | undefined>(undefined);


    const supplierData = CreatePurchaseInvoiceRoute.useLoaderData();
    const filteredSupplierData = useMemo(() => {
        return supplierData.filter(s => {
            if (data.payment_type === "INSTANT") {
                return true;
            }
            if (data.payment_type === "CREDIT") {
                return s.credit_terms.is_credit_allowed
            }
            return true;
        }).map((s) => {
            return {
                value: s.id,
                label: s.name,
                is_credit_allowed: s.credit_terms.is_credit_allowed.toString(),
            }
        })
    }, [data.payment_type])
    useEffect(() => {
        if (data.supplier_id) {
            const local_supplier = supplierData.find(s => s.id === data.supplier_id);
            if (local_supplier) {
                setSupplier(local_supplier.name);
                setSupplierName(local_supplier.name);
                setSupplierValue({
                    label: local_supplier.name,
                    value: data.supplier_id,
                    is_credit_allowed: local_supplier.credit_terms.is_credit_allowed.toString(),
                });
            }
        }
    }, [data.supplier_id, data.payment_type])

    return (
        <>
            <form
                className="w-full flex flex-col gap-4 mb-24"
            >
                <div className="grid grid-cols-2 gap-x-4 justify-items-stretch">
                    <div>
                        <p className="text-sm font-semibold mt-4 mb-2">
                            Invoice Date{" "}
                            <span className="text-red-400">*</span>
                        </p>
                        <div className="space-y-2">
                            <Popover>
                                <PopoverTrigger asChild>
                                    <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-full pl-3 text-left font-normal",
                                            !data.invoice_date && "text-muted-foreground"
                                        )}
                                    >
                                        {data.invoice_date ? (
                                            format(data.invoice_date, "PPP")
                                        ) : (
                                            <span>Invoice date</span>
                                        )}
                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="w-auto p-0"
                                    align="start"
                                >
                                    <Calendar
                                        mode="single"
                                        selected={data.invoice_date}
                                        onSelect={(date) => {
                                            setData({
                                                ...data,
                                                invoice_date: date
                                            })
                                        }}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>

                    </div>

                    <div>
                        <p className="text-sm font-semibold mt-4 mb-2">
                            Delivery Date{" "}
                            <span className="text-red-400">*</span>
                        </p>
                        <div className="space-y-2">
                            <Popover>
                                <PopoverTrigger asChild>

                                    <Button
                                        variant={"outline"}
                                        className={cn(
                                            "w-full pl-3 text-left font-normal",
                                            !data.delivery_date && "text-muted-foreground"
                                        )}
                                    >
                                        {data.delivery_date ? (
                                            format(data.delivery_date, "PPP")
                                        ) : (
                                            <span>Delivery date</span>
                                        )}
                                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                    </Button>

                                </PopoverTrigger>
                                <PopoverContent
                                    className="w-auto p-0"
                                    align="start"
                                >
                                    <Calendar
                                        mode="single"
                                        selected={data.delivery_date}
                                        onSelect={(date) => {
                                            setData({
                                                ...data,
                                                delivery_date: date
                                            })
                                        }}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>

                    </div>

                    <div>
                        <p className="text-sm font-semibold mt-4 mb-2">
                            Invoice Number
                            <span className="text-red-400">*</span>
                        </p>
                        <div className="space-y-2">
                            <Input
                                placeholder="12345678901234567890"
                                value={data.invoice_number}
                                onChange={(e) => {
                                    setData({
                                        ...data,
                                        invoice_number: e.target.value
                                    })

                                }}
                            />
                        </div>

                    </div>
                </div>

                <hr className="my-6" />

                <h2 className="text-xl font-bold tracking-tight">Payment Info</h2>

                <div className="grid grid-cols-2 space-x-4 justify-items-stretch">
                    <div>
                        <p className="text-sm font-semibold mt-4 mb-2">
                            Method of Payment{" "}
                            <span className="text-red-400">*</span>
                        </p>
                        <div className="space-y-2">
                            <Select
                                onValueChange={(value: "CASH" | "CHEQUE" | "CREDIT_CARD" | "BANK_TRANSFER") => {
                                    setData({
                                        ...data,
                                        payment_mode: value
                                    })
                                }}
                                value={data.payment_mode}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Method of Payment" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="CASH">Cash</SelectItem>
                                    <SelectItem value="CHEQUE">Cheque</SelectItem>
                                    <SelectItem value="CREDIT_CARD">Credit Card</SelectItem>
                                    <SelectItem value="BANK_TRANSFER">Bank Transfer</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                    <div>
                        <p className="text-sm font-semibold mt-4 mb-2">
                            Payment Type{" "}
                            {
                                supplierValue && supplierValue.is_credit_allowed == "false" &&
                                "(Credit not allowed for this supplier)"
                            }
                        </p>
                        <div className="space-y-2">
                            <Select
                                onValueChange={(value: "CREDIT" | "INSTANT") => {
                                    setData({
                                        ...data,
                                        payment_type: value
                                    })
                                }}
                                value={data.payment_type}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Payment Type" />
                                </SelectTrigger>
                                <SelectContent>
                                    {
                                        supplierValue ?
                                            (supplierValue.is_credit_allowed === "true" ?
                                                <>
                                                    <SelectItem value="CREDIT">Credit</SelectItem>
                                                    <SelectItem value="INSTANT">Instant</SelectItem>
                                                </>
                                                :
                                                <SelectItem value="INSTANT">Instant</SelectItem>
                                            )
                                            :
                                            <>
                                                <SelectItem value="CREDIT">Credit</SelectItem>
                                                <SelectItem value="INSTANT">Instant</SelectItem>
                                            </>
                                    }
                                </SelectContent>
                            </Select>
                        </div>

                    </div>
                </div>

                <hr className="my-6" />

                <h2 className="text-xl font-bold tracking-tight">Supplier Info</h2>

                <div className="grid grid-cols-2 space-x-4 justify-items-stretch">
                    <div>
                        <p className="text-sm font-semibold mt-2 mb-2">
                            Selected Supplier Name{": "}{supplierName}
                            {
                                data.payment_type === "CREDIT" &&
                                " (Only suppliers with credit terms are allowed)"
                            }
                            <span className="text-red-400">*</span>
                        </p>
                        <div className="border border-muted-input rounded-md">
                            <AutoComplete
                                options={filteredSupplierData}
                                placeholder="Search for supplier"
                                emptyMessage="No supplier found"
                                input={supplier}
                                onInputChanged={(value) => setSupplier(value)}
                                onValueChange={(value) => {
                                    setData({
                                        ...data,
                                        supplier_id: value.value
                                    })
                                }}
                                value={supplierValue}
                            />
                        </div>
                    </div>
                </div>

                <AddSupplier />
            </form>
        </>
    )
}

export default PurchaseInvoiceForm