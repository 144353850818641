import { z } from "zod"

export const numericString = z.object({
  value: z.number(),
  strValue: z.string(),
})

export function numericStringDefault(init?: number) {
  return init ? { value: init, strValue: init.toString() } : { value: 0, strValue: "" }
}

export type NumericString = z.infer<typeof numericString>

export const stringToNumericString = (value: string, setValue: (value: NumericString) => void, validator?: (value: string) => boolean) => {
  if (validator && !validator(value)) {
    return
  }
  if (value === "") {
    setValue({ value: 0, strValue: "" })
    return
  }
  const num = Number(value)
  if (isNaN(num)) {
    return
  }
  setValue({ value: num, strValue: value })

}

//convert a schema of a form to a schema with all number fields as numeric strings
//apply recursively for nested objects
export type NumericForm<T> = {
  [K in keyof T]: T[K] extends number ? NumericString : T[K] extends object ? NumericForm<T[K]> : T[K]
}

export function normalizeNumericForm<T extends Record<string, any>>(numericForm: NumericForm<T>) {
  let ret = {} as T;
  for (let key of Object.keys(numericForm) as (keyof T)[]) {
    //check for objects
    if (typeof numericForm[key] === "object") {
      const obj = numericForm[key] as any;
      const obj_keys = Object.keys(obj) as (keyof typeof obj)[]
      //check if object is numeric string
      if (obj_keys.length == 2 && obj_keys.includes("value") && obj_keys.includes("strValue")) {
        ret[key] = obj.value
      } else {
        //apply recursively if not numeric string
        ret[key] = normalizeNumericForm(obj)
      }
    } else {
      //guranteed to be primitive type
      //@ts-ignore
      ret[key] = numericForm[key]
    }
  }
  return ret;
}

