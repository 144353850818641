import { ChartColumnBig, ChevronUp, ChevronDown } from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer, LabelList } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import dayjs from "dayjs";
import { convertToCurrencyRounded } from "@/utils/currency_converter";

interface BarChartProps {
  title: string;
  value: string;
  trend: string;
  trendDirection: "up" | "down";
  data: Array<{ [key: string]: string | number }>;
  xAxisKey: string;
  dataKey: string;
  color: string;
  footer?: string;
  onClick?: () => void;
  active?: boolean;
  xAxisType?: "number" | "date" | "amount" | "string";
  yAxisType?: "number" | "date" | "amount" | "string";
}

export default function Component({
  title,
  value,
  trend,
  trendDirection,
  data,
  xAxisKey,
  dataKey,
  color,
  footer,
  onClick,
  active = true,
  xAxisType = "string",
  yAxisType = "number",
}: BarChartProps) {
  const chartConfig: ChartConfig = {
    [dataKey]: {
      label: dataKey,
      color: color,
    },
  };

  const formatValue = (value: any, type: "number" | "date" | "amount" | "string") => {
    if (type === "string") {
      return dayjs(value).format("MMM DD, YYYY");
    } else if (type === "amount") {
      return value === 0 ? "0" : convertToCurrencyRounded(value as number);
    } else if (type === "number") {
      return "AED " + value.toString();
    } else {
      return value.toString();
    }
  };

  return (
    <Tooltip>
      {!active && <TooltipContent>
        <p>Coming Soon</p>
      </TooltipContent>}
      <TooltipTrigger>
        <Card className={`bg-[#FCFCFC] ${active ? '' : 'opacity-25'}`} onClick={onClick} style={{
          cursor: onClick ? "pointer" : "default"
        }}>
          <div className="m-4 flex justify-between text-gray-600">
            <div className="flex">
              <ChartColumnBig className="w-4 h-4" />
              <p className="text-sm ml-1">{title}</p>
            </div>
          </div>
          <hr className="my-4" />
          <CardContent>
            <div className="flex gap-4 gap-x-12">
              <div className="bg-[#F4F4F580]/50 flex flex-col justify-between p-8 w-1/4 rounded-md">
                <div>
                  <CardTitle className="text-4xl text-left">{value}</CardTitle>
                  <CardDescription>
                    <div className="font-medium leading-none text-sm mt-1 flex items-center">
                      {trendDirection === "up" ? (
                        <ChevronUp className="w-4 h-4 mr-1 text-green-500" />
                      ) : (
                        <ChevronDown className="w-4 h-4 mr-1 text-red-500" />
                      )}
                      {trend}
                    </div>
                  </CardDescription>
                </div>
                {footer && (
                  <div className="leading-none text-xs text-left text-muted-foreground">{footer}</div>
                )}
              </div>
              <div className="w-3/4">
                <ResponsiveContainer width="100%" height={300}>
                  <ChartContainer config={chartConfig} className="h-full w-full">
                    <BarChart data={data} layout="horizontal">
                      <CartesianGrid vertical={false} />
                      <XAxis
                        dataKey={xAxisKey}
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        tickFormatter={(value) => formatValue(value, xAxisType)}
                        tick={{ dx: 0, dy: 10 }}
                      />
                      <YAxis
                        dataKey={dataKey}
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                        padding={{ top: 25 }}
                        tickFormatter={(value) => formatValue(value, yAxisType)}
                        tick={{ dx: -10 }}
                      />
                      <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent
                          className="text-left"
                          indicator="dot"
                          labelFormatter={(value) => formatValue(value, xAxisType)}
                          formatter={(value) => formatValue(value, yAxisType)}
                        />}
                      />
                      <Bar dataKey={dataKey} fill={color} radius={8}>
                        <LabelList
                          dataKey={dataKey}
                          position="top"
                          offset={12}
                          className="fill-foreground"
                          fontSize={12}
                          formatter={(value: any) => formatValue(value, yAxisType)}
                        />
                      </Bar>
                    </BarChart>
                  </ChartContainer>
                </ResponsiveContainer>
              </div>
            </div>
          </CardContent>
        </Card>
      </TooltipTrigger>
    </Tooltip>
  );
}
