import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Route as salesOneRoute } from "@/routes/overview/sales_history/$saleId";
import { Link, useNavigate } from "@tanstack/react-router";

const DetailsPath: React.FC = () => {
  const data = salesOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = salesOneRoute.useSearch();
  return (
    <div className="">
      <Breadcrumb>
        <BreadcrumbList className="">
          <BreadcrumbItem>
            <BreadcrumbLink
              asChild
            >
              <Link to="/overview" preload="intent">Overview</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              asChild
            >
              <Link to="/overview/sales_history" search={searchParams} preload="intent">Sales History</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{data.invoice_id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <hr className="my-4" />
    </div>
  );
};

export default DetailsPath;
