import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Timer, X } from 'lucide-react'
import { useState } from 'react'
import dayjs from 'dayjs'
import { endShift, endShiftSystem } from '@/screens/sales/api'
import { toast } from '../ui/use-toast'
import { useRouter } from '@tanstack/react-router'

type FormValuesSchema = {
    cash_drawer_end_amount: number | undefined,
    card_amount?: number,
    payouts?: number,
    end_time: string
}

const AutoEndShiftModal = ({
    value,
    setValue,
    endTime,
    shiftID,
}: {
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
    endTime: string,
    shiftID: string
}) => {

    const [formValues, setFormValues] = useState<FormValuesSchema>({
        cash_drawer_end_amount: undefined,
        card_amount: undefined,
        payouts: undefined,
        end_time: endTime
    })

    const [loading, setLoading] = useState(false)
    const router = useRouter()
    
    return (
        <Dialog open={value}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Shift Ended Automatically</DialogTitle>
                    <DialogDescription>
                        The system automatically ended your shift at {endTime}. Please enter the following details.
                    </DialogDescription>
                    <Button
                        variant={'ghost'} 
                        className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                        onClick={() => setValue(false)}
                    >
                        <X className="h-4 w-4" />
                        <span className="sr-only">Close</span>
                    </Button>
                </DialogHeader>
                <div className="grid gap-1 py-4">
                    <p className='text-sm font-semibold'>Cash Drawer Amount*</p>
                    <Input 
                        placeholder='AED 20.00'
                        value={formValues.cash_drawer_end_amount!}
                        type='number'
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                cash_drawer_end_amount: e.target.value ? parseFloat(e.target.value) : undefined
                            })
                        }}
                        disabled={loading}
                    />
                    <p className='text-sm font-semibold mt-4'>Card Amount (Optional)</p>
                    <Input 
                        placeholder='AED 20.00'
                        value={formValues.card_amount!}
                        type='number'
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                card_amount: e.target.value ? parseFloat(e.target.value) : undefined
                            })
                        }}
                        disabled={loading}
                    />
                    <p className='text-sm font-semibold mt-4'>Payouts (Optional)</p>
                    <Input 
                        placeholder='1'
                        value={formValues.payouts}
                        type='number'
                        onChange={(e) => {
                            setFormValues({
                                ...formValues,
                                payouts: e.target.value ? parseFloat(e.target.value) : undefined
                            })
                        }}
                        disabled={loading}
                    />
                </div>
                <DialogFooter>
                    <Button 
                        onClick={async () => {
                            try {
                                setLoading(true);
                                if (formValues.cash_drawer_end_amount === undefined) {
                                    toast({
                                        title: "Error",
                                        description: "Cash drawer amount is required",
                                        duration: 5000,
                                    })
                                    setLoading(false);
                                    return;
                                }
                                const res = await endShiftSystem(
                                    formValues.cash_drawer_end_amount, 
                                    formValues.card_amount || 0, 
                                    formValues.payouts || 0,
                                    shiftID
                                )
                                if (res) {
                                    toast({
                                        title: "Success",
                                        description: "Shift ended successfully",
                                        duration: 5000,
                                    });
                                    setLoading(false);
                                    router.invalidate()
                                    setValue(false)
                                }
                            } catch (error) {
                                console.error('Error:', error);
                                toast({
                                    title: "Error",
                                    description: "An error occurred",
                                    duration: 5000,
                                })
                                setLoading(false);
                            }
                        }}
                        disabled={loading}
                    >
                        <Timer size={20} className='mr-2' />
                        {loading ? "Please Wait..." : "Confirm shift update"}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AutoEndShiftModal