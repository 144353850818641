import { DatePickerWithRange } from "@/components/ui/date-picker";
import Overview from "./components/overview";
import Revenue from "./components/revenue";
import ViewReports from "./components/view-reports";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import dayjs from "dayjs";
import { Route as invoicingRoute } from "@/routes/invoicing/index";
import { useState } from "react";
import { InvoicingOverview } from "./components/data/schema";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
// import { invoicingRoute } from '.';
const API_URL = import.meta.env.VITE_API_URL as string;

const Component = () => {
  //   const patients = patientRoute.useLoaderData();
  const props = invoicingRoute.useLoaderData();
  const [initialOverviewData, setInitialOverviewData] =
    useState<InvoicingOverview>(props.overview);

  async function handleDateChange(date: string) {
    const response = await httpClient.post(
      API_URL + "/public/pharmacy/overview/invoicing",
      {
        pharmacy_code: getPharmacyID(),
        month: parseInt(date.split("_")[0]),
        year: parseInt(date.split("_")[1]),
      }
    );
    const response_current_month_transactions_data = await response.data;
    console.log(response_current_month_transactions_data);
    setInitialOverviewData(
      response_current_month_transactions_data.data as InvoicingOverview
    );
  }

  return (
    <>
      <div className="space-y-8 overflow-y-scroll h-screen pb-48">
        <ViewReports />

        <div className="flex items-center justify-between">
          <div className="flex gap-x-4">
            <h2 className="text-4xl font-bold tracking-tight">
              Reports Overview
            </h2>
            <Select
              onValueChange={(v) => {
                handleDateChange(v);
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="This Month" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={dayjs().format("MM_YYYY")} defaultChecked>
                  This Month
                </SelectItem>
                <SelectItem
                  value={dayjs().subtract(1, "month").format("MM_YYYY")}
                  defaultChecked
                >
                  Last Month
                </SelectItem>
                {[...Array(12).keys()].map((i) => {
                  const month = dayjs().subtract(i + 2, "month");
                  return (
                    <SelectItem
                      value={month.format("MM_YYYY")}
                      key={i.toString()}
                    >
                      {month.format("MMMM YYYY")}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>
          {/* <div className="flex flex-row ml-auto">
            <Link to="/overview/sales_history">
              <Button>View Sales History</Button>
            </Link>
          </div> */}
        </div>
        <Overview data={initialOverviewData} />
        <Revenue data={props.revenue} />
      </div>
    </>
  );
};

export default Component;
