import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/create-purchase-order/component";
import { Supplier } from "@/screens/suppliers/components/data/schema";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
export const Route = createFileRoute("/create-purchase-order/")({
    component: Component,
    loader: async (): Promise<Supplier[]> => {
        const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/supplier/get/list?offset=0&limit=100000`, {
            pharmacy_code: getPharmacyID()
        })
        return response.data.data as Supplier[];
    }
})