import currency from "currency.js";

function convertToCurrencyRounded(amount: number, allowNegativeNumbers: boolean = true): string {
  return currency(amount, {
    symbol: "AED ",
    precision: 2,
    increment: 0.25,
    negativePattern: allowNegativeNumbers ? "-!#" : "!#",
  }).format();
}
function getItemCost(item: {
  price: number,
  quantity: number,
  discount: number
  vat: number,
  discount_type: string
}) {
  const {discount,discount_type,price,quantity,vat} = item;
  if(quantity <= 0) return 0;
  let cost = price * quantity;
  cost = cost - getDiscount({
    price: price,
    quantity: quantity,
    discount_amt: discount,
    discount_type: discount_type
  });
  cost = cost + (cost * vat / 100);
  return cost;
}
function getDiscount(item: {
  price: number,
  quantity: number,
  discount_amt: number,
  discount_type: string
}) {
  const { discount_amt, discount_type, price, quantity } = item;
  if (discount_amt == 0) {
    return 0
  }
  if (discount_type == "PERCENTAGE") {
    return (price * quantity) * (discount_amt / 100);
  }
  return discount_amt * quantity;
}


export { convertToCurrencyRounded, getItemCost,getDiscount };