import {
  Outlet,
  ScrollRestoration,
  useRouterState,
} from "@tanstack/react-router";
import Header from "./header";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getOidc } from "@/oidc";
import { redirect } from "@tanstack/react-router";
import GlobalCommandMenu from "@/components/command-modal/globalCommandModal";
import { Input } from "@/components/ui/input";
import db from "@/db";


//only avialable when VITE_ENV is "DEV" and ctrl + shift + Q is pressed
const Repl = () => {
  const [q, setQ] = useState<string>("")
  const [history, setHistory] = useState<string[]>([])
  const [histIdx, setHistIdx] = useState<number>()
  return <form onSubmit={(e) => {
    e.preventDefault()
    console.log(q)
    //@ts-ignore
    setHistory([q, ...history])
    setQ("")
  }}>
    <Input value={q}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp") {
          if (histIdx === undefined) {
            setHistIdx(0)
            setQ(history[0])
          }
          else if (histIdx < history.length - 1) {
            setHistIdx(histIdx + 1)
            setQ(history[histIdx + 1])
          }
        }
        if (e.key === "ArrowDown") {

          if (histIdx && histIdx > 0) {
            setHistIdx(histIdx - 1)
            setQ(history[histIdx - 1])
          }
          else if (histIdx === 0) {
            setHistIdx(undefined)
            setQ("")
          }
        }
      }}

      onChange={(e) => {
        setQ(e.target.value)
      }}></Input>
  </form>
}

const PrintContext = createContext<PrintContext | null>(null)

export function usePrint() {
  const ctx = useContext(PrintContext)
  if (!ctx) {
    throw new Error("usePrint must be used within a PrintProvider")
  }
  return ctx
}

type PrintContext = {
  printMode: boolean,
  setPrintMode: (v: boolean) => void
}

export default function Component() {
  const router = useRouterState();
  const [showRepl, setShowRepl] = useState(false)
  const [printMode, setPrintMode] = useState(false)
  const printContextValue = useMemo(
    () => ({
      printMode,
      setPrintMode
    })
    , [printMode, setPrintMode])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === "Q") {
        setShowRepl((v) => !v)
      }
    }
    window.addEventListener("keydown", handleKeyDown)

    const initialLoad = async () => {
      try {
        const oidc = await getOidc();
        if (!oidc.isUserLoggedIn) {
          await oidc.login({
            doesCurrentHrefRequiresAuth: true,
          });
        } else {
          const pharmacy_id_list = oidc.getTokens().decodedIdToken.pharmacy_id;
          localStorage.setItem(
            "pharmacy_id_list",
            JSON.stringify(pharmacy_id_list)
          );
          localStorage.setItem("token", oidc.getTokens().accessToken);
          if (pharmacy_id_list.length === 1) {
            localStorage.setItem("pharmacy_id", pharmacy_id_list[0]);
            if (router.location.pathname === "/") {
              throw redirect({ to: "/overview" });
            }
          } else {
            throw redirect({ to: "/" });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    initialLoad();
    async function initialCreation() {
      console.log("Creating tables");
      await db.transaction("rw", db.active_sales, db.add_stock_instance, db.create_pi_instance, async (s) => {
        await s.active_sales.put({ id: 1, created_at: new Date(), selected: true })
      })
    }
    initialCreation();
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, []);
  return (
    <>
      <PrintContext.Provider value={printContextValue}>
        {/* <Header /> */}
        <main className={`${printMode ? "" : "max-w-screen h-screen max-h-screen overflow-hidden"}`}>
          <div className={`flex flex-col w-full h-full`}>
            {
              showRepl && import.meta.env.VITE_ENV === "DEV" && <Repl />
            }
            {
              printMode || <Header />
            }
            <div className={`px-4 mb-4 h-full relative ${printMode ? "" : "overflow-hidden"}`}>
              <GlobalCommandMenu />
              <Outlet />
            </div>
          </div>
        </main>
        <ScrollRestoration />
      </PrintContext.Provider>
    </>
  );
}
