import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "@/screens/reports/customer-wise-revenue/model";
import { getCustomerWiseRevenue } from "@/screens/reports/customer-wise-revenue/api";
import { CustomerWiseRevenue } from "@/screens/reports/customer-wise-revenue/type";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/customer-wise-revenue/component";

export const Route = createFileRoute("/reports/customer-wise-revenue/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize } }) => ({
    page,
    pageSize
  }),
  loader: async (params) => {
    const { page, pageSize } = params.deps;
    try {
      const offset = page - 1;
      const limit = pageSize;
      return getCustomerWiseRevenue(offset, limit);
    } catch (error) {
      console.error("Error fetching Supplier Profitability Report:", error);
      throw new Error("Failed to fetch Supplier Profitability Report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
