// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute, redirect } from "@tanstack/react-router";
import Component from "@/screens/home/component";
import getPharmacyID from "@/utils/pharmacy_code";
import { Loading } from "@/layouts/loading";

export const Route = createFileRoute("/")({
    component: Component,
    beforeLoad: () => {
      if (getPharmacyID() !== "") {
        redirect({
          to: "/overview",
          replace: true,
        });
      }
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});