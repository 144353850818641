// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/overview/sales-history/sale-info/component";
import { SalesHistorySearchParams } from "@/screens/overview/components/data/schema";
import { SaleOne } from "@/screens/receipt/model";
import { httpClient } from "@/httpClient";
import { Loading } from "@/layouts/loading";

const API_URL = import.meta.env.VITE_API_URL as string;

export const Route = createFileRoute("/overview/sales_history/$saleId")({
  component: Component,
    validateSearch: SalesHistorySearchParams,
    loader: async ({params}): Promise<SaleOne> => {
        if (!params.saleId) {
            throw new Error("Sale ID is required");
        }
        const response = await httpClient.post(API_URL + '/public/pharmacy/sales/get/one', {
            sale_id: params.saleId,
        });

        const data = await response.data;

        if (!data.status) {
            throw new Error("API Fetch Error");
        }

        return data.data as SaleOne;
    },
    pendingComponent: () => <Loading />,
    pendingMinMs: 1000,
    pendingMs: 1
});
