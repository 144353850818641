import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Chart from "./revenue-chart";
import { InvoicingRevevue } from "./data/schema";
import dayjs from "dayjs";
import { useState } from "react";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { convertToCurrencyRounded } from "@/utils/currency_converter";

const API_URL = import.meta.env.VITE_API_URL as string;

const Revenue = ({ data }: { data: InvoicingRevevue }) => {
  const [initialRevenueData, setInitialRevenueData] =
    useState<InvoicingRevevue>(data);

  async function handleDateChange(date: string) {
    const response = await httpClient.post(
      API_URL + "/public/pharmacy/overview/revenue",
      {
        pharmacy_code: getPharmacyID(),
        year: parseInt(date),
      }
    );
    const response_revenue_data = await response.data;
    setInitialRevenueData(response_revenue_data.data as InvoicingRevevue);
  }

  const grossVolumePercentage =
    ((initialRevenueData.request_year_sales.gross_sales -
      initialRevenueData.last_year_sales.gross_sales) /
      initialRevenueData.last_year_sales.gross_sales) *
    100;

  const netVolumePercentage =
    ((initialRevenueData.request_year_sales.net_sales -
      initialRevenueData.last_year_sales.net_sales) /
      initialRevenueData.last_year_sales.net_sales) *
    100;

  const cardTransactionPercentage =
    ((initialRevenueData.request_year_sales.card_sales -
      initialRevenueData.last_year_sales.card_sales) /
      initialRevenueData.last_year_sales.card_sales) *
    100;

  const cashSalesPercentage =
    ((initialRevenueData.request_year_sales.cash_sales -
      initialRevenueData.last_year_sales.cash_sales) /
      initialRevenueData.last_year_sales.cash_sales) *
    100;

  const insuranceSalesPercentage =
    ((initialRevenueData.request_year_sales.insurance_sales -
      initialRevenueData.last_year_sales.insurance_sales) /
      initialRevenueData.last_year_sales.insurance_sales) *
    100;

  return (
    <div>
      <p className="text-gray-700 text-3xl font-semibold">Revenue</p>
      <div className="flex items-center justify-between mt-6">
        <div className="grid grid-cols-2 gap-x-8">
          <div className="border-r-[0.5px]">
            <div className="flex items-center space-x-2">
              <div className="bg-[#5469D4] min-h-4 min-w-4 rounded-sm" />
              <p>Gross Volume</p>
            </div>
            <div className="flex items-center space-x-4 text-2xl">
              <p className="font-semibold">
                {convertToCurrencyRounded(
                  initialRevenueData.request_year_sales.gross_sales
                )}
              </p>
              {initialRevenueData.request_year_sales.gross_sales ===
              initialRevenueData.last_year_sales.gross_sales ? (
                <p className="text-sm">No Change from last year</p>
              ) : initialRevenueData.last_year_sales.gross_sales !== 0 ? (
                initialRevenueData.request_year_sales.gross_sales >
                initialRevenueData.last_year_sales.gross_sales ? (
                  <p className="text-green-500 text-sm">
                    {grossVolumePercentage > 1000
                      ? "1000%+ from last year"
                      : `+${grossVolumePercentage.toFixed(2)}% from last year`}
                  </p>
                ) : (
                  <p className="text-red-500 text-sm">
                    -{grossVolumePercentage.toFixed(2)}% from last year
                  </p>
                )
              ) : (
                <p className="text-green-500 text-sm">
                  +AED{" "}
                  {initialRevenueData.request_year_sales.gross_sales.toFixed(2)}
                  from last year
                </p>
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <div className="bg-[#E4E4E7]  min-h-4 min-w-4 rounded-sm" />
              <p>Net Volume</p>
            </div>
            <div className="flex items-center space-x-4 text-2xl">
              <p className="font-semibold">
                {convertToCurrencyRounded(
                  initialRevenueData.request_year_sales.net_sales
                )}
              </p>
              {initialRevenueData.request_year_sales.net_sales ===
              initialRevenueData.last_year_sales.net_sales ? (
                <p className="text-sm">No Change from last year</p>
              ) : initialRevenueData.last_year_sales.net_sales !== 0 ? (
                initialRevenueData.request_year_sales.net_sales >
                initialRevenueData.last_year_sales.net_sales ? (
                  <p className="text-green-500 text-sm">
                    {netVolumePercentage > 1000
                      ? "1000%+ from last year"
                      : `+${netVolumePercentage.toFixed(2)}% from last year`}
                  </p>
                ) : (
                  <p className="text-red-500 text-sm">
                    - {netVolumePercentage.toFixed(2)}% from last year
                  </p>
                )
              ) : (
                <p className="text-green-500 text-sm">
                  +AED{" "}
                  {initialRevenueData.request_year_sales.net_sales.toFixed(2) +
                    " "}
                  from last year
                </p>
              )}
            </div>
          </div>
        </div>
        <Select
          onValueChange={(e) => {
            handleDateChange(e);
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue
              placeholder={`This Year (${dayjs().format("YYYY")})`}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={dayjs().format("YYYY")}>
              This Year ({dayjs().format("YYYY")})
            </SelectItem>
            <SelectItem value={dayjs().subtract(1, "year").format("YYYY")}>
              Last Year ({dayjs().subtract(1, "year").format("YYYY")})
            </SelectItem>
            {[...Array(5).keys()].map((i) => {
              const year = dayjs()
                .subtract(i + 2, "year")
                .format("YYYY");
              return (
                <SelectItem value={year} key={i.toString()}>
                  {year}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>
      </div>

      <div className="grid grid-cols-7">
        <div className="col-span-5 border-t-[1px] border-b-[1px] mx-2 my-1">
          <Chart data={initialRevenueData.revenue_by_month} />
        </div>
        <div className="col-span-2 border p-4 rounded-lg flex flex-col justify-between">
          <div>
            <p className="text-sm">Card Transactions</p>
            <div className="flex items-center space-x-4 text-2xl">
              <p className="font-semibold">
                {convertToCurrencyRounded(
                  initialRevenueData.request_year_sales.card_sales
                )}
              </p>
              {initialRevenueData.request_year_sales.card_sales ===
              initialRevenueData.last_year_sales.card_sales ? (
                <p className="text-sm">No Change from last year</p>
              ) : initialRevenueData.last_year_sales.card_sales !== 0 ? (
                initialRevenueData.request_year_sales.card_sales >
                initialRevenueData.last_year_sales.card_sales ? (
                  <p className="text-green-500 text-sm">
                    {cardTransactionPercentage > 1000
                      ? "1000%+ from last year"
                      : `+${cardTransactionPercentage.toFixed(2)}% from last year`}
                  </p>
                ) : (
                  <p className="text-red-500 text-sm">
                    -{cardTransactionPercentage.toFixed(2)}% from last year
                  </p>
                )
              ) : (
                <p className="text-green-500 text-sm">
                  +AED{" "}
                  {initialRevenueData.request_year_sales.card_sales.toFixed(2) +
                    " "}
                  from last year
                </p>
              )}
            </div>
          </div>
          <div>
            <p className="text-sm">Cash Transactions</p>
            <div className="flex items-center space-x-4 text-2xl">
              <p className="font-semibold">
                {convertToCurrencyRounded(
                  initialRevenueData.request_year_sales.cash_sales
                )}
              </p>
              {initialRevenueData.request_year_sales.cash_sales ===
              initialRevenueData.last_year_sales.cash_sales ? (
                <p className="text-sm">No Change from last year</p>
              ) : initialRevenueData.last_year_sales.cash_sales !== 0 ? (
                initialRevenueData.request_year_sales.cash_sales >
                initialRevenueData.last_year_sales.cash_sales ? (
                  <p className="text-green-500 text-sm">
                    {cashSalesPercentage > 1000
                      ? "1000%+ from last year"
                      : `+${cashSalesPercentage.toFixed(2)}% from last year`}
                  </p>
                ) : (
                  <p className="text-red-500 text-sm">
                    -{cashSalesPercentage.toFixed(2)}% from last year
                  </p>
                )
              ) : (
                <p className="text-green-500 text-sm">
                  +AED{" "}
                  {initialRevenueData.request_year_sales.cash_sales.toFixed(2) +
                    " "}
                  % from last year
                </p>
              )}
            </div>
          </div>
          <div>
            <p className="text-sm">Insurance Transactions</p>
            <div className="flex items-center space-x-4 text-2xl">
              <p className="font-semibold">
                {convertToCurrencyRounded(
                  initialRevenueData.request_year_sales.insurance_sales
                )}
              </p>
              {initialRevenueData.request_year_sales.insurance_sales ===
              initialRevenueData.last_year_sales.insurance_sales ? (
                <p className="text-sm">No Change from last year</p>
              ) : initialRevenueData.last_year_sales.insurance_sales !== 0 ? (
                initialRevenueData.request_year_sales.insurance_sales >
                initialRevenueData.last_year_sales.insurance_sales ? (
                  <p className="text-green-500 text-sm">
                    {insuranceSalesPercentage > 1000
                      ? "1000%+ from last year"
                      : `+${insuranceSalesPercentage.toFixed(2)}% from last year`}
                  </p>
                ) : (
                  <p className="text-red-500 text-sm">
                    -{insuranceSalesPercentage.toFixed(2)}% from last year
                  </p>
                )
              ) : (
                <p className="text-green-500 text-sm">
                  + AED{" "}
                  {initialRevenueData.request_year_sales.insurance_sales.toFixed(
                    2
                  ) + " "}
                  from last year
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
