import { createFileRoute } from "@tanstack/react-router";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/shift-history/shift-summary/component";
import { httpClient } from "@/httpClient";
import { ShiftSummarySchema } from "@/screens/reports/shift-history/shift-summary/type";

const API_URL = import.meta.env.VITE_API_URL as string;

export const Route = createFileRoute(
  "/reports/shift-history/shift-summary/$shiftId"
)({
  component: Component,
  loader: async ({ params }): Promise<ShiftSummarySchema> => {
    try {
      const getShiftSummaryReportData = await httpClient.post(
        API_URL + "/public/pharmacy/shifts/get/summary",
        {
          shift_id: params.shiftId,
        }
      );
      return getShiftSummaryReportData.data.data;
    } catch (error) {
      console.error("Error fetching shift summary report:", error);
      throw new Error("Failed to fetch shift summary report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
