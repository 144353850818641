import React, { useEffect, useState } from 'react'
import { AutoComplete } from '@/components/ui/autocomplete'
import { Button } from '@/components/ui/button'
import { CirclePlus, Minus, User } from 'lucide-react'
import { useDebounce } from '@uidotdev/usehooks'
import { Option } from '@/components/ui/autocomplete'
import getPharmacyID from '@/utils/pharmacy_code'
import { httpClient } from '@/httpClient'
import { Patient, PatientMinimal } from '@/screens/patients/components/data/schema'
import { toast } from '@/components/ui/use-toast'

const PatientInfo = ({
    head,
    value
}: {
    head: string,
    value: string
}) => {
    return (
        <div>
            <p className='font-light text-sm'>{head}</p>
            <p className='font-semibold text-sm'>{value}</p>
        </div>
    )
}

const PatientInfoInput = ({
    head,
    value,
    onChange
}: {
    head: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
    return (
        <div>
            <p className='text-sm font-semibold mt-2'>{head}<span className='text-red-400'>*</span></p>
            <input
                type='text'
                className='border-[0.5px] rounded-lg p-2 text-sm w-full'
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

enum PatientState {
    Closed = 'closed',
    Open = 'open',
    Added = 'added',
}

const API_URL = import.meta.env.VITE_API_URL as string

const AttachPatient = ({patientInfo, setPatientInfo}: {patientInfo: PatientMinimal | undefined, setPatientInfo: React.Dispatch<React.SetStateAction<PatientMinimal | undefined>>}) => {
    const [patientName, setPatientName] = useState<string>('')
    const [patientPhone, setPatientPhone] = useState<string>('')
    const [patientEmiratesID, setPatientEmiratesID] = useState<string>('')
    const [createPatientState, setCreatePatientState] = useState<PatientState>(PatientState.Closed)

    const [searchPatient, setSearchPatient] = useState<string>('')
    const [patientOptions, setPatientOptions] = useState<Option[]>([])
    const [selectedPatient, setSelectedPatient] = useState<Option>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        async function getPatient() {
            const response = await httpClient.post(API_URL + `/public/pharmacy/patients/search?offset=0&limit=20`, {
                pharmacy_code: getPharmacyID(),
                search: searchPatient
              });
          
              const data = await response.data;
          
              if (!data.status) {
                throw new Error("API Fetch Error");
              }
          
              const f = data.data as Patient[];
                const options = f.map((p) => {
                    return {
                        label: p.name + ' | ' + p.phone + ' | ' + p.emirates_id,
                        value: p.id
                    }
                })
                setPatientOptions(options)
        }
        if (searchPatient !== "") {
            getPatient()
        }
    }, [searchPatient])

    useEffect(() => {
        if (selectedPatient) {
            setPatientEmiratesID(selectedPatient.label.split(' | ')[2])
            setPatientName(selectedPatient.label.split(' | ')[0])
            setPatientPhone(selectedPatient.label.split(' | ')[1])
            setCreatePatientState(PatientState.Added)
            setPatientInfo({
                id: selectedPatient.value,
                name: selectedPatient.label.split(' | ')[0],
                phone: selectedPatient.label.split(' | ')[1],
                emirates_id: selectedPatient.label.split(' | ')[2]
            })
        }
    }, [selectedPatient])
    

    return (
        <div className='px-4 min-h-[300px] w-[97%] grid place-items-center'>
            <div className="p-4 bg-gray-100 h-full rounded-lg w-full">
                <div className='flex items-center justify-between'>
                    <div>
                        <p className="font-semibold">Attach a patient <span className='font-normal'>(Optional)</span></p>
                        <p className="text-gray-500">
                            Select a patient or create one
                        </p>
                    </div>                    
                    {
                        (createPatientState === PatientState.Added) && (
                            <Button
                                variant="outline"
                                className='bg-white text-black'
                                onClick={() => {
                                    setCreatePatientState(PatientState.Closed)
                                    setPatientName('')
                                    setPatientPhone('')
                                    setPatientEmiratesID('')
                                    setSelectedPatient(undefined)
                                    setPatientInfo(undefined)
                                }}
                            >
                                Remove Patient
                            </Button>
                        )
                    }
                    {
                        (createPatientState === PatientState.Open) && (
                            <Button
                                variant="outline"
                                className='bg-white text-black'
                                onClick={() => setCreatePatientState(PatientState.Closed)}
                            >
                                <Minus className='w-4 h-4 mr-2' />
                                Cancel
                            </Button>
                        )
                    }
                </div>
                <hr className='my-2' />
                {
                    (createPatientState === PatientState.Closed) && (
                        <AutoComplete 
                            options={patientOptions}
                            emptyMessage="No results."
                            placeholder="Search..."
                            onValueChange={setSelectedPatient}
                            value={selectedPatient}
                            input={searchPatient}
                            onInputChanged={setSearchPatient}
                        />
                    )                    
                }

               {
                    (createPatientState === PatientState.Added) && (
                        <div className='flex flex-col space-y-2 p-2 rounded-lg border-[0.5px] bg-white'>
                        <PatientInfo head='Name' value={patientName} />
                        <PatientInfo head='Phone Number' value={patientPhone} />
                        <PatientInfo head='Emirates ID' value={patientEmiratesID} />
                        </div>
                    )
               }

                {
                    (createPatientState === PatientState.Open) && (
                        <div className='flex flex-col space-y-2 p-2 rounded-lg border-[0.5px] bg-white'>
                            <PatientInfoInput head='Name' value={patientName} onChange={(e) => setPatientName(e.target.value)} />
                            <PatientInfoInput head='Phone Number' value={patientPhone} onChange={(e) => setPatientPhone(e.target.value)} />
                            <PatientInfoInput head='Emirates ID' value={patientEmiratesID} onChange={(e) => setPatientEmiratesID(e.target.value)} />
                            <Button
                                variant="outline"
                                className='text-blue-700 border-[0.5px] border-blue-700 bg-white'
                                onClick={async () => {
                                    try {
                                        if (!patientName || !patientPhone || !patientEmiratesID) {
                                            toast({
                                                title: "Error",
                                                description: "Please fill all the fields",
                                                duration: 5000,
                                            });
                                            setLoading(false);
                                            return;
                                        }
                                        const response = await httpClient.post(
                                            `${import.meta.env.VITE_API_URL as string}/public/pharmacy/patients/create`,
                                            {
                                                name: patientName,
                                                phone: patientPhone,
                                                emirates_id: patientEmiratesID,
                                                pharmacy_code: getPharmacyID(),
                                            }
                                        );
                            
                            
                                        toast({
                                            title: "New Patient Added",
                                            description: `Patient ${patientName} has been added successfully`,
                                            duration: 5000,
                                        });
                                        setCreatePatientState(PatientState.Added)
                                        setPatientInfo({
                                            id: response.data.data as string,
                                            name: patientName,
                                            phone: patientPhone,
                                            emirates_id: patientEmiratesID
                                        })
                                    } catch (error) {
                                        console.error('Error:', error);
                                        toast({
                                            title: "Error",
                                            description: "Failed to add patient. Please try again later.",
                                            duration: 5000,
                                        });
                                    }
                                }}
                            >
                                <User className='w-4 h-4 mr-2' />
                                Create Patient
                            </Button>
                        </div>
                    )
                }

                {
                    (createPatientState === PatientState.Closed) && (
                        <Button
                            variant="outline"
                            className='w-full bg-white text-black text-sm my-24'
                            onClick={() => setCreatePatientState(PatientState.Open)}
                        >
                            <CirclePlus className='w-4 h-4 mr-2' />
                            Create New Patient
                        </Button>
                    )
                }
            </div>
        </div>
    )
}

export default AttachPatient