import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { SalesSummarySchema } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getSalesReport = async (

  date ?: string
): Promise<SalesSummarySchema> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/sales_summary`,
      {
        date,
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data; 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
