import { Button } from "@/components/ui/button"
import { useOidc } from "@/oidc"
import getPharmacyID from "@/utils/pharmacy_code"
import Cookie from "js-cookie"
import React, { useEffect } from "react"


const Component = () => {
  const [data, setData] = React.useState<string[]>([]);
  const [count, setCount] = React.useState(0);
  const oidc = useOidc({assertUserLoggedIn: true});

  return (
    <div className="grid place-items-center">
      <div className="border-[0.5px] border-gray-200 rounded-md text-center px-24 py-12">
        <p className="font-semibold text-xl">Hi, {oidc.oidcTokens.decodedIdToken.preferred_username}!</p>
        <p className="text-sm text-gray-600">Select your branch to proceed</p>
        <hr className="my-6" />

            <>
              <p className="text-sm text-gray-600 mb-4">Select from the options below</p>
              <div className="flex flex-col space-y-2">
                {oidc.oidcTokens.decodedIdToken.pharmacy_id.map((pharmacy_id) => (
                    <Button
                      key={pharmacy_id}
                      variant={'outline'}
                      size={'sm'}
                      onClick={() => {
                        localStorage.setItem('pharmacy_id', pharmacy_id);
                        window.location.href = '/overview';
                      }}
                    >{pharmacy_id?.slice(1)}</Button>
                  )
                )}
              </div>
            </>
      </div>
      {/* <h1>Select Pharmacy</h1>
      <Button onClick={() => {
        setCount(count + 1);
      }}>Get Pharmacies</Button>
      <Button
        onClick={() => {
          kc.logout()
        }}
      >Logout</Button>
      <br />
      {data.map((pharmacy_id) => (
        <Button
          onClick={() => {
            Cookie.set('pharmacy_id', pharmacy_id);
            router.navigate({
              to: '/overview',
              replace: true,
            })
          }}
        >{pharmacy_id}</Button>
      )
      )} */}
    </div>
  )
}

export default Component