import { ChartColumnBig } from "lucide-react";
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import dayjs from "dayjs";

interface BarChartProps {
  title: string;
  value: string;
  trend: string;
  data: Array<{ [key: string]: string | number }>;
  xAxisKey: string;
  dataKey: string;
  color: string;
  footer?: string;
  active?: boolean;
  onClick?: () => void;
}

const formatValue = (value: any, type: "number" | "date" | "amount" | "string") => {
  if (type === "string") {
    return dayjs(value).format("MMM DD, YYYY");
  } else if (type === "amount") {
    return value === 0 ? "0" : convertToCurrencyRounded(value as number);
  } else if (type === "number") {
    return "AED " + value.toString();
  } else {
    return value.toString();
  }
};

export default function Component({
  title,
  value,
  trend,
  data,
  xAxisKey,
  dataKey,
  color,
  footer,
  active,
  onClick
}: BarChartProps) {
  const chartConfig: ChartConfig = {
    [dataKey]: {
      label: dataKey,
      color: color,
    },
  };

  return (
    <Tooltip>
      {!active && <TooltipContent>
        <p>Coming Soon</p>
      </TooltipContent>}
      <TooltipTrigger>
        <Card className={`bg-[#FCFCFC] ${active ? '' : 'opacity-25'}`}
          onClick={() => {
            onClick && onClick()
          }}
        >
          <div className="m-4 flex items-center text-gray-600">
            <ChartColumnBig className="w-4 h-4" />
            <p className="text-sm ml-1">{title}</p>
          </div>
          <hr className="my-4" />
          <CardHeader>
            <CardTitle>{value}</CardTitle>
            <CardDescription>
              <div className="font-medium leading-none">{trend}</div>
            </CardDescription>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={400}>
              <ChartContainer config={chartConfig} className="h-full w-full">
                <BarChart accessibilityLayer data={data}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey={xAxisKey}
                    tickLine={false}
                    tickMargin={10}
                    axisLine={false}
                    tickFormatter={(value) => String(value).slice(0, 3)}
                  />
                  <YAxis
                    dataKey={dataKey}
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    padding={{ top: 25 }}
                    tickFormatter={(value) => formatValue(value, "number")}
                    tick={{ dx: -10 }}
                  />
                  <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                  />
                  <Bar dataKey={dataKey} fill={color} radius={8} >
                    <LabelList
                      dataKey={dataKey}
                      position="top"
                      offset={12}
                      className="fill-foreground"
                      fontSize={12}
                      formatter={(value: any) => formatValue(value, "number")}
                    />
                  </Bar>
                </BarChart>
              </ChartContainer>
            </ResponsiveContainer>
          </CardContent>
          {footer && (
            <CardFooter className="flex-col items-start gap-2 text-sm">
              <div className="leading-none text-muted-foreground">{footer}</div>
            </CardFooter>
          )}
        </Card>
      </TooltipTrigger>
    </Tooltip>
  );
}
