const API_URL = import.meta.env.VITE_API_URL as string

// import { API_URL, API_URL_SUFFIX } from "@/constants/api";
// import { extractProps } from "@/utils/api";
import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/patients/component";
import getPharmacyID from "@/utils/pharmacy_code";
import { Patient, PatientSearchParams } from "@/screens/patients/components/data/schema";
import { httpClient } from "@/httpClient";
import { Loading } from "@/layouts/loading";

export const Route = createFileRoute("/patients/")({
  component: Component,
  loaderDeps: ({ search: { page, pageSize, search } }) => ({ page, pageSize, search }),
  loader: async (params): Promise<Patient[]> => {
    if (!params.deps.search) {
      const response = await httpClient.post(API_URL + `/public/pharmacy/patients/get/list?offset=${params.deps.page-1}&limit=${params.deps.pageSize}`, {
        pharmacy_code: getPharmacyID(),     
      });
  
      const data = await response.data;
  
      if (!data.status) {
        throw new Error("API Fetch Error");
      }
  
      return data.data as Patient[];
    } else {
      const response = await httpClient.post(API_URL + `/public/pharmacy/patients/search?offset=${params.deps.page-1}&limit=${params.deps.pageSize}`, {
        pharmacy_code: getPharmacyID(),
        search: params.deps.search  
      });
  
      const data = await response.data;
  
      if (!data.status) {
        throw new Error("API Fetch Error");
      }
  
      return data.data as Patient[];
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
  validateSearch: PatientSearchParams
});