import dayjs from "dayjs";
import { SetStateAction, useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { CSVLink } from "react-csv";
import { Route as ReportAvailableRoute } from "@/routes/reports/expiry-list/index";
import {
  CaretSortIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import { convertToCurrencyRounded } from "@/utils/currency_converter";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const parseDate = (
  date:
    | string
    | number
    | Date
    | dayjs.Dayjs
    | { Time: string; Valid: boolean }
    | null
    | undefined
) => {
  if (typeof date === "string") {
    return dayjs(date, "YYYY-MM-DD"); // Adjust format as necessary
  }
  return dayjs(); // Default to current date if invalid
};

const Component = () => {
  const searchParams = ReportAvailableRoute.useSearch();
  const data = ReportAvailableRoute.useLoaderData();
  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("expiry_date");

  const handleRequestSort = (property: SetStateAction<string>) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting data
  const sortedData = data.data.slice().sort((a, b) => {
    if (orderBy === "expiry_date") {
      const dateA = parseDate(a.expiry_date).valueOf();
      const dateB = parseDate(b.expiry_date).valueOf();
      return order === "asc" ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  const headers = [
    { label: "Trade Name", key: "trade_name" },
    { label: "Unit Type", key: "unit_type" },
    { label: "Barcode", key: "barcode" },
    { label: "Price", key: "price" },
    { label: "Cost", key: "cost" },
    { label: "Available Stock (Unit per stock)", key: "available_stock" },
    { label: "Batch Number", key: "batch_number" },
    { label: "Expiry Date", key: "expiry_date" },
    { label: "Last Sold On", key: "last_sold_date" },
  ];

  const csvData = data.data.map((item) => ({
    trade_name: item.trade_name,
    unit_type: item.unit_type,
    barcode: item.barcode ? item.barcode : "Not Available",
    price: convertToCurrencyRounded(item.price),
    cost: convertToCurrencyRounded(item.cost),
    available_stock: `${item.available_stock} (${item.unit})`,
    batch_number: item.batch_number,
    expiry_date: parseDate(item.expiry_date).format("DD/MM/YYYY"),
    last_sold_date: item.last_sold_date
      ? parseDate(item.last_sold_date).format("DD/MM/YYYY")
      : "Not Available",
  }));

  const filename = "exported_data.csv";

  return (
    <>
      <div className="overflow-y-scroll h-screen pb-48">
        <div className="flex items-center justify-between">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink
                  onClick={() => {
                    navigate({
                      to: "/invoicing",
                    });
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Reports Overview
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Expiry List</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <CSVLink data={csvData} headers={headers} filename={filename}>
            <Button>Export List</Button>
          </CSVLink>
        </div>
        <hr className="my-4" />

        <Table className="mt-4 text-sm">
          <TableHeader>
            <TableRow>
              <TableHead>Trade Name</TableHead>
              <TableHead>Unit Type</TableHead>
              <TableHead>Barcode</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>Cost</TableHead>
              <TableHead>
                Available Stock <br /> (Unit per stock)
              </TableHead>
              <TableHead>Batch Number</TableHead>
              <TableHead
                onClick={() => handleRequestSort("expiry_date")}
                style={{ cursor: "pointer" }}
              >
                <div className="flex items-center">
                  <span>Expiry Date</span>
                  {orderBy === "expiry_date" && (
                    <CaretSortIcon
                      className={`ml-2 h-4 w-4 ${
                        order === "asc" ? "rotate-180" : ""
                      }`}
                    />
                  )}
                </div>
              </TableHead>
              <TableHead>Last Sold On</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sortedData.map((item, index) => (
              <TableRow key={index} className="font-medium">
                <TableCell>{item.trade_name}</TableCell>
                <TableCell>{item.unit_type}</TableCell>
                <TableCell>
                  {item.barcode ? item.barcode : "Not Available"}
                </TableCell>
                <TableCell>{convertToCurrencyRounded(item.price)}</TableCell>
                <TableCell>{convertToCurrencyRounded(item.cost)}</TableCell>
                <TableCell>
                  {item.available_stock} ({item.unit})
                </TableCell>
                <TableCell>{item.batch_number}</TableCell>
                <TableCell>
                  {parseDate(item.expiry_date).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {item.last_sold_date
                    ? parseDate(item.last_sold_date).format("DD/MM/YYYY")
                    : "Not Available"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <hr className="my-0" />

        <div className="flex items-center justify-center w-full fixed bottom-4 bg-white pt-6">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {searchParams.page} of{" "}
            {data.data.length > 0
              ? Math.ceil(data.data[0].full_count / searchParams.pageSize)
              : 1}
          </div>
          <div className="flex space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page - 1,
                  },
                });
              }}
              disabled={searchParams.page === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: searchParams.page + 1,
                  },
                });
              }}
              disabled={
                data.data.length === 0 ||
                data.data[0].full_count <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => {
                navigate({
                  search: {
                    ...searchParams,
                    page: Math.ceil(
                      data.data[0].full_count / searchParams.pageSize
                    ),
                  },
                });
              }}
              disabled={
                data.data.length === 0 ||
                data.data[0].full_count <=
                  searchParams.page * searchParams.pageSize
              }
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Component;
