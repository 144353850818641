import { createFileRoute } from "@tanstack/react-router";
import Component from "@/screens/reports/shift-history/component"
import { ShiftHistoryParams } from "@/screens/reports/shift-history/model";
import { shiftDetailsSchema, ShiftHistoryResponseSchema } from "@/screens/reports/shift-history/type";
import { getShiftHistory } from "@/screens/reports/shift-history/apit";
import { Loading } from "@/layouts/loading";
export const Route = createFileRoute("/reports/shift-history/")
    ({
        component: Component,
        validateSearch: ShiftHistoryParams,
        loaderDeps: ({ search: { page, pageSize } }) => ({
            page,
            pageSize,
        }),
        loader: async (params): Promise<ShiftHistoryResponseSchema[]> => {
            const response = await getShiftHistory(
                params.deps.page - 1,
                params.deps.pageSize
            );
            return response;
        },
        pendingComponent: () => <Loading />,
        pendingMinMs: 1000,
        pendingMs: 1,
    });
