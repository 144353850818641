import { useState } from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { useRouter } from "@tanstack/react-router";
import { X } from "lucide-react";

const AddPatient = () => {
  const { toast } = useToast();

  const [patient_name, set_patient_name] = useState("");
  const [patient_phone, set_patient_phone] = useState("");
  const [patient_emirates_id, set_patient_emirates_id] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const SubmitPatientDetails = async () => {
    setLoading(true);

    try {
      if (!patient_name || !patient_phone || !patient_emirates_id) {
        toast({
          title: "Error",
          description: "Please fill all the fields",
          duration: 5000,
        });
        setLoading(false);
        return;
      }
      const response = await httpClient.post(
        `${import.meta.env.VITE_API_URL as string}/public/pharmacy/patients/create`,
        {
          name: patient_name,
          phone: patient_phone,
          emirates_id: patient_emirates_id,
          pharmacy_code: getPharmacyID(),
        }
      );

      console.log("Response:", response.data);

      toast({
        title: "New Patient Added",
        description: `Patient ${patient_name} has been added successfully`,
        duration: 5000,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to add patient. Please try again later.",
        duration: 5000,
      });
    } finally {
      setLoading(false);
      router.invalidate();
      setIsOpen(false);
      set_patient_emirates_id("");
      set_patient_name("");
      set_patient_phone("");
    }
  };

  return (
    <>
      <Sheet open={isOpen}>
        <SheetTrigger>
          <Button onClick={() => setIsOpen(!isOpen)}>Add Patient</Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Add a new patient</SheetTitle>
            <SheetDescription>
              The following details are required to add a new patient
            </SheetDescription>
            <SheetClose
              className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-secondary"
              onClick={() => {
                set_patient_emirates_id("");
                set_patient_name("");
                set_patient_phone("");
                setIsOpen(false);
              }}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </SheetHeader>
          <p className="text-sm font-semibold mt-4 mb-2">Patient Name</p>
          <Input
            placeholder="Patient Name"
            value={patient_name}
            onChange={(e) => set_patient_name(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Phone Number</p>
          <Input
            placeholder="Phone Number"
            type="number"
            value={patient_phone}
            onChange={(e) => set_patient_phone(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Emirates ID</p>
          <Input
            placeholder="Patient Emirates ID"
            value={patient_emirates_id}
            onChange={(e) => set_patient_emirates_id(e.target.value)}
          />
          {/* <hr className='my-4'/> */}
          <Button
            className="absolute bottom-2 right-2"
            onClick={SubmitPatientDetails}
            disabled={loading}
          >
            {loading ? "Loading..." : "Add Patient"}
          </Button>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default AddPatient;
