import { Document, Page, View, Font } from "@react-pdf/renderer";
import lexendFontURL from "@/assets/Lexend.ttf";
import { createTw } from "react-pdf-tailwind";
import { cn } from "@/lib/utils";

Font.register({
    family: 'Lexend',
    src: lexendFontURL
})

// tailwind config for react-pdf-tailwind
const tw = createTw({
    theme: {
        container: {
            center: true,
            padding: "2rem",
            screens: {
                "2xl": "1400px",
            },
        },
        extend: {
            colors: {
                border: "hsl(var(--border))",
                input: "hsl(var(--input))",
                ring: "hsl(var(--ring))",
                background: "hsl(var(--background))",
                foreground: "hsl(var(--foreground))",
                primary: {
                    DEFAULT: "hsl(var(--primary))",
                    foreground: "hsl(var(--primary-foreground))",
                },
                secondary: {
                    DEFAULT: "hsl(var(--secondary))",
                    foreground: "hsl(var(--secondary-foreground))",
                },
                destructive: {
                    DEFAULT: "hsl(var(--destructive))",
                    foreground: "hsl(var(--destructive-foreground))",
                },
                muted: {
                    DEFAULT: "hsl(var(--muted))",
                    foreground: "hsl(var(--muted-foreground))",
                },
                accent: {
                    DEFAULT: "hsl(var(--accent))",
                    foreground: "hsl(var(--accent-foreground))",
                },
                popover: {
                    DEFAULT: "hsl(var(--popover))",
                    foreground: "hsl(var(--popover-foreground))",
                },
                card: {
                    DEFAULT: "hsl(var(--card))",
                    foreground: "hsl(var(--card-foreground))",
                },
            },
            borderRadius: {
                lg: "var(--radius)",
                md: "calc(var(--radius) - 2px)",
                sm: "calc(var(--radius) - 4px)",
            },
            keyframes: {
                "accordion-down": {
                    from: { height: "0" },
                    to: { height: "var(--radix-accordion-content-height)" },
                },
                "accordion-up": {
                    from: { height: "var(--radix-accordion-content-height)" },
                    to: { height: "0" },
                },
            },
            animation: {
                "accordion-down": "accordion-down 0.2s ease-out",
                "accordion-up": "accordion-up 0.2s ease-out",
            },
            fontFamily: {
                lexend: "Lexend, sans-serif",
            },
        },
    },
})

//extend styles as needed
export const pdfCN = (style: string, className?: string) => {
    return tw(cn(style, className))
}

type PropsWithClassAndChildren<T = unknown> = T & {
    className?: string
    children?: React.ReactNode | undefined;
}

export const PDFRoot = (props: PropsWithClassAndChildren) => {
    return <Document pageLayout="singlePage" >
        <Page style={pdfCN("flex flex-col items-center font-[Lexend]")}>
            {props.children}
        </Page>
    </Document>
}

export const PDFHead = (props: PropsWithClassAndChildren) => {
    return <View style={pdfCN("flex flex-row gap-1 w-full text-[18pt] bg-[#f4f4f4] font-[700] px-10", props.className)}>
        {
            props.children
        }
    </View>
}

export const PDFBody = (props: PropsWithClassAndChildren) => {
    return <View style={pdfCN("flex flex-col w-full justify-start px-10 my-5", props.className)}>
        {props.children}
    </View>

}


export const PDFTableRow = (props: PropsWithClassAndChildren) => {
    return <View style={pdfCN("w-full border-b border-b-[#d9d9d9] flex flex-row font-[300] text-[10pt] py-2", props.className)}>
        {props.children}
    </View>
}

export const PDFTable = (props: PropsWithClassAndChildren) => {
    return <View style={pdfCN("", props.className)}>
        {props.children}
    </View>
}



