import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { PurchaseOrderItem } from "../component"

export default function Review(
    { purchaseOrderItems, supplierName }: {
        purchaseOrderItems: PurchaseOrderItem[],
        supplierName: string
    }
) {
    return <>
        <div className="col-span-6 overflow-auto">
            <>
                <div>
                    <h2 className="text-2xl font-bold tracking-tight">Purchase Order</h2>
                    <p className="text-muted-foreground">
                        Below are the details you've entered in step 1. Please review them before submitting.
                    </p>
                </div>
                <hr className="my-4" />

                <div className="grid grid-cols-5 divide-x">
                    <div className="flex flex-col gap-y-12 font-semibold text-xl">
                        <p>Supplier Info</p>
                    </div>
                    <div
                        className="col-span-4 px-4 flex flex-col gap-y-8"
                    >
                        <div className="grid grid-cols-3 gap-x-4">
                            <div>
                                <p className='font-semibold'>{supplierName}</p>
                                <p className='text-gray-500 text-sm'>Supplier</p>
                            </div>
                        </div>
                    </div>
                </div>


                <hr className="my-4" />

                <div>
                    <h2 className="text-2xl font-bold tracking-tight">Item List</h2>
                    <p className="text-muted-foreground">
                        Below are the details you've entered in step 2. Please review them before submitting.
                    </p>
                </div>
                <hr className="my-4" />

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-12">Serial</TableHead>
                            <TableHead>Product Name</TableHead>
                            <TableHead className="w-24">Quantity</TableHead>
                            <TableHead className="w-24">Bonus Quantity</TableHead>
                            <TableHead className="w-24">Intended Cost</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {purchaseOrderItems.map((item, index) => (<TableRow key={index} className="divide-x">
                            <TableCell className="w-12">{index + 1}</TableCell>
                            <TableCell className="font-medium">{item.trade_name}</TableCell>

                            <TableCell className="w-24">{item.quantity}</TableCell>
                            <TableCell className="w-24">{item.bonus_quantity}</TableCell>
                            <TableCell className="w-24">{item.intended_cost}</TableCell>
                        </TableRow>))}
                    </TableBody>
                </Table>
            </>
        </div>
        <div className="col-span-2 bg-[#F5F6F7] min-h-full p-4">
            <div>
                <div className='pb-2 border-b-[0.5px] '>
                    <p className='font-semibold'>Breakdown</p>
                    <p className='text-gray-500'>This contains the item you add /text needs to be worked on</p>
                </div>

                <div className='mt-6'>
                    <div className='bg-white px-2 py-2 w-full my-1 flex items-center justify-between'>
                        <p className='text-sm font-semibold'>No of Items</p>
                        <p className='text-sm text-gray-600 font-semibold'>{purchaseOrderItems.length}</p>
                    </div>
                    <div className='bg-white px-2 py-2 w-full my-1 flex items-center justify-between'>
                        <p className='text-sm font-semibold'>Total Quantity</p>
                        <p className='text-sm text-gray-600 font-semibold'>{purchaseOrderItems.reduce((sum, item) => sum + item.quantity, 0)}</p>
                    </div>
                </div>
            </div>
        </div>

    </>
}
