import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { getOidc } from '@/oidc';
import { Clock, ExternalLink } from 'lucide-react'

interface IntegrationCard {
    name: string;  // Explicitly defining the type of name as string
}

const IntegrationCard = ({name} : IntegrationCard) => {
    return (
        <div className='flex flex-col gap-y-2 border rounded-lg p-4'>
            <Clock className='w-8 h-8 bg-gray-200 rounded-lg p-2' />
            <p >{name}</p>
            <p className='text-gray-500 text-sm'>Billing text goes here, brief description on what this functionality does.</p>
            <Switch 
            />
        </div>
    )
}

const QuickStart = () => {
    return (
        <div className='flex flex-col gap-y-4 border rounded-lg p-4'>
            <p className='text-gray-700 mb-2'>Quick Start guide</p>

            <div className='flex items-center gap-x-2' onClick={async () => {
                const oidc = await getOidc()
                oidc.isUserLoggedIn && oidc.goToAuthServer({
                    extraQueryParams: {
                        kc_action: 'UPDATE_PASSWORD'
                    }
                })
            }}>
                <p className='text-gray-700 text-sm underline'>Reset Password</p>
                <ExternalLink className='w-3 h-3' />
            </div>

            <div className='flex items-center gap-x-2'>
                <p className='text-gray-700 text-sm underline'>Contacting Support</p>
                <ExternalLink className='w-3 h-3' />
            </div>

        </div>
    )
}

const Overview = () => {
    return (
        <>
            <div>
                <h2 className="text-2xl font-bold tracking-tight">Pharmacy Overview</h2>
                <p className="text-muted-foreground">
                    Manage your settings and other preferences.
                </p>
            </div>

            <hr className='my-4' />

            <div className='flex items-center justify-between'>
                <p className="text-lg font-medium">
                    Add Integrations
                </p>
                <Button
                    variant='ghost'
                    size='sm'
                    className='text-blue-700'
                >
                    View All
                </Button>   
            </div>

            <div
                className='grid grid-cols-3 gap-4 mt-4'
            >
                <IntegrationCard name='Noon'/>
                <IntegrationCard name='Talabat' />
                <IntegrationCard name='InstaShop'/>
                <IntegrationCard name='Amazon'/>
                <IntegrationCard name='Deliveroo'/>
                <IntegrationCard name='Careem'/>
            </div>

            <div className='mt-6'>
                <QuickStart />
            </div>
        </>
    )
}

export default Overview