import React from 'react'
import { Link } from '@tanstack/react-router'
import { ChevronRight } from 'lucide-react';

const QuickLinkComponent = ({ title, link }: { title: string; link: string }) => {
    return (
        <Link
            to={link}
            className='text-sm bg-[#F4F4F5] rounded-lg'
            preload='intent'
        >
            <div className='px-6 py-3 flex items-center justify-between'>
                <p>{title}</p>
                <ChevronRight size={18} />
            </div>
        </Link>
    )
}

const QuickLinks = () => {
    return (
        <div>
            <p className='text-xl font-semibold text-gray-600 mb-4 mt-12'>Quick Links</p>
            <div
                className='grid grid-cols-3 gap-x-4 gap-y-4'
            >
                <QuickLinkComponent 
                    title='View Available Inventory'
                    link='/inventory/available-inventory'
                />
                <QuickLinkComponent 
                    title='View Expiring Inventory'
                    link='/inventory/available-inventory/?expiring_in_30_days=true'
                />
                <QuickLinkComponent 
                    title='View Expired Inventory'
                    link='/inventory/available-inventory/?expired=true'
                />
                <QuickLinkComponent 
                    title='View Medical Master'
                    link='/inventory/medical-master'
                />
                <QuickLinkComponent 
                    title='View Non Medical Master'
                    link='/inventory/non-medical-master'
                />
            </div>
        </div>
    )
}

export default QuickLinks