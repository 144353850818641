import { useEffect, useState } from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { useRouter } from "@tanstack/react-router";
import { X } from "lucide-react";
import { getPatientDetails } from "../api";
import dayjs from "dayjs";
import DatePicker from "@/components/ui/date-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Calendar } from "@/components/ui/calendar";

const EditPatient = ({ patient_id }: { patient_id: string }) => {
  const { toast } = useToast();

  const [patient_name, set_patient_name] = useState("");
  const [patient_phone, set_patient_phone] = useState("");
  const [patient_emirates_id, set_patient_emirates_id] = useState("");
  const [patient_address, set_patient_address] = useState("");
  const [patient_dob, set_patient_dob] = useState<Date | null>(null);
  const [patient_gender, set_patient_gender] = useState<
    "male" | "female" | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const SubmitPatientDetails = async () => {
    setLoading(true);

    try {
      if (!patient_name || !patient_phone || !patient_emirates_id) {
        toast({
          title: "Error",
          description: "Please fill all the fields",
          duration: 5000,
        });
        setLoading(false);
        return;
      }
      const response = await httpClient.post(
        `${import.meta.env.VITE_API_URL as string}/public/pharmacy/patients/update`,
        {
          name: patient_name,
          phone: patient_phone,
          emirates_id: patient_emirates_id,
          id: patient_id,
          metadata: {
            address: patient_address ? patient_address : "",
            date_of_birth: patient_dob
              ? dayjs(patient_dob).format("YYYY-MM-DD")
              : "",
            gender: patient_gender ? patient_gender : "",
          },
        }
      );

      console.log("Response:", response.data);

      toast({
        title: "Patient Edited",
        description: `Patient ${patient_name} has been edited successfully`,
        duration: 5000,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error",
        description: "Failed to edit patient. Please try again later.",
        duration: 5000,
      });
    } finally {
      setLoading(false);
      router.invalidate();
      setIsOpen(false);
      set_patient_emirates_id("");
      set_patient_name("");
      set_patient_phone("");
      set_patient_address("");
      set_patient_dob(null);
      set_patient_gender(null);
    }
  };

  useEffect(() => {
    async function getPatient() {
      try {
        const res = await getPatientDetails(patient_id);
        set_patient_name(res.name);
        set_patient_phone(res.phone);
        set_patient_emirates_id(res.emirates_id);
        set_patient_address(res.metadata.address);
        set_patient_gender(res.metadata.gender ? res.metadata.gender : null);
        set_patient_dob(
          dayjs(res.metadata.date_of_birth).isValid()
            ? dayjs(res.metadata.date_of_birth).toDate()
            : null
        );
      } catch (error) {
        console.error("Error:", error);
        toast({
          title: "Error",
          description: "Failed to get patient details. Please try again later.",
          duration: 5000,
        });
      }
    }
    if (patient_id) {
      getPatient();
    }
  }, [patient_id]);

  return (
    <>
      <Sheet open={isOpen}>
        <SheetTrigger>
          <Button onClick={() => setIsOpen(!isOpen)} variant={"outline"}>
            Edit Patient
          </Button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Edit patient</SheetTitle>
            <SheetDescription>
              The following details are required to edit patient
            </SheetDescription>
            <SheetClose
              className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-secondary"
              onClick={() => {
                set_patient_emirates_id("");
                set_patient_name("");
                set_patient_phone("");
                setIsOpen(false);
              }}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </SheetHeader>
          <p className="text-sm font-semibold mt-4 mb-2">Patient Name</p>
          <Input
            placeholder="Patient Name"
            value={patient_name}
            onChange={(e) => set_patient_name(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Phone Number</p>
          <Input
            placeholder="Phone Number"
            type="number"
            value={patient_phone}
            onChange={(e) => set_patient_phone(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Emirates ID</p>
          <Input
            placeholder="Patient Emirates ID"
            value={patient_emirates_id}
            onChange={(e) => set_patient_emirates_id(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Address</p>
          <Input
            placeholder="Patient Address"
            value={patient_address}
            onChange={(e) => set_patient_address(e.target.value)}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Date of Birth</p>
          <DatePicker
            onSelect={(date) => set_patient_dob(date!)}
            selected={patient_dob!}
            maxDate={new Date()}
          />
          <p className="text-sm font-semibold mt-4 mb-2">Gender</p>
          <Select
            onValueChange={(value: string) => {
              set_patient_gender(value as "male" | "female");
            }}
            value={patient_gender!}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select Discount Type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="male">Male</SelectItem>
              <SelectItem value="female">Female</SelectItem>
            </SelectContent>
          </Select>
          {/* <hr className='my-4'/> */}
          <Button
            className="absolute bottom-2 right-2"
            onClick={SubmitPatientDetails}
            disabled={loading}
          >
            {loading ? "Loading..." : "Edit Patient"}
          </Button>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default EditPatient;
