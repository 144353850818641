import { httpClient } from "@/httpClient";

const API_URL = import.meta.env.VITE_API_URL;

export async function markSaleAsVoid(sale_id: string): Promise<boolean> {
    const response = await httpClient.post(`${API_URL}/public/pharmacy/sales/void`, {
        sale_id: sale_id
    })
    return response.data.status;
}

export async function markSaleAsRefunded(sale_id: string, partialMedicalItems: string[], partialNonMedicalItems: string[]): Promise<string> {
    const response = await httpClient.post(`${API_URL}/public/pharmacy/sales/return`, {
        partial_medical_items: partialMedicalItems,
        partial_non_medical_items: partialNonMedicalItems,
        sale_id: sale_id
    })
    return response.data.data as string;
}