import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { InventoryOverviewSchema, PricingPendingAcceptance } from "./model";

const API_URL = import.meta.env.VITE_API_URL as string

export async function fetchInventoryOverview() {
    const response = await httpClient.post(`${API_URL}/public/pharmacy/inventory/overview`, {
        pharmacy_code: getPharmacyID(),
    });

    const data = await response.data;

    if (!data.status) {
        throw new Error("API Fetch Error");
    }

    return data.data as InventoryOverviewSchema;
}

export async function fetchPricingPendingAcceptance() {
    const response = await httpClient.post(`${API_URL}/public/pharmacy/medical_master/pricing/get/pending_acceptance?page=0&limit=5`, {
        pharmacy_code: getPharmacyID(),
    });

    const data = await response.data;

    if (!data.status) {
        throw new Error("API Fetch Error");
    }

    return data.data as PricingPendingAcceptance;
}

export async function acceptAllPricingChanges() {
    try {
        const response = await httpClient.post(`${API_URL}/public/pharmacy/medical_master/pricing/accept/all`, {
            pharmacy_code: getPharmacyID(),
        });
    
        const data = await response.data;
    
        if (!data.status) {
            throw new Error("API Fetch Error");
        }
    
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}