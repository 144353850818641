import React, { useState } from "react";
import {
  Ban,
  CalendarClock,
  CalendarFold,
  ChartNoAxesColumn,
  ChartPie,
  CornerDownLeft,
  Filter as FilterIcon,
  Store,
  Plus,
  Minus
} from "lucide-react";
import ExploreItem from "./explore-item";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"; 
import { Route as revenueRoute } from '@/routes/accounts/cost-of-goods/index'
import { useNavigate } from "@tanstack/react-router";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const Explore = () => {
  const { type, time_compare, moving_average, moving_average_days } = revenueRoute.useSearch()
  const navigate = useNavigate();

  return (
    <div className="flex flex-col divide-y-[0.5px] font-light">
      <ExploreItem
        icon={<ChartPie className="w-4 h-4" />}
        text="Metrics"
        content={
        <>
          <div className="grid gap-2">
            <h4 className="font-medium leading-none">Metrics</h4>
            <hr />
            <div className="">
              <Button variant={time_compare ? 'secondary' : 'ghost'} className="w-full justify-start p-0 font-light" onClick={() => {
                // navigate({
                //   search: {
                //     time_compare: !time_compare,
                //     type,
                //     moving_average: false
                //   }
                // })
              }}>
                <Ban className="mr-2 p-1 text-gray-600" /> Cancelled orders
              </Button>
              <Button variant={time_compare ? 'secondary' : 'ghost'} className="w-full justify-start p-0 font-light" onClick={() => {
                // navigate({
                //   search: {
                //     time_compare: !time_compare,
                //     type,
                //     moving_average: false
                //   }
                // })
              }}>
                <CornerDownLeft className="mr-2 p-1 text-gray-600" /> Returns
              </Button>
            </div>      
          </div>  
        </>}
      />
      <ExploreItem
        icon={<ChartNoAxesColumn className="w-4 h-4" />}
        text="Compare"
        defaultExpanded={time_compare || moving_average}
        content={
          <div className="grid gap-2">
            <h4 className="font-medium leading-none">Time</h4>
            <hr />
            <div className="">
              <Button variant={time_compare ? 'secondary' : 'ghost'} className="w-full justify-start p-0 font-light" onClick={() => {
                navigate({
                  search: {
                    time_compare: !time_compare,
                    type,
                    moving_average: false
                  }
                })
              }}>
                <CalendarFold className="mr-2 p-1 text-gray-600" /> Previous period
              </Button>
            </div>
            <h4 className="font-medium leading-none mt-4">Moving Average</h4>
            <hr />
            <div className="grid">
              <Button variant={moving_average && moving_average_days === 7 ? 'secondary' : 'ghost'} className="w-full justify-start p-0 font-light" onClick={() => {
                navigate({
                  search: {
                    time_compare: false,
                    type,
                    moving_average: moving_average && moving_average_days === 7 ? false : true,
                    moving_average_days: 7
                  }
                })
              }}>
                <CalendarClock className="mr-2 p-1 text-gray-600" /> 7-day average
              </Button>
              <Button variant={moving_average && moving_average_days === 28 ? 'secondary' : 'ghost'} className="w-full justify-start p-0 font-light" onClick={() => {
                navigate({
                  search: {
                    time_compare: false,
                    type,
                    moving_average: moving_average && moving_average_days === 28 ? false : true,
                    moving_average_days: 28
                  }
                })
              }}>
                <CalendarClock className="mr-2 p-1 text-gray-600" /> 28-day average
              </Button>
            </div>
          </div>
        }
      />
      <ExploreItem
        icon={<FilterIcon className="w-4 h-4" />}
        text="Filters"
        content={
          <div className="bg-gray-100 border-[0.5px] border-gray-400 rounded-lg mt-4 p-2">
            <h4 className="font-medium leading-none mb-2">Branch</h4>
            <span
              className="w-full flex items-center justify-start p-0 font-light text-sm"
              onClick={() => {
                // navigate logic here
              }}
            >
              <Store className="mr-2 p-1 text-gray-600" /> Charan's tea house
            </span>
          </div>
        }
      />
    </div>
  );
};

const About = () => <div>About Component</div>;

const Filter = () => {
  const [activeComponent, setActiveComponent] = useState("Explore");

  return (
    <div className="max-w-[250px] xl:max-w-[400px] w-full h-full border-l-[0.5px] p-4">
      <div className="flex space-x-4 mb-4">
        <button
          onClick={() => setActiveComponent("Explore")}
          className={activeComponent === "Explore" ? "" : "text-gray-500"}
        >
          Explore
        </button>
        <button
          onClick={() => setActiveComponent("About")}
          className={activeComponent === "About" ? "" : "text-gray-500"}
        >
          About
        </button>
      </div>
      {activeComponent === "Explore" && <Explore />}
      {activeComponent === "About" && <About />}
    </div>
  );
};

export default Filter;
