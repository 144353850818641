import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis } from 'recharts';
import { SalesByDayByHour } from './components/data/schema';
import dayjs from 'dayjs';

const Chart = ({sales_by_day_by_hour, yesterday_sales_by_hour_by_day}: {sales_by_day_by_hour: SalesByDayByHour[]; yesterday_sales_by_hour_by_day: SalesByDayByHour[]}) => {
   sales_by_day_by_hour = sales_by_day_by_hour.map((item) => {
        const yesterday_sale_count = yesterday_sales_by_hour_by_day.find((yesterday_sale) => dayjs(yesterday_sale.h_hour + "+00:00").format('hh:mm A') === dayjs(item.h_hour + "+00:00").format('hh:mm A'))?.sales_count || 0
        return {
            h_hour: dayjs(item.h_hour + "+00:00").format('hh:mm A'),
            sales_count: item.sales_count,
            yesterday_sales_count: yesterday_sale_count
        }
   })
    return (
        <div className='col-span-1 px-4 border rounded-lg'>
            <p className='text-lg font-semibold mt-4'>Average Busy Time Daily</p>
            <p className='text-gray-500 text-sm'>{
                  sales_by_day_by_hour.reduce((prev, curr) => {
                     return prev.sales_count > curr.sales_count ? prev : curr
                  }).h_hour
            }</p>
            <ResponsiveContainer width="100%" height={500}>
                <LineChart
                    height={500}
                    data={sales_by_day_by_hour}
                    className='mt-4'
                >
                    <CartesianGrid strokeDasharray="2 5" />
                    <XAxis dataKey="h_hour" style={{
                            fontSize: '12px',
                            color: "#697386"
                        }} 
                    />
                    <YAxis dataKey="sales_count" style={{
                            fontSize: '12px',
                            color: "#697386"
                        }} />
                    <Line type="monotone" dataKey="sales_count" stroke="#5469D4" />
                    <Line type="monotone" dataKey="yesterday_sales_count" stroke="#5469D4" strokeDasharray="5 5" />
                    <Tooltip 
                        labelStyle={{
                            display: "none"
                        }}
                        itemStyle={{
                            color: "#5469D4",
                            fontSize: "14px",
                            fontWeight: ""
                        }}
                        formatter={(value, name, props) => [`${props.payload.sales_count > 1 ? "Sales" : "Sale"} at ${props.payload.h_hour} ${name === 'yesterday_sales_count' ? "Yesterday" : ""}`, value]}
                        separator=' '
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart