import { convertToCurrencyRounded } from "@/utils/currency_converter";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Route as salesOneRoute } from "@/routes/overview/sales_history/$saleId";
import { useNavigate } from "@tanstack/react-router";
import dayjs from "dayjs";

const CompanyDetails: React.FC = () => {
  const data = salesOneRoute.useLoaderData();
  const navigate = useNavigate();
  const searchParams = salesOneRoute.useSearch();
  
  return (
    <div className="col-span-1 lg:max-w-[400px] overflow-hidden border-r-[0.5px] h-full border-gray-200 shadow-transparent">
      <CardHeader className="p-0 space-y-0 flex flex-row items-start ">
        <div className="grid gap-0.5">
          <CardTitle className="group flex items-center font-bold text-2xl">
            {data.invoice_id}
          </CardTitle>
        </div>
      </CardHeader>
      <CardContent className="p-4 pl-0 text-xs">
        <div className="grid gap-2">
          {data.is_valid_patient && (
            <p className="text-base font-bold">Customer Details</p>
          )}
          {data.is_valid_patient && (
            <div className="grid grid-cols-3 gap-y-4 my-2">
              <p className=" text-gray-500 whitespace-nowrap col-span-1">
                Customer Type
              </p>
              <p className=" text-gray-500 font-medium col-span-2">
                Registered
              </p>
              <p className="text-gray-500 whitespace-nowrap col-span-1">
                Full Name
              </p>
              <p className=" text-gray-500 font-medium col-span-2">
                {data.patient_info.name}
              </p>

              <p className="text-gray-500 whitespace-nowrap col-span-1">
                Emirates ID
              </p>
              <p className=" text-gray-500 font-medium col-span-2">
                {data.patient_info.emirates_id}
              </p>

              <p className="text-gray-500 whitespace-nowrap col-span-1">
                Delivery Details
              </p>
              <p className=" text-gray-500 font-medium col-span-2">
                {data.patient_info.metadata.address}
              </p>

              <p className="text-gray-500 whitespace-nowrap col-span-1">
                Contact details
              </p>
              <div className="flex flex-col col-span-2">
                <p className=" text-gray-500 font-medium">
                  {data.patient_info.phone}
                </p>
              </div>
            </div>
          )}
          {!data.is_valid_patient && (
            <div className="border-b border-gray-300">
              <p className="text-base font-bold">Customer Details</p>
            </div>
          )}
          {!data.is_valid_patient && (
            <div className="grid grid-cols-2 gap-y-4">
              <p className="text-gray-500 whitespace-nowrap">Customer Type</p>
              <p className=" text-gray-700">Unregistered</p>
              <p className="text-gray-500 whitespace-nowrap">Customer Name</p>
              <p className=" text-gray-700">Over the Counter Customer</p>
            </div>
          )}
          <div className="flex justify-between items-center border-b border-gray-300 mb-2">
            <p className="text-base font-bold mb-4">Transaction Details</p>
          </div>
          <ul className="flex flex-wrap gap-10 gap-y-4">
            <li className="flex flex-col justify-between mb-2">
              <span className="text-gray-500">Paid Amount</span>
              <span className=" text-gray-500 font-bold">
                {convertToCurrencyRounded(
                  data.sale_amounts.card +
                    data.sale_amounts.cash +
                    data.sale_amounts.insurance_amount +
                    data.sale_amounts.vat
                )}
              </span>
            </li>
            <li className="flex flex-col justify-between mb-2">
              <span className="text-gray-500">Total Amount</span>
              <span className=" text-gray-500 font-bold">
                {convertToCurrencyRounded(
                  data.sale_amounts.card +
                    data.sale_amounts.cash +
                    data.sale_amounts.insurance_amount +
                    data.sale_amounts.total_discount +
                    data.sale_amounts.vat
                )}
              </span>
            </li>
            <li className="flex flex-col justify-between mb-2">
              <span className="text-gray-500">Discount</span>
              <span className=" text-gray-500 font-bold">
                {convertToCurrencyRounded(data.sale_amounts.total_discount)}
              </span>
            </li>
            <li className="flex flex-col justify-between mb-2">
              <span className="text-gray-500">Payment By</span>
              <span className=" text-gray-500 font-bold">
                {data.sale_payment_type}
              </span>
            </li>
            <li className="flex flex-col justify-between mb-2 mr-4">
              <span className="text-gray-500">Refunded</span>
              <span className=" text-gray-500 font-bold">
                {data.sale_status.refunded ? "Yes" : "No"}
              </span>
            </li>
            <li className="flex flex-col justify-between mb-2">
              <span className="text-gray-500">Void</span>
              <span className=" text-gray-500 font-bold">
                {data.sale_status.void ? "Yes" : "No"}
              </span>
            </li>
          </ul>
          <div className="grid grid-cols-2 gap-y-4">
            <p className="text-gray-500 whitespace-nowrap">VAT</p>
            <p className=" text-gray-500 font-bold">
              {convertToCurrencyRounded(data.sale_amounts.vat)}
            </p>
          </div>
          <div className="grid grid-cols-2 gap-y-4">
            <p className="text-gray-500 whitespace-nowrap">Sale Time</p>
            <p className=" text-gray-700 font-semibold">
              {dayjs(data.created_at).format("DD MMM, YYYY hh:mm A")}
            </p>
          </div>
        </div>
      </CardContent>
    </div>
  );
};

export default CompanyDetails;
