import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { PLReportSchema } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getPLReport = async (
  offset: number,
  limit: number,
  startDate?: string,
  endDate ?: string
): Promise<PLReportSchema[]> => {
  try {
    const response = await httpClient.post(
      `${apiUrl}/public/pharmacy/reports/pl_report?offset=${offset}&limit=${limit}`,
      {
        start_date: startDate,
        end_date: endDate,
        pharmacy_code: getPharmacyID(),
      }
    );
    return response.data.data.map((item: any) => PLReportSchema.parse(item)); 
  } catch (error) {
    console.error("Error fetching sales report:", error);
    throw new Error("Failed to fetch sales report");
  }
};
