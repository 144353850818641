import { TableHead, TableRow, TableHeader, TableCell, TableBody, Table } from "@/components/ui/table"
import { Button } from "@/components/ui/button"
import { PurchaseInvoiceSchema } from "./data/schema"
import dayjs from "dayjs"
import { AddedItemsSchema } from "@/screens/inventory/add-stock/schema"
import normalizeEnums from "@/utils/normalize_enums"
import ActionAndInfo from "@/screens/inventory/add-stock/components/action-and-info"
import { Pen } from "lucide-react"

export default function ReviewTable({ globalItems, purchaseInvoiceData, supplierName, deleteItem,editItem }: {
  globalItems: AddedItemsSchema[]
  purchaseInvoiceData: PurchaseInvoiceSchema | undefined
  supplierName: string
  deleteItem: (item: AddedItemsSchema) => void,
  editItem: (item: AddedItemsSchema) => void
}) {
  return (
    <>
      <div>
        <h2 className="text-2xl font-bold tracking-tight">Purchase Invoice</h2>
        <p className="text-muted-foreground">
          Below are the details you've entered in step 1. Please review them before submitting.
        </p>
      </div>
      <hr className="my-4" />

      <div className="grid grid-cols-5 divide-x">
        <div className="flex flex-col gap-y-12 font-semibold text-xl">
          <p>Info</p>
          <p>Payment Info</p>
          <p>Supplier Info</p>
        </div>
        <div
          className="col-span-4 px-4 flex flex-col gap-y-8"
        >
          <div className="grid grid-cols-3 gap-x-4">
            <div>
              <p className='font-semibold'>{dayjs(purchaseInvoiceData?.invoice_date).format("DD MMM, YYYY")}</p>
              <p className='text-gray-500 text-sm'>Invoice Date</p>
            </div>
            <div>
              <p className='font-semibold'>{dayjs(purchaseInvoiceData?.delivery_date).format("DD MMM, YYYY")}</p>
              <p className='text-gray-500 text-sm'>Delivery Date</p>
            </div>
            <div>
              <p className='font-semibold'>{purchaseInvoiceData?.invoice_number}</p>
              <p className='text-gray-500 text-sm'>Invoice Number</p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-4">
            <div>
              <p className='font-semibold'>{normalizeEnums(purchaseInvoiceData?.payment_mode || "")}</p>
              <p className='text-gray-500 text-sm'>Method of Payment</p>
            </div>
            <div>
              <p className='font-semibold'>{normalizeEnums(purchaseInvoiceData?.payment_type || "")}</p>
              <p className='text-gray-500 text-sm'>Payment Type</p>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-x-4">
            <div>
              <p className='font-semibold'>{supplierName}</p>
              <p className='text-gray-500 text-sm'>Supplier</p>
            </div>
          </div>
        </div>
      </div>


      <hr className="my-4" />

      <div>
        <h2 className="text-2xl font-bold tracking-tight">Item List</h2>
        <p className="text-muted-foreground">
          Below are the details you've entered in step 2. Please review them before submitting.
        </p>
      </div>
      <hr className="my-4" />

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12">Serial</TableHead>
            <TableHead>Product Name</TableHead>
            <TableHead>Dates</TableHead>
            <TableHead className="w-24">Quantity</TableHead>
            <TableHead className="w-24">Bonus</TableHead>
            <TableHead className="w-24">Cost</TableHead>
            <TableHead className="w-24">Total</TableHead>
            <TableHead className="w-16 flex justify-center"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {globalItems.map((item, index) => (<TableRow key={index} className="divide-x">
            <TableCell className="w-12">{index + 1}</TableCell>
            <TableCell className="font-medium">{item.trade_name} | {item.batch_number}</TableCell>
            <TableCell>
              <p>Expires On: {dayjs(item.expiry_date).format("DD/MM/YYYY")}</p>
              <p>Produced On: {item.production_date ? dayjs(item.production_date).format("DD/MM/YYYY") : "---"}</p>
            </TableCell>
            <TableCell className="w-24">{item.quantity.original}</TableCell>
            <TableCell className="w-24">{item.quantity.bonus}</TableCell>
            <TableCell className="w-24">AED {item.cost}</TableCell>
            <TableCell className="w-24">AED {item.cost * (item.quantity.original)}</TableCell>
            <TableCell className="flex gap-x-2">
              <Button size="icon" variant="outline" onClick={() => {
                deleteItem(item)
              }}>
                <TrashIcon className="h-4 w-4" />
              </Button>
              {/* Resued ActionAndInfo component to view info */}
              <ActionAndInfo value={
                {
                  id: (item.medical_master_id || item.non_medical_master_id)!,
                  type: item.type
                }
              } />
              <Button size="icon" variant="outline" onClick={()=>{
                editItem(item)
              }}>
                <Pen className="h-4 w-4"></Pen>
              </Button>
            </TableCell>
          </TableRow>))}
        </TableBody>
      </Table>
    </>
  )
}

function ListIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="8" x2="21" y1="6" y2="6" />
      <line x1="8" x2="21" y1="12" y2="12" />
      <line x1="8" x2="21" y1="18" y2="18" />
      <line x1="3" x2="3.01" y1="6" y2="6" />
      <line x1="3" x2="3.01" y1="12" y2="12" />
      <line x1="3" x2="3.01" y1="18" y2="18" />
    </svg>
  )
}


function TrashIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 6h18" />
      <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
      <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
    </svg>
  )
}
