import { LineChart, Line, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis } from 'recharts';
import { InvoicingRevevue, InvoicingRevevueByMonth } from './data/schema';
import dayjs from 'dayjs';

const Chart = ({data}: {data: InvoicingRevevueByMonth[]}) => {
    return (
        <div className='col-span-1 px-4 rounded-lg'>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart
                    height={300}
                    data={data.map((d) => {
                        return {
                            month: dayjs(d.h_month).format("MMM"),
                            "Sale(s)": d.sales_count
                        }
                    })}
                    className='mt-4'
                >
                    <CartesianGrid strokeDasharray="2 5" />
                    <XAxis dataKey="month" style={{
                            fontSize: '12px',
                            color: "#697386"
                        }}
                    />
                    <YAxis  style={{
                     fontSize: '12px',
                     color: '#697386'
                    }}
                    label={{
                        value: 'Sale(s)',
                        angle: -90,
                        position: 'insideLeft',
                        style: {
                            fontSize: '15px',
                            color: '#697386'
                        }
                    }}
                    />
                    <Tooltip 
                        labelStyle={{
                            display: "none"
                        }}
                        itemStyle={{
                            color: "#5469D4",
                            fontSize: "14px",
                            fontWeight: ""
                        }}
                        formatter={(value, name) => [name, value]}
                        separator=' '
                    />
                    {/* <Legend /> */}
                    <Line type="monotone" dataKey="Sale(s)" stroke="#5469D4" />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Chart