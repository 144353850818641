import React from 'react'
import BackNav from '@/components/back-nav'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { ClipboardList } from 'lucide-react'

const Component = () => {
    return (
        <div className='flex flex-col items-start justify-start'>
            <BackNav title='Inventory' id='inventory' />
            <div className='grid grid-cols-6 gap-x-4 mt-8 w-full'>
                <div className='col-span-4'>
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight">Your Inventory</h2>
                        <p className="text-muted-foreground">
                            Stock info in relation with your pharmacy
                        </p>
                    </div>
                    <hr className="my-4" />

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Batch</TableHead>
                                <TableHead>Quantity</TableHead>
                                <TableHead>Stock</TableHead>
                                <TableHead>Bonus</TableHead>
                                <TableHead>Cost</TableHead>
                                <TableHead>Expiry</TableHead>
                                <TableHead className="flex justify-center"></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <div className='mt-6'>
                        <h2 className="text-2xl font-bold tracking-tight">Past price updates</h2>
                        <p className="text-muted-foreground">
                            Stock info in relation with your pharmacy
                        </p>
                    </div>
                    <hr className="my-4" />

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Batch</TableHead>
                                <TableHead>Quantity</TableHead>
                                <TableHead>Stock</TableHead>
                                <TableHead>Bonus</TableHead>
                                <TableHead>Cost</TableHead>
                                <TableHead>Expiry</TableHead>
                                <TableHead className="flex justify-center"></TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>254141234</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>24</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>AED 25.50</TableCell>
                                <TableCell>29 March 2023</TableCell>
                                <TableCell className="flex justify-center">
                                    <ClipboardList 
                                        size={20} 
                                        className='text-gray-600'
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>

                <div className='col-span-2 gap-x-4 bg-[#FAFAFA] rounded-lg h-full px-4'>
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight">Item Details</h2>
                        <p className="text-muted-foreground">
                            Details acquired from DCC
                        </p>
                    </div>
                    <hr className="my-4" />
                    <div className="grid grid-cols-2 gap-x-4 divide-y pl-2">
                        <div className="py-2 col-span-2">
                            <p className="text-gray-500">Display Name</p>
                            <p>TEST Display Name</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Generic Name</p>
                            <p>Acetaminophen</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Code</p>
                            <p>ACET001</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Granular</p>
                            <p>Granular Sample Value</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Unit Type</p>
                            <p>Tablet</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Package Type</p>
                            <p>Bottle</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Generic Code</p>
                            <p>GEN001</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Package Size</p>
                            <p>100 Tablets</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Ingredients</p>
                            <p>Acetaminophen 500mg</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Route of Administration</p>
                            <p>Oral</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Dosage Form</p>
                            <p>Tablet</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Strength</p>
                            <p>500mg</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">ATC Code</p>
                            <p>ATC001</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Division</p>
                            <p>Division Sample Value</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Delivery</p>
                            <p>Delivery Sample Value</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Max Refill</p>
                            <p>5</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Price</p>
                            <p>$10.00</p>
                        </div>

                        <div className="py-2">
                            <p className="text-gray-500">Cost</p>
                            <p>$5.00</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Component