import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { createNonMedicalItem } from "@/screens/inventory/item/api"
import { NonMedicalItemCreateSchema } from "@/screens/inventory/item/model"
import { useState } from "react"
import { toast } from "./ui/use-toast"
import { NumericForm, NumericString, numericStringDefault, stringToNumericString, normalizeNumericForm } from "@/utils/numeric_string"

export function AddNonMedical() {
  type NonMedicalItemCreateSchemaForm = NumericForm<NonMedicalItemCreateSchema>
  const [data, setData] = useState<NonMedicalItemCreateSchemaForm>({
    trade_name: "",
    unit: numericStringDefault(),
    unit_type: "",
    metadata: {
      manufacturer: "",
      owner: "",
      category: "",
      sub_category: "",
    },
    barcode: "",
    vat: numericStringDefault(5),
    pharmacy_code: "",
  })
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <Dialog open={open}>
      <DialogTrigger asChild>
        <p className="underline text-xs text-right cursor-pointer" onClick={() => {
          setOpen(true)
        }}>Don't see what you are looking for?</p>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Non Medical Item</DialogTitle>
          <DialogDescription>
            Create a new non medcial item by entering the following details.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Trade Name
            </Label>
            <Input
              value={data.trade_name}
              onChange={(e) => setData({ ...data, trade_name: e.target.value })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Unit
            </Label>
            <Input
              type="number"
              value={data.unit.strValue}
              onChange={(e) => {
                stringToNumericString(e.target.value, (value) => {
                  setData({ ...data, unit: value })
                })
              }}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Unit Type
            </Label>
            <Input
              value={data.unit_type}
              onChange={(e) => setData({ ...data, unit_type: e.target.value })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Manufacturer
            </Label>
            <Input
              value={data.metadata.manufacturer}
              onChange={(e) => setData({ ...data, metadata: { ...data.metadata, manufacturer: e.target.value } })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Owner
            </Label>
            <Input
              value={data.metadata.owner}
              onChange={(e) => setData({ ...data, metadata: { ...data.metadata, owner: e.target.value } })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Category
            </Label>
            <Input
              value={data.metadata.category}
              onChange={(e) => setData({ ...data, metadata: { ...data.metadata, category: e.target.value } })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Sub Category
            </Label>
            <Input
              value={data.metadata.sub_category}
              onChange={(e) => setData({ ...data, metadata: { ...data.metadata, sub_category: e.target.value } })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Barcode
            </Label>
            <Input
              value={data.barcode}
              onChange={(e) => setData({ ...data, barcode: e.target.value })}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              VAT %
            </Label>
            <Input
              value={data.vat.strValue}
              onChange={(e) => {
                stringToNumericString(e.target.value, (value) => {
                  setData({ ...data, vat: value })
                })
              }}
              type="number"
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" disabled={loading} onClick={async () => {
            try {
              setLoading(true)
              await createNonMedicalItem(normalizeNumericForm<NonMedicalItemCreateSchema>(data))
              setLoading(false)
              toast({
                title: "Success",
                description: "Non medical item created successfully",
                duration: 5000,
              })
              setOpen(false)
            } catch (error) {
              console.log(error)
              toast({
                title: "Error",
                description: "Failed to create non medical item",
                duration: 5000,
              })
              setLoading(false)
            }
          }}>{loading ? "Please Wait..." : "Save Changes"}</Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}


