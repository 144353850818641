import { createFileRoute } from "@tanstack/react-router";
import { SearchParams } from "../../../screens/reports/product-profitability/model";
import { getProfitabilityReportNonMedical, getProfitabilityReportMedical } from "@/screens/reports/product-profitability/api";
import { ProductProfitabilty } from "@/screens/reports/product-profitability/type";
import { Loading } from "@/layouts/loading";
import Component from "@/screens/reports/product-profitability/component";

export const Route = createFileRoute("/reports/product-profitablity/")({
  component: Component,
  validateSearch: SearchParams,
  loaderDeps: ({ search: { page, pageSize, type } }) => ({
    page,
    pageSize,
    type
  }),
  loader: async (params) => {
    const { page, pageSize, type } = params.deps;
    try {
      const offset = page - 1;
      const limit = pageSize;
      if (type === "MEDICAL") {
        return await getProfitabilityReportMedical(offset, limit);
      } else {
        return await getProfitabilityReportNonMedical(offset, limit);
      }
    } catch (error) {
      console.error("Error fetching Product Profitability Report:", error);
      throw new Error("Failed to fetch Product Profitability Report");
    }
  },
  pendingComponent: () => <Loading />,
  pendingMinMs: 1000,
  pendingMs: 1,
});
