import { z } from "zod"

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.
export const Patient = z.object({
  id: z.string(),
  name: z.string(),
  phone: z.string(),
  emirates_id: z.string(),
  pharmacy_code: z.string(),
  created_at: z.string(),
  status: z.string(),
  last_visited_date: z.nullable(z.string()),
  full_count: z.number().optional(),
})

export const PatientId = z.object({
    id: z.string(),
    name: z.string(),
    phone: z.string(),
    emirates_id: z.string(),
    pharmacy_code: z.string(),
    created_at: z.string(),
    metadata: z.object({ address: z.string(), date_of_birth: z.string(), gender: z.enum(["male", "female"]) }),
    status: z.string()
})

export const PatientSalesHistory = z.object({
  sale_id: z.string(),
  pharmacy_code: z.string(),
  user_info: z.object({
    user_id: z.string(),
    username: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email: z.string()
  }),
  sale_payment_type: z.string(),
  is_valid_insurance: z.boolean(),
  insurance_details: z.object({
    insurance_id: z.string(),
    erx_number: z.string(),
    insurance_name: z.string()
  }),
  sale_status: z.object({
    paid: z.boolean(),
    void: z.boolean(),
    refunded: z.boolean()
  }),
  sale_amounts: z.object({
    cash: z.number(),
    card: z.number(),
    total_discount: z.number(),
    insurance_amount: z.number(),
    vat: z.number(),
    cash_received: z.number()
  }),
  created_at: z.string(),
  invoice_id: z.string(),
  full_count: z.number()
})

export const PatientMinimal = z.object({
  id: z.string(),
  name: z.string(),
  phone: z.string(),
  emirates_id: z.string(),
})

export const PatientSearchParams = z.object({
  page: z.number().int().default(1),
  pageSize: z.number().int().default(10),
  search: z.string().optional(),
})

export type Patient = z.infer<typeof Patient>
export type PatientId = z.infer<typeof PatientId>
export type PatientSalesHistory = z.infer<typeof PatientSalesHistory>
export type PatientMinimal = z.infer<typeof PatientMinimal>
export type PatientSearchParams = z.infer<typeof PatientSearchParams>