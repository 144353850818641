import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { shiftDetailsSchema, ShiftHistoryResponseSchema } from "./type";

const apiUrl = import.meta.env.VITE_API_URL as string;

export const getShiftHistory = async (offset: number, limit: number): Promise<ShiftHistoryResponseSchema[]> => {
    try {
        const response = await httpClient.post(
            `${apiUrl}/public/pharmacy/shifts/get/list/pharmacy?offset=${offset}&limit=${limit}`, {
            pharmacy_code: getPharmacyID(),
        }
        );
        return response.data.data.map((item: any) => ShiftHistoryResponseSchema.parse(item)).filter((item:ShiftHistoryResponseSchema) => item.shift_details.end_time.Valid);
    } catch (error) {
        console.error("Error fetching shift history:", error);
        throw error;
    }
}
