import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { FinalSalesData, InventoryCombinedData, MedicalInventoryData, MedicalItemsForSale, NonMedicalInventoryData, NonMedicalItemsForSale, SalesRowData } from "./models/inventoryData";
import dayjs from "dayjs";
import { formatRFC3339 } from "date-fns";

// dayjs utc plugin
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(utc)
dayjs.extend(customParseFormat)

export const getMedicalItem = async (id: string, is_not_expired: boolean = true, include_offer: boolean = true, active_offer_only: boolean = true) => {
    const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/medical/get/one`, {
        pharmacy_code: getPharmacyID(),
        medical_master_id: id,
        is_not_expired: is_not_expired,
        include_offer,
        active_offer_only
    })
    return data.data.data as MedicalInventoryData[]
}

export const getNonMedicalItem = async (id: string, include_offer: boolean = true, active_offer_only: boolean = true) => {
    const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/inventory/non_medical/get/one`, {
        non_medical_master_id: id,
        pharmacy_code: getPharmacyID(),
        include_offer,
        active_offer_only
    })

    return data.data.data as NonMedicalInventoryData[]
}

export const makeSaleByCash = async (items: FinalSalesData[], cashReceived: number, discount: number, total: number, vat: number, patientId: string | undefined): Promise<string> => {
    const medical_items: MedicalItemsForSale[] = items.filter(item => item.type === "MEDICAL").map(item => {
        if (item.type === "MEDICAL") {
            return {
                medical_inventory_id: item.inventory_id,
                medical_price_history_id: item.price_history_id,
                metadata: {
                    discount_amount: parseFloat(item.item_discount!.toFixed()) || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: parseFloat(item.quantity_added!.toFixed()) || 0,
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as MedicalItemsForSale[]
    const non_medical_items: NonMedicalItemsForSale[] = items.filter(item => item.type === "NON_MEDICAL").map(item => {
        if (item.type === "NON_MEDICAL") {
            return {
                non_medical_inventory_id: item.inventory_id,
                non_medical_inventory_price_id: item.price_history_id,
                metadata: {
                    discount_amount: parseFloat(item.item_discount!.toFixed()) || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: parseFloat(item.quantity_added!.toFixed()) || 0,
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as NonMedicalItemsForSale[]
    const finalData = {
        amounts: {
            card: 0,
            cash: total,
            cash_received: cashReceived,
            insurance_amount: 0,
            total_discount: discount,
            vat: vat,
        },
        card_details: {
            auth_code: "",
            card_number: ""
        },
        insurance_details: {
            erx_number: "",
            insurance_id: ""
        },
        patient_id: patientId ? patientId : "",
        payment_type: "CASH",
        pharmacy_code: getPharmacyID(),
        status: {
            paid: true,
            refunded: false,
            void: false
        },
        medical_items: medical_items,
        non_medical_items: non_medical_items
    }
    const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/sales/new`, finalData)
    return data.data.data as string
}
export const makeSaleByCard = async (items: FinalSalesData[], discount: number, total: number, vat: number, last4: string, auth_code: string, patientId: string | undefined): Promise<string> => {
    const medical_items: MedicalItemsForSale[] = items.filter(item => item.type === "MEDICAL").map(item => {
        if (item.type === "MEDICAL") {
            return {
                medical_inventory_id: item.inventory_id,
                medical_price_history_id: item.price_history_id,
                metadata: {
                    discount_amount: item.item_discount || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: item.quantity_added!
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as MedicalItemsForSale[]
    const non_medical_items: NonMedicalItemsForSale[] = items.filter(item => item.type === "NON_MEDICAL").map(item => {
        if (item.type === "NON_MEDICAL") {
            return {
                non_medical_inventory_id: item.inventory_id,
                non_medical_inventory_price_id: item.price_history_id,
                metadata: {
                    discount_amount: item.item_discount || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: item.quantity_added!
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as NonMedicalItemsForSale[]
    const finalData = {
        amounts: {
            card: total,
            cash: 0,
            cash_received: 0,
            insurance_amount: 0,
            total_discount: discount,
            vat: vat,
        },
        card_details: {
            auth_code: auth_code,
            card_number: last4
        },
        insurance_details: {
            erx_number: "",
            insurance_id: ""
        },
        patient_id: patientId ? patientId : "",
        payment_type: "CARD",
        pharmacy_code: getPharmacyID(),
        status: {
            paid: true,
            refunded: false,
            void: false
        },
        medical_items: medical_items,
        non_medical_items: non_medical_items
    }
    const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/sales/new`, finalData)
    return data.data.data as string
}

export const makeSaleBySplit = async (items: FinalSalesData[], discount: number, total: number, vat: number, last4: string, auth_code: string, card_amount: number, cash_received: number, patientId: string | undefined): Promise<string> => {
    const medical_items: MedicalItemsForSale[] = items.filter(item => item.type === "MEDICAL").map(item => {
        if (item.type === "MEDICAL") {
            return {
                medical_inventory_id: item.inventory_id,
                medical_price_history_id: item.price_history_id,
                metadata: {
                    discount_amount: item.item_discount || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: item.quantity_added!
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as MedicalItemsForSale[]
    const non_medical_items: NonMedicalItemsForSale[] = items.filter(item => item.type === "NON_MEDICAL").map(item => {
        if (item.type === "NON_MEDICAL") {
            return {
                non_medical_inventory_id: item.inventory_id,
                non_medical_inventory_price_id: item.price_history_id,
                metadata: {
                    discount_amount: item.item_discount || 0,
                    insurance_amount: 0,
                    insurance_used: false,
                    refunded: false
                },
                quantity: item.quantity_added!
            }
        } else {
            return null
        }
    }).filter(item => item !== null) as NonMedicalItemsForSale[]
    const finalData = {
        amounts: {
            card: card_amount,
            cash: total - card_amount,
            cash_received: cash_received,
            insurance_amount: 0,
            total_discount: discount,
            vat: vat,
        },
        card_details: {
            auth_code: auth_code,
            card_number: last4
        },
        insurance_details: {
            erx_number: "",
            insurance_id: ""
        },
        patient_id: patientId ? patientId : "",
        payment_type: "SPLIT",
        pharmacy_code: getPharmacyID(),
        status: {
            paid: true,
            refunded: false,
            void: false
        },
        medical_items: medical_items,
        non_medical_items: non_medical_items
    }
    const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/sales/new`, finalData)
    return data.data.data as string
}

export const startShift = async (cash_drawer_amount: number, card_amount: number, terminal_id: string, shift_slot: string, start_time: string): Promise<boolean> => {
    try {
        const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/shifts/start`, {
            cash_drawer_start_amount: cash_drawer_amount,
            card_start_amount: card_amount,
            pharmacy_code: getPharmacyID(),
            shift_name: shift_slot,
            start_time: dayjs(start_time, 'HH:mm').utc().format("YYYY-MM-DDTHH:mm:ssZ"),
            cash_register_id: terminal_id
        })
        return data.data.status
    } catch (error) {
        console.error('Error:', error)
        return false
    }
}

export const endShift = async (cash_drawer_end_amount: number, card_amount: number, payouts: number, end_time: string): Promise<boolean> => {
    try {
        const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/shifts/end`, {
            cash_drawer_end_amount: cash_drawer_end_amount,
            card_end_amount: card_amount,
            payouts: payouts,
            pharmacy_code: getPharmacyID(),
            end_time: dayjs(end_time + ":59", 'HH:mm::ss').utc().format("YYYY-MM-DDTHH:mm:ssZ")
        })
        return data.data.status
    } catch (error) {
        console.error('Error:', error)
        return false
    }
}
export const endShiftSystem = async (cash_drawer_end_amount: number, card_amount: number, payouts: number, shift_id: string): Promise<boolean> => {
    try {
        const data = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/shifts/end/system`, {
            cash_drawer_end_amount: cash_drawer_end_amount,
            card_end_amount: card_amount,
            payouts: payouts,
            pharmacy_code: getPharmacyID(),
            shift_id: shift_id
        })
        return data.data.status
    } catch (error) {
        console.error('Error:', error)
        return false
    }
}