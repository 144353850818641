import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useNavigate } from '@tanstack/react-router';
import Chart from './components/chart';
import Filter from './components/filter'
import { Route as revenueRoute } from '@/routes/accounts/revenue/index'

const Component = () => {
  const navigate = useNavigate()
  return (
    <div className="overflow-scroll h-full flex flex-col">
      <div className='flex items-center justify-between'>
          <Breadcrumb>
              <BreadcrumbList>
                  <BreadcrumbItem>
                      <BreadcrumbLink
                          onClick={() => {
                              navigate({
                                  to: "/accounts",
                              });
                          }}
                          style={{
                              cursor: "pointer",
                          }}
                      >
                          Accounts Overview
                      </BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbSeparator />
                  <BreadcrumbItem>
                      <BreadcrumbPage>Revenue</BreadcrumbPage>
                  </BreadcrumbItem>
              </BreadcrumbList>
          </Breadcrumb>
      </div>
      <hr className='mt-4' />

      <div className='flex grow gap-x-4'>
        <Chart />
        <Filter />           
      </div>
    </div>
  )
}

export default Component 