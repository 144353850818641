import { Button } from '@/components/ui/button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Timer, X } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import { useState } from 'react'
import dayjs from 'dayjs'
import { startShift } from '@/screens/sales/api'
import { toast } from '../ui/use-toast'
import { useRouter } from '@tanstack/react-router'

type FormValuesSchema = {
    cash_drawer_amount: number | undefined,
    card_amount: number | undefined,
    terminal_id: string,
    shift_slot: string,
    start_time: string
}

const StartShiftModal = ({
    value,
    setValue,
    setDisplaySalesComp,
    showTriggerButton,
    page = 'overview',
}: {
    value: boolean,
    setValue: React.Dispatch<React.SetStateAction<boolean>>,
    setDisplaySalesComp: React.Dispatch<React.SetStateAction<boolean>>,
    showTriggerButton?: boolean,
    page?: string
}) => {

    const [formValues, setFormValues] = useState<FormValuesSchema>({
        cash_drawer_amount: undefined,
        card_amount: undefined,
        terminal_id: '',
        shift_slot: '',
        start_time: dayjs().format('HH:mm')
    })

    const [loading, setLoading] = useState(false)
    const router = useRouter()
    
    return (
        <>
            <Dialog
                open={value}
            >
                {
                    showTriggerButton && (
                        <DialogTrigger asChild>
                            <Button
                                onClick={() => setValue(true)}
                            >
                                Start Shift
                            </Button>
                        </DialogTrigger>
                    )
                }
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Start Shift</DialogTitle>
                        <DialogDescription>
                            Following details are required.
                        </DialogDescription>
                        <Button
                            variant={'ghost'} 
                            className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                            onClick={() => setValue(false)}
                        >
                            <X className="h-4 w-4" />
                            <span className="sr-only">Close</span>
                        </Button>
                    </DialogHeader>
                        <div className="grid gap-1 py-4">
                            <p className='text-sm font-semibold'>Cash Drawer Amount*</p>
                            <Input 
                                placeholder='AED 20.00'
                                value={formValues.cash_drawer_amount!}
                                type='number'
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        cash_drawer_amount: e.target.value ? parseFloat(e.target.value) : undefined
                                    })
                                }}
                                disabled={loading}
                            />
                            <p className='text-sm font-semibold'>Card Amount*</p>
                            <Input 
                                placeholder='AED 20.00'
                                value={formValues.card_amount!}
                                type='number'
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        card_amount: e.target.value ? parseFloat(e.target.value) : undefined
                                    })
                                }}
                                disabled={loading}
                            />
                            <p className='text-sm font-semibold mt-4'>Terminal ID</p>
                            <Input 
                                placeholder='1'
                                value={formValues.terminal_id}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        terminal_id: e.target.value
                                    })
                                }}
                                disabled={loading}
                            />
                            <p className='text-sm text-gray-500'>Enter '1' if you only have one device</p>
                            <p className='text-sm font-semibold mt-4'>Shift Slot*</p>
                            <Select
                                onValueChange={(value: string) => {
                                    setFormValues({
                                        ...formValues,
                                        shift_slot: value
                                    })
                                }}
                                value={formValues.shift_slot}
                                disabled={loading}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Shift Slot" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value='All Day'>All Day</SelectItem>
                                    <SelectItem value='Morning'>Morning</SelectItem>
                                    <SelectItem value='Afternoon'>Afternoon</SelectItem>
                                    <SelectItem value='Evening'>Evening</SelectItem>
                                    <SelectItem value='Night'>Night</SelectItem>
                                </SelectContent>
                            </Select>
                             <p className='text-sm font-semibold mt-4'>Start time</p>
                            <Input 
                                placeholder='6:00 am'
                                type='time'
                                value={formValues.start_time}
                                onChange={(e) => {
                                    setFormValues({
                                        ...formValues,
                                        start_time: e.target.value
                                    })
                                }}
                                disabled={loading}
                            />
                        </div>
                    <DialogFooter>
                        <Button 
                            onClick={async () => {
                                try {
                                    setLoading(true);
                                    if (formValues.cash_drawer_amount === undefined || formValues.card_amount === undefined || !formValues.terminal_id || !formValues.shift_slot) {
                                        toast({
                                            title: "Error",
                                            description: "All fields are required",
                                            duration: 5000,
                                        })
                                        return;
                                    }
                                    const res = await startShift(formValues.cash_drawer_amount!, formValues.card_amount!, formValues.terminal_id, formValues.shift_slot, formValues.start_time)
                                    if (res) {
                                        toast({
                                            title: "Success",
                                            description: "Shift started successfully",
                                            duration: 5000,
                                        });
                                        setLoading(false);
                                        router.invalidate()
                                        page !== 'overview' ? setDisplaySalesComp(true) : null
                                        setValue(false)
                                    }
                                } catch (error) {
                                    console.error('Error:', error);
                                    toast({
                                        title: "Error",
                                        description: "An error occurred",
                                        duration: 5000,
                                    })
                                    setLoading(false);
                                } finally {
                                    setFormValues({
                                        cash_drawer_amount: undefined,
                                        card_amount: undefined,
                                        terminal_id: '',
                                        shift_slot: '',
                                        start_time: dayjs().format('HH:mm')
                                    })
                                }
                            }}
                            disabled={loading}
                        >
                            <Timer
                                size={20}
                                className='mr-2'
                            />
                            {loading ? "Please Wait..." : "Save and Start"}</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default StartShiftModal