import { httpClient } from "@/httpClient";
import getPharmacyID from "@/utils/pharmacy_code";
import { NonMedicalMasterSchema } from "./type";

export const getNonMedicalMasterItems = async (offset: number, limit: number, search: string): Promise<NonMedicalMasterSchema[]> => {
    const response = await httpClient.post(`${import.meta.env.VITE_API_URL as string}/public/pharmacy/non_medical_master/get/list?offset=${offset}&limit=${limit}&q=${search}`, {
        pharmacy_code: getPharmacyID(),
        is_active: true
    });
    return response.data.data as NonMedicalMasterSchema[];
};